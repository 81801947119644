import React from 'react'
import Skeleton from 'react-loading-skeleton'
import Row from 'layouts/row/row'

const TableSkeleton = ({classNames}) => {
  return (
    <div className={classNames || ''}>
        <div>
            <Skeleton width="100%" height={30} />
        </div>
        <Row classNames="mt-2" gap="2rem">
            <Skeleton containerClassName="flex-1" />
            <Skeleton containerClassName="flex-1" />
            <Skeleton containerClassName="flex-1" />
            <Skeleton containerClassName="flex-1" />
        </Row>
        <Row classNames="mt-2" gap="2rem">
            <Skeleton containerClassName="flex-1" />
            <Skeleton containerClassName="flex-1" />
            <Skeleton containerClassName="flex-1" />
            <Skeleton containerClassName="flex-1" />
        </Row>
        <Row classNames="mt-2" gap="2rem">
            <Skeleton containerClassName="flex-1" />
            <Skeleton containerClassName="flex-1" />
            <Skeleton containerClassName="flex-1" />
            <Skeleton containerClassName="flex-1" />
        </Row>
        <Row classNames="mt-2" gap="2rem">
            <Skeleton containerClassName="flex-1" />
            <Skeleton containerClassName="flex-1" />
            <Skeleton containerClassName="flex-1" />
            <Skeleton containerClassName="flex-1" />
        </Row>
    </div>
  )
}

export default TableSkeleton