import styles from './radio.module.scss';

const RadioInput = ({status, label, onClick, id}) => {
  return (
    <div className={styles.radio} onClick={onClick}>
        <input type='radio' checked={status} id={id} />
        <label for={id}>{label}</label>
    </div>
  )
}

export default RadioInput