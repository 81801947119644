import { useState, useEffect, useContext }              from "react";
import { useParams, useNavigate }           from "react-router-dom";
import { checkIfEmpty }                     from "functions/functions";
import { showErrorToast, showSuccessToast } from "functions/toasts";
import { useAuth } from "contexts/authContext";
import BreadCrumbContext from "contexts/breadcrumbContext";
import useApi                               from "hooks/useApi";
import Cookies from "js-cookie";

const useSiteDetails = () => {

    const [siteName, setSiteName]                   = useState('');
    const [siteAddress, setSiteAddress]             = useState('');
    const [multiTenant, setMultiTenant]             = useState(true);
    const [isActive, setIsActive]                   = useState(true);
    const [loading, setLoading]                     = useState(true);
    const [site, setSite]                           = useState([]);
    const [error, setError]                         = useState([]);
    const [errorMessages, setErrorMessages]         = useState([]);
    const [deleteModalStatus, setDeleteModalStatus] = useState(false);

    const {id} = useParams();
    const navigate = useNavigate();
    const {user, setUser, currentBuilding, setCurrentBuilding} = useAuth();

    const {
        setManagedSiteName
    } = useContext(BreadCrumbContext);

    console.log(user)

    const {get, post, del}  = useApi();


    useEffect(() => {
        fetchSiteData();
    }, []);


    // Fetch the specific site details
    const fetchSiteData = async() => {
        setLoading(true);

        try {
            const response = await get(`buildings/${id}`);
            const data = response.data.data;

            setSiteName(data.attributes.name || '');
            setSiteAddress(data.attributes.address || '');
            setMultiTenant(data.attributes.settings.has_multiple_tenants);
            setIsActive(data.attributes.is_active);

            setLoading(false);

            return data;
        } catch(err) {
            console.log(err)
        }
       
    }

    // Save Changes Fucntion
    const onSaveChanges = async () => {
        let trimmedSiteName = siteName.trim();
        let trimmedSiteAddress = siteAddress.trim();

        const errorObj = {
            siteName : false,
        }

        const errorMessageObj = {
            siteName : '',
        }

        if(checkIfEmpty(trimmedSiteName)) {
            errorObj.siteName = true;
            errorMessageObj.siteName = "Site Name is required";
        }

        if(!errorObj.siteName) {

            let data = {
                name : trimmedSiteName,
                is_active : isActive ? 1 : 0,
                address : trimmedSiteAddress,
                has_multiple_tenants : multiTenant ? 1 : 0
            }
            
            try {
                await post(`buildings/${id}`, data)
                
                const siteResult = await fetchSiteData();

                const userResponse = await get(`admin-user/${user.user_id}`);
                setUser(userResponse.data.data);

                
                if(currentBuilding.attributes.building_id == id) {
                    Cookies.set('currentBuilding', JSON.stringify(siteResult));
                    setCurrentBuilding(siteResult);
                }

                setManagedSiteName(trimmedSiteName);
                localStorage.setItem('managedSiteName', trimmedSiteName);
                showSuccessToast('Site Updated Successfully');

            } catch(err) {
                console.log(err);
                showErrorToast('Something Went Wrong');
            }
           
        }

        setError(errorObj);
        setErrorMessages(errorMessageObj)
    }


    // Delete Site Function
    const deleteSite = async () => {
        try {
            await del(`buildings/${id}`);

            const response = await get(`admin-user/${user.user_id}`);
            console.log(response)
            setUser(response.data.data);

            showSuccessToast('Site Deleted Successfully');
            navigate('/manage/sites');

        } catch(err) {
            showErrorToast('Something Went Wrong.')
        }
        
        setDeleteModalStatus(false)
    }

    return {
        siteName, setSiteName,
        siteAddress, setSiteAddress,
        multiTenant, setMultiTenant,
        isActive, setIsActive,
        loading, setLoading,
        site,setSite,
        onSaveChanges,
        error, setError,
        errorMessages, setErrorMessages,
        deleteSite,
        deleteModalStatus, setDeleteModalStatus,
        user
    }
}

export default useSiteDetails;