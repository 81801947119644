import { useState }     from "react";
import { checkIfEmpty } from "functions/functions";
import TextInput        from "components/inputs/textInput/textInput";
import Button           from "components/buttons/button";
import RSwitch          from "components/switch/switch";
import useApi           from "hooks/useApi";
import CustomModal      from "../modal"
import styles           from './addNewSiteModal.module.scss';


// Body Section of Modal
const Body = ({status, setStatus, error, errorMessages, siteName, setSiteName}) => {
    return (
        <div className={styles.bodyContainer}>
            <TextInput 
                value={siteName} 
                onChange={(e) => setSiteName(e.target.value)} 
                label="Site Name" 
                placeholder="Enter Site Name" 
                required
                isError={error.siteName}
                errorMessage={errorMessages.siteName} 
            />
            <RSwitch status={status} onChange={() => setStatus(!status)} label="Site has multiple tenants" />
        </div>
    )
}

// Footer Section of Modal
const Footer = ({buttonClick, siteAddLoading}) => {
    return (
        <div className={styles.footer}>
            <Button disabled={siteAddLoading} loading={siteAddLoading} loadingText="Adding Site" text="Add Site" onClick={buttonClick} />
        </div>
    )
}

// Add New Site Modal
const AddNewSiteModal = ({isModalOpen, closeModal, properties}) => {

    return (
        <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} width="50%" minHeight="50%">
            {{ 
                header : 'Add New Site',
                body :  <Body 
                            status              = {properties.multiTenant} 
                            setStatus           = {properties.setMultiTenant}
                            error               = {properties.error}
                            errorMessages       = {properties.errorMessages}
                            siteName            = {properties.siteName}
                            setSiteName         = {properties.setSiteName}
                            siteAddLoading      = {properties.siteAddLoading}
                            setSiteAddLoading   = {properties.setSiteAddLoading}
                        />,
                footer : <Footer 
                    buttonClick         = {properties.onButtonClick}
                    siteAddLoading      = {properties.siteAddLoading}
                    setSiteAddLoading   = {properties.setSiteAddLoading} 
                />
            }}
        </CustomModal>
    )
}

export default AddNewSiteModal