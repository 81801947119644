import { HexColorPicker }   from "react-colorful";
import { APP_CONFIG } from "constants/config";
import { getImageURL } from "functions/functions";
import DashboardLayout      from "layouts/dashboardLayout/dashboard.jsx";
import SubMenuLayout        from "layouts/subMenuLayout/subMenuLayout"
import TextInput            from "components/inputs/textInput/textInput";
import RadioInput           from "components/inputs/radio/radio";
import Button               from "components/buttons/button";
import DefaultBG            from 'assets/images/tab-default-bg.png';
import Template01           from 'assets/images/template-01.png';
import Template02           from 'assets/images/template-02.png';
import ColorWheel           from 'assets/images/color-wheel.png';
import RSwitch              from "components/switch/switch";
import useBranding          from "./useBranding";
import styles               from './branding.module.scss'
import CheckPermission from "components/checkPermission/checkPermission";
import Checkbox from "components/inputs/checkbox/checkbox";


const SiteBrandingScreen = () => {

    const {
        headline1,
        setHeadline1,
        headline2,
        setHeadline2,
        showLogo, 
        setShowLogo,
        alignContent, 
        setAlignContent,
        template, setTemplate,
        frosted, setFrosted,
        colorPickerRef,
        headline2ColorPickerRef,
        showColorPicker1,
        showColorPicker2,
        onColorWheel1Click,
        onColorWheel2Click,
        logo,
        backgroundImage,
        bgImage, setBgImage,
        saveBranding,
        inputFile,
        bgImageFile,
        onFileUploadClick,
        onBgFileUploadClick,
        onFileSelected,
        onBgImageSelected,
        updateWelcomeScreen,
        loading,
        preview,
        newImage,
        bgPreview, setBgPreview
    } = useBranding()

    const getColorValue = (obj) => {
        if(obj.color[0] === '#') {
            return obj.color.slice(1);
        }
        return obj.color;
    }

  return (
    <DashboardLayout>
        <SubMenuLayout subMenuType="site" currentMenu="Branding">
            <div className={styles.brandingContainer}>
                {!loading ?
                <div className={styles.settings}>
                    <div className={styles.siteLogo}>
                        <h3 className="gb_title">Site Logo</h3>
                        <p className="gb_subtitle">Update the site logo so that visitors would see your brand instead of the default logo.</p>

                        <div className={styles.logoImageContainer}>
                        {logo ? <img src={preview ? preview : getImageURL(logo)} alt="Logo" /> : preview ? <img src={preview} alt="Logo" /> : <p>Logo Not Available. Upload New Logo</p> }
                        </div>
                        <div className={styles.buttonContainer}>
                            <input
                                type="file"
                                accept="image/png, image/jpeg, image/jpg"
                                ref={inputFile}
                                style={{ display : 'none' }}
                                onChange={onFileSelected}
                            />
                            <CheckPermission permission="sites_add_edit">
                                <Button text="Upload New Logo" onClick={onFileUploadClick} backgroundColor = "#E6E6E6" color = "#4B4B4B" />
                                {newImage && <Button text="Update Logo" onClick={saveBranding} /> }
                            </CheckPermission>
                        </div>
                    </div>
                    <div className={styles.welcomeScreenContainer}>
                        <h3 className="gb_title">Welcome Screen</h3>
                        <p className="gb_subtitle">Customize the welcome screen that visitors see first.</p>

                        {/* Select Template Section */}
                        <div className={styles.templateContainer}>
                            <p>Select Welcome Screen Template</p>
                            <div className={styles.templatesContainer}>
                                <div className={styles.template}>
                                    <img src={Template01} onClick={() => setTemplate(1)} />
                                    <RadioInput id="1" label="Welcome Screen 1" status = {template === 1} onClick = {() => setTemplate(1)}  />
                                </div>
                                <div className={styles.template}>
                                    <img src={Template02} onClick={() => setTemplate(2)} />
                                    <RadioInput id="2" label="Welcome Screen 2" status = {template === 2} onClick = {() => setTemplate(2)}  />
                                </div>
                            </div>
                        </div>

                        {/* Headline 1 Section */}
                        <div className={styles.headlineContainer}>
                            {/* <Checkbox checked={headline1.show} onChange={() => setHeadline1({...headline1, show : !headline1.show})}  label="Show Healine 1" /> */}
                            
                            <div className={styles.headlineForm}>
                                <TextInput value={headline1.value} showError={false} onChange={(e) => setHeadline1({...headline1, value : e.target.value})} label="Headline 1" placeholder="Enter Headline 1" />
                                <div className={styles.colorContainer}>
                                    <p className={styles.label}>Select Color</p>
                                    <div className={styles.colorPicker}>
                                        <div className={styles.colorPreview} style={{ backgroundColor : headline1.color }} onClick={onColorWheel1Click}>
                                            <div className={styles.colorPickerInput} style={{ display : `${showColorPicker1 ? 'block' : 'none'}` }} ref={colorPickerRef}>
                                                <HexColorPicker color={headline1.color} onChange={(e) => setHeadline1({...headline1, color : e})} />
                                            </div>
                                        </div>
                                        <TextInput placeholder="Color Code" size="sm" prefix="#" value={getColorValue(headline1)} onChange={(e) => setHeadline1({...headline1, color : `#${e.target.value}`})} />
                                        {/* <div className={styles.picker}>
                                            <img src={ColorWheel} onClick={onColorWheel1Click} />
                                            
                                        </div> */}
                                        
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* Headline 2 Section */}
                        <div className={styles.headlineContainer}>
                            {/* <RSwitch status={headline2.show} onChange={() => setHeadline2({...headline2, show : !headline2.show})} label="Show Healine 2" /> */}
                            
                            <div className={styles.headlineForm}>
                                <TextInput value={headline2.value} onChange={(e) => setHeadline2({...headline2, value : e.target.value})} label="Headline 2" placeholder="Enter Headline 2" />
                                <div className={styles.colorContainer}>
                                    <p className={styles.label}>Select Color</p>
                                    <div className={styles.colorPicker}>
                                        <div className={styles.colorPreview} style={{ backgroundColor : headline2.color }} onClick={onColorWheel2Click}>
                                            <div className={styles.colorPickerInput} style={{ display : `${showColorPicker2 ? 'block' : 'none'}` }} ref={headline2ColorPickerRef}>
                                                <HexColorPicker color={headline2.color} onChange={(e) => setHeadline2({...headline2, color : e})} />
                                            </div>
                                        </div>
                                        <TextInput placeholder="Color Code" size="sm" prefix="#" value={getColorValue(headline2)} onChange={(e) => setHeadline2({...headline2, color : `#${e.target.value}`})} />
                                        {/* <div className={styles.picker}>
                                            <img src={ColorWheel} onClick={onColorWheel2Click} />
                                            <div className={styles.colorPickerInput} style={{ display : `${showColorPicker2 ? 'block' : 'none'}` }} ref={headline2ColorPickerRef}>
                                                <HexColorPicker color={headline2.color} onChange={(e) => setHeadline2({...headline2, color : e})} />
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* Site Logo Section */}
                        <div className={styles.showSiteLogoContainer}>
                            <RSwitch status={showLogo} onChange={() => setShowLogo(() => !showLogo)} label="Show Site Logo" />
                        </div>


                         {/* Align Content Section Section */}
                         <div className={styles.alignContentContainer}>
                            <p>Align Content</p>
                            <div className={styles.inputs}>
                                <RadioInput id="left" label="Left" status = {alignContent === 'left'} onClick = {() => setAlignContent('left')}  />
                                <RadioInput id="center" label="Center" status = {alignContent === 'center'} onClick = {() => setAlignContent('center')}  />
                                <RadioInput id="right" label="Right" status = {alignContent === 'right'} onClick = {() => setAlignContent('right')}  />
                            </div>
                         </div>


                         {/* Frosted Background Section */}
                        <div className={styles.showSiteLogoContainer}>
                            <RSwitch status={frosted} onChange={() => setFrosted(!frosted)} label="Frosted Background" />
                        </div>

                    </div>
                </div> : '' }
                {!loading ?
                <div className={styles.preview}>
                    <div className={styles.sticky}>
                        {/* TEMPLATE 1 PREVIEW */}
                        {template === 1 && <div className={styles.template1}>
                            <div className={`${styles.template1__header}`} style={{ 
                                    backgroundImage :  `url(${bgPreview || getImageURL(backgroundImage)})`, 
                                    alignItems : `${alignContent === 'left' ? 'flex-start' : alignContent === 'center' ? 'center' : 'flex-end'}`
                                }}>
                                {headline1.value ? <h4 className={styles.headline1} style={{ color : headline1.color || '' }}>{headline1.value}</h4> : ''}
                                {headline2.value ? <h3 className={styles.headline2} style={{ color : headline2.color || '' }}>{headline2.value}</h3> : ''}
                            </div>
                            <div className={styles.template1__footer}>
                                {showLogo && (logo || preview) ? <img src={preview ? preview : getImageURL(logo)} className={styles.logo} />  : ''}
                                <p className={styles.headline3}>Tap to Continue</p>
                            </div>
                        </div> }
                        {/* TEMPLATE 2 PREVIEW */}
                        {template === 2 && <div className={styles.tabletContainer} style={{ backgroundImage :  `url(${bgPreview || getImageURL(backgroundImage)})`, alignItems : `${alignContent === 'left' ? 'flex-start' : alignContent === 'center' ? 'center' : 'flex-end'}`}}>
                            {headline1.value ? <h4 className={styles.headline1} style={{ color : headline1.color || '' }}>{headline1.value}</h4> : ''}
                            {headline2.value ? <h3 className={styles.headline2} style={{ color : headline2.color || '' }}>{headline2.value}</h3> : ''}
                            {showLogo && logo ? <img src={preview ? preview : getImageURL(logo)} className={styles.logo} />  : ''}
                            <p className={styles.headline3}>Tap to Continue</p>
                        </div> }
                        
                        <CheckPermission permission="sites_add_edit">
                            <input
                                type="file"
                                accept="image/png, image/jpeg, image/jpg"
                                ref={bgImageFile}
                                style={{ display : 'none' }}
                                onChange={onBgImageSelected}
                            />
                            <Button text="Upload New Image" onClick={onBgFileUploadClick} backgroundColor="#E6E6E6" color="#4B4B4B" />
                            <Button text="Update Welcome Screen Changes" onClick={updateWelcomeScreen} />
                        </CheckPermission>
                    </div>
                </div> : '' }
            </div>
        </SubMenuLayout>
    </DashboardLayout>
  )
}

export default SiteBrandingScreen