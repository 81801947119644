import styles from './checkbox.module.scss';

const Checkbox = ({label, id, name, checked, onChange, disabled=false, classNames}) => {
  return (
    <>
        <label className={`${styles.container} ${classNames || ''}`}>{label}
            <input type="checkbox" disabled={disabled} checked={checked} id={id} name={name} onChange={onChange} />
            <span className={styles.checkmark}></span>
        </label>
    </>
  )
}

export default Checkbox