import useApi from "hooks/useApi";

const useTenantFunctions = () => {
    
    const {get, post} = useApi();

    // Get All Tenants of a Site
    const getAllTenants = async (site_id) => {
        try {
            const response = await get(`buildings/${site_id}/tenants`);
            const data = response.data.data;
            return data;
        } catch(err) {
            throw err;
        }
    }

    return {
        getAllTenants
    }

}

export default useTenantFunctions