import { useNavigate } from "react-router-dom";
import CustomModal from "../modal";
import Button from "components/buttons/button";
import Row from "layouts/row/row";
import useApi from "hooks/useApi";
import Lottie               from "lottie-react";
import { useAuth } from "contexts/authContext";
import axios from "axios";
import { showErrorToast } from "functions/toasts";
import Warning from 'assets/animations/warning.json';
import Verifying from 'assets/animations/verifying.json';
import Verified from 'assets/animations/verified.json';
import styles from './emailVerificationModal.module.scss'
import { useState } from "react";
import { useEffect } from "react";

const Body = ({
    verified, setVerified,
    verifying, setVerifying,
    showError, setShowError,
    verifyAccount,
}) => {
    return (
        <div className={styles.content}>
            {!verified ?
            <>
                <div className={styles.alertContainer}>
                    <Lottie animationData={Warning} loop={true} style={{ width : 70}} />
                    <p>We have sent a verification link to your registered email address. Please check your inbox and verify the email address to continue.</p>
                </div>
                <div className={styles.verificationContainer}>
                    <div className={styles.confirmVerification} onClick={verifyAccount}>
                        <p>Confirm Verification</p>
                    </div>
                    {verifying ?
                        <div className={styles.verifyingContainer}>
                            <Lottie animationData={Verifying} loop={true} style={{ width : 60}} />
                            <p>Checking you account details...</p>
                        </div> : 
                    '' }
                </div>
                {showError ?
                    <div className={styles.failedVerification}>
                        <p>It seems like account is not yet verified. Please try again.</p>
                    </div> : 
                '' }
            </> :
            <div className={styles.verifiedContainer}>
                <Lottie animationData={Verified} loop={true} style={{ width : 120}} />
                <p>Great! Account Verified Successfully</p>
            </div> }
        </div>
    )
}

const Footer = () => {

    return (
        <Row justify="flex-end">
            {/* <Button text="Logout" /> */}
        </Row>
    )
}

const EmailVerificationModal = ({isModalOpen, closeModal}) => {
    const [verified, setVerified] = useState(false);
    const [verifying, setVerifying] = useState(false);
    const [showError, setShowError] = useState(false);

    const {user, setUser, setEmailVerified} = useAuth();
    const {get} = useApi();

    useEffect(() => {
        if(verified) {
            const verificationTimeOut = setTimeout(() => {
                setEmailVerified(true);
            }, 3000);

            // Cleanup the timeout to avoid memory leaks
            return () => clearTimeout(verificationTimeOut);
        }

    },[verified]);

    // Checking User Account
    const verifyAccount = async () => {
        setVerifying(true);

        try {
            const response = await get(`admin-user/${user.user_id}`);
            const data = response.data.data;

            if(data.email_verified_at) {
                // setEmailVerified(true);
                setUser(user);
                setVerified(true);
            } else {
                setShowError(true);
            }
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }

        setVerifying(false);
        
    }

    return (
        <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} width="40%" minHeight="35%" >
            {{ 
                header : 'Email Verification Required',
                body :  <Body 
                            verified={verified} setVerified={setVerified}
                            verifying={verifying} setVerifying={setVerifying}
                            showError={showError} setShowError={setShowError}
                            verifyAccount={verifyAccount}
                        />,
                footer : <Footer />
            }}
        </CustomModal>
    )
}

export default EmailVerificationModal