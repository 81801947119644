import {useState, useEffect, useRef }   from 'react';
import { useParams }            from 'react-router-dom';
import useApi                   from 'hooks/useApi';
import axios                            from "axios";
import { APP_CONFIG } from "constants/config";
import { checkIfEmpty } from 'functions/functions';
import { showErrorToast, showSuccessToast }       from 'functions/toasts';

const useHealthSafety = () => {

    const [showMessage, setShowMessage]                 = useState(false);
    const [actionRequired, setActionRequired]           = useState(false);
    const [signatureRequired, setSignatureRequired]     = useState(false);
    const [isLoading, setLoading]                       = useState(true);
    const [attachedFile, setAttachedFile]           = useState('');
    const [error, setError]                             = useState('');
    const [errorMessages, setErrorMessages]             = useState('');
    const [showErrorAlert, setShowErrorAlert]       = useState(false);
    const [message, setMessage]                         = useState('');

    const { get, post } = useApi();
    const { id, tenantId } = useParams();

    const attachmentFile = useRef(null);


    useEffect(() => {
        fetchHealthSafety();
    },[]);

    // Fetch Health & Safety Data
    const fetchHealthSafety = async () => {
        setLoading(true);

        try {
            const response = await get(`tenants/${tenantId}`);
            const data = response.data.data.attributes;

            setShowMessage(data.show_health_and_safety_message);
            setActionRequired(data.visitor_action_required);
            setSignatureRequired(data.visitor_signature_required);
            setMessage(data.additional_health_and_safety_requirements);
            setAttachedFile(data.attachment_url);

            setLoading(false);
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }

    // Update Health & Safety Message Options
    const onSaveChanges = async () => {

        setLoading(true);

        const trimmedMessage = message ? message.trim() : '';

        let errorObj = {
            message : false,
            data : false
        }

        let errorMessagesObj = {
            message : '',
            data : ''
        }

        if(showMessage) {
            if(checkIfEmpty(trimmedMessage) && !attachedFile) {
                errorObj.data = true;
                setShowErrorAlert(true);
            }
        }

        setError(errorObj);
        setErrorMessages(errorMessagesObj);

        if(!errorObj.data) {
            try {
                const formData = new FormData();
                
                formData.append("attachment", attachedFile);
                formData.append("show_health_and_safety_message", showMessage ? 1 : 0);
                formData.append("visitor_signature_required", signatureRequired ? 1 : 0);
                formData.append("additional_health_and_safety_requirements", message || ' ');

                const config = {
                    headers : {'Content-Type' : 'multipart/form-data'},
                    withCredentials : true
                }

                await axios.post(`${APP_CONFIG.BASE_URL}tenants/${tenantId}`, formData, config);

                // await post(`tenants/${tenantId}`, {
                //     show_health_and_safety_message : showMessage ? 1 : 0,
                //     visitor_action_required : actionRequired ? 1 : 0,
                //     visitor_signature_required : signatureRequired ? 1 : 0,
                //     additional_health_and_safety_requirements : message,
                // })
                
                await fetchHealthSafety();
                showSuccessToast('Updated Successfully');
                setShowErrorAlert(false);

            } catch(err) {
                showErrorToast('Something Went Wrong');
            } finally {
                setLoading(false)
            }
        } else {
            setLoading(false)
        }

    }

    const attachmentUploadClick = () => {
        attachmentFile.current.click();
    }

    const onFileSelected = (e) => {
        console.log(e);
        console.log('here');
        const file = e.target.files[0];
        if (file) {
            const extension = file.name.split('.').pop().toLowerCase();
            const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
            if (['jpeg', 'png', 'jpg', 'pdf'].includes(extension) && fileSizeInMB <= 5) {
                console.log(file);
                setAttachedFile(file);
                // const objectUrl = URL.createObjectURL(file);
                // setPreview(objectUrl);
            } else {
                let errorMessage = 'Please select a ';
  
                if (!['jpeg', 'png', 'jpg', 'pdf'].includes(extension)) {
                  errorMessage += 'JPEG, PNG, JPG or PDF';
                }
  
                if (fileSizeInMB > 5) {
                  errorMessage += 'file less than 5 MB';
                }
  
                alert(errorMessage);
  
                // setShowError(true);
                // setErrorMessage(errorMessage)
  
                // Display error message or handle invalid file type
                // alert('Please select a JPEG, PNG, or JPG image.');
                // Clear the file input
                e.target.value = null;
            }
        }
    };

    const onFileRemoved = () => {
        setAttachedFile(null);
        attachmentFile.current.value = '';
    }


    return {
        showMessage, setShowMessage,
        message, setMessage,
        actionRequired, setActionRequired,
        signatureRequired, setSignatureRequired,
        onSaveChanges,
        attachmentUploadClick,
        onFileRemoved,
        onFileSelected,
        attachedFile, setAttachedFile,
        attachmentFile,
        error, setError,
        errorMessages, setErrorMessages,
        showErrorAlert, setShowErrorAlert,
        isLoading, setLoading
    }
}

export default useHealthSafety;