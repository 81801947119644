import { useState, useEffect } from "react";
import useApi from "hooks/useApi";
import { useParams }            from "react-router-dom";
import moment from "moment/moment";
import { showSuccessToast, showErrorToast } from "functions/toasts";

const useSettings = () => {

    const [siteSignOutPolicy, setSiteSignOutPolicy]     = useState(true);
    const [siteBadgePolicy, setSiteBadgePolicy]         = useState(true);
    const [deliveriesStatus, setDeliveriesStatus]       = useState(false);
    const [buddyAppStatus, setBuddyAppStatus]           = useState(false);
    const [signOutStatus, setSignOutStatus]             = useState(false);
    const [signOutTime, setSignOutTime]                 = useState('');
    const [badgePrintingStatus, setBadgePrintingStatus] = useState(false);
    const [error, setError]                             = useState('');
    const [errorMessage, setErrorMessage]               = useState('');
    const [selectedBadge, setSelectedBadge]             = useState('template-01');
    const [loading, setLoading]                         = useState(true);

    const {id, tenantId} = useParams();

    const { get, post } = useApi();

    const moment = require('moment');

    useEffect(() => {
        fetchTenantSettings()
    }, []);


    const fetchTenantSettings = async () => {
        setLoading(true);

        try {
            const response = await get(`tenants/${tenantId}`);
            const data = response.data.data.attributes;

            console.log(data);

            // setBuddyAppStatus(data.buddy_app_enabled);
            // setDeliveriesStatus(data.deliveries_enabled);
            setSiteSignOutPolicy(data.follow_site_signout_policy);
            setSiteBadgePolicy(data.follow_site_badge_printing_policy);
            setBadgePrintingStatus(data.print_badge_on_visitor_signin);
            setSignOutStatus(data.sign_out_all);
            setSignOutTime(data.default_signout_time || '12:00');

            if(data.print_badge_on_visitor_signin) {
                setSelectedBadge(data.badge_template);
            }


            setLoading(false);

        } catch(err) {
            console.log(err);
        }
    }

    const saveChanges = async () => {
        
        const inputTime = signOutTime ? moment(signOutTime, "HH:mm:ss").format("HH:mm") : '';

        console.log(inputTime);

        let errorObj = {
            time : false
        }

        let errorMessageObj = {
            time : ''
        }

        if(signOutStatus) {
            if(!signOutTime || signOutTime == '' || signOutTime == null) {
                errorObj.time = true;
                errorMessageObj.time = 'Enter a valid time in 24hr format';
            }
        }

        console.log(siteSignOutPolicy, siteBadgePolicy, signOutStatus, signOutTime, badgePrintingStatus)

        if(!errorObj.time) {
            try {

                const data = {
                    follow_site_signout_policy : siteSignOutPolicy ? 1 : 0,
                    sign_out_all : signOutStatus ? 1 : 0,
                    default_signout_time : signOutTime,
                    follow_site_badge_printing_policy : siteBadgePolicy ? 1 : 0,
                    print_badge_on_visitor_signin : badgePrintingStatus ? 1 : 0,
                }

                if(badgePrintingStatus) {
                    data['badge_template'] = selectedBadge;
                }

                if(signOutStatus) {
                    data['signout_time'] = inputTime
                }

                await post(`tenants/${tenantId}`, data);
                
                await fetchTenantSettings();
                showSuccessToast('Site Updated Successfully');

            } catch(err) {
                showErrorToast('Something Went Wrong');
            }
        }

        setError(errorObj);
        setErrorMessage(errorMessageObj);
        
    }
    

    return {
        siteSignOutPolicy, setSiteSignOutPolicy,
        siteBadgePolicy, setSiteBadgePolicy,
        deliveriesStatus, setDeliveriesStatus,
        buddyAppStatus, setBuddyAppStatus,
        signOutStatus, setSignOutStatus,
        signOutTime, setSignOutTime,
        badgePrintingStatus, setBadgePrintingStatus,
        error, setError,
        errorMessage, setErrorMessage,
        selectedBadge, setSelectedBadge,
        loading, setLoading,
        saveChanges
    }

}

export default useSettings