import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate }           from "react-router-dom";
import useApi                               from "hooks/useApi";
import axios from "axios";
import { showSuccessToast, showErrorToast } from "functions/toasts";
import { APP_CONFIG } from "constants/config";


const useBranding = () => {

    const [loading, setLoading] = useState(true);
    const [logoUrl, setLogoUrl] = useState('');
    const [newImage, setNewImage] = useState('');
    const [preview, setPreview] = useState('');
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')

    const inputFile = useRef(null);

    const {id, tenantId} = useParams();

    const navigate = useNavigate();

    const {get, post, del}  = useApi();

    useEffect(() => {
        fetchTenantDetails();
    }, []);

    const fetchTenantDetails = async () => {
        setLoading(true);

        try {
            const response = await get(`tenants/${tenantId}`);
            const data = response.data.data.attributes;

            setLogoUrl(data.logo_url);

            setLoading(false);
        } catch(err) {
            console.log(err)
        }
    }

    // const onFileSelected = (e) => {
    //     console.log(e.target.files[0])
    //     setNewImage(e.target.files[0])

    //     const objectUrl = URL.createObjectURL(e.target.files[0]);

    //     setPreview(objectUrl)
    // }

    const onFileSelected = (e) => {
      const file = e.target.files[0];
      if (file) {
          const extension = file.name.split('.').pop().toLowerCase();
          const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
          if (['jpeg', 'png', 'jpg'].includes(extension) && fileSizeInMB <= 2) {
              console.log(file);
              setNewImage(file);
              const objectUrl = URL.createObjectURL(file);
              setPreview(objectUrl);
          } else {
              let errorMessage = 'Please select a ';

              if (!['jpeg', 'png', 'jpg'].includes(extension)) {
                errorMessage += 'JPEG, PNG, or JPG ';
              }

              if (fileSizeInMB > 2) {
                errorMessage += 'file less than 2 MB';
              }

              setShowError(true);
              setErrorMessage(errorMessage)

              // Display error message or handle invalid file type
              // alert('Please select a JPEG, PNG, or JPG image.');
              // Clear the file input
              e.target.value = null;
          }
      }
  };
    
    const onFileUploadClick = () => {
        inputFile.current.click();
    }

    const saveChanges = async () => {
        try {
            const formData = new FormData();
            if (newImage) {
              formData.append("logo", newImage);
            } else {
              console.error("Logo is undefined or null");
              return; // Exit the function if logo is not set
            }
  
            console.log(formData);
  
            const config = {
              headers : {'Content-Type' : 'multipart/form-data'},
              withCredentials : true
            }
  
            await axios.post(`${APP_CONFIG.BASE_URL}tenants/${tenantId}`, formData, config);

            setNewImage('');
            setPreview('');
            
            fetchTenantDetails();
            showSuccessToast('Logo Updated Successfully');


          } catch(err) {
            console.log(err);
            showErrorToast('Something Went Wrong');
          }
    }

    return {
        loading,
        inputFile,
        onFileUploadClick,
        newImage, setNewImage,
        logoUrl,
        onFileSelected,
        preview,
        saveChanges,
        errorMessage, showError
    }
}

export default useBranding;