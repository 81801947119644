import { useState, useEffect } from "react";
import useApi from "hooks/useApi";
import { checkIfEmpty } from "functions/functions";
import { showErrorToast, showSuccessToast } from "functions/toasts";

const useVisitorDetailsModal = (visitor_id) => {

    const [note, setNote]       = useState('');
    const [notes, setNotes]     = useState([]);
    const [loader, setLoader]   = useState({
        notes : true
    });
    const [error, setError] = useState([]);
    const [errorMessage, setErrorMessage] = useState([]);

    const {get, post} = useApi();

    useEffect(() => {
        fetchVisitorNotes()
    }, []);

    const fetchVisitorNotes = async () => {
        try {
            const response = await get(`visitor-notes/${visitor_id}`);
            setNotes(response.data.data);
            setLoader({
                ...loader,
                notes : false
            })
        } catch(err) {  
            console.log(err);
        }
    }

    const onNoteAdded = async (e) => {
        e.preventDefault();

        let trimmedNote = note.trim();
        
        let errorObj = {
            'note' : false
        }

        let errorMessageObj = {
            'note' : false
        }
        if(checkIfEmpty(trimmedNote)) {
            errorObj.note = true;
            errorMessageObj.note = "Please add a note"
        } else if(trimmedNote.length > 250) {
            errorObj.note = true;
            errorMessageObj.note = "Maximum character limit is 250 characters"
        }

        if(!errorObj.note) {
            try {
                let data = {
                    'note' : trimmedNote
                };

                const response = await post(`visitor-notes/${visitor_id}`, data);
                let addedNote = {
                    'note' : response.data.data.note,
                    'user' : response.data.data.user,
                    'created_at' : response.data.data.created_at
                }

                setNotes([
                    ...notes, 
                    addedNote
                ])
                
                setNote('');
                showSuccessToast('Note Added Successfully');

            } catch(err) {
                showErrorToast('Something Went Wrong');
            }
            

        }

        setError(errorObj);
        setErrorMessage(errorMessageObj);
    }

    return {
        loader,
        notes,
        note, setNote,
        onNoteAdded,
        error, errorMessage
    }
}

export default useVisitorDetailsModal;