import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomModal from "../modal";
import Button from "components/buttons/button";
import Row from "layouts/row/row";
import RSwitch from "components/switch/switch";
import useApi from "hooks/useApi";
import axios from "axios";
import DeleteRoleModal from "../deleteRoleModal/deleteRoleModal";
import { showErrorToast, showSuccessToast } from "functions/toasts";
import AddNewRoleModal from "../addNewRoleModal/addNewRoleModal";
import { useAuth } from "contexts/authContext";
import styles from './userTypeModal.module.scss';
import Checkbox from "components/inputs/checkbox/checkbox";
import TextInput from "components/inputs/textInput/textInput";
import { FEATURES } from "constants/features";

const Body = ({setAddUserModal, roles, setRoles, loading, showDeleteRoleModal, setShowRoleModal, closeDeleteModal, onDeleteClick, deleteRole}) => {

    const onRoleChange = (role, permission) => {
        
        let status = !role.permissions[permission];

        const updatedArray = roles.map((item) => {
            if(item.role_id === role.role_id) {
                return {
                    ...item,
                    permissions : {
                        ...item.permissions,
                        [permission] : status
                    }
                }
            }
            return item;
        });

        setRoles(updatedArray);
    }


    const standard_user_privileges = [
        'visitors_sign_in',
        'visitors_sign_out',
        'visitors_sign_out_all',
        'visitors_view_todays_visitors',
        'pre_reg_view',
        'pre_reg_add_new',
        'deliveries_show_page',
        'deliveries_add_new_delivery',
        'deliveries_assign_delivery',
    ];
    
    return (
        <>
        <div className="pad-x-2 mt-2">
            <p>Here you can set up different user roles and the permissions associated with those roles.</p>
            <Row classNames="mt-1" justify="flex-end">
                <Button text="Add New Role" onClick={()=> setAddUserModal(true)} />
            </Row>
            {!loading ?
            <div className={`${styles.rolesTable} mt-4`}>
                <div className={styles.stickySidebar}>
                    <div className={styles.corner}>
                        
                    </div>
                    {FEATURES.map((feature) => {
                        return (
                            <div className={styles.feature}>
                                <p className={styles.feature__header}>{feature.feature}</p>
                                {feature.permissions.map((permission) => {
                                    return (
                                        <div className={styles.feature__permission}>
                                            <p className={styles.feature__permission__line1}>{permission.label}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
                <div className={styles.roles}>
                    <div className={`${styles.permissions_role} ${styles.admin}`}>
                        <div className={styles.permissions_role__header}>
                            Administrator
                        </div>
                        <p style={{ opacity : 0 }}>Delete</p>
                        {FEATURES.map((feature) => {
                            return (
                                <div className={styles.permissions_role__section}>
                                    {feature.permissions.map((permission) => {
                                        return (
                                            <div className={styles.permissions_role__section__checkbox}>
                                                <Checkbox checked={true} />
                                            </div>
                                        )
                                    })}
                                    
                                </div>
                            )
                        })}
                    </div>
                    <div className={`${styles.permissions_role} ${styles.standard}`}>
                        <div className={styles.permissions_role__header}>
                            Standard User
                        </div>
                        <p style={{ opacity : 0 }}>Delete</p>
                        {FEATURES.map((feature) => {
                            return (
                                <div className={styles.permissions_role__section}>
                                    {feature.permissions.map((permission) => {
                                        return (
                                            <div className={styles.permissions_role__section__checkbox}>
                                                <Checkbox checked={standard_user_privileges.includes(permission.permission)} />
                                            </div>
                                        )
                                    })}
                                    
                                </div>
                            )
                        })}
                    </div>
                    {roles && roles.map((role) => {
                        return (
                        <div className={`${styles.permissions_role} ${styles.standard}`}>
                            <div className={styles.permissions_role__header}>
                                {role.name}
                            </div>
                            <p style={{ textAlign : 'center' }} onClick={() => onDeleteClick(role)}>Delete</p>
                            {FEATURES.map((feature) => {
                                return (
                                    <div className={styles.permissions_role__section}>
                                        {feature.permissions.map((permission) => {
                                            return (
                                                <div className={styles.permissions_role__section__checkbox}>
                                                    <Checkbox 
                                                        checked={role.permissions[permission.permission]} 
                                                        onChange={() => onRoleChange(role, permission.permission)} 
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div> 
                        )
                    })}
                    
                </div>
            </div> : '' }
        </div>
        {showDeleteRoleModal && <DeleteRoleModal isModalOpen={showDeleteRoleModal} closeModal={closeDeleteModal} deleteRole={deleteRole} /> }
        </>
    )
}

const Footer = ({saveChanges}) => {

    return (
        <Row justify="flex-end">
            <Button text="Save Changes" onClick={saveChanges} />
        </Row>
    )
}

const UserTypeModal = ({isModalOpen, closeModal}) => {
    const [addUserModal, setAddUserModal] = useState(false);
    const [roles, setRoles] = useState([]);
    const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false);
    const [selectedRole, setSelectedRole] = useState('');
    const [loading, setLoading] = useState(true);
    const [saveChangesLoading, setSaveChangesLoading] = useState(false);

    const { get, post, del } = useApi();
    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    },[]);

    const fetchData = async () => {
        setLoading(true);

        try {
            const response = await get(`roles`);
            const filteredRoles = response.data.data.filter((role) => role.name !== 'admin-user' && role.name !== 'standard-user');
            console.log(filteredRoles);
            const filteredRolesWithPermissions = filteredRoles.map((role) => {
                const permissions = FEATURES.reduce((acc, feature) => {
                  feature.permissions.forEach((permission) => {
                    acc[permission.permission] = role.permissions.includes(permission.permission);
                  });
                  return acc;
                }, {});
              
                return {
                  role_id: role.role_id,
                  name: role.name,
                  permissions: permissions,
                };
              });
            setRoles(filteredRolesWithPermissions);
            setLoading(false);
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }

    }

    const saveChanges = async () => {
        setSaveChangesLoading(true);
        roles.forEach(async (role) => {
            let permissions = [];
            for (const [permission, value] of Object.entries(role.permissions)) {
                console.log(permission, value)
                if(value) {
                    permissions.push(permission);
                }
            }
            
            let data = {
                permissions : permissions
            }

            console.log(data);

            try {
                await post(`roles/${role.role_id}`, data);
            } catch (err) {
                showErrorToast('Something Went Wrong');
            }
        });
        setSaveChangesLoading(false);
        window.location.reload();

    }

    const closeAddUserModal = async () => {
        await fetchData();
        setAddUserModal(false);
    }

    const onDeleteClick = (role) => {
        setSelectedRole(role);
        setShowDeleteRoleModal(true);
    }

    const deleteRole = async () => {
        setLoading(true);
        try {
            await del(`roles/${selectedRole.role_id}`);
            showSuccessToast('Role Deleted Successdfully');
            window.location.reload();
        } catch(err) {
            console.log(err);
            showErrorToast('Something Went Wrong');
        }
        

    }

    const closeDeleteModal = () => {
        setShowDeleteRoleModal(false);
    }

    return (
        <>
            <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} width="80%"  minHeight="85%" >
                {{ 
                    header : 'User Roles & Permissions',
                    body : <Body 
                                setAddUserModal={setAddUserModal} 
                                roles={roles} 
                                setRoles={setRoles}
                                showDeleteRoleModal={showDeleteRoleModal}
                                setShowDeleteRoleModal={setShowDeleteRoleModal}
                                closeDeleteModal={closeDeleteModal} 
                                onDeleteClick={onDeleteClick}
                                deleteRole={deleteRole}
                                loading={loading} 
                            />,
                    footer : <Footer saveChanges={saveChanges} />
                }}
            </CustomModal>
            {addUserModal && <AddNewRoleModal isModalOpen={addUserModal} closeModal={closeAddUserModal} />}
        </>
    )
}

export default UserTypeModal