import { useAuth } from "contexts/authContext"

const CheckPermission = ({children, permission}) => {
    const {user} = useAuth();

    return (
        <>
            {user.role.permissions.includes(permission) && children }
        </>
    )
    
    
}

export default CheckPermission