import { useState, useEffect }              from "react";
import { useParams, useNavigate }           from "react-router-dom";
import useApi                               from "hooks/useApi";
import { showSuccessToast, showErrorToast } from "functions/toasts";
import { checkIfEmpty, isValidEmail } from "functions/functions";

const useDetails = () => {

    const [tenantName, setTenantName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [deleteModalStatus, setDeleteModalStatus] = useState(false);
    const [error, setError] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);
    const [loading, setLoading] = useState(true);

    const {id, tenantId} = useParams();

    const navigate = useNavigate();

    const {get, post, del}  = useApi();

    useEffect(() => {
        fetchTenantDetails();
    }, []);

    const fetchTenantDetails = async () => {
        setLoading(true);

        try {
            const response = await get(`tenants/${tenantId}`);
            const data = response.data.data.attributes;

            console.log(data)

            setTenantName(data.name);
            setAddress(data.address);
            setEmail(data.email)
            setIsActive(data.is_active)

            setLoading(false);
        } catch(err) {
            console.log(err)
        }
    }

    const saveChanges = async () => {
        setLoading(true);

        let errorObj = {
            name : false,
            email : false
        }

        let errorMessageObj = {
            name : '',
            email : ''
        }

        if(checkIfEmpty(tenantName)) {
            errorObj.name = true;
            errorMessageObj.name = "Tenant Name is mandatory"
        }

        if(email && !isValidEmail(email)) {
            errorObj.email = true;
            errorMessageObj.email = "Enter a valid email"
        }

        if(!errorObj.name && !errorObj.email) {

            try {

                await post(`tenants/${tenantId}`, {
                    name : tenantName,
                    address : address,
                    email : email,
                    is_active : isActive ? 1 : 0
                })

                await fetchTenantDetails();
                showSuccessToast('Tenant Updated Successfully')            
                setLoading(false);


            } catch(err) {
                console.log(err);
                showSuccessToast('Something Went Wrong')
            }

        }

        setError(errorObj);
        setErrorMessages(errorMessageObj);

        setLoading(false);

    }

    // Delete Site Function
    const deleteTenant = async () => {
        try {
            const response = await del(`tenants/${tenantId}`);
            showSuccessToast('Tenant Deleted Successfully');
            navigate(`/manage/sites/${id}/tenants`);

        } catch(err) {
            showErrorToast('Something Went Wrong.')
        }
        
        // setDeleteModalStatus(false)
    }

    return {
        tenantName, setTenantName,
        email, setEmail,
        address, setAddress,
        isActive, setIsActive,
        saveChanges,
        deleteTenant,
        error, setError,
        errorMessages, setErrorMessages,
        deleteModalStatus, setDeleteModalStatus,
        loading, setLoading
    }
}

export default useDetails;