import { useState } from 'react';
import { APP_CONFIG } from '../constants/config';
import { useAuth } from 'contexts/authContext';
import axios from 'axios';

const useApi = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [apiLoading, setApiLoading] = useState(false);

  const {setIsAuthenticated} = useAuth();

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // Handle 401 error here
        // handleUnauthenticated();
        setIsAuthenticated(false);
        console.log('ERROR UNATH');
      }
      return Promise.reject(error);
    }
  );

  const makeRequest = async (url, method = 'get', data = null) => {
    setApiLoading(true);
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Accept': 'application/json',
      
    };

    const config = {
      method: method.toLowerCase(),
      url : `${APP_CONFIG.BASE_URL}${url}`,
      headers,
      withCredentials: true,
      data,
    };

    try {
      const response = await axios(config);
      return response;

    } catch (err) {
      if(err.response.status === 401) {
        console.log('Unauth');
      } else {
        console.log(err);
        throw err;
      }
      
    }

    setApiLoading(false);
  };

  return {
    data,
    error,
    apiLoading,
    setApiLoading,
    get: (url) => makeRequest(url, 'get'),
    post: (url, postData) => makeRequest(url, 'post', postData),
    put: (url, putData) => makeRequest(url, 'put', putData),
    del: (url) => makeRequest(url, 'delete'),
  };
};

export default useApi;
