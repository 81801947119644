import { useContext } from "react";
import { useNavigate }      from "react-router-dom";
import BreadCrumbContext from "contexts/breadcrumbContext";
import Skeleton             from "react-loading-skeleton";
import DashboardLayout      from "layouts/dashboardLayout/dashboard.jsx"
import SubMenuLayout        from "layouts/subMenuLayout/subMenuLayout";
import DeleteSiteModal      from "components/modal/deleteSiteModal/deleteSiteModal";
import PageHeader           from "components/pageHeader/pageHeader";
import TextInput            from "components/inputs/textInput/textInput"; 
import RSwitch              from "components/switch/switch";
import Col                  from "layouts/col/col";
import Button               from "components/buttons/button";
import useSiteDetails       from "./useSiteDetails";
import styles               from './details.module.scss';
import CheckPermission from "components/checkPermission/checkPermission";

const SiteDetailsScreen = () => {

    const {
        siteName, setSiteName,
        siteAddress, setSiteAddress,
        multiTenant, setMultiTenant,
        isActive, setIsActive,
        loading,
        onSaveChanges,
        error,
        errorMessages,
        deleteSite,
        deleteModalStatus, setDeleteModalStatus,
        user
    } = useSiteDetails();

    const navigate = useNavigate();

    const {
        managedSiteName, setManagedSiteName,
        managedSiteId, setManagedSiteId
    } = useContext(BreadCrumbContext);

    console.log(managedSiteId)

    return (
        <>
            <DashboardLayout>
                <SubMenuLayout subMenuType="site" currentMenu="Site Details">
                    {!loading ?
                    <div className={styles.siteDetailsForm}>
                        <TextInput 
                            label="Site Name" 
                            placeholder="Enter Site Name"
                            isError={error.siteName}
                            errorMessage={errorMessages.siteName}
                            value={siteName}
                            onChange={(e) => setSiteName(e.target.value)}
                            required 
                        />
                        <TextInput 
                            label="Site Address"  
                            placeholder="Enter Site Address"
                            value={siteAddress}
                            onChange={(e) => setSiteAddress(e.target.value)} 
                        />
                        <Col gap="3rem">
                            {/* <RSwitch 
                                status={multiTenant} 
                                onChange={() => setMultiTenant(!multiTenant)} 
                                label="Site has multiple tenants" 
                            /> */}
                            <RSwitch 
                                status={isActive} 
                                onChange={() => setIsActive(!isActive)} 
                                label="Site is Active" 
                            />
                        </Col>
                        <CheckPermission permission="sites_add_edit">
                            <div className={styles.bottomContainer}>
                                <div className={styles.buttons}>
                                    <Button disabled={user.approved_buildings.buildings.length < 2} text="Delete Site" backgroundColor="#FFEEEE" color="#B00000" onClick={() => setDeleteModalStatus(true)}  />
                                    <Button text="Save Changes" onClick={onSaveChanges} />
                                </div>
                            </div>
                        </CheckPermission>
                    </div> : 
                    <div>
                        <div>
                            <Skeleton width="20%" />
                            <Skeleton height={40} />
                        </div>
                        <div className="mt-2">
                            <Skeleton width="20%" />
                            <Skeleton height={40} />
                        </div>
                        <div className="mt-2">
                            <Skeleton width="20%" height={40} />
                        </div>
                        <div className="mt-2">
                            <Skeleton width="20%" height={40} />
                        </div>
                    </div>}
                    
                </SubMenuLayout>
            </DashboardLayout>

            {/* Delete Site Confirmation Modal */}
            <DeleteSiteModal isModalOpen={deleteModalStatus} closeModal={() => setDeleteModalStatus(false)} deleteSite={deleteSite} />
        </>
    )
}

export default SiteDetailsScreen