import { useState, useEffect, useRef } from "react"
import useApi from "hooks/useApi";
import { useParams }            from "react-router-dom";
import axios                            from "axios";
import { APP_CONFIG } from "constants/config";
import { checkIfEmpty } from "functions/functions";
import { showSuccessToast, showErrorToast } from "functions/toasts";

const useHealthSafety = () => {

    const [message, setMessage]                     = useState('');
    const [loading, setLoading]                     = useState(true);
    const [showMessage, setShowMessage]             = useState(true);
    const [signatureRequired, setSignatureRequired] = useState(true);
    const [isRequired, setIsRequired]               = useState(false);
    const [attachedFile, setAttachedFile]           = useState(''); 
    const [error, setError]                         = useState(false);
    const [showErrorAlert, setShowErrorAlert]       = useState(false);
    const [errorMessages, setErrorMessages]         = useState('');

    const {id} = useParams();

    const { get, post } = useApi();

    const attachmentFile = useRef(null);

    useEffect(() => {
        fetchHealthSafetyData();
    }, []);

    const fetchHealthSafetyData = async () => {
        try {
            const response = await get(`buildings/${id}`);
            const data = response.data.data;

            console.log(data);

            setMessage(data.attributes.settings.health_and_safety_message);
            setShowMessage(data.attributes.settings.show_health_and_safety_message);
            setSignatureRequired(data.attributes.settings.health_and_safety_signature_required);
            setIsRequired(data.attributes.settings.health_and_safety_message_required);
            setAttachedFile(data.attributes.settings.attachment_url);

            setLoading(false);

        } catch(err) {
            console.log(err);
        }
    }

    const onSaveChanges = async () => {

        setLoading(true);

        const trimmedMessage = message ? message.trim() : '';

        let errorObj = {
            message : false,
            data : false
        }

        let errorMessagesObj = {
            message : '',
            data : ''
        }

        if(showMessage) {
            if(checkIfEmpty(trimmedMessage) && !attachedFile) {
                errorObj.data = true;
                setShowErrorAlert(true);
            }
        }

        setError(errorObj);
        setErrorMessages(errorMessagesObj);
        
        if(!errorObj.data) {
            try {
                const formData = new FormData();

                // If the file is uploaded
                
                formData.append("attachment", attachedFile);
                formData.append("show_health_and_safety_message", showMessage ? 1 : 0);
                formData.append("health_and_safety_signature_required", signatureRequired ? 1 : 0);
                formData.append("health_and_safety_message", message || ''); 

                const config = {
                    headers : {'Content-Type' : 'multipart/form-data'},
                    withCredentials : true
                }

                await axios.post(`${APP_CONFIG.BASE_URL}buildings/${id}`, formData, config);

                // await post(`buildings/${id}`, {
                //     show_health_and_safety_message : showMessage ? 1 : 0,
                //     health_and_safety_message_required : isRequired ? 1 : 0,
                //     health_and_safety_signature_required : signatureRequired ? 1 : 0,
                //     health_and_safety_message : message,
                // })
                
                await fetchHealthSafetyData();
                showSuccessToast('Health & Safety Updated Successfully');
                setShowErrorAlert(false);

            } catch(err) {
                showErrorToast('Something Went Wrong');
            }
        }

        setLoading(false);

    }

    const attachmentUploadClick = () => {
        attachmentFile.current.click();
    }

    const onFileSelected = (e) => {
        console.log(e);
        console.log('here');
        const file = e.target.files[0];
        if (file) {
            const extension = file.name.split('.').pop().toLowerCase();
            const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
            if (['jpeg', 'png', 'jpg', 'pdf'].includes(extension) && fileSizeInMB <= 5) {
                console.log(file);
                setAttachedFile(file);
                // const objectUrl = URL.createObjectURL(file);
                // setPreview(objectUrl);
            } else {
                let errorMessage = 'Please select a ';
  
                if (!['jpeg', 'png', 'jpg', 'pdf'].includes(extension)) {
                  errorMessage += 'JPEG, PNG, JPG or PDF';
                }
  
                if (fileSizeInMB > 5) {
                  errorMessage += 'file less than 5 MB';
                }
  
                alert(errorMessage);
  
                // setShowError(true);
                // setErrorMessage(errorMessage)
  
                // Display error message or handle invalid file type
                // alert('Please select a JPEG, PNG, or JPG image.');
                // Clear the file input
                e.target.value = null;
            }
        }
    };

    const onFileRemoved = () => {
        setAttachedFile(null);
        attachmentFile.current.value = '';
    }

    return {
        message, setMessage,
        loading, setLoading,
        showMessage, setShowMessage,
        signatureRequired, setSignatureRequired,
        isRequired, setIsRequired,
        onSaveChanges,
        attachmentUploadClick,
        onFileRemoved,
        onFileSelected,
        attachedFile, setAttachedFile,
        attachmentFile,
        showErrorAlert, setShowErrorAlert,
        error, errorMessages
    }

}

export default useHealthSafety