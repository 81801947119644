import { useEffect, useState, useContext } from "react";
import { showErrorToast, showSuccessToast } from "functions/toasts";
import { AuthContext } from "contexts/authContext";
import useSiteFunctions from "functions/useSiteFunctions";
import CustomModal from "../modal"
import TextInput from "components/inputs/textInput/textInput";
import SelectInput from "components/inputs/selectInput/selectInput";
import UserTypeModal from "../userTypeModal/userTypeModal";
import RadioInput from "components/inputs/radio/radio";
import Button from "components/buttons/button";
import Checkbox from "components/inputs/checkbox/checkbox";
import Col from "layouts/col/col";
import Row from "layouts/row/row";
import useApi from "hooks/useApi";

import styles from './addNewUserModal.module.scss'
import { capitalizeWords, checkIfEmpty, isValidEmail } from "functions/functions";
import CheckPermission from "components/checkPermission/checkPermission";

const Body = (
    {
        name, setName,
        email, setEmail,
        role, setRole,
        password, setPassword,
        approvedSites, setApprovedSites,
        approvedSiteType, setApprovedSiteType,
        userTypeModalStatus, setUserTypeModalStatus,
        mode,
        loading,
        roles,
        sites
    }
) => { 

    return (
        <>
        <div style={{ paddingBottom : '7rem' }}>
            {!loading ?
            <Col gap="0.2rem">
                <TextInput label="Name" required placeholder="Enter Name" value={name} onChange={(e) => setName(e.target.value)} />
                <TextInput label="Email" disabled={mode==='edit'} required placeholder="Enter Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                <SelectInput label="User Role" disabled={role.value === 1} placeholder="Select Role" options={roles} required flex="1" defaultValue={role} onChange={(e) => setRole(e)} showError />
                {mode == "edit" && <TextInput label="Reset Password" placeholder="Enter New Password" value={password} onChange={(e) => setPassword(e.target.value)} /> }
                <div className={styles.approvedSites}>
                    <p>Approved Sites</p>
                    <Row gap="2rem" classNames="mt-2">
                        <Checkbox label="All Sites" checked={approvedSiteType === 'all'} onChange={() => setApprovedSiteType('all')} />
                        {role.value !== 1 && <Checkbox label="Custom Sites" checked={approvedSiteType === 'custom'} onChange={() => setApprovedSiteType('custom')} /> }
                    </Row>
                    {approvedSiteType === "all" &&
                    <div className={styles.buildingsContainer}>
                        {sites.map((site) => {
                            return (
                                <p>{site.label}</p> 
                            )
                        })} 
                    </div> }
                    {approvedSiteType == 'custom' &&
                    <div className="mt-2">
                        <SelectInput placeholder="Select Sites" multi={true} options={sites} defaultValue={approvedSites} onChange={(e) => setApprovedSites(e)} />
                    </div> }
                </div>
            </Col> : ''}
        </div>
        {userTypeModalStatus && <UserTypeModal isModalOpen={userTypeModalStatus} closeModal={() => setUserTypeModalStatus(false)} /> }
        </>
    )
}

const Footer = ({onSubmit, loading, mode}) => {
    return (
        <>
            {!loading ?
            <Row justify="flex-end">
                {mode === "add" ? 
                    <Button text='Add & Send Invitation' onClick={onSubmit} /> : ''
                }
                {mode === "edit" ?
                    <CheckPermission permission="portal_users_edit"> 
                        <Button text='Save Changes' onClick={onSubmit} />
                    </CheckPermission> : ''
                }
            </Row> : '' }
        </>
    )
}


const AddNewUserModal = ({isModalOpen, closeModal, mode, selectedUser}) => {

    const [roles, setRoles]                                 = useState([]);
    const [name, setName]                                   = useState('');
    const [email, setEmail]                                 = useState('');
    const [password, setPassword]                           = useState('');
    const [role, setRole]                                   = useState('');
    const [approvedSites, setApprovedSites]                 = useState('');
    const [sites, setSites]                                 = useState([]);
    const [approvedSiteType, setApprovedSiteType]           = useState('all');
    const [userTypeModalStatus, setUserTypeModalStatus]     = useState(false);
    const [loading, setLoading]                             = useState(true);

    const {get, post, put} = useApi();

    const {user} = useContext(AuthContext);
    const { getAllAccountSites } = useSiteFunctions();

    useEffect(() => {
        fetchData();
    }, []);


    const fetchData = async () => {

        try {

            await Promise.all([fetchUserRoles(), fetchSites()]);

            if(mode === 'edit') {
                console.log(selectedUser);
                setName(selectedUser.name);
                setEmail(selectedUser.email);
            }

        } catch(err) {
            showErrorToast('Error in loading data');
            closeModal();
        }
        
    }

    // Fetch Sites
    const fetchSites = async () => {
        setLoading(true)
        try {
            const siteResponse = await getAllAccountSites();
            const siteOptions = siteResponse.map((site) => {
                return {
                    label : site.attributes.name,
                    value : site.attributes.building_id
                }
            });

            if(mode === 'edit') {

                if(selectedUser.approved_buildings?.privilege == 'all') {
                    setApprovedSiteType('all');
                } else {
                    setApprovedSiteType('custom');
                    console.log(siteOptions)
                    let approvedBuildingIds = selectedUser.approved_buildings.buildings.map((building) => building.attributes.building_id);
                    console.log(approvedBuildingIds);
                
                    let approvedSites = siteOptions.filter((site) => approvedBuildingIds.includes(site.value));

                    setApprovedSites(approvedSites);
                }

            }

            setSites(siteOptions);
            setLoading(false);
            
        } catch(err) {
            console.log(err);
        }
    }

    // Fetch User Roles
    const fetchUserRoles = async () => {
        setLoading(true);

        let defaultRoles = [
            {
                value : 1,
                name : 'owner',
                label : 'Owner'
            },
            {
                value : 2,
                name : 'admin-user',
                label : 'Admininstrator'
            },
            {
                value : 3,
                name : 'standard-user',
                label : 'Standard User'
            }
        ];

        // Hide Owner Option
        if(mode === 'add' || (mode === 'edit' && selectedUser.role.role_id !== 1)) {
            defaultRoles = defaultRoles.filter((role) => {
                return role.value !== 1
            });
        };



        try {
            const response = await get(`roles`);
            let data = response.data.data;

            
            // Adding Label and Value Keys for the Select Input
            data = data.map((role) => {
                return {
                    ...role,
                    value : role.role_id,
                    label : capitalizeWords(role.display_name)
                }
            })

            const options = [...defaultRoles, ...data];
            

            setRoles(options);

            // If in edit mode
            if(mode === 'edit') {
                let userRole = options.filter((role) => role.value === selectedUser.role.role_id)[0];
                console.log(userRole)
                setRole(userRole)
            }

            setLoading(false);

        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }

    const onSubmit = async () => {
        setLoading(true);

        let errorObj = {
            name : false,
            email : false,
            customSites : false
        }

        let errorMessageObj = {
            name : '',
            enail : '',
            customSites : ''
        }

        if(checkIfEmpty(name)) {
            errorObj.name = true;
            errorMessageObj.name = 'Name is mandatory';
        }

        if(checkIfEmpty(email)) {
            errorObj.email = true;
            errorMessageObj.name = 'Email is mandatory';
        } else if(!isValidEmail(email)) {
            errorObj.email = true;
            errorMessageObj.name = 'Please enter a valid email';
        }

        let approvedSitesIds = [];

        if(approvedSiteType == 'custom') {
            if(approvedSites.length === 0) {
                errorObj.customSites = true;
                errorMessageObj.customSites = "Please select atleast one site";
            } else {
                approvedSites.forEach(site => {
                    approvedSitesIds.push(site.value)
                });
            } 
        } else {
            approvedSitesIds.push('all');
        }

        console.log(approvedSitesIds);

        if(!errorObj.name && !errorObj.email) {
            let data; 
            if(mode === 'add') {
                data = {
                    name : name,
                    email_id : email,
                    user_type_id : role.value,
                    approved_buildings : JSON.stringify(approvedSitesIds)
                }
            } else if(mode === 'edit') {
                data = {
                    name : name,
                    approved_buildings : JSON.stringify(approvedSitesIds),
                    role : role.name,
                }
                if(password) {
                    data['password'] = password
                }
            }

            try {
                mode === 'add' ? await post(`account/${user.account_id}/user`, data) : await post(`admin-user/${selectedUser.user_id}`, data);
                showSuccessToast(mode === 'add' ? 'User Invited Successfully' : 'User Updated Successfully');
                closeModal();

            } catch(err) {
                if(err.response.status === 406) {
                    if(err.response.data.message == "user_already_exists") {
                        showErrorToast('User Already Registered.');
                    } else if(err.response.data.message == "user_already_invited") {
                        showErrorToast('User Already Invited.');
                    }
                } else showErrorToast('Something Went Wrong');
            }
            
        }

        setLoading(false)
    }

  return (
    <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} width="50%">
        {{ 
            header  : mode === 'add' ? 'Add New User' : 'Edit User',
            body    : <Body 
                        name={name} setName={setName}
                        email={email} setEmail={setEmail}
                        role={role} setRole={setRole}
                        sites={sites}
                        password={password} setPassword={setPassword}
                        approvedSites={approvedSites} setApprovedSites={setApprovedSites}
                        approvedSiteType={approvedSiteType} setApprovedSiteType={setApprovedSiteType}
                        userTypeModalStatus={userTypeModalStatus} setUserTypeModalStatus={setUserTypeModalStatus}
                        mode={mode}
                        loading={loading}
                        roles={roles}
                    />,
            footer  : <Footer onSubmit={onSubmit} loading={loading} mode={mode} />
        }}
    </CustomModal>
  )
}

export default AddNewUserModal