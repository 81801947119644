import { useState }                                             from "react";
import { useNavigate }                                          from "react-router-dom";
import { showErrorToast, showSuccessToast }                     from "functions/toasts";
import { checkIfEmpty, isValidEmail, checkPasswordComplexity }  from "functions/functions";
import { useAuth }                                              from "contexts/authContext";
import axios                                                    from "axios";
import { APP_CONFIG } from "constants/config";
import Cookies from "js-cookie";



// Custom Hook for Register Screen
const useRegister = () => {

    const [step, setStep]                           = useState(1);
    const [name, setName]                           = useState('')
    const [email, setEmail]                         = useState('');
    const [password, setPassword]                   = useState('');
    const [confirmPassword, setConfirmPassword]     = useState('');
    const [code, setCode]                           = useState('');
    const [error, setError]                         = useState('');
    const [errorMessages, setErrorMessages]         = useState('');
    const [passwordStatus, setPassordStatus]        = useState('');
    const [loading, setLoading]                     = useState(false);

    const navigate = useNavigate();

    const {setIsAuthenticated, setEmailVerified, setEmailVerificationModal, setUser, setApprovedBuildings, setCurrentBuilding} = useAuth();


    const onEmailBlur = (e) => {
        const trimmedEmail = e.target.value.trim();

        const errorObj = {
            email           : false
        }

        const errorMessageObj = {
            email           : ''
        }

        if(!isValidEmail(trimmedEmail)) {
            errorObj.email = true;
            errorMessageObj.email = 'Enter a valid email address'
        }

        setError(errorObj);
        setErrorMessages(errorMessageObj)

    }

    const onPasswordChange = (e) => {
        setPassword(e.target.value);
    
        const errorObj = {
            password           : false
        }

        const errorMessageObj = {
            password           : ''
        }

        const passwordComplexity = checkPasswordComplexity(password);

        if(passwordComplexity.status == false) {
            errorObj.password = true;
            errorMessageObj.password = 'Password is not strong enough'
        }

        setPassordStatus(passwordComplexity);

        setError(errorObj);
        setErrorMessages(errorMessageObj)
        
    }


    const onPasswordBlur = (e) => {
        const password = e.target.value;

        const errorObj = {
            password           : false
        }

        const errorMessageObj = {
            password           : ''
        }

        const passwordComplexity = checkPasswordComplexity(password);

        if(passwordComplexity.status == false) {
            errorObj.password = true;
            errorMessageObj.password = 'Password is not strong enough'
        }

        setPassordStatus(passwordComplexity);

        setError(errorObj);
        setErrorMessages(errorMessageObj)
    }

    // Register Input Validation Function
    const onNextClick = (e) => {

        e.preventDefault();

        // Removing spaces from the input at the end
        let trimmedName             = name.trim();
        let trimmedEmail            = email.trim();
        let trimmedPassword         = password.trim();
        let trimmedConfirmPassword  = confirmPassword.trim();

        // Initialising Error Object Values
        const errorObj = {
            name            : false,
            email           : false,
            password        : false,
            confirmPassword : false
        }

        const errorMessageObj = {
            name            : '',
            email           : '',
            password        : '',
            confirmPassword : ''
        }

        // Validating Email
        if(checkIfEmpty(trimmedName)) {
            errorObj.name = true;
            errorMessageObj.name = 'Full Name is mandatory'
        }

        // Validating Email
        if(checkIfEmpty(trimmedEmail)) {
            errorObj.email = true;
            errorMessageObj.email = 'Email Address is mandatory'
        } else if(!isValidEmail(trimmedEmail)) {
            errorObj.email = true;
            errorMessageObj.email = 'Enter a valid email address'
        }

        const passwordComplexity = checkPasswordComplexity(password);
        
        // Validating Password
        if(checkIfEmpty(trimmedPassword)) {
            errorObj.password = true;
            errorMessageObj.password = 'Password is mandatory'
        } else if(passwordComplexity.status == false) {
            errorObj.password = true;
            errorMessageObj.password = 'Password is not strong enough'
        }

        setPassordStatus(passwordComplexity);

        // Validating Confirm Password
        if(checkIfEmpty(trimmedConfirmPassword)) {
            errorObj.confirmPassword = true;
            errorMessageObj.confirmPassword = 'Confirm Password is mandatory'
        } else if(trimmedConfirmPassword !== trimmedPassword) {
            errorObj.confirmPassword = true;
            errorMessageObj.confirmPassword = 'Passwords do not match'
        }

        // If all inputs are valid
        if(!errorObj.name && !errorObj.email && !errorObj.password && !errorObj.confirmPassword) {
            // setStep(2)
            const data = {
                name : trimmedName,
                email : trimmedEmail,
                password : trimmedPassword,
                password_confirmation : trimmedConfirmPassword
            }
            registerUser(data);
        }

        // Setting Error Values
        setError(errorObj);
        setErrorMessages(errorMessageObj)
    }

    // Register Function
    const onCreateAccountClick = (e) => {
        e.preventDefault();

        // Removing spaces from the input at the end
        let trimmedCode = code.trim();

        const errorObj = {
            code : false,
        }

        const errorMessageObj = {
            code : '',
        }

        // Validating Email
        if(checkIfEmpty(trimmedCode)) {
            errorObj.code = true;
            errorMessageObj.code = 'Verification Code is mandatory'
        }

        if(!errorObj.code) {
            navigate('/');
        }

        setError(errorObj);
        setErrorMessages(errorMessageObj);

    }


    const registerUser = async (data) => {

        setLoading(true);

        const headers = {
            'Content-Type': 'application/json',
            'Accept' : 'application/json'
        }
        
        try {

            const result = await axios.post(`${APP_CONFIG.BASE_URL}register`, data, {headers, withCredentials : true});
            console.log('REGISTER',result);
            if(result.status === 201) {
                setIsAuthenticated(true);
                setEmailVerified(false);
                setUser(result.data.data);
                setApprovedBuildings(result.data.data.approved_buildings.buildings);
                setCurrentBuilding(result.data.data.approved_buildings.buildings[0]);
                Cookies.set('currentBuilding', JSON.stringify(result.data.data.approved_buildings.buildings[0]));
                setEmailVerificationModal(true);
                showSuccessToast('Registered Successfully');
                navigate('/dashboard');
            }

            setLoading(false)

        } catch(err) {
            console.log(err);

            const errorObj = {
                name : false,
                email : false,
            }
    
            const errorMessageObj = {
                name : '',
                email : '',
            }

            if(err.response.status === 422) {
                
                if(err.response.data.errors.name) {
                    errorObj.name = true;
                    errorMessageObj.name = err.response.data.errors.name[0];
                }

                if(err.response.data.errors.email) {
                    errorObj.email = true;
                    errorMessageObj.email = err.response.data.errors.email[0];
                }

                setError(errorObj);
                setErrorMessages(errorMessageObj);
            } else {
                showErrorToast('Something Went Wrong');
            }

            setLoading(false)
        }
    }


    // Returning Hook Values
    return {
        step,
        setStep,
        name,
        setName,
        onEmailBlur,
        email,
        setEmail,
        password,
        setPassword,
        onPasswordChange,
        onPasswordBlur,
        passwordStatus,
        confirmPassword,
        setConfirmPassword,
        code,
        setCode,
        onCreateAccountClick,
        error,
        errorMessages,
        onNextClick,
        loading
    }
}

export default useRegister;