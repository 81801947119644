import { useState, useRef }     from "react"
import {SlArrowDown}            from 'react-icons/sl';
import {IoCheckmarkCircleSharp} from 'react-icons/io5';
import DashboardLayout          from "layouts/dashboardLayout/dashboard.jsx"
import PageHeader               from "components/pageHeader/pageHeader"
import SubMenuLayout            from "layouts/subMenuLayout/subMenuLayout"
import { Modal } from 'rsuite';
import DataTable                from 'react-data-table-component';
import Icon                     from "components/icon/icon"
import EmployeeModal            from "components/modal/employeeModal/employeeModal";
import BulkImportEmployeeModal  from "components/modal/bulkImportEmployeeModal/bulkImportEmployeeModal";
import Col                      from "layouts/col/col"
import Row                      from "layouts/row/row"
import ConfirmationModal        from "components/modal/confirmationModal/confirmationModal";
import TextInput                from "components/inputs/textInput/textInput"
import TextArea                 from "components/inputs/textarea/textarea"
import RSwitch                  from "components/switch/switch"
import { Dropdown } from 'rsuite';
import Button                   from "components/buttons/button"
import useEmployees             from "./useEmployees";
import TableSkeleton            from "layouts/skeletons/tableSkeleton/tableSkeleton";
import styles                   from './employees.module.scss'
import CheckPermission          from "components/checkPermission/checkPermission";
import useApi                   from "hooks/useApi";
import { showErrorToast, showSuccessToast }       from "functions/toasts";

const TenantEmployeesScreen = () => {

    const {
        employees, setEmployees,
        filteredEmployees, setFilteredEmployees,
        loading, setLoading,
        selectedRows, setSelectedRows,
        handleTableRowSelected,
        deleteEmployeesBulk,
        fetchEmployeeData,
        openDeleteConfirmation, closeDeleteConfirmation,
        confirmationModalOpen,
        activateEmployeesBulk,
        selectedEmployeeIds,
        deactivateEmployeesBulk,
        toggleActivateConfirmModal,
        toggleDeactivateConfirmModal,
    } = useEmployees();

    const {post} = useApi();

    const [employeeModalStatus, setEmployeeModalStatus] = useState(false);
    const [employeeModalMode, setEmployeeModalMode] = useState('add');
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [showBulkImportEmployeeModal, setShowBulkImportEmployeeModal] = useState(false);

    const columns = [
        {
            name: 'Name',
            selector: row => row.attributes.name,
        },
        {
            name: 'Email',
            selector: row => row.attributes.contact_emails,
        },
        {
            name : "Buddy App Installed", 
            width: '180px',  
            cell : (row) => (
            <div style={{ display : 'flex', justifyContent : 'center', alignItems : 'center' }}>
                <Icon icon={<IoCheckmarkCircleSharp />} color={row.attributes.redeemed ? '#42BA78' : '#E3E3E3'} size="2rem" />
            </div>
            ),
            center : true
        },
        {
            name : "Status",
            cell : (row) => (
            <div>
                <RSwitch status={row.attributes.is_active} onChange={() => handleStatusChange(row.attributes.employee_id, !row.attributes.is_active)} />
            </div>
            ),
            center : true
        },
    ];

    const handleStatusChange = async (rowId, newStatus) => {
        console.log(rowId, newStatus);

        let data = {
            is_active : newStatus ? 1 : 0,
            add_to_buddy_app : 0
        }

        try {
            await post(`employees/${rowId}`, data);
            showSuccessToast('Updated Successfully');
            const updatedData = filteredEmployees.map((employee) => {
                console.log(employee)
                if(employee.attributes.employee_id === rowId) {
                    console.log(employee)
                    return {
                        attributes : {
                            ...employee.attributes,
                            is_active : newStatus === true ? 1 : 0,
                        },
                        tenant_id : employee.tenant_id
                    }
                }

                return employee
            });

            console.log(updatedData)

            setFilteredEmployees(updatedData);

        } catch(err) {
            showErrorToast('Something Went Wrong');
        }

    }

    const closeEmployeeModal = () => {
        setEmployeeModalStatus(false);
    }

    const onAddNewEmployeeClick = () => {
        setEmployeeModalMode('add');
        setEmployeeModalStatus(true);
    }

    const onEditEmployeeClick = (row) => {
        setSelectedEmployee(row.attributes);
        setEmployeeModalMode('edit');
        setEmployeeModalStatus(true);
    }

    const closeBulkImportModal = () => {
        setShowBulkImportEmployeeModal(false);
    }

     return (
        <>
        <DashboardLayout>
           
            <SubMenuLayout menu="tenant" subMenuType="tenant" currentMenu="Employees">
                <Row justify="space-between" align="center">
                    <Col>
                        <p className="gb_title">Employees</p>
                        <p className="gb_subtitle">Manage and add new employees to tenant.</p>   
                    </Col>
                    <CheckPermission permission="tenants_add_edit">
                        <Row gap="1rem">
                            <Button text="Bulk Import" backgroundColor="#037093" onClick={() => setShowBulkImportEmployeeModal(true)} />
                            <Button text="+ Add New" onClick={onAddNewEmployeeClick} />
                        </Row>
                    </CheckPermission>
                </Row>
                <Row classNames="mt-3" justify="space-between" align="center">

                    <TextInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder="Search by name" maxWidth="300px" />
                    <Dropdown title="Quick Actions">
                        <Dropdown.Item disabled={selectedEmployeeIds.length < 1} onSelect={() => toggleActivateConfirmModal(true)}>Activate</Dropdown.Item>
                        <Dropdown.Item disabled={selectedEmployeeIds.length < 1} onSelect={() => toggleDeactivateConfirmModal(true)}>Deactivate</Dropdown.Item>
                        <Dropdown.Item disabled={selectedEmployeeIds.length < 1} onSelect={openDeleteConfirmation}>Delete</Dropdown.Item>
                    </Dropdown>
                </Row>
                
                <div className="mt-2">
                    {!loading ?
                    <DataTable
                        columns={columns}
                        data={filteredEmployees.filter(item => {
                            if(searchValue == '') return item
                            if(item.attributes.name.toLowerCase().includes(searchValue.toLowerCase())) return item
                        })}
                        onRowClicked={onEditEmployeeClick}
                        highlightOnHover
                        selectableRows
                        onSelectedRowsChange={handleTableRowSelected}
                        pagination
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[3, 5, 10, 15, 20]}
                        paginationComponentOptions={{
                            rowsPerPageText: "Records per page:",
                            rangeSeparatorText: "out of"
                        }}
                        style={{ width : '100%' }}
                    /> : <TableSkeleton /> }
                </div>
                <ConfirmationModal />
            </SubMenuLayout>
        </DashboardLayout>
        {employeeModalStatus &&
        <EmployeeModal 
            isModalOpen={employeeModalStatus} 
            closeModal={closeEmployeeModal} 
            mode={employeeModalMode} 
            fetchEmployeeData={fetchEmployeeData}
            selectedEmployee={selectedEmployee}
            setSelectedEmployee={setSelectedEmployee}
        /> }
        <BulkImportEmployeeModal 
            isModalOpen={showBulkImportEmployeeModal} 
            closeModal={closeBulkImportModal}
            fetchEmployeeData={fetchEmployeeData} 
        />
            <ConfirmationModal 
                open={confirmationModalOpen.delete} 
                handleClose={closeDeleteConfirmation} 
                title="Delete Employees"
                body={<p>Are you sure you want to delete {selectedRows.selectedCount} employees ?</p>}
                confirmButtonTitle="Delete Employees"
                onConfirm={deleteEmployeesBulk} 
            />

            <ConfirmationModal 
                open={confirmationModalOpen.deactivate} 
                handleClose={() => toggleDeactivateConfirmModal(false)} 
                title="Deactivate Employees"
                body={<p>Are you sure you want to deactivate {selectedRows.selectedCount} employees ?</p>}
                confirmButtonTitle="Confirm"
                onConfirm={deactivateEmployeesBulk} 
            />

            <ConfirmationModal 
                open={confirmationModalOpen.activate} 
                handleClose={() => toggleActivateConfirmModal(false)} 
                title="Activate Employees"
                body={<p>Are you sure you want to activate {selectedRows.selectedCount} employees ?</p>}
                confirmButtonTitle="Confirm"
                onConfirm={activateEmployeesBulk} 
            />
        </>
    )
}

export default TenantEmployeesScreen