import DashboardLayout from "layouts/dashboardLayout/dashboard.jsx"
import PageHeader from "components/pageHeader/pageHeader"
import SubMenuLayout from "layouts/subMenuLayout/subMenuLayout"
import AddNewTemplateModal from "components/modal/addNewTemplateModal/addNewTemplateModal";
import DataTable from 'react-data-table-component';
import useTemplates from "./useTemplates";
import styles from './templates.module.scss';
import CheckPermission from "components/checkPermission/checkPermission";

const SiteTemplatesScreen = () => {

    const {
        modalMode, setModalMode,
        templates,
        tenantTemplates,
        fetchTemplates,
        addNewTemplateModalStatus, 
        setAddNewTemplateModalStatus,
        closeAddNewTemplateModal,
        addNewTemplateModalType, 
        setAddNewTemplateModalType,
        addNewGlobalTemplateModal,
        addNewTenantTemplateModal,
        onTemplateClick,
        selectedTemplate, setSelectedTemplate,
        loading
    } = useTemplates();


    const globalColumns = [
        {
            name: 'Template Name',
            selector: row => row.name,
        },
        // {
        //     name: 'Used By',
        //     selector: row => row.year || 1,
        // },
    ];

    const tenantColumns = [
        {
            name: 'Template Name',
            selector: row => row.name,
        },
        {
            name: 'Tenant',
            selector: row => row.tenant.attributes.name,
        },
    ];


  return (
    <>
    <DashboardLayout>
        
        <SubMenuLayout subMenuType="site" currentMenu="Templates">
            <p className="gb_title">Sign In Templates</p>
            <p className="gb_subtitle">Manage Sign In Templates for site or tenants</p>
            <div className={styles.templatesContainer}>
                <div className={`${styles.template} ${styles.globalTemplate}`}>
                    <div className={styles.templateHeader}>
                        <p>Global Templates</p>
                        <p className={styles.addBtn} onClick={addNewGlobalTemplateModal}>+ Add New</p>
                    </div>
                    <div className={styles.template_content}>
                        {/* <p>What is Global Template ?</p> */}
                        <p className={styles.template_content__info}>Global Templates are accessible by all tenants. Create global templates so as to share template with multiple tenants. Changes made to global template would affect all tenants using the template.</p>
                        <div className="mt-3">
                            <DataTable
                                columns={globalColumns}
                                data={templates}
                                onRowClicked={(row) => onTemplateClick('global', row)}
                                highlightOnHover
                                pagination
                                paginationPerPage={10}
                                paginationRowsPerPageOptions={[3, 5, 10, 15, 20]}
                                // paginationComponentOptions={{
                                //     rowsPerPageText: "Records per page:",
                                //     rangeSeparatorText: "out of"
                                // }}
                            />
                        </div> 
                    </div>
                </div>
                <div className={`${styles.template} ${styles.tenantTemplate}`}>
                    <div className={styles.templateHeader}>
                            <p>Tenant Templates</p>
                            <p className={styles.addBtn} onClick={addNewTenantTemplateModal}>+ Add New</p>
                    </div>
                    <div className={styles.template_content}>
                        <p className={styles.template_content__info}>Tenant templates are specific to tenants. Changes made to a tenant template would only affect the assigned tenant.</p>
                        <div className="mt-3">
                            <DataTable
                                columns={tenantColumns}
                                data={tenantTemplates}
                                onRowClicked={(row) => onTemplateClick('tenant', row)}
                                highlightOnHover
                                pagination
                                paginationPerPage={10}
                                paginationRowsPerPageOptions={[3, 5, 10, 15, 20]}
                                // paginationComponentOptions={{
                                //     rowsPerPageText: "Records per page:",
                                //     rangeSeparatorText: "out of"
                                // }}
                            />
                        </div> 
                    </div>
                </div>
            </div>
            {/* <div className={styles.templateContainer}>
                <div className={`${styles.templateHeader} ${styles.global}`}>
                    <p>Global Templates</p>
                </div>
                <p className="gb_subtitle">Global Templates are accessible by all tenants. Create global templates so as to share template with multiple tenants. Changes made to global template would affect all tenants using this template.</p>
                {!loading ?
                <div className={styles.templatesContainer}>
                    <CheckPermission permission="sites_add_edit">
                        <div className={`${styles.template} ${styles.addNew}`} onClick={addNewGlobalTemplateModal}>
                            <h4>+ Add New</h4>
                        </div>
                    </CheckPermission>
                    {templates.map((template) => {
                        return (
                            <div className={styles.template} onClick={() => onTemplateClick('global', template)}>
                                <h4>{template.name}</h4>
                                <p>Used by 01 tenants</p>
                            </div>
                        )
                    })}
                    
                </div> : '' }

                <div className={`${styles.templateHeader} ${styles.tenant} mt-3`}>
                    <p>Tenant Templates</p>
                </div>
                <p className="gb_subtitle">Tenant templates are specific to tenants. Changes made to a tenant template would only affect the assigned tenant.</p>
                {!loading ?
                <div className={styles.templatesContainer}>
                    <CheckPermission permission="sites_add_edit">
                        <div className={`${styles.template} ${styles.addNew}`} onClick={addNewTenantTemplateModal}>
                            <h4>+ Add New</h4>
                        </div>
                    </CheckPermission>
                    {tenantTemplates.map((template) => {
                        return (
                            <div className={styles.template} onClick={() => onTemplateClick('tenant', template)}>
                                <h4>{template.name}</h4>
                                <p>{template.tenant.attributes.name}</p>
                            </div>
                        )
                    })}
                    
                </div> : '' }
            </div> */}
        </SubMenuLayout>
    </DashboardLayout>

    {addNewTemplateModalStatus &&  <AddNewTemplateModal fetchTemplates={fetchTemplates} mode={modalMode} selectedTemplate={selectedTemplate} isModalOpen={addNewTemplateModalStatus} closeModal={closeAddNewTemplateModal} templateType={addNewTemplateModalType} /> }
    </>
  )
}

export default SiteTemplatesScreen