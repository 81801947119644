import React, {useEffect}   from 'react'
import Modal                from 'react-modal';
import Icon                 from 'components/icon/icon';
import { AiOutlineClose }   from 'react-icons/ai';
import styles               from './modal.module.scss';


/**
 * Modal component.
 * @param {Boolean} isModalOpen   - Boolean to show or hide modal
 * @param {Function} closeModal   - Function to execute to close modal.
 * @param {String} width          - Custom width of modal.
 * @param {String} minHeight      - Custom Minimum Height for modal.
 * @param {String} maxHeight      - Custom Maximum Height for modal.
 * @param {Element} children      - Content to show inside the modal
 */

const CustomModal = ({isModalOpen, closeModal, width, minHeight, maxHeight, children}) => {

    const customModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            width : width || '50%',
            transform: 'translate(-50%, -50%)',
            // maxHeight : maxHeight || '80%',
            //minHeight : minHeight || '40%',
            padding: 0
          },
          overlay: {
            transition: 'rgba(17, 17, 17, 0.555) 0.3s ease',
        },
    };

    useEffect(() => {
        const bodyStyle = document.body.style;
        const htmlStyle = document.documentElement.style;

        if (isModalOpen) {
            bodyStyle.overflow = 'hidden';
            htmlStyle.overflow = 'hidden';
        } else {
            bodyStyle.overflow = '';
            htmlStyle.overflow = '';
        }

        // Cleanup function to reset overflow when the component unmounts or modal state changes
        return () => {
            bodyStyle.overflow = '';
            htmlStyle.overflow = '';
        };
    }, [isModalOpen]);

    return (
        <>
            <Modal 
                isOpen={isModalOpen}
                style={customModalStyles}
                onRequestClose={closeModal}
                preventScroll={false}
                ariaHideApp={false}
            >
                <div className={styles.modalHeader}>
                    <h2>{children.header}</h2>
                    <div className={styles.closeIcon}>
                        <Icon onClick={closeModal} icon={<AiOutlineClose />} size="2.3rem" color="#d1d1d1" />
                    </div>
                </div>
                <div style={{ minHeight : minHeight || '' }} className={styles.modalBody}>
                    {children.body}
                </div>
                <div className={styles.modalFooter}>
                    {children.footer}
                </div>
            </Modal>
        </>
    )
}

export default CustomModal