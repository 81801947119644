import { useEffect, useState, useContext } from "react";
import { showErrorToast, showSuccessToast } from "functions/toasts";
import { AuthContext } from "contexts/authContext";
import useSiteFunctions from "functions/useSiteFunctions";
import CustomModal from "../modal"
import TextInput from "components/inputs/textInput/textInput";
import SelectInput from "components/inputs/selectInput/selectInput";
import UserTypeModal from "../userTypeModal/userTypeModal";
import RadioInput from "components/inputs/radio/radio";
import Button from "components/buttons/button";
import Checkbox from "components/inputs/checkbox/checkbox";
import Col from "layouts/col/col";
import Row from "layouts/row/row";
import useApi from "hooks/useApi";

import styles from './addNewRoleModal.module.scss'
import { capitalizeWords, checkIfEmpty, isValidEmail } from "functions/functions";

const Body = ({role, setRole}) => { 

    return (
        <TextInput label="Role Name" placeholder="Enter Role Name" required value={role} onChange={(e) => setRole(e.target.value)} />
    )
}

const Footer = ({onRoleAdd, loading}) => {
    return (
        <Row justify="flex-end">
            <Button text="Add Role" onClick={onRoleAdd} disabled={loading} loading={loading} loadingText="Adding..." />
        </Row>
    )
}


const AddNewRoleModal = ({isModalOpen, closeModal}) => {
    const [roleName, setRoleName] = useState('');
    const [errors, setErrors] = useState('');
    const [errorMessages, setErrorMessages] = useState('');
    const [loading, setLoading] = useState(false);

    const {post} = useApi();

    const onRoleAdd = async () => {
        setLoading(true);

        let errorObj = {
            roleName : false,
        }

        let errorMessageObj = {
            roleName : ''
        }

        if(checkIfEmpty(roleName)) {
            errorObj.roleName = true;
            errorMessageObj.roleName = 'Role Name is mandatory'
        }

        if(!errorObj.roleName) {

            const data = {
                name : roleName
            }

            try {
                await post(`roles`, data);
                showSuccessToast('New Role Added Successfully');
                closeModal();
            } catch(err) {
                if(err.response.status === 409) {
                    showErrorToast('Role Already Exists');
                } else {
                    showErrorToast('Something Went Wrong');
                }
                
                closeModal();
            }
        }

        setLoading(false);

    }

  return (
    <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} width="40%">
        {{ 
            header : 'Add New Role',
            body : <Body role={roleName} setRole={setRoleName} />,
            footer : <Footer onRoleAdd={onRoleAdd} loading={loading} />
        }}
    </CustomModal>
  )
}

export default AddNewRoleModal