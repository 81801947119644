import  { createContext, useState, useEffect, useRef } from 'react';

const SidebarContext = createContext();

// SidebarProvider component manages the sidebar state and interactions
export function SidebarProvider({children}) {

    // State to track the sidebar's open/closed status
    const [sidebarStatus, setSidebarStatus] = useState(false);

    // State to track the active menu item
    const [activeMenu, setActiveMenu] = useState('dashboard');

    // Ref to the sidebar element, used to detect clicks outside the sidebar
    const sidebarRef = useRef(null);

    useEffect(() => {
        // Add event listener to the document object
        document.addEventListener('mousedown', handleClickOutside);
    
        // Remove event listener when the component unmounts
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      },[])
    
      // Function to handle clicks outside the sidebar, closing it
      function handleClickOutside(event) {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
          setSidebarStatus(false);
        }
      }
      

    return (
        <SidebarContext.Provider value={{sidebarStatus, setSidebarStatus, sidebarRef, activeMenu, setActiveMenu}}>
          {children}
        </SidebarContext.Provider>
    )
}

export default SidebarContext;