import  { createContext, useState } from 'react';

const BreadCrumbContext = createContext();

export function BreadCrumbProvider({children}) {

    const [managedSiteName, setManagedSiteName]     = useState(localStorage.getItem('managedSiteName'));
    const [managedSiteId, setManagedSiteId]         = useState(localStorage.getItem('managedSiteId'));
    const [managedTenantName, setManagedTenantName] = useState(localStorage.getItem('managedTenantName'));
    const [managedTenantId, setManagedTenantId]     = useState(localStorage.getItem('managedTenantId'));

    const setBreadcrumbData = (type, data) => {
        if(type === "site") {
            setManagedSiteId(data.id)
            setManagedSiteName(data.name);
            localStorage.setItem('managedSiteId', data.id);
            localStorage.setItem('managedSiteName', data.name);
        } else if(type === "tenant") {
            setManagedTenantId(data.id);
            setManagedTenantName(data.name);
            localStorage.setItem('managedTenantId', data.id);
            localStorage.setItem('managedTenantName', data.name);
        }
    }
    
    return (
        <BreadCrumbContext.Provider value={{ 
            managedSiteName, setManagedSiteName,
            managedSiteId, setManagedSiteId,
            managedTenantName, setManagedTenantName,
            managedTenantId, setManagedTenantId,
            setBreadcrumbData
         }}>
            {children}
        </BreadCrumbContext.Provider>
    )
}

export default BreadCrumbContext;