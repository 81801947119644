import Lottie               from "lottie-react";
import styles from './loader.module.scss';
import Logo from 'assets/images/logo-theme.png';
import Alert from 'assets/animations/main_loader.json';


const Loader = () => {
    return (
        <div className={styles.loader}>
            <Lottie animationData={Alert} loop={true} style={{ width : 150}} />
            {/* <img src={Logo} /> */}
        </div>
    )
}

export default Loader;