import { useState, useEffect } from "react";
import { useParams }            from 'react-router-dom';
import useApi from "hooks/useApi";
import { showErrorToast, showSuccessToast } from "functions/toasts";

const useEmployees = () => {
    
    const [employees, setEmployees] = useState([]);
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState({
        delete : false,
        activate : false,
        deactivate : false
    })
    const [loading, setLoading] = useState(true);
    
    const { id, tenantId } = useParams();

    const { get, post } = useApi();

    useEffect(() => {
        fetchEmployeeData();
    }, []);

    const fetchEmployeeData = async () => {
        setLoading(true);

        try {
            const response = await get(`tenants/${tenantId}/employees`);

            const data = response.data.data;

            console.log(data)

            setEmployees(data);
            setFilteredEmployees(data);

            setLoading(false);
        } catch(err) {
            console.log(err);
            showErrorToast('Something Went Wrong');
        }
    }

    // When checkbox on table row is selected
    const handleTableRowSelected = (e) => {
        let employeeIds = [];
        setSelectedRows(e);
        e.selectedRows.forEach((employee) => {
            employeeIds.push(employee.attributes.employee_id);
        });

        setSelectedEmployeeIds(employeeIds);
    }

    // Open Delete Confirmation Modal
    const openDeleteConfirmation = () => {
        setConfirmationModalOpen({
            ...confirmationModalOpen,
            delete : true
        })
    };

    // Close Delete Confirmation Modal
    const closeDeleteConfirmation = () => {
        setConfirmationModalOpen({
            ...confirmationModalOpen,
            delete : false
        })
    }

    // Toggle Deactivation Confirmation Modal
    const toggleDeactivateConfirmModal = (status) => {
        setConfirmationModalOpen({
            ...confirmationModalOpen,
            deactivate : status
        })
    }

     // Toggle Activation Confirmation Modal
     const toggleActivateConfirmModal = (status) => {
        setConfirmationModalOpen({
            ...confirmationModalOpen,
            activate : status
        })
    }

    // Function to activate employees in bulk
    const activateEmployeesBulk = async () => {
        let data = {
            employee_array : selectedEmployeeIds
        }

        try {
            await post(`tenants/${tenantId}/employees/activateBulk`, data);
            fetchEmployeeData();
            setSelectedEmployeeIds([]);
            showSuccessToast('Employees Activated Successfully');
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }

    }

    // Function to deactivate employees in bulk
    const deactivateEmployeesBulk = async () => {
        let data = {
            employee_array : selectedEmployeeIds
        }

        try {
            await post(`tenants/${tenantId}/employees/deactivateBulk`, data);
            fetchEmployeeData();
            setSelectedEmployeeIds([]);
            showSuccessToast('Employees Deactivated Successfully');
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }

    }

    // Function to delete employees in bulk
    const deleteEmployeesBulk = async () => {
        console.log(selectedRows);
        const selectedEmployeesIds = [];

        selectedRows.selectedRows.forEach((employee) => {
            selectedEmployeesIds.push(employee.attributes.employee_id);
        });

        let data = {
            employee_array : selectedEmployeesIds
        };

        try {
            await post(`tenants/${tenantId}/employees/deleteBulk`, data);
            fetchEmployeeData();
            setSelectedEmployeeIds([]);
            showSuccessToast('Employees Deleted Successfully');
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }

    }

    return {
        employees, setEmployees,
        filteredEmployees, setFilteredEmployees,
        loading, setLoading,
        handleTableRowSelected,
        selectedRows, setSelectedRows,
        fetchEmployeeData,
        openDeleteConfirmation, closeDeleteConfirmation,
        confirmationModalOpen,
        selectedEmployeeIds,
        deleteEmployeesBulk,
        activateEmployeesBulk,
        deactivateEmployeesBulk,
        toggleDeactivateConfirmModal,
        toggleActivateConfirmModal,
    }

}

export default useEmployees;