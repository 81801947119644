
import { Outlet, Navigate } from 'react-router-dom'

const RedirectRoute = ({isAuthenticated, loading, user}) => {


    return (
        <>
            
            {!loading && isAuthenticated ? 
                user.approved_buildings.buildings.length === 0 ? 
                    <Navigate to="/access-denied" replace /> : 
                    <Navigate to='/dashboard' replace /> 
                : <Outlet /> 
            }
        </>
    )
}

export default RedirectRoute;