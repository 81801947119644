import React, { useState, useEffect } from 'react';
import { Button, Modal, DatePicker, Form, Alert, Select, Checkbox  } from 'antd';
import useVisitorExportModal from './useDeliveryExportModal';

const { RangePicker } = DatePicker;

const DeliveryExportModal = ({ isModalOpen, setIsModalOpen, closeExportModal, selectedDate, selectedSite, siteOptions, selectedTenant, tenantOptions }) => {
  
  const {
      dates, setDates,
      value, setValue,
      disabledDate,
      onChange,
      onCalendarChange,
      tenant, setTenant,
      addCustomFields, setAddCustomFields,
      onExportClick,
      contextHolder,
      loader
  } = useVisitorExportModal({selectedDate, setIsModalOpen, selectedSite, siteOptions, selectedTenant, tenantOptions});

  const [modalWidth, setModalWidth] = useState('90%');

  useEffect(() => {
    const updateWidth = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setModalWidth('90%'); // For small screens
      } else if (width < 1200) {
        setModalWidth('70%'); // For medium screens
      } else if (width < 1800) {
        setModalWidth('50%'); // For medium screens
      } else {
        setModalWidth('30%'); // For large screens
      }
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  return (
    <>
    {contextHolder}
      <Modal
        title={<span className="text-2xl font-medium">Export Data</span>}
        open={isModalOpen}
        onOk={onExportClick}
        onCancel={closeExportModal} 
        width={modalWidth}
        okText={loader.exporting ? 'Exporting Data' : 'Export Data'}
        okButtonProps={{ 
          disabled : !((dates[0] && dates[1]) || (selectedDate && selectedDate.startDate && selectedDate.endDate)),
          loading : loader.exporting
        }}
      >
        <Alert message="Please note: Exporting deliveries data is limited to a maximum of 60 days at a time." type="warning" showIcon className='mb-10' />
        <Form layout="vertical">
          <div className='flex gap-5'>  
            <Form.Item className='flex-1'>
              <Select
                  disabled
                  defaultValue    = {selectedSite}
                  options         = {siteOptions}
                  className       = 'w-full'
              />
            </Form.Item>
            <Form.Item className='flex-1'>
              <Select
                  defaultValue    = {tenant}
                  options         = {tenantOptions}
                  className       = 'w-full'
                  onChange        = {(e) => setTenant(e)}  
              />
            </Form.Item>
            <Form.Item>
              <RangePicker
                value             = {value}
                disabledDate      = {disabledDate}
                onCalendarChange  = {onCalendarChange}
                onChange          = {onChange}
                format            = "YYYY-MM-DD"
              />
            </Form.Item>
          </div>
          {/* <div className='mt-2'>
            <h6 className='font-medium text-xl'>Add Custom Fields</h6>
            <p className='text-[1.2rem] text-gray-700'>Select a tenant to include their custom fields with the report.</p>
            
            <Checkbox 
              checked   = {addCustomFields} 
              className = 'mt-5'
              disabled  = {tenant == 'all'} 
              onChange  = {() => setAddCustomFields(prevState => !prevState)}
            >
                Include Tenant Custom Fields
            </Checkbox>
          </div> */}
        </Form>
      </Modal>
    </>
  );
};

export default DeliveryExportModal;
