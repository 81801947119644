import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useApi from "hooks/useApi";


const useTemplates = () => {

    const [modalMode, setModalMode] = useState('add');
    const [addNewTemplateModalStatus, setAddNewTemplateModalStatus] = useState(false);
    const [addNewTemplateModalType, setAddNewTemplateModalType] = useState('global');
    const [templates, setTemplates] = useState([]);
    const [tenantTemplates, setTenantTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [loading, setLoading] = useState(true);

    const {get, post} = useApi();
    const {id} = useParams();

    useEffect(() => {
        fetchTemplates();
    }, []);

    const fetchTemplates = async () => {
        setLoading(true);
        await Promise.all([fetchGlobalTemplates(), fetchTenantTemplates()]);

        setLoading(false);
        
    }

    const fetchGlobalTemplates = async () => {
        try {
            const response = await get(`buildings/${id}/form-templates`);
            const data = response.data.data;
            setTemplates(data);

            console.log(data);
            
        } catch(err) {
            console.log(err);
        }
    }

    const fetchTenantTemplates = async () => {
        try {
            const response = await get(`buildings/${id}/tenant-form-templates`);
            const data = response.data.data;
            setTenantTemplates(data);

            console.log(data);
            
        } catch(err) {
            console.log(err);
        }
    }

    const closeAddNewTemplateModal = () => {
        setModalMode('add');
        setSelectedTemplate('');
        setAddNewTemplateModalStatus(false)
    }

    const addNewGlobalTemplateModal = () => {
        setModalMode('add');
        setAddNewTemplateModalType('global');
        setAddNewTemplateModalStatus(true);
    }

    const addNewTenantTemplateModal = () => {
        setAddNewTemplateModalType('tenant');
        setAddNewTemplateModalStatus(true);
    }

    const onTemplateClick = (type, template) => {
        setModalMode('edit');
        setSelectedTemplate(template);
        setAddNewTemplateModalType(type);
        setAddNewTemplateModalStatus(true);
    }


    return {
        modalMode, setModalMode,
        templates,
        tenantTemplates,
        fetchTemplates,
        addNewTemplateModalStatus, 
        setAddNewTemplateModalStatus,
        closeAddNewTemplateModal,
        addNewTemplateModalType, 
        setAddNewTemplateModalType,
        addNewGlobalTemplateModal,
        addNewTenantTemplateModal,
        onTemplateClick,
        selectedTemplate, setSelectedTemplate,
        loading
    }
}

export default useTemplates;