import React            from 'react'
import CustomModal      from '../modal'
import Row              from 'layouts/row/row';
import Button           from 'components/buttons/button';

const Body = () => {
    return (
        <div>
            <p>Are you sure you want to disconnect this device from the site ?</p>
        </div>
    )
}

const Footer = ({deleteDevice, closeModal, loading}) => {

    return (
        <Row justify="flex-end" gap="1rem">
            <Button text="No, Cancel" onClick={closeModal} backgroundColor="#D3D3D3" color="#3A3A3A" />
            <Button disabled={loading} loading={loading} loadingText="Deleting Device" text="Yes, Disconnect" onClick={deleteDevice} backgroundColor="#FFEEEE" color="#B00000" />
        </Row>
    )
}

/**
 * 
 * @param {Boolean} isModalOpen     - Shows or hides modal
 * @param {Function} closeModal     - Function to close the modal 
 */

const DeleteDeviceModal = ({isModalOpen, closeModal, deleteDevice, loading}) => {
  return (
    <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} width="40%" minHeight="30%">
        {{ 
            header : 'Disconnect Device',
            body : <Body />,
            footer : <Footer deleteDevice={deleteDevice} closeModal={closeModal} loading={loading} />
         }}
    </CustomModal>
  )
}

export default DeleteDeviceModal