import { useState, useRef, useEffect }  from "react";
import { useParams }                    from "react-router-dom";
import axios                            from "axios";
import useApi                           from "hooks/useApi";
import { showErrorToast, showSuccessToast } from "functions/toasts";
import { APP_CONFIG } from "constants/config";

const useBranding = () => {

    const [showHeadline1, setShowHeadline1]         = useState(true);
    const [headline1, setHeadline1]                 = useState({value : 'Welcome To', show : true, color : '#002547' })  
    const [headline2, setHeadline2]                 = useState({value : '6 Gracechurch Street', show : true, color : '#002547'})  
    const [showLogo, setShowLogo]                   = useState(true);
    const [template, setTemplate]                   = useState(1);
    const [alignContent, setAlignContent]           = useState('left');
    const [frosted, setFrosted]                     = useState(false);
    const [backgroundImage, setBackgroundImage]     = useState('');
    const [showColorPicker1, setShowColorPicker1]   = useState(false);
    const [showColorPicker2, setShowColorPicker2]   = useState(false);
    const [logo, setLogo]                           = useState('');
    const [newImage, setNewImage]                   = useState('');
    const [preview, setPreview]                     = useState('');

    const [bgImage, setBgImage]                     = useState('');
    const [newBgImage, setNewBgImage]               = useState('');
    const [bgPreview, setBgPreview]                 = useState('')

    const [loading, setLoading]                     = useState(true);

    const { get, post } = useApi();

    const {id} = useParams();

    const colorPickerRef = useRef(null);

    const headline2ColorPickerRef = useRef(null);

    const inputFile = useRef(null);
    const bgImageFile = useRef(null);

    const onColorWheel1Click = () => {
        setShowColorPicker1(!showColorPicker1)
    }

    const onColorWheel2Click = () => {
        setShowColorPicker2(!showColorPicker2)
    }

    useEffect(() => {
      fetchSiteBrandingData();
    },[]);

    useEffect(() => {
        // Add event listener to the document object
        document.addEventListener('mousedown', handleClickOutside);
    
        // Remove event listener when the component unmounts
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      },[])
      

      // Function to fetch site branding data
      const fetchSiteBrandingData = async () => {
        setLoading(true);

        try {
            const response = await get(`buildings/${id}`);
            const data = response.data.data.attributes;
            const welcomeScreenSettings = data.welcome_screen;

            setHeadline1({
              value : welcomeScreenSettings.headline_1_text,
              show : welcomeScreenSettings.headline_1_visible,
              color : welcomeScreenSettings.headline_1_colour,
            });

            setHeadline2({
              value : welcomeScreenSettings.headline_2_text,
              show : welcomeScreenSettings.headline_2_visible,
              color : welcomeScreenSettings.headline_2_colour,
            });

            setTemplate(welcomeScreenSettings.template)
            setShowLogo(welcomeScreenSettings.logo_shown);
            setAlignContent(welcomeScreenSettings.alignment);
            setFrosted(welcomeScreenSettings.frosted_background);
            setBackgroundImage(welcomeScreenSettings.background_image);

            console.log(data);
            setLogo(data.logo_url);

            setLoading(false);
        } catch(err) {
            console.log(err)
        }
      }
    
      // Function to handle clicks outside the sidebar, closing it
      function handleClickOutside(event) {
        if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
          setShowColorPicker1(false);
        }
        
        if (headline2ColorPickerRef.current && !headline2ColorPickerRef.current.contains(event.target)) {
            setShowColorPicker2(false);
          }
      }

      const saveBranding = async () => {
        console.log(logo);
        try {
          const formData = new FormData();
          if (newImage) {
            formData.append("logo", newImage);
          } else {
            console.error("Logo is undefined or null");
            return; // Exit the function if logo is not set
          }

          console.log(formData);

          const config = {
            headers : {'Content-Type' : 'multipart/form-data'},
            withCredentials : true
          }

          const response = await axios.post(`${APP_CONFIG.BASE_URL}buildings/${id}`, formData, config);
          showSuccessToast('Site Logo Updated Successfully');
          setNewImage('');
          console.log(response)
        } catch(err) {
          showErrorToast('Something Went Wrong');
        }
      }

      const updateWelcomeScreen = async () => {

        const formData = new FormData();

        formData.append("headline_1_visible", headline1.value ? 1 : 0);
        formData.append("headline_1_text", headline1.value);
        formData.append("headline_1_colour", headline1.color);
        formData.append("headline_2_visible", headline2.value ? 1 : 0);
        formData.append("headline_2_text", headline2.value);
        formData.append("headline_2_colour", headline2.color);
        formData.append("logo_shown", showLogo ? 1 : 0);
        formData.append("alignment", alignContent);
        formData.append("frosted_background", frosted ? 1 : 0);
        formData.append("template", template);

        console.log(newBgImage)
        if (newBgImage) {
          formData.append("background_image", newBgImage);
        }

        try {

          // const response = await post(`buildings/${id}/welcome-screen`, data);
          const config = {
            headers : {'Content-Type' : 'multipart/form-data'},
            withCredentials : true
          }

          const response = await axios.post(`${APP_CONFIG.BASE_URL}buildings/${id}/welcome-screen`, formData, config);

          await fetchSiteBrandingData();
          showSuccessToast('Site Welcome Screen Updated Successfully');

        } catch(err) {
          console.log(err)
          showErrorToast('Something Went Wrong');
        }
      }

      const onFileUploadClick = () => {
        inputFile.current.click();
    }

    const onBgFileUploadClick = () => {
      bgImageFile.current.click();
    }

    // const onFileSelected = (e) => {
    //     console.log(e.target.files[0])
    //     setNewImage(e.target.files[0])

    //     const objectUrl = URL.createObjectURL(e.target.files[0]);

    //     setPreview(objectUrl)
    // }


    const onFileSelected = (e) => {
      const file = e.target.files[0];
      if (file) {
          const extension = file.name.split('.').pop().toLowerCase();
          const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
          if (['jpeg', 'png', 'jpg'].includes(extension) && fileSizeInMB <= 2) {
              console.log(file);
              setNewImage(file);
              const objectUrl = URL.createObjectURL(file);
              setPreview(objectUrl);
          } else {
              let errorMessage = 'Please select a ';

              if (!['jpeg', 'png', 'jpg'].includes(extension)) {
                errorMessage += 'JPEG, PNG, or JPG ';
              }

              if (fileSizeInMB > 2) {
                errorMessage += 'file less than 2 MB';
              }

              alert(errorMessage);

              // setShowError(true);
              // setErrorMessage(errorMessage)

              // Display error message or handle invalid file type
              // alert('Please select a JPEG, PNG, or JPG image.');
              // Clear the file input
              e.target.value = null;
          }
      }
  };

    // const onBgImageSelected = (e) => {
    //   setNewBgImage(e.target.files[0]);
    //   const objectUrl = URL.createObjectURL(e.target.files[0]);
    //   setBgPreview(objectUrl)
    // }

    const onBgImageSelected = (e) => {
      const file = e.target.files[0];
      if (file) {
          const extension = file.name.split('.').pop().toLowerCase();
          const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
          if (['jpeg', 'png', 'jpg'].includes(extension) && fileSizeInMB <= 2) {
              console.log(file);
              setNewBgImage(file);
              const objectUrl = URL.createObjectURL(file);
              setBgPreview(objectUrl);
          } else {
              let errorMessage = 'Please select a ';

              if (!['jpeg', 'png', 'jpg'].includes(extension)) {
                errorMessage += 'JPEG, PNG, or JPG ';
              }

              if (fileSizeInMB > 2) {
                errorMessage += 'file less than 2 MB';
              }

              alert(errorMessage);

              // setShowError(true);
              // setErrorMessage(errorMessage)

              // Display error message or handle invalid file type
              // alert('Please select a JPEG, PNG, or JPG image.');
              // Clear the file input
              e.target.value = null;
          }
      }
    }

    return {
        showHeadline1,
        setShowHeadline1,
        headline1,
        setHeadline1,
        headline2,
        setHeadline2,
        showLogo, 
        setShowLogo,
        template, setTemplate,
        alignContent, 
        setAlignContent,
        frosted, setFrosted,
        colorPickerRef,
        backgroundImage,
        headline2ColorPickerRef,
        showColorPicker1,
        setShowColorPicker1,
        showColorPicker2,
        setShowColorPicker2,
        onColorWheel1Click,
        onColorWheel2Click,
        logo, setLogo,
        bgImage, setBgImage,
        saveBranding,
        inputFile,
        bgImageFile,
        onFileUploadClick,
        onBgFileUploadClick,
        onFileSelected,
        onBgImageSelected,
        newImage, setNewImage,
        updateWelcomeScreen,
        preview, bgPreview,
        loading,
    }
}

export default useBranding;