import { useNavigate } from 'react-router-dom';
import { useContext } from "react";
import BreadCrumbContext from "contexts/breadcrumbContext";
import PageHeader from 'components/pageHeader/pageHeader';
import styles from './subMenuLayout.module.scss';

const SubMenuLayout = ({menu, subMenuType = 'site', currentMenu, children}) => {
    const siteSubMenus = [
        {
            name : 'Details',
            to : 'details'
        },
        {
            name : 'Branding',
            to : 'branding'
        },
        {
            name : 'Sign In Form',
            to : 'templates'
        },
        {
            name : 'Health & Safety',
            to : 'health-and-safety'
        },
        {
            name : 'Devices',
            to : 'devices'
        },
        {
            name : 'Notifications',
            to : 'notifications'
        },
        {
            name : 'Settings',
            to : 'settings'
        },
    ]

    const tenantSubMenus  = [
        {
            name : 'Details',
            to : 'details'
        },
        {
            name : 'Branding',
            to : 'branding'
        },
        {
            name : 'Sign In Form',
            to : 'sign-in-form-new'
        },
        {
            name : 'Health & Safety',
            to : 'health-and-safety'
        },
        {
            name : 'Employees',
            to : 'employees'
        },
        {
            name : 'Notification',
            to : 'notifications'
        },
        {
            name : 'Settings',
            to : 'settings'
        },
    ]

    const subMenus = menu === 'tenant' ? tenantSubMenus : siteSubMenus;

    const path = window.location.pathname;
    const pathSplit = path.split('/');

    const currentPath = pathSplit[pathSplit.length - 1];

    const navigate = useNavigate();

    const {
        managedSiteName,
        managedSiteId,
        managedTenantName,
        managedTenantId,
    } = useContext(BreadCrumbContext);

    let breadcrumbs;
    
    if(subMenuType == "site") {
        breadcrumbs =  [
            {
                name : 'Sites',
                to : '/manage/sites'
            },
            {
                name : managedSiteName,
                to : `/manage/sites/${managedSiteId}`
            },
            {
                name : currentMenu,
            }
        ];
    } else if(subMenuType == "tenant") {
        breadcrumbs =  [
            {
                name : 'Sites',
                to : '/manage/sites'
            },
            {
                name : managedSiteName,
                to : `/manage/sites/${managedSiteId}`
            },
            {
                name : managedTenantName,
                to : `/manage/sites/${managedSiteId}/tenants`
            },
            {
                name : currentMenu,
            }
        ];
    }

    const subMenuNavigate = (to) => {
        pathSplit[pathSplit.length - 1] = to;
        navigate(pathSplit.join('/'));
    }

  return (
    <>
        <PageHeader 
            title = {managedSiteName} 
            breadcrumbsArray = {breadcrumbs}
        />
        <div className={styles.subMenuLayoutContainer}>
            <div className={styles.subMenuContainer}>
                {subMenus.map((menu) => {
                    return (
                        <div className={`${styles.menu} ${currentPath == menu.to ? styles.menuActive : ''}`} onClick={() => currentPath != menu.to && subMenuNavigate(menu.to)}>
                            <p>{menu.name}</p>
                        </div>
                    )
                })}
            </div>
            <div className={styles.mainContainer}>
                {children}
            </div>
        </div>
    </>
  )
}

export default SubMenuLayout