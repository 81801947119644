import React            from 'react'
import CustomModal      from '../modal'
import Row from 'layouts/row/row';
import Button           from 'components/buttons/button';

const Body = () => {
    return (
        <div>
            <p>Are you sure you want to delete this site ?</p>
        </div>
    )
}

const Footer = ({deleteSite}) => {
    return (
        <Row justify="flex-end">
            <Button text="Yes Delete" onClick={deleteSite} />
        </Row>
    )
}

/**
 * 
 * @param {Boolean} isModalOpen     - Shows or hides modal
 * @param {Function} closeModal     - Function to close the modal 
 */

const DeleteSiteModal = ({isModalOpen, closeModal, deleteSite}) => {
  return (
    <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} width="40%" minHeight="30%">
        {{ 
            header : 'Delete Site',
            body : <Body />,
            footer : <Footer deleteSite={deleteSite} />
         }}
    </CustomModal>
  )
}

export default DeleteSiteModal