import { useState, useEffect } from 'react';
import { useAuth } from 'contexts/authContext';
import React            from 'react'
import useApi from 'hooks/useApi';
import InnerImageZoom   from 'react-inner-image-zoom';
import CustomModal      from '../modal'
import SelectInput      from 'components/inputs/selectInput/selectInput'
import Button           from 'components/buttons/button';
import Parcel           from 'assets/images/parcel.jpg';
import styles           from './confirmDeliveryModal.module.scss';

import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import { showErrorToast, showSuccessToast } from 'functions/toasts';
import TextInput from 'components/inputs/textInput/textInput';
import { getImageURL } from 'functions/functions';

const Body = ({
    selectedDelivery,
    tenants,
    onTenantChange,
    employees,
    onEmployeeChange,
    notes, setNotes,
    errors, setErrors,
    errorMessages, setErrorMessages,
    mode
}) => {
    return (
        <div className={styles.confirmDeliveryModalContainer}>
            {mode == 'edit' && <div className={styles.deliveryImageContainer}>
                <InnerImageZoom src={getImageURL(selectedDelivery.image_url)} zoomType='hover' zoomPreload={true} zoomScale={1} hideHint={true} />
            </div> }
            <div className={styles.inputsContainer}>
                <p>Confirm the recipient details and assign the delivery to notify them.</p>
                <div className={styles.inputs}>
                    <SelectInput placeholder = "Select Tenant" showError={true} isError={errors.tenant} errorMessage={errorMessages.tenant} label = "Recipient Tenant" options={tenants} onChange={onTenantChange} />
                    <SelectInput placeholder = "Select Person" label = "Recipient Person" options={employees} onChange={onEmployeeChange} />
                    <TextInput classNames="mt-2" label="Notes (Shelf No/Rack No...)" placeholder="Enter delivery notes (if any)" value={notes} onChange={(e) => setNotes(e.target.value)} />
                </div>
            </div>
        </div>
    )
}

const Footer = ({confirmDelivery, loading}) => {

    return (
        <div className={styles.footerContainer}>
            <Button text="Confirm Delivery" onClick={confirmDelivery} loading={loading} disabled={loading} loadingText="Confirming..." />
        </div>
    )
}

/**
 * 
 * @param {Boolean} isModalOpen     - Shows or hides modal
 * @param {Function} closeModal     - Function to close the modal 
 */

const ConfirmDeliveryModal = ({isModalOpen, closeModal, selectedDelivery, fetchData, mode}) => {
    console.log(selectedDelivery)
    const [tenants, setTenants] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [selectedTenant, setSelectedTenant] = useState('');
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [errors, setErrors] = useState('');
    const [errorMessages, setErrorMessages] = useState('');
    const [notes, setNotes] = useState('');
    const [loading, setLoading] = useState(false);

    const {get, post} = useApi();
    const {currentBuilding} = useAuth();

    useEffect(() => {
        fetchModalData();
    },[]);

    const fetchModalData = () => {
        fetchTenantsData();
    };

    // Fetch Tenants Options
    const fetchTenantsData = async () => {
        try {
            const response = await get(`buildings/${currentBuilding.attributes.building_id}/tenants`);
            const data = response.data.data;
            let tenantOptions = [];
            data.forEach(tenant => {
                if(tenant.attributes.is_active === 1) {
                    tenantOptions.push({
                        ...tenant.attributes,
                        label : tenant.attributes.name,
                        value : tenant.attributes.tenant_id,
                    })
                }
            });

            setTenants(tenantOptions);
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }

    // When a tenant is selected
    const onTenantChange = async (tenant) => {
        setSelectedTenant(tenant);
        
        try {
            const response = await get(`tenants/${tenant.tenant_id}/employees`);
            const data = response.data.data;

            let employeeOptions = [];
            data.forEach(employee => {
                if(employee.attributes.is_active === 1) {
                    employeeOptions.push({
                        ...employee.attributes,
                        label : employee.attributes.name,
                        value : employee.attributes.employee_id,
                    })
                }
            });

            setEmployees(employeeOptions)

            console.log(data);
        } catch(err) {
            showErrorToast('Something Went Wrong')
        }
    }

    // When employee is selected
    const onEmployeeChange = (employee) => {
        console.log(employee)
        setSelectedEmployee(employee)
    }

    // On Confirm Delivery
    const confirmDelivery = async () => {
        setLoading(true);
        let errorObj = {
            tenant : false
        }

        let errorMessageObj = {
            tenant : ''
        }

        if(!selectedTenant) {
            errorObj.tenant = true;
            errorMessageObj.tenant = "Select Tenant"
        }

        if(!errorObj.tenant) {
            try {
                let data = {
                    building_id : currentBuilding.attributes.building_id,
                    tenant_id : selectedTenant.tenant_id,
                    un_delivery_id : selectedDelivery.un_delivery_id
                }
    
                if(notes) {
                    data['notes'] = notes;
                }
    
                if(selectedEmployee) {
                    data['employee_id'] = selectedEmployee.employee_id
                }
    
                await post(`deliveries`, data);
                await fetchData();
                showSuccessToast('Delivery Confirmed Successfully');
                closeModal();
    
            } catch(err) {
    
                showErrorToast('Something Went Wrong');
            }
        }

        setErrors(errorObj);
        setErrorMessages(errorMessageObj);
        setLoading(false);
        
    }

  return (
    <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} width="50%" minHeight="65%">
        {{ 
            header : `${mode === 'add' ? 'Add' : 'Confirm'} Delivery`,
            body :  <Body 
                        selectedDelivery={selectedDelivery}
                        tenants={tenants}
                        onTenantChange={onTenantChange}
                        employees={employees}
                        onEmployeeChange={onEmployeeChange}
                        notes={notes}
                        errors={errors} setErrors={setErrors}
                        errorMessages={errorMessages} setErrorMessages={setErrorMessages}
                        setNotes={setNotes}
                        mode={mode}
                    />,
            footer : <Footer confirmDelivery={confirmDelivery} loading={loading} />
         }}
    </CustomModal>
  )
}

export default ConfirmDeliveryModal