import { useState }     from "react";
import DashboardLayout  from "layouts/dashboardLayout/dashboard.jsx"
import Skeleton         from "react-loading-skeleton";
import SubMenuLayout    from "layouts/subMenuLayout/subMenuLayout"
import { MdDelete } from "react-icons/md";
import MultiTextInput   from "components/inputs/multiTextInput/multiTextInput";
import Col              from "layouts/col/col"
import Row              from "layouts/row/row"
import TextArea         from "components/inputs/textarea/textarea"
import RSwitch          from "components/switch/switch"
import Button           from "components/buttons/button"
import useNotifications from "./useNotifications";
import Checkbox         from "components/inputs/checkbox/checkbox";
import styles           from './notifications.module.scss';
import CheckPermission from "components/checkPermission/checkPermission";
import TextInput from "components/inputs/textInput/textInput";
import Icon from "components/icon/icon";
import { isValidEmail } from "functions/functions";


const TenantNotificationsScreen = () => {
    

    const {
        followSiteNotifPolicy, setFollowSiteNotiffPolicy,
        notifOptions, setNotifOptions,
        visitorReceiverEmail, setVisitorReceiverEmail,
        visitorReceiverEmails, setVisitorReceiverEmails,
        deliveryReceiverEmail, setDeliveryReceiverEmail,
        deliveryReceiverEmails, setDeliveryReceiverEmails,
        onSaveChanges,
        onStatusChange,
        onPolicyChange,
        showSaveChanges,
        loading, setLoading
    } = useNotifications();

    const [tab, setTab] = useState("tenants");
    const [error, setError] = useState('');
    const [errorMessages, setErrorMessages] = useState('');

    const onKeyPress = (e) => {
        if(e.key === 'Enter') {
        //   if(visitorReceiverEmail.trim() !== '') {
        //     setVisitorReceiverEmails((prev) => [...prev, visitorReceiverEmail.trim()]);
        //     setVisitorReceiverEmail('');
        //   }
        //   console.log(visitorReceiverEmails);
            addEmailToArray(visitorReceiverEmail);
        }
    }

    const onRemoveEmailClick = (emailToDelete) => {
        setVisitorReceiverEmails(visitorReceiverEmails.filter((email) => {
            return email !== emailToDelete;
        }))
    }

    const addEmailToArray = (email) => {
        let errorObj = {
            email : false
        }

        let errorMessageObj = {
            email : ''
        }

        if(isValidEmail(email)) {
            if(!visitorReceiverEmails.includes(email.toLowerCase()) && visitorReceiverEmail.trim() !== '') {
                setVisitorReceiverEmails((prev) => [...prev, visitorReceiverEmail.toLowerCase().trim()]);
            }
            setVisitorReceiverEmail('');
        } else {
            errorObj.email = true;
            errorMessageObj.email = 'Enter Valid Email Address'
        }

        setError(errorObj)
        setErrorMessages(errorMessageObj);
        
    }

     return (
        <DashboardLayout>
            
            <SubMenuLayout menu="tenant" subMenuType="tenant" currentMenu="Notifications">
                <p className="gb_title">Notifications</p>
                <p className="gb_subtitle">Manage when tenant should be notitified and whom should be notified.</p>
                {!loading ?
                <div className="mt-4">
                    <RSwitch label="Follow Site Notification Policy" status={followSiteNotifPolicy} onChange={onPolicyChange} />
                    {!followSiteNotifPolicy &&
                    <> 
                    <div className="tab__container mt-3">
                        <div className={`tab__container__tab ${tab === "tenants" ? 'active' : ''}`} onClick={() => setTab("tenants")}>
                            Tenant Notifications
                        </div>
                        <div className={`tab__container__tab ${tab === "employees" ? 'active' : ''}`} onClick={() => setTab("employees")}>
                            Employees Notifications
                        </div>
                    </div>
                    {tab === "tenants" && 
                    <div className="tab__content">
                        <p>Notify Tenants on Following Events</p>
                        <div className={styles.notificationOptions}>
                            <div className={styles.events}>
                            <Checkbox 
                                    label="Visitor for Tenant Signed In"
                                    id="signIn"
                                    name="signIn"
                                    checked={notifOptions.tenant_notify_tenant_visitor_sign_in}
                                    onChange={() => onStatusChange("tenant_notify_tenant_visitor_sign_in")}
                                />
                                <Checkbox 
                                    label="Visitor for Tenant Signed Out" 
                                    id="signIn"
                                    name="signIn"
                                    checked={notifOptions.tenant_notify_tenant_visitor_sign_out}
                                    onChange={() => onStatusChange("tenant_notify_tenant_visitor_sign_out")}
                                />
                                <Checkbox 
                                    label="Visitor for Employee Signed In"
                                    id="signIn"
                                    name="signIn"
                                    checked={notifOptions.tenant_notify_employee_visitor_sign_in}
                                    onChange={() => onStatusChange("tenant_notify_employee_visitor_sign_in")}
                                />
                                <Checkbox 
                                    label="Visitor for Employee Signed Out" 
                                    id="signIn"
                                    name="signIn"
                                    checked={notifOptions.tenant_notify_employee_visitor_sign_out}
                                    onChange={() => onStatusChange("tenant_notify_employee_visitor_sign_out")}
                                />
                            </div>
                            <div className={styles.events}>
                            <Checkbox 
                                    label="Delivery for Tenant Arrived"
                                    id="signIn"
                                    name="signIn"
                                    checked={notifOptions.tenant_notify_tenant_delivery_arrived}
                                    onChange={() => onStatusChange("tenant_notify_tenant_delivery_arrived")}
                                />
                                <Checkbox 
                                    label="Delivery for Tenant Collected" 
                                    id="signIn"
                                    name="signIn"
                                    checked={notifOptions.tenant_notify_tenant_delivery_collected}
                                    onChange={() => onStatusChange("tenant_notify_tenant_delivery_collected")}
                                />
                                <Checkbox 
                                    label="Delivery for Employee Arrived"
                                    id="signIn"
                                    name="signIn"
                                    checked={notifOptions.tenant_notify_employee_delivery_arrived}
                                    onChange={() => onStatusChange("tenant_notify_employee_delivery_arrived")}
                                />
                                <Checkbox 
                                    label="Delivery for Employee Collected" 
                                    id="signIn"
                                    name="signIn"
                                    checked={notifOptions.tenant_notify_employee_delivery_collected}
                                    onChange={() => onStatusChange("tenant_notify_employee_delivery_collected")}
                                />
                            </div>
                            <div className={styles.events}>
                            <Checkbox 
                                    label="Preregistering Visitor for Tenant"
                                    id="signIn"
                                    name="signIn"
                                    checked={notifOptions.tenant_notify_tenant_preregister_visitor}
                                    onChange={() => onStatusChange("tenant_notify_tenant_preregister_visitor")}
                                />
                                <Checkbox 
                                    label="Preregistering Visitor for Employee"
                                    id="signIn"
                                    name="signIn"
                                    checked={notifOptions.tenant_notify_employee_preregister_visitor}
                                    onChange={() => onStatusChange("tenant_notify_employee_preregister_visitor")}
                                />
                            </div>
                        </div>
                    </div> }
                    {tab === "employees" &&
                    <div className="tab__content">
                        <p>Notify Employees on Following Events</p>
                        <div className={styles.notificationOptions}>
                            <div className={styles.events}>
                                <Checkbox 
                                    label="Visitor for Employee Signed In"
                                    id="signIn"
                                    name="signIn"
                                    checked={notifOptions.employee_notify_employee_visitor_sign_in}
                                    onChange={() => onStatusChange("employee_notify_employee_visitor_sign_in")}
                                />
                                <Checkbox 
                                    label="Visitor for Employee Signed Out" 
                                    id="signIn"
                                    name="signIn"
                                    checked={notifOptions.employee_notify_employee_visitor_sign_out}
                                    onChange={() => onStatusChange("employee_notify_employee_visitor_sign_out")}
                                />
                            </div>
                            <div className={styles.events}>
                                <Checkbox 
                                    label="Delivery for Employee Arrived"
                                    id="signIn"
                                    name="signIn"
                                    checked={notifOptions.employee_notify_employee_delivery_arrived}
                                    onChange={() => onStatusChange("employee_notify_employee_delivery_arrived")}
                                />
                                <Checkbox 
                                    label="Delivery for Employee Collected" 
                                    id="signIn"
                                    name="signIn"
                                    checked={notifOptions.employee_notify_employee_delivery_collected}
                                    onChange={() => onStatusChange("employee_notify_employee_delivery_collected")}
                                />
                            </div>
                            <div className={styles.events}>
                                <Checkbox 
                                    label="Preregistering Visitor for Employee"
                                    id="signIn"
                                    name="signIn"
                                    checked={notifOptions.employee_notify_employee_preregister_visitor}
                                    onChange={() => onStatusChange("employee_notify_employee_preregister_visitor")}
                                />
                            </div>
                        </div>
                    </div> }
                    </> }
                </div> : 
                
                <div className="mt-4">
                    <div>
                        <Skeleton width="40%" height={30} />
                    </div>
                    <Row classNames="mt-2" gap="2rem">
                        <Row classNames="flex-1" gap="1rem" align="center">
                                <Skeleton circle height={20} width={20} />
                                <Skeleton containerClassName="flex-1" />
                        </Row>
                        <Row classNames="flex-1" gap="1rem" align="center">
                                <Skeleton circle height={20} width={20} />
                                <Skeleton containerClassName="flex-1" />
                        </Row>
                        <Row classNames="flex-1" gap="1rem" align="center">
                                <Skeleton circle height={20} width={20} />
                                <Skeleton containerClassName="flex-1" />
                        </Row>
                    </Row>
                    <Row classNames="mt-2" gap="2rem">
                        <Row classNames="flex-1" gap="1rem" align="center">
                                <Skeleton circle height={20} width={20} />
                                <Skeleton containerClassName="flex-1" />
                        </Row>
                        <Row classNames="flex-1" gap="1rem" align="center">
                                <Skeleton circle height={20} width={20} />
                                <Skeleton containerClassName="flex-1" />
                        </Row>
                        <Row classNames="flex-1" gap="1rem" align="center">
                                <Skeleton circle height={20} width={20} />
                                <Skeleton containerClassName="flex-1" />
                        </Row>
                    </Row>
                    <Row classNames="mt-2" gap="2rem">
                        <Row classNames="flex-1" gap="1rem" align="center">
                                <Skeleton circle height={20} width={20} />
                                <Skeleton containerClassName="flex-1" />
                        </Row>
                        <Row classNames="flex-1" gap="1rem" align="center">
                                <Skeleton circle height={20} width={20} />
                                <Skeleton containerClassName="flex-1" />
                        </Row>
                        <Row classNames="flex-1" gap="1rem" align="center">
                                <Skeleton circle height={20} width={20} />
                                <Skeleton containerClassName="flex-1" />
                        </Row>
                    </Row>
                    <Row classNames="mt-2" gap="2rem">
                        <Row classNames="flex-1" gap="1rem" align="center">
                                <Skeleton circle height={20} width={20} />
                                <Skeleton containerClassName="flex-1" />
                        </Row>
                        <Row classNames="flex-1" gap="1rem" align="center">
                                <Skeleton circle height={20} width={20} />
                                <Skeleton containerClassName="flex-1" />
                        </Row>
                        <Row classNames="flex-1" gap="1rem" align="center">
                                <Skeleton circle height={20} width={20} />
                                <Skeleton containerClassName="flex-1" />
                        </Row>
                    </Row>
                    
                </div>
                
                }

                {!loading ?
                <div>
                    <p className="gb_title mt-4">Tenant Receiver Emails</p>

                    {/* <MultiTextInput 
                        label="Visitor Notification Receiver Emails" 
                        placeholder="Enter Email and press enter"
                        value={visitorReceiverEmail}
                        setValue={setVisitorReceiverEmail}
                        onChange={(e) => setVisitorReceiverEmail(e.target.value)}
                        valueArray={visitorReceiverEmails}
                        setValueArray={setVisitorReceiverEmails}
                        onKeyPress={onKeyPress}
                        classNames="mt-1"
                    /> */}

                    {/* <MultiTextInput 
                        label="Delivery Notification Receiver Emails" 
                        placeholder="Enter Email and press enter"
                        value={deliveryReceiverEmail}
                        setValue={setDeliveryReceiverEmail}
                        onChange={(e) => setDeliveryReceiverEmail(e.target.value)}
                        valueArray={deliveryReceiverEmails}
                        setValueArray={setDeliveryReceiverEmails}
                        onKeyPress={onKeyPress}
                        classNames="mt-3"
                    /> */}
                    <p>Add emails to be notified for tenant notifications.</p>
                    <Row gap="1rem" classNames="mt-2">
                        <TextInput 
                            value={visitorReceiverEmail} 
                            onChange={(e) => setVisitorReceiverEmail(e.target.value)} 
                            onKeyPress={onKeyPress} 
                            placeholder="Enter email address and press enter" 
                            maxWidth="50%"
                            isError={error.email}
                            errorMessage={errorMessages.email} 
                        />
                        <Button text="+ Add" backgroundColor="#f7dc97" color="#333333" onClick={() => addEmailToArray(visitorReceiverEmail)} />
                    </Row>
                    <div className={styles.receiverEmails}>
                        {visitorReceiverEmails.map((email) => {
                            return (
                                <div className={styles.email}>
                                    <p>{email}</p>
                                    <Icon icon={<MdDelete />} color="#ff4f4f" size="1.6rem" onClick={() => onRemoveEmailClick(email)} />
                                </div>
                            )
                        })}
                    </div>

                <div className={styles.bottom}>
                    <CheckPermission permission="tenants_add_edit">
                        <Row classNames="mt-5" justify="right">
                            <Button text="Save Changes" onClick={onSaveChanges} />    
                        </Row>
                    </CheckPermission> 
                </div>

                </div> : '' }
            </SubMenuLayout>
        </DashboardLayout>
    )
}

export default TenantNotificationsScreen