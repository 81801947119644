import { CiExport }                                                                 from "react-icons/ci";
import { Bar, Line }                                                                from 'react-chartjs-2';
import {Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement}    from 'chart.js' 
import { Select, Form, Table, DatePicker, Collapse, Input, Button, Card }           from 'antd';
import VisitorExportModal                                                           from "./visitorExportModal/visitorExportModal";
import DashboardLayout                                                              from 'layouts/dashboardLayout/dashboard.jsx';
import styles                                                                       from './visitorReports.module.scss';
import useVisitorReports                                                            from './useVisitorReports';
import 'react-date-range/dist/styles.css';                                      
import 'react-date-range/dist/theme/default.css';
import { useEffect, useState } from "react";

const { RangePicker } = DatePicker;

ChartJS.register(
    LineElement, CategoryScale, LinearScale, PointElement
)

const VisitorReportsScreen = () => {

    const {
        loading,
        dataLoading,
        filterLoading,
        data,
        tenants,
        selectedTenant,
        sites,
        selectedSite, setSelectedSite,
        columns,
        applyFilters,
        onSiteChange,
        onTenantChange,
        search, setSearch,
        tableParams,
        handleTableChange,
        onDateChange,
        searchOptions,
        date,
        isModalOpen, setIsModalOpen,
        closeExportModal,
        loader
    } = useVisitorReports();
    
    const [form] = Form.useForm();


    // Define the expanded row render function
    const expandedRowRender = (record) => {

        
        // Define columns for the expanded row content
        // Prepare the data for the expanded row
    let expandedData = [
        ...record.form_data.fields || [],
      ];
      if(record.employee_id) {
        expandedData.push({
            name: record.form_data.employees.label,
            value: record.employee_id ? record.employee.name : "-",
        })
      }
  
      // Define columns for the expanded row content
      const expandedColumns = [
        {
          title: "Field Name",
          dataIndex: "name",
          key: "name", // fixed typo from 'nane' to 'name'
        },
        {
          title: "Value",
          dataIndex: "value",
          key: "value",
        },
      ];

        return (
            <Table
                columns     =   {expandedColumns}
                dataSource  =   {expandedData}
                pagination  =   {false} // Disable pagination for the expanded table
                rowKey      =   "label"
                size        =   "small"
                style       =   {{ margin: 0 }} // Optional: Adjust margin for a cleaner look
            />
        );
    };

    console.log(loader)

  return (
    <>
        <DashboardLayout>
            <div className="gb-head">
                <h2 className='font-semibold'>Visitor Reports</h2>
            </div>
            {/* <div className={styles.analyticsContainer}>
                <Card>
                    <h3 className="mb-1">Overview</h3>
                    <div className={styles.analyticsContainer__options}>
                        <p className={selectedUnit === 'week' ? styles.active : ''} onClick={() => onChartUnitChange('week')}>This Week</p>
                        <p className={selectedUnit === 'this-month' ? styles.active : ''} onClick={() => onChartUnitChange('this-month')}>This Month</p>
                        <p className={selectedUnit === 'month' ? styles.active : ''} onClick={() => onChartUnitChange('month')}>This Year</p>
                    </div>
                    <div className={styles.analyticsContainer__line}>
                        {lineChartData && lineChartData.datasets ?
                        <Line
                            data={lineChartData}
                            options={{ 
                                stacked: true, 
                                responsive: true, 
                                maintainAspectRatio : false,
                                scales : {
                                    x: {
                                        ticks: {
                                            maxRotation: 90, // Rotate x-axis labels for better visibility
                                            autoSkip: false, // Prevent labels from being skipped
                                            fontSize: 12, // Adjust font size for labels
                                        },
                                    },
                                    y: {
                                    },
                                },
                                plugins: {
                                    title: {
                                        display: true,
                                        text: 'Visitors Signed In & Out',
                                        font: {
                                            size: 16,
                                            weight: 'bold',
                                        },
                                    },
                                },
                            }} 
                        /> : ''}
                    </div>
                </Card>
            </div> */}
            <div className={styles.reportsContainer}>
                <div className={styles.reportsContainer__filter}>
                    <Card>
                        <div className={styles.reportsContainer__filter__content}>
                            <h3>Apply Filters</h3>
                                <Form className="mt-5" form={form} layout='vertical' onFinish={applyFilters}>
                                    <Form.Item
                                        label   = "Select Site" 
                                        name    = "site"
                                    >
                                        <Select
                                            size            = "large"
                                            defaultValue    = {selectedSite}
                                            onChange        = {(e) => { setSelectedSite(e); onSiteChange(e); form.setFieldsValue({tenant: 'all'}) }}
                                            options         = {sites}
                                            className       = 'w-full'
                                            disabled        = {loader.sites} 
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label   = "Select Tenant"
                                        name    = "tenant"
                                    >
                                        <Select
                                            size            = "large"
                                            defaultValue    = {selectedTenant}
                                            onChange        = {onTenantChange}
                                            options         = {tenants}
                                            className       = "w-full"
                                            disabled        = {loader.tenants}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Select Date Range"
                                        name="date"
                                    >
                                        <RangePicker 
                                            size        = 'large'
                                            onChange    = {onDateChange}
                                            className   =  "w-full"
                                        />
                                    </Form.Item>
                                    <Collapse
                                        items={[
                                            {
                                            key     : '1',
                                            label   : 'Advanced Search',
                                            children: 
                                                <>
                                                    <Form.Item>
                                                        <Select
                                                            allowClear
                                                            placeholder     = "Search by"
                                                            size            = "large"
                                                            onChange        = {(e) => setSearch({...search, searchBy : e})}
                                                            className       = "w-full"
                                                            defaultValue    = "Name"
                                                            options         = {searchOptions}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <Input
                                                            allowClear
                                                            size            = "large"
                                                            placeholder     = 'Enter search keyword'
                                                            value           = {search.searchByValue}
                                                            onChange        = {(e) => {setSearch({...search, searchByValue : e.target.value}) }}
                                                            className       = "w-full"
                                                        />
                                                    </Form.Item>
                                                </>,
                                            },
                                        ]}
                                    />
                                    <Form.Item>
                                        <Button
                                            block 
                                            className   = 'mt-10' 
                                            type        = "primary" 
                                            size        = "large" 
                                            htmlType    = 'submit'
                                        >
                                            Apply Filters
                                        </Button>
                                    </Form.Item>
                                </Form>
                        </div>
                    </Card>
                </div>
                <div className={styles.reportsContainer__data}>
                    <Card>
                        <div className='flex justify-end'>
                            <Button 
                                size="large" 
                                icon={<CiExport />} 
                                onClick={() => setIsModalOpen(true)}>
                                    Export to CSV
                                </Button>
                        </div>
                        <div>
                            <Table 
                                columns     = {columns} 
                                dataSource  = {data}
                                className   = 'mt-5' 
                                pagination  = {tableParams.pagination}
                                loading     = {loader.visitors}
                                onChange    = {handleTableChange}
                                expandable  = {{
                                    expandedRowRender,
                                    defaultExpandedRowKeys: [], // Optionally set default expanded rows
                                }}
                            />
                        </div> 
                    </Card>
                </div>
            </div>
            {isModalOpen &&
            <VisitorExportModal 
                isModalOpen         = {isModalOpen}
                setIsModalOpen      = {setIsModalOpen} 
                closeExportModal    = {closeExportModal}
                selectedDate        = {date}
                selectedSite        = {selectedSite}
                siteOptions         = {sites}
                selectedTenant      = {selectedTenant}
                tenantOptions       = {tenants} 
            />}
        </DashboardLayout>
    </>
  )
}

export default VisitorReportsScreen