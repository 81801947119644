import { Route, Navigate, Routes, Outlet, useNavigate } from 'react-router-dom';
import { AuthProvider, useAuth }  from 'contexts/authContext';
import { showErrorToast } from 'functions/toasts';
import axios from 'axios';
import { APP_CONFIG } from "constants/config";
import { useEffect } from 'react';

const PrivateRoute = ({loading, isAuthenticated, user}) => {
    const loginTime = localStorage.getItem('loginTime');

    const {setIsAuthenticated, setShowSessionWarning} = useAuth();

    // useEffect(() => {
    //     let logoutTimeout;
    //     let warningTimeout
    
    //     if(loginTime) {
    //       const loginTimestamp = new Date(loginTime).getTime();
    //       const logoutTime = loginTimestamp + (4 * 60 * 1000);
    //       const showSessionTimerTime = loginTimestamp + (3 * 60 * 1000);
    //       const timeDifferenceToShowWarning = showSessionTimerTime - Date.now();
    //       const timeDifferenceToLogout = logoutTime - Date.now();
    
    //       if (timeDifferenceToShowWarning > 0) {
    //         warningTimeout = setTimeout(() => {
    //           setShowSessionWarning(true);
    //         }, timeDifferenceToShowWarning);
    //       }

    //       if (timeDifferenceToLogout > 0) {
    //         logoutTimeout = setTimeout(() => {
    //           console.log('LOGOUT');
    //             logout();
    //         }, timeDifferenceToLogout);
    //       }
    //     }
    
    //     return () => {
    //         clearTimeout(warningTimeout);
    //         clearTimeout(logoutTimeout);
    //     }
    
    //   }, [loginTime]);


      const logout = async () => {

        const headers = {
            'Content-Type': 'application/json',
            'Accept' : 'application/json'
        }

        try {

            const response = await axios.post(`${APP_CONFIG.BASE_URL}logout`, null, {headers, withCredentials : true});

            console.log(response)

            if(response.status === 200) {
                setIsAuthenticated(false);
                setShowSessionWarning(false);
            }

        } catch(err) {
            
            showErrorToast('Something Went Wrong');
        }
    }

    return (
        // !loading && user && isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />

        <>
            
            {!loading && user && isAuthenticated ? 
                user.approved_buildings.buildings.length === 0 ? 
                    <Navigate to="/no-sites" replace /> : 
                    <Outlet /> 
                : <Navigate to="/login" replace />
            }
        </>
    )
    
}

export default PrivateRoute;