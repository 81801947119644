import { useState, useEffect } from "react";
import useApi from "hooks/useApi";
import { FEATURES } from "constants/features";
import { showErrorToast, showSuccessToast } from "functions/toasts";

const useRolesAndPermissions = () => {

    const [addRoleModal, setAddRoleModal] = useState(false);
    const [roles, setRoles] = useState([]);
    const [deleteRoleModal, setDeleteRoleModal] = useState(false);
    const [selectedRole, setSelectedRole] = useState('');
    const [loading, setLoading] = useState(true);

    const { get, post, del } = useApi();

    useEffect(() => {
        fetchRoles();
    }, []);

    // Function to get all roles of the building
    const fetchRoles = async () => {
        setLoading(true);

        try {
            const response = await get(`roles`);
            // Removing defualt roles Admin User & Standard User from result
            const filteredRoles = response.data.data.filter((role) => role.name !== 'admin-user' && role.name !== 'standard-user');
            console.log(filteredRoles);
            // Updating object by adding permission value for each features
            const filteredRolesWithPermissions = filteredRoles.map((role) => {
                const permissions = FEATURES.reduce((acc, feature) => {
                  feature.permissions.forEach((permission) => {
                    acc[permission.permission] = role.permissions.includes(permission.permission);
                  });
                  return acc;
                }, {});
              
                return {
                  role_id: role.role_id,
                  name: role.name,
                  display_name : role.display_name,
                  permissions: permissions,
                };
              });
            setRoles(filteredRolesWithPermissions);
            setLoading(false);
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }

    // on Permission Checkbox clicked
    const onPermissionChange = async (role, permission, mustEnable = [], mustDisableOnFalse = []) => {

        let status = !role.permissions[permission];
        let updatedRole;

        const updatedArray = roles.map((item) => {
            if(item.role_id === role.role_id) {
                let data =  {
                    ...item,
                    permissions : {
                        ...item.permissions,
                        [permission] : status,
                    }
                }
                
                if(status && mustEnable) {
                    
                    mustEnable.forEach((permission) => {
                        console.log(data[permission])
                        if(!role.permissions[permission]) {
                            data.permissions[permission] = true
                        }
                    })
                }

                if(!status && mustDisableOnFalse) {
                    mustDisableOnFalse.forEach((permission) => {
                        console.log(data[permission])
                        if(role.permissions[permission]) {
                            data.permissions[permission] = false
                        }
                    })
                }

                updatedRole = data;

                console.log(updatedRole)

                return data;
            }
            return item;
        });
        console.log(updatedArray)
        await saveChanges(updatedRole);
        setRoles(updatedArray);
    }

    // Save Permission Change
    const saveChanges = async (updatedRole) => {

        // Creating an array of permissions with value true
        let permissions= [];
        for (const [permission, value] of Object.entries(updatedRole.permissions)) {
            if(value) {
                permissions.push(permission);
            }
        }

        // Data object for api call
        let data = {
            permissions : permissions
        }

        // Calling API to update the permission
        try {
            await post(`roles/${updatedRole.role_id}`, data);
            showSuccessToast('Permission Updated')
        } catch (err) {
            showErrorToast('Something Went Wrong');
        }
    }

    // Function to close Add Role Modal
    const closeAddRoleModal = () => {
        fetchRoles();
        setAddRoleModal(false);
    }

    // On Delete Click
    const onDeleteRoleClick = (role) => {
        setSelectedRole(role);
        setDeleteRoleModal(true);
    }

    // Function to delete a role - passed with delete role modal
    const deleteRole = async () => {
        setLoading(true);
        try {
            await del(`roles/${selectedRole.role_id}/delete`);
            showSuccessToast('Role Deleted Successdfully');
            window.location.reload();
        } catch(err) {
            console.log(err);
            showErrorToast('Something Went Wrong');
        }
    }

    const closeDeleteRoleModal = () => {
        fetchRoles();
        setDeleteRoleModal(false);
    }

    return {
        addRoleModal, setAddRoleModal,
        roles, setRoles,
        deleteRoleModal, setDeleteRoleModal,
        selectedRole, setSelectedRole,
        onPermissionChange,
        closeAddRoleModal,
        onDeleteRoleClick,
        closeDeleteRoleModal,
        deleteRole,
        fetchRoles,
        loading, setLoading,
    }
}

export default useRolesAndPermissions;