import { render } from '@testing-library/react';
import UserPlaceholder from 'assets/images/user-placeholder.png';

export const DEFAULT_TENANT_OPTIONS = [
    {
        label : 'All Tenants',
        value : 'all'
    }
];

export const DEFAULT_TABLE_PARAMS = {
    pagination: {
        pageSizeOptions : ['10', '20', '30', '50'],
        showSizeChanger : true,
        current         : 1,
        pageSize        : 10,
        defaultPageSize : 10,
    },
}

export const DEFAULT_SEARCH_PARAMS = {
    searchBy        : 'name',
    searchByValue   : ''
}

export const DEFAULT_TABLE_COLUMNS = [
    {
        title           : 'Visitor Name',
        dataIndex       : 'visitor_name',
        key             : 'visitor_name',
        render          : (_, { visitor_name, photo_url }) =>  
                            <div style={{ display : 'flex', justifyContent : 'flex-start', alignItems : 'center', gap : '1rem'}}>
                                {/* <img src={photo_url ? getImageURL(photo_url) : UserPlaceholder} style={{ borderRadius : '50%', width : '35px' }} /> */}
                                <img src={UserPlaceholder} style={{ borderRadius : '50%', width : '35px' }} />
                                <p style={{ fontWeight : '500' }}>{visitor_name}</p>
                            </div>,
    },
    {
        title           : 'Tenant',
        dataIndex       : 'tenant_name',
        key             : 'tenant_name',
    },
    {
        title           : 'Sign In Time',
        dataIndex       : 'sign_in_time',
        key             : 'sign_in_time',
    },
    {
        title           : 'Sign Out Time',
        dataIndex       : 'sign_out_time',
        key             : 'sign_out_time',
    }
];

export const DEFAULT_SEARCH_OPTIONS = [
    {
        label   : 'Name',
        value   : 'name',
        key     : 'name'
    },
    {
        label   : 'Others (Email, Phone, etc)',
        value   : 'other',
        key     : 'other'
    }
];

export const DEFAULT_LOADER_PARAMS = {
    sites       : true,
    tenants     : true,
    visitors    : true
}