import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useApi from "hooks/useApi";
import { showErrorToast, showSuccessToast } from "functions/toasts";

const useSignInForm = () => {

    const [templateType, setTemplateType] = useState('global');
    const [globalTemplates, setGlobalTemplates] = useState([]);
    const [tenantTemplates, setTenantTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [selectedTemplateType, setSelectedTemplateType] = useState('');
    const [modalMode, setModalMode] = useState('add');
    const [addNewTemplateModalStatus, setAddNewTemplateModalStatus] = useState(false);
    const [templateModalType, setTemplateModalType] = useState('global');
    const [templateModalStatus, setTemplateModalStatus] = useState(false);
    const [loading, setLoading] = useState(true);

    const {id, tenantId} = useParams();
    const {get, post} = useApi();

    useEffect(() => {
        fetchGlobalTemplates();
    },[]);

    const fetchGlobalTemplates = async () => {
        setLoading(true);

        try {
            const response = await get(`buildings/${id}/form-templates`);
            const data = response.data.data;

            let globalTemplates = [];

            data.forEach(template => {
                globalTemplates.push({
                    label       : template.name,
                    value       : template.template_id,
                    form        : template.form,
                    templateId  : template.template_id
                })
            });

            console.log('Global', globalTemplates)
            setGlobalTemplates(globalTemplates);


            // Get Tenants Template
            try {
                const response = await get(`tenants/${tenantId}/form-templates`);
                const data = response.data.data;
                const tenantTemplates = [];

                data.forEach((template) => {
                    tenantTemplates.push({
                        label       : template.name,
                        value       : template.template_id,
                        form        : template.form,
                        templateId  : template.template_id
                    })
                })

                console.log('Tenant Temp', tenantTemplates);
                const allTemplates = [...globalTemplates, ...tenantTemplates];
                console.log('All Temps', allTemplates);
                setTenantTemplates(tenantTemplates);
                console.log(data);



                // Get Tenant Current Template
                try {
                    const response = await get(`tenants/${tenantId}`);
                    const data = response.data.data.attributes;
                    if (data.template_id) {
                        const selectedGlobalTemplate = globalTemplates.filter((template) => template.templateId === data.template_id)[0];
                        const selectedTenantTemplate = tenantTemplates.filter((template) => template.templateId === data.template_id)[0];
                        if(selectedGlobalTemplate) {
                            setTemplateType('global')
                            setSelectedTemplate(selectedGlobalTemplate);
                            setSelectedTemplateType('global');
                        } else {
                            setTemplateType('custom')
                            setSelectedTemplate(selectedTenantTemplate);
                            setSelectedTemplateType('custom');
                        }
                        // console.log(tenantTemplate)
                        // setSelectedTemplate(tenantTemplate[0]); // Assuming you want to select the first matching template
                    }
    
    
                    
        
                    setLoading(false);
                } catch(err) {
                    console.log(err);
                }



    
                setLoading(false);
            } catch(err) {
                console.log(err);
            }



            

            setLoading(false);

        } catch(err) {
            console.log(err);
            showErrorToast('Something Went Wrong');
        }
    }








    const fetchTenantTemplates = async () => {
        try {
            const response = await get(`tenants/${tenantId}/form-templates`);
            const data = response.data.data;
            setTenantTemplates(data);
            console.log(data);

            setLoading(false);
        } catch(err) {
            console.log(err);
        }
    }

    
    const closeTemplateModal = () => {
        // setModalMode('add');
        // setSelectedTemplate('');
        setTemplateModalStatus(false)
    }

    const addNewGlobalTemplateModal = () => {
        setModalMode('add');
        templateModalStatus('tenant');
        setAddNewTemplateModalStatus(true);
    }


    const fetchTenantData = async () => {
        setLoading(true);
        try {
            console.log(globalTemplates);
            const response = await get(`tenants/${tenantId}`);
            const data = response.data.data.attributes;
            if (data.template_id) {
                const tenantTemplate = globalTemplates.filter((template) => template.templateId === data.template_id);
                setSelectedTemplate(tenantTemplate[0]); // Assuming you want to select the first matching template
            }

            setLoading(false);
        } catch(err) {
            console.log(err);
        }
    }


    // Function to update the selected template to the tenant
    const updateTenantTemplate = async () => {
        console.log(selectedTemplate);

        try {
            await post(`tenants/${tenantId}/sign-in-form`, {
                template_id : selectedTemplate.templateId
            });

            showSuccessToast('Sign In Form Updated Successfully');
        } catch(err) {
            console.log(err);
        }
    }

    return {
        templateType, setTemplateType,
        fetchGlobalTemplates,
        globalTemplates, setGlobalTemplates,
        tenantTemplates, setTenantTemplates,
        selectedTemplate, setSelectedTemplate,
        addNewGlobalTemplateModal,
        closeTemplateModal,
        templateModalType, 
        setTemplateModalType,
        modalMode, setModalMode,
        fetchTenantTemplates,
        addNewTemplateModalStatus, setAddNewTemplateModalStatus,
        updateTenantTemplate,
        selectedTemplateType, setSelectedTemplateType,
        templateModalStatus, setTemplateModalStatus,
        loading, setLoading
    }
}

export default useSignInForm;