import { useState, useEffect }  from "react"
import { useParams }            from "react-router-dom";
import { showSuccessToast, showErrorToast } from "functions/toasts";
import { checkIfEmpty } from "functions/functions";
import useApi                   from "hooks/useApi";

const useAllTenants = () => {

    const [addNewTenantModal, setAddNewTenantModal] = useState(false);
    const [tenants, setTenants]                     = useState([]);
    const [searchBy, setSearchBy]                   = useState('');
    const [tenantName, setTenantName]               = useState('');
    const [error, setError]                         = useState('');
    const [errorMessages, setErrorMessages]         = useState('');
    const [tenantAddLoading, setTenantAddLoading]   = useState(false);
    const [loading, setLoading]                     = useState(true);

    const {id} = useParams();

    const {get, post} = useApi();

    useEffect(() => {
        fetchAllTenants();
    }, []);

    // Get All Tenants
    const fetchAllTenants = async () => {
        try {
            const response = await get(`buildings/${id}/tenants`);
            console.log(response);
             // Sorting Response based on tenant name
             response.data.data.sort((a,b) => {
                if(a.attributes.name < b.attributes.name) {
                    return -1;
                }
                if(a.attributes.name > b.attributes.name) {
                    return 0;
                }
            });
            setTenants(response.data.data);

            setLoading(false);

        } catch(err) {
            console.log(err);
        }
        
    }

    // When button is pressed
    const onButtonClick = async () => {

        setTenantAddLoading(true);

        let trimmedTenantName = tenantName.trim();

        const errorObj = {
            tenantName : false
        }

        const errorMessageObj = {
            tenantName : ''
        }

        if(checkIfEmpty(trimmedTenantName)) {
            errorObj.tenantName = true;
            errorMessageObj.tenantName = "Tenant Name is required";
        }

        if(!errorObj.tenantName) {
            try {

                // Calling API to add new site
                await post(`buildings/${id}/add-tenant`, {
                    name : tenantName,
                });

                await fetchAllTenants();

                closeAddNewTenantModal();

                showSuccessToast('New Tenant Added Successfully');

    
            } catch(err) {
                showErrorToast('Something Went Wrong');
            }
        }

        setError(errorObj);
        setErrorMessages(errorMessageObj);

        setTenantAddLoading(false);
    }
    

    
    const closeAddNewTenantModal = () => {
        setTenantName('');
        setAddNewTenantModal(false);
    }

    return {    
        addNewTenantModal,
        setAddNewTenantModal,
        closeAddNewTenantModal,
        tenants,
        searchBy, setSearchBy,
        tenantName, setTenantName,
        error, setError,
        errorMessages, setErrorMessages,
        tenantAddLoading, setTenantAddLoading,
        onButtonClick,
        loading
    }
}

export default useAllTenants