import { useNavigate } from 'react-router-dom';
import TextInput from 'components/inputs/textInput/textInput';
import Col from 'layouts/col/col';
import Button from 'components/buttons/button';
import useLogin from './useLogin';
import styles from './login.module.scss';
import Logo from 'assets/images/visitorpad logo.png';
import { useEffect } from 'react';


const LoginScreen = () => {
  const {
      email,
        setEmail,
        password,
        setPassword,
        error,
        errorMessages,
        onLoginClick,
        loading,
        message
  } = useLogin();

  const navigate = useNavigate();

  useEffect(() => {
    console.log('login')
  }, [])

  return (
    <div className="authContainer">
        <div className='left'>
            <div className='background'></div>
        </div>
        <div className='right'>
            <div className="content">
              <div className="logo">
                <img src={Logo} />
              </div>
              {message &&
              <div className='success-alert mt-2'>
                <p>{message}</p>
              </div> }
              <div className="head">
                <h3 className='heading'>Log in to Dashboard</h3>
                <p className='info'>Log in to your VisitorPower Dashboard to manage visitors, deliveries, sites, tenants and many more.</p>
              </div>
              <form>
                <Col gap="0.5rem">
                  <TextInput 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    placeholder="Enter Email Address" 
                    label="Email Address" 
                    isError={error.email}
                    errorMessage={errorMessages.email}
                    required />
                  <TextInput 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    type="password" 
                    placeholder="Enter Password" 
                    label="Password"
                    isError={error.password}
                    errorMessage={errorMessages.password} 
                    required />
                  <Button text="Login to Dashboard" onClick={onLoginClick} loading={loading} disabled={loading} loadingText="Logging In..." classNames="mt-1" />
                  <p className='text-center mt-1'>Forget Password ? <span><a className='link' onClick={() => navigate('/reset-password')}>Reset Password</a></span></p>
                  {/* <p className='text-center mt-1' onClick={() => navigate('/register')}>Don't have an Account ? <span><a className='link'>Create New Account</a></span></p> */}
                </Col>
              </form>
            </div>
        </div>
    </div>
  )
}

export default LoginScreen