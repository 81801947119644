import { useState } from 'react';
import InnerImageZoom   from 'react-inner-image-zoom';
import useApi from 'hooks/useApi';
import CustomModal      from '../modal'
import SelectInput      from 'components/inputs/selectInput/selectInput'
import Button           from 'components/buttons/button';
import Parcel           from 'assets/images/parcel.jpg';
import { dateTimeFormat } from 'functions/functions';
import styles           from './collectionDetailsModal.module.scss';
import { showErrorToast, showSuccessToast } from 'functions/toasts';



const Body = ({
    selectedDelivery
}) => {
    return (
       <div className={styles.collectionBody}>
            <h3 className='gb_title'>Parcels to Collect</h3>
            {selectedDelivery.deliveries.map((delivery) => {
                return (
                    <div className={styles.parcel}>
                        <h3 className='text-reg text-bold'>Parcel No: UD-{delivery.delivery_id.toString().padStart(3, '0')}</h3>
                        <p>Arrival Time : {dateTimeFormat(delivery.arrival_time)}</p>
                        <p>Notes : {delivery.notes || '-'}</p>
                    </div>
                )
            })}
            
       </div>
    )
}

const Footer = ({markAsCollected, loading}) => {
    return (
        <div className={styles.footerContainer}>
            <div className={styles.left}>
                {/* <Button text="Delete Delivery" backgroundColor="#F9C8C8" color="#B00000" /> */}
            </div>
            <div className={styles.right}>
                {/* <Button text="Save Changes" backgroundColor="#002547" /> */}
                <Button text="Mark as Collected" onClick={markAsCollected} loading={loading} disabled={loading} loadingText="Submitting..." />
            </div>
        </div>
    )
}

/**
 * 
 * @param {Boolean} isModalOpen     - Shows or hides modal
 * @param {Function} closeModal     - Function to close the modal 
 */

const CollectionDetailsModal = ({isModalOpen, closeModal, selectedDelivery, fetchData}) => {

    const [loading, setLoading] = useState(false);

    const {post} = useApi();

    const markAsCollected = async () => {
        setLoading(true);
        try {

            await post(`deliveries/collection/${selectedDelivery.collection_id}/collected`);
            await fetchData();
            showSuccessToast('Collection mark as collected');
            closeModal();

        } catch(err) {
            showErrorToast('Something Went Wrong');
        }

        setLoading(false);
    }

  return (
    <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} width="65%" minHeight="60%">
        {{ 
            header : 'Collection Details',
            body : <Body selectedDelivery={selectedDelivery} />,
            footer : <Footer markAsCollected={markAsCollected} loading={loading} />
         }}
    </CustomModal>
  )
}

export default CollectionDetailsModal