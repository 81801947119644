import { useContext } from "react"
import { useNavigate, useLocation, useParams } from "react-router-dom"
import BreadCrumbContext from "contexts/breadcrumbContext"
import DashboardLayout from "layouts/dashboardLayout/dashboard.jsx"
import PageHeader from "components/pageHeader/pageHeader"
import AddNewTenantModal from "components/modal/addNewTenantModal/addNewTenantModal"
import TextInput from "components/inputs/textInput/textInput"
import Button from "components/buttons/button"
import Card from "components/card/card"
import Row from "layouts/row/row"
import Skeleton from "react-loading-skeleton"
import ViPowerLogo from 'assets/images/ViPower Logo.png';
import PCPLogo from 'assets/images/PCP Logo.png';
import TenantIcon from 'assets/images/tenants green.png';
import useAllTenants from "./useAllTenant"
import styles from './allTenants.module.scss'
import CheckPermission from "components/checkPermission/checkPermission"
import { getImageURL, truncateString } from "functions/functions"

const AllTenantsScreen = () => {

    const {
        addNewTenantModal,
        setAddNewTenantModal,
        closeAddNewTenantModal,
        tenants,
        searchBy, setSearchBy,
        tenantName, setTenantName,
        error, setError,
        errorMessages, setErrorMessages,
        tenantAddLoading, setTenantAddLoading,
        onButtonClick,
        loading
    } = useAllTenants();

    const navigate = useNavigate();

    const { id, tenantId } = useParams();

    const {
        managedSiteName, setManagedSiteName,
        managedSiteId, setManagedSiteId,
        managedTenantName, setManagedTenantName,
        managedTenantId, setManagedTenantId,
        setBreadcrumbData
    } = useContext(BreadCrumbContext);

    const location = useLocation();

    function Box({ children }) {
        return (
          <div
            style={{
              border: '0.1px solid #ebebeb',
              borderRadius : '5px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent : 'center',
              lineHeight: 2,
              padding: '1rem',
              marginBottom: '0.5rem',
              width: 200,
              height: 200
            }}
          >
            {children}
          </div>
        );
    }

  return (
    <>
        <DashboardLayout>
            <PageHeader 
            title = "Tenants" 
            breadcrumbsArray = {[
                {
                    name : 'Sites',
                    to : '/manage/sites'
                },
                {
                    name : managedSiteName,
                    to : `/manage/sites/${managedSiteId}/`
                },
                {
                    name : 'Manage Tenants',
                }
            ]}  
            />
        <Card>
            <div className={styles.topContainer}>
                <div className={styles.left}>
                    <TextInput value={searchBy} onChange={(e) => setSearchBy(e.target.value)} showError={false} flex={1} placeholder="Search by name" />
                </div>
                <CheckPermission permission="tenants_add_edit">
                    <div className={styles.right}>
                        <Button text="+ Add New Tenant" onClick={() => setAddNewTenantModal(true)} />
                    </div>
                </CheckPermission>
            </div>
            {!loading ?
            <div className={styles.tenantsContainer}>
                {tenants.filter((tenant) => tenant.attributes.name.toLowerCase().includes(searchBy.toLowerCase())).map((tenant) => {
                    return (
                        <div className={styles.tenant} onClick={() => {setBreadcrumbData("tenant", {id : tenant.attributes.tenant_id, name : tenant.attributes.name}); navigate(`/manage/sites/${id}/tenants/${tenant.attributes.tenant_id}/details`) }}>
                            <p className={styles.tenantName}>{tenant.attributes.name}</p>
                            {/* <p className={styles.status}>Active</p> */}
                            {tenant.attributes.logo_url && 
                                <div className={styles.tenantImg}>
                                    <img src={tenant.attributes.logo_url ? getImageURL(tenant.attributes.logo_url) : TenantIcon} />
                                </div>
                            }
                        </div>
                    )
                })}
            </div> : 
            <Row gap="1rem" classNames="mt-3">
                <Box>
                    <Row justify="center">
                        <Skeleton circle width={70} height={70} />
                    </Row>
                    <div className="mt-2">
                        <Skeleton count={2} />
                    </div>
                </Box>
                <Box>
                    <Row justify="center">
                        <Skeleton circle width={70} height={70} />
                    </Row>
                    <div className="mt-2">
                        <Skeleton count={2} />
                    </div>
                </Box>
                <Box>
                    <Row justify="center">
                        <Skeleton circle width={70} height={70} />
                    </Row>
                    <div className="mt-2">
                        <Skeleton count={2} />
                    </div>
                </Box>
            </Row>
            }

        </Card>
        </DashboardLayout>

        <AddNewTenantModal 
            isModalOpen={addNewTenantModal} 
            closeModal={closeAddNewTenantModal}
            properties={{ 
                tenantName, setTenantName,
                error, setError,
                errorMessages, setErrorMessages,
                tenantAddLoading, setTenantAddLoading,
                onButtonClick
             }} 
        />
    </>
  )
}

export default AllTenantsScreen