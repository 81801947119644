import React, {useState}                    from 'react'
import { showErrorToast, showSuccessToast } from 'functions/toasts';
import CustomModal                          from '../modal'
import Row                                  from 'layouts/row/row';
import Button                               from 'components/buttons/button';
import useApi                               from 'hooks/useApi';
import Checkbox                             from 'components/inputs/checkbox/checkbox';
import Col                                  from 'layouts/col/col';

const Body = ({
    deleteType, setDeleteType,
    selectedRole
}) => {
    return (
        <div>
            <p>Are you sure you want to delete the role {selectedRole.display_name} ?</p>
            {/* <p>All the users with this role would be changed to STANDARD USER</p> */}
            <Col classNames="mt-3">
                <>
                    <Checkbox label="Delete role only" checked={deleteType === "role"} onChange={() => setDeleteType("role")} />
                    {deleteType === "role" && <p className='warning-text'>Users currently assigned with this role will be re-assign to "STANDARD USER"</p> }
                    <Checkbox classNames="mt-1" label="Delete role and users with this role" checked={deleteType === "role_users"} onChange={() => setDeleteType("role_users")} />
                </>
            </Col>
        </div>
    )
}

const Footer = ({deleteRole, closeModal, deleteType}) => {
    return (
        <Row justify="flex-end" gap="1rem">
            <Button text="No, Cancel" onClick={closeModal} backgroundColor="#D3D3D3" color="#3A3A3A" />
            <Button text="Yes Delete" onClick={deleteRole} disabled={deleteType === ''} backgroundColor="#eb4034" />
        </Row>
    )
}

/**
 * 
 * @param {Boolean} isModalOpen     - Shows or hides modal
 * @param {Function} closeModal     - Function to close the modal 
 */

const DeleteRoleModal = ({isModalOpen, closeModal, selectedRole}) => {

    console.log(selectedRole)
    
    const [deleteType, setDeleteType] = useState('');

    const {post} = useApi();

    // Function to delete a role - passed with delete role modal
    const deleteRole = async () => {
        // setLoading(true);
        let data = {
            delete_type : deleteType
        }

        try {
            await post(`roles/${selectedRole.role_id}/delete`, data);
            showSuccessToast('Role Deleted Successdfully');
            closeModal();
        } catch(err) {
            console.log(err);
            showErrorToast('Something Went Wrong');
        }
    }


  return (
    <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} width="35%" minHeight="40%">
        {{ 
            header : 'Delete Role',
            body :  <Body  
                        deleteType = {deleteType} setDeleteType={setDeleteType}
                        selectedRole={selectedRole}
                    />,
            footer : <Footer deleteRole={deleteRole} closeModal={closeModal} deleteType={deleteType} />
         }}
    </CustomModal>
  )
}

export default DeleteRoleModal