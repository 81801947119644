import { useState, useEffect, useContext }  from "react"
import { capitalizeWords }                  from "functions/functions";
import { AuthContext }                      from 'contexts/authContext';
import { showErrorToast, showSuccessToast } from "functions/toasts";
import useApi                               from "hooks/useApi";
import RSwitch                              from "components/switch/switch";

const useUsers = () => {

    const [loading, setLoading]                             = useState(true);
    const [searchByKey, setSearchByKey]                     = useState('');
    const [users, setUsers]                                 = useState([]);
    const [filteredUsers, setFilteredUsers]                 = useState([]);
    const [userRoles, setUserRoles]                         = useState([]);
    const [selectedRole, setSelectedRole]                   = useState('');
    const [selectedUser, setSelectedUser]                   = useState('');
    const [mode, setMode]                                   = useState('add');
    const [addNewUserModalStatus, setAddNewUserModalStatus] = useState(false);

    const {get, post} = useApi();

    const {user} = useContext(AuthContext);

    console.log(user);

    useEffect(() => {
        fetchData();
    },[])

    // Get All Data
    const fetchData = async () => {
        fetchUsers();
        fetchUserRoles();
    }

    // Fetch Users Data
    const fetchUsers = async () => {
        setLoading(true);

        try {
            const response = await get(`account/${user.account_id}/users`);
            setUsers(response.data.data);
            console.log(response.data.data)
            setFilteredUsers(response.data.data);
            setLoading(false);
        } catch(err) {
            showErrorToast('Something Went Wrong');
            console.log(err);
        }
    }

    // Fetch User Roles
    const fetchUserRoles = async () => {
        setLoading(true);

        let defaultRoles = [
            {
                value : 'all',
                label : 'All Roles'
            },
            {
                value : 1,
                name : 'owner',
                label : 'Owner'
            },
            {
                value : 2,
                name : 'admin-user',
                label : 'Admininstrator'
            },
            {
                value : 3,
                name : 'standard-user',
                label : 'Standard User'
            }
        ];

        try {
            const response = await get(`roles`);
            let data = response.data.data;

            console.log(response);

            data = data.map((role) => {
                return {
                    ...role,
                    value : role.role_id,
                    label : capitalizeWords(role.display_name)
                }
            })

            const options = [...defaultRoles, ...data];

            setUserRoles(options);
            setSelectedRole({
                label : 'All Roles',
                value : 'all'
            })
            setLoading(false);
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }

    // On User Type is Changed
    const onUserTypeChange = (e) => {
        console.log(e);
        setSelectedRole(e);
        if(e.value !== 'all') setFilteredUsers(users.filter((user) => user.role.role_id == e.value));
        else setFilteredUsers(users)
    }
    
    // Table Columns
    const columns = [
        
        {
            name: 'Name',
            selector: row => row.name,
        },
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'Role',
            selector: row => capitalizeWords(row.role.display_name),
        },
        {
            name : "Status", 
            width: '250px', 
            cell : (row) => (
            <div style={{ display : 'flex', justifyContent : 'flex-start', alignItems : 'center', gap : '1rem'}}>
                <RSwitch disabled={(row.user_id === user.user_id) || (row.role.role_id === 1)} status={row.is_active} onChange={() => handleStatusChange(row.user_id, !row.is_active)} />
            </div>
            )
        },
    ];

    // On a user row click
    const onRowClick = (row) => {
        setSelectedUser(row);
        setMode('edit');
        setAddNewUserModalStatus(true);
    }

    const closeAddNewUserModal = async () => {
        await fetchData();
        setMode('add');
        setSelectedUser('');
        setAddNewUserModalStatus(false);
    }

    // When user status is changed
    const handleStatusChange = async (rowId, newStatus) => {

        let data = {
            is_active : newStatus ? 1 : 0
        }

        try {
            await post(`admin-user/${rowId}`, data);

            console.log(rowId, newStatus);
            // Create a copy of the data array to avoid mutating the state directly
            const updatedData = users.map((user) => {
                if(user.user_id === rowId) {
                    return {
                        ...user, 
                        is_active: newStatus === true ? 1 : 0
                    }
                }

                return user
            }
            //   user.user_id === rowId ? { ...user, is_active: newStatus === true ? 1 : 0 } : user
            );

            

            console.log(updatedData)
            setFilteredUsers(updatedData);
            showSuccessToast('User Status Updated');

        } catch(err) {
            showErrorToast('Something Went Wrong')
        }
        

        
      }
  
    return {
        loading,
        users,setUsers,
        filteredUsers, setFilteredUsers,
        searchByKey, setSearchByKey,
        columns, handleStatusChange,
        userRoles, setUserRoles,
        selectedRole, setSelectedRole,
        onRowClick,
        onUserTypeChange,
        addNewUserModalStatus, setAddNewUserModalStatus,
        closeAddNewUserModal,
        selectedUser, mode
    }
}

export default useUsers