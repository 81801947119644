import { useState } from "react"
import { useParams } from "react-router-dom"
import useApi from "hooks/useApi"
import CustomModal from "../modal"
import TextInput from "components/inputs/textInput/textInput"
import SelectInput from "components/inputs/selectInput/selectInput"
import { MdDelete }                                 from "react-icons/md";
import Icon from "components/icon/icon"
import Checkbox from "components/inputs/checkbox/checkbox"
import Button from "components/buttons/button"
import styles from './addNewFieldModal.module.scss';
import { showSuccessToast } from "functions/toasts"
import { useEffect } from "react"
import { capitalizeWords } from "functions/functions"


const Body = ({
        mode,
        fieldName,
        setFieldName,
        displayLabel,
        setDisplayLabel,
        optionValue, setOptionValue,
        options, setOptions,
        addOption,
        removeOption,
        required,
        setRequired,
        selectedField,
        fieldType,
        setFieldType, 
        fieldTypeOptions,
        handleOptionKeyPress,
        loading
    }) => {

        console.log(options)
        console.log(loading)

    return (
        <>
        {!loading?
            <div className={styles.formContainer}>
                {mode === 'edit' ? <p className="mb-2">Editing field inside template would update the field for this specific template only. No other templates would reflect this change.</p> : ''}
                <TextInput disabled={mode === 'edit' ? true : false} required value={fieldName} onChange={(e) => setFieldName(e.target.value)} label="Field Name" placeholder="Enter Field Name" />
                <TextInput required value={displayLabel} onChange={(e) => setDisplayLabel(e.target.value)} label="Field Display Label" placeholder="Enter Field Label" />
                {selectedField.fieldId !== 'employees' && <SelectInput label="Field Type" required menuPlacement="top" defaultValue={fieldType} onChange={(e) => setFieldType(e)} placeholder="Select Field Type" options={fieldTypeOptions} /> }
                {fieldType?.value === "dropdown" && 
                <div className={styles.optionsContainer}>
                    <div className={styles.optionsContainer__inputContainer}>
                        <TextInput placeholder="Enter Option" value={optionValue} onChange={(e) => setOptionValue(e.target.value)} onKeyPress={handleOptionKeyPress} />
                        <div className={styles.optionsContainer__inputContainer__add} onClick={addOption}><p>+</p></div>
                    </div>
                    <div className={styles.optionsContainer__values}>
                        <ul>
                            {options.length > 0 && options.map((option) => {
                                return (
                                    <li>
                                        <p>{option}</p>
                                        {/* <p onClick={() => removeOption(option)}>Remove</p> */}
                                        <div className={styles.removeOption} onClick={() => removeOption(option)}>
                                            <Icon icon={<MdDelete />} />
                                            <p>Remove</p>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>}
                {mode !== 'add' && <Checkbox classNames="mt-2" label="Field is Required" checked={required} id="required" onChange={()=> setRequired(!required)} /> }
            </div>
        : '' }
        </>
    )
}

const Footer = ({mode, onAddFieldClick, onUpdateFieldClick,  addLoading}) => {
    return (
        <div className={styles.footerContainer}>
            <Button disabled={addLoading} loading={addLoading} loadingText="Adding Field..." text={`${mode == 'add' ? 'Add Field' : 'Update Field'}`} onClick={() => mode === 'edit' ? onUpdateFieldClick() : onAddFieldClick()} />
        </div>
    )
}

const AddNewFieldModal = ({isModalOpen, closeModal, fetchSiteFields, newFieldValue, setField, mode, updateTemplateField, selectedField}) => {
    const [fieldName, setFieldName] = useState(capitalizeWords(newFieldValue));
    const [displayLabel, setDisplayLabel] = useState(`Enter ${newFieldValue}`);
    const [fieldType, setFieldType] = useState({
        value : 'string',
        label : 'Text',
    });
    const [required, setRequired] = useState(false);
    const [optionValue, setOptionValue] = useState('');
    const [options, setOptions] = useState([]);

    const fieldTypeOptions = [
        {
            value : 'string',
            label : 'Text',
        },
        {
            value : 'numeric',
            label : 'Number',
        },
        {
            value : 'email',
            label : 'Email',
        },
        {
            value : 'dropdown',
            label : 'Dropdown'
        }
    ]
    const [loading, setLoading] = useState(true);
    const [addLoading, setAddLoading] = useState(false);

    useEffect(() => {
        if(mode === 'edit') {
            setSelectedField();
        } else {
            setLoading(false);
        }
    }, [])


    const {post} = useApi();
    const {id} = useParams();

    const addOption = () => {
        if (!options.some(option => option.toLowerCase() === optionValue.toLowerCase())) {
            setOptions(options => [...options, optionValue]);
        }
        setOptionValue('');
    }

    const removeOption = (value) => {
        const newOptions = options.filter(option => option !== value);
        setOptions(newOptions);
    }

    // When enter key is pressed on option value
    const handleOptionKeyPress = (e) => {
        if(e.key === 'Enter') {
            e.preventDefault();
            addOption();
        }
    }

    const setSelectedField = () => {
        setLoading(true);

        console.log(typeof selectedField.dropdownOptions)
    
        setFieldName(selectedField.name);
        setDisplayLabel(selectedField.label);
        setRequired(selectedField.required == true ? true : false)
        setFieldType(fieldTypeOptions.filter((type) => type.value === selectedField.type)[0]);
        if(selectedField.type === 'dropdown') {
            setOptions(typeof selectedField.dropdownOptions !== 'object' ? JSON.parse(selectedField.dropdownOptions) : selectedField.dropdownOptions)
        }
        setLoading(false);
    }

    const onUpdateFieldClick = () => {
        updateTemplateField(selectedField, displayLabel, fieldType, required, options);
    }

    const onAddFieldClick = async () => {
        setAddLoading(true);

        const data = {
            name : fieldName,
            type : fieldType.value,
            label : displayLabel
        }

        if(fieldType.value === 'dropdown') {
            data['dropdownOptions'] = options;
        }

        console.log(data);

        try {
            await post(`buildings/${id}/fields`, data);
            // await fetchSiteFields();
            showSuccessToast('Field Added Successfully.');
            setFieldName('');
            setDisplayLabel('');
            setFieldType({
                value : 'string',
                label : 'Text',
            })
            let fieldData = {
                value : data.name,
                label : data.name,
                displayLabel : data.label,
                type : data.type,
            }
            if(data.type === 'dropdown') {
                fieldData['dropdownOptions'] = options
            }
            setField(fieldData)
            closeModal();

        }catch(err) {
            console.log(err);
        }

    }

  return (
    <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} width="40%" >
        {{ 
            header : `${mode == 'add' ? 'Add New Field' : 'Update Field'}`,
            body : <Body 
                mode={mode}
                fieldName={fieldName}
                setFieldName={setFieldName}
                displayLabel={displayLabel}
                setDisplayLabel={setDisplayLabel}
                required={required}
                setRequired={setRequired}
                selectedField={selectedField}
                optionValue={optionValue} setOptionValue={setOptionValue}
                options={options} setOptions={setOptions}
                addOption={addOption}
                removeOption={removeOption}
                handleOptionKeyPress={handleOptionKeyPress}
                fieldType={fieldType}
                setFieldType={setFieldType}
                fieldTypeOptions={fieldTypeOptions}
                loading={loading} 
            />,
            footer : <Footer mode={mode} onUpdateFieldClick={onUpdateFieldClick} onAddFieldClick={onAddFieldClick} addLoading={addLoading} />
         }}
    </CustomModal>
  )
}

export default AddNewFieldModal