import { useState, useEffect }  from "react";
import moment                   from 'moment';
import useApi                   from "hooks/useApi";
import { APP_CONFIG }           from "constants/config";
import { message }      from 'antd';
import axios                    from "axios";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { showSuccessToast } from "functions/toasts";
dayjs.extend(customParseFormat);

const useDeliveryExportModal = ({selectedDate, setIsModalOpen, selectedSite, siteOptions, selectedTenant, tenantOptions }) => {

    const [dates, setDates]                     = useState([]);
    const [value, setValue]                     = useState([]);
    const [tenant, setTenant]                   = useState(selectedTenant || 'all');
    const [addCustomFields, setAddCustomFields] = useState(false);
    const [loader, setLoader]                   = useState({
        'exporting' : false
    });

    const {get} = useApi();
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        if (selectedDate?.startDate && selectedDate?.endDate) {
            // Convert startDate and endDate to moment objects
            const startMoment   = dayjs(selectedDate.startDate, 'YYYY-MM-DD HH:mm:ss');
            const endMoment     = dayjs(selectedDate.endDate, 'YYYY-MM-DD HH:mm:ss');

            // Check if the selected range is within 60 days
            const daysDifference = endMoment.diff(startMoment, 'days');
            if (daysDifference <= 59) {
                // Set the dates and value state with these moments
                setDates([startMoment, endMoment]);
                setValue([startMoment, endMoment]); 
            } else {
                setDates([]);
                setValue([]);
            }
        }
    }, [selectedDate]);

    // Disable dates logic
    const disabledDate = (current) => {
        if (!current) {
            return false;
        }

        // Disable future dates
        if (current > moment().endOf('day')) {
            return true;
        }

        // Check if there are selected dates
        if (dates.length > 0) {
            const [start, end] = dates;

            if (start && end) {
                // Disable dates outside the 60-day range of the start or end date
                const endLimit      = start.clone().add(59, 'days');
                const startLimit    = end.clone().subtract(59, 'days');

                return current.isBefore(startLimit, 'day') || current.isAfter(endLimit, 'day');
            }

            if (start) {
                // When only start date is selected
                const endLimit      = start.clone().add(59, 'days');
                const startLimit    = start.clone().subtract(59, 'days');

                return current.isBefore(startLimit, 'day') || current.isAfter(endLimit, 'day');
            }

            if (end) {
                // When only end date is selected
                const endLimit      = end.clone().add(59, 'days');
                const startLimit    = end.clone().subtract(59, 'days');

                return current.isBefore(startLimit, 'day') || current.isAfter(endLimit, 'day');
            }
        }

        return false;
    };

    // Handle date change
    const onChange = (dates) => {
        console.log(dates)
        setValue(dates);
    };

    // Handle date change
    const onCalendarChange = (dates) => {
        setDates(dates || []);
    };

    const buildQueryParams = ({ tenant, selectedDate }) => {
        const params = new URLSearchParams();
    
        if (tenant) {
            params.append('tenant', tenant);
        }

        if(selectedDate && selectedDate.startDate && selectedDate.endDate) {
            if (selectedDate?.startDate) {
                params.append('start_date', selectedDate.startDate);
            }
        
            if (selectedDate?.endDate) {
                params.append('end_date', selectedDate.endDate);
            }
        } else if(dates) {
            params.append('start_date', dates[0]?.format('YYYY-MM-DD HH:mm:ss'));
            params.append('end_date', dates[1]?.format('YYYY-MM-DD HH:mm:ss'));
        }

        return params.toString();
    };

    const queryParams = buildQueryParams({
        tenant,
        selectedDate
    });

    // Exporting Data
    const onExportClick = async () => {
        setLoader({
            ...loader,
            'exporting' : true
        });
        try {
            const config = {
                headers         : {'Content-Type' : 'multipart/form-data'},
                withCredentials : true,
                responseType    : 'blob',
            }

            const response  = await axios.get(`${APP_CONFIG.BASE_URL}buildings/${selectedSite}/export-deliveries?${queryParams}`, config);

            // Create a URL for the file
            const url       = window.URL.createObjectURL(new Blob([response.data]));
            
            // Create a link element and trigger the download
            const link      = document.createElement('a');
            link.href       = url;

            link.setAttribute('download', 'Deliveries Report.xlsx'); // Change filename as needed
            document.body.appendChild(link);
            link.click();
            
            // Clean up the URL object
            window.URL.revokeObjectURL(url);

            setTimeout(() => {
                setLoader({
                    ...loader,
                    'exporting' : false
                });
                message.success('Deliveries Data Exported Successfully', 3);
                setIsModalOpen(false);
            }, 1000)
            

        } catch(err) {
            console.log(err);
        }

        
        
    }


    return {
        dates, setDates,
        value, setValue,
        disabledDate,
        onChange,
        onCalendarChange,
        tenant, setTenant,
        addCustomFields, setAddCustomFields,
        onExportClick,
        contextHolder,
        loader
    }
}

export default useDeliveryExportModal;