import React from 'react'
import styles from './row.module.scss';

const Row = ({children, gap, backgroundColor, width, height, margin, justify, align, classNames}) => {
  return (
    <div 
    style={{ 
        gap : gap ? gap : '0',
        backgroundColor : backgroundColor ? backgroundColor : '',
        width : width ? width : '',
        height : height ? height : '', 
        margin : margin ? margin : '',
        justifyContent : justify ? justify : 'left',
        alignItems : align ? align : 'flex-start'
    }}
    className={`${styles.row} ${classNames ? classNames : ''}`}>
        {children}
    </div>
  )
}

export default Row