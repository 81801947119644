import { useState }     from "react"
import DashboardLayout  from "layouts/dashboardLayout/dashboard.jsx"
import PageHeader       from "components/pageHeader/pageHeader"
import SubMenuLayout    from "layouts/subMenuLayout/subMenuLayout"
import Skeleton         from "react-loading-skeleton"
import Col              from "layouts/col/col"
import Row              from "layouts/row/row"
import TextInput        from "components/inputs/textInput/textInput"
import TextArea         from "components/inputs/textarea/textarea"
import RSwitch          from "components/switch/switch"
import Button           from "components/buttons/button"
import DeleteTenantModal from "components/modal/deleteTenantModal/deleteTenantModal"
import useDetails       from "./useDetails"
import styles           from './details.module.scss'
import CheckPermission from "components/checkPermission/checkPermission"

const TenantDetailsScreen = () => {

    // const [tenantStatus, setTenantStatus] = useState(true);
    // const [tenantName, setTenantName] = useState('');
    // const [tenantEmail, setTenantEmail] = useState('');

    const {
        tenantName, setTenantName,
        email, setEmail,
        address, setAddress,
        isActive, setIsActive,
        saveChanges,
        deleteTenant,
        error, setError,
        errorMessages, setErrorMessages,
        deleteModalStatus, setDeleteModalStatus,
        loading, setLoading
    } = useDetails();

     return (
        <>
        <DashboardLayout>
            <SubMenuLayout menu="tenant" subMenuType="tenant" currentMenu="Tenant Details">
                <p className="gb_title">Basic Details</p>
                <p className="gb_subtitle">Add or update basic tenant details here.</p>
                {!loading ?
                <Col gap="2rem" classNames="mt-3">
                    <TextInput value={tenantName} isError={error.name} errorMessage={errorMessages.name} onChange={(e) => setTenantName(e.target.value)} required label="Tenant Name" placeholder="Enter Tenant Name" />
                    <TextInput value={email} isError={error.email} errorMessage={errorMessages.email} onChange={(e) => setEmail(e.target.value)} label="Email Address" placeholder="Enter Email Address" />
                    <TextArea value={address} onChange={(e) => setAddress(e.target.value)} label="Tenant Address" />
                    <RSwitch status={isActive} onChange={() => setIsActive(!isActive)} label="Tenant is Active" />
                </Col> : 
                <div className="mt-3">
                    <div>
                        <Skeleton width="20%" />
                        <Skeleton height={40} />
                    </div>
                    <div className="mt-2">
                        <Skeleton width="20%" />
                        <Skeleton height={100} />
                    </div>
                    <div className="mt-2">
                        <Skeleton width="20%" height={30} />
                    </div>
                    <div className="mt-2">
                        <Skeleton width="20%" height={30} />
                    </div>
                </div>
                }
                <CheckPermission permission="tenants_add_edit">
                    <Col classNames="mt-5">
                        <Row gap="1rem" justify="right" classNames={styles.redBG}>
                            <Button text="Delete Tenant" onClick={() => setDeleteModalStatus(true)} backgroundColor="#FFEEEE" color="#B00000" />
                            <Button text="Save Changes" onClick={saveChanges} />
                        </Row>
                    </Col>
                </CheckPermission>
            </SubMenuLayout>
        </DashboardLayout>
        <DeleteTenantModal isModalOpen={deleteModalStatus} closeModal={() => setDeleteModalStatus(false)} deleteTenant={deleteTenant} />
        </>
    )
}

export default TenantDetailsScreen