import { useAuth } from "contexts/authContext"
import { Navigate, Outlet } from "react-router-dom";

// Protected Route Component to prevent user to access unauthorised access 
const ProtectedRoute = ({requiredPermissions}) => {
    const {user} = useAuth();

    // Checking if user has permission to access the page
    const hasPermission = requiredPermissions.every((permission) => user.role.permissions.includes(permission));

    return hasPermission ? <Outlet /> : <Navigate to="/no-sites" />
}

export default ProtectedRoute;