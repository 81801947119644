import { useState, useEffect, useRef }      from 'react';
import { useAuth }                          from 'contexts/authContext';
import { capitalizeWords, checkIfEmpty, 
    isValidEmail, checkIfNameIsValid }      from 'functions/functions';
import { showErrorToast, showSuccessToast } from 'functions/toasts';
import useApi                               from 'hooks/useApi';
import RDatePicker                          from 'components/inputs/datepicker/datepicker';
import CustomModal                          from 'components/modal/modal';
import SignInModal                          from '../signInModal/signInModal';
import TextInput                            from 'components/inputs/textInput/textInput';
import SelectInput                          from 'components/inputs/selectInput/selectInput';
import BulkPreRegVisitorModal               from '../bulkPreRegVisitorModal/bulkPreRegVisitorModal';
import PreRegisterVisitorTemplate           from 'assets/pre_registration_template.csv';
import Button                               from 'components/buttons/button';
import Skeleton                             from 'react-loading-skeleton';
import Papa                                 from 'papaparse';
import Row                                  from 'layouts/row/row';
import TimePick                             from 'components/inputs/timePick/timePick';
import ConfirmationModal                    from '../confirmationModal/confirmationModal';
import moment                               from 'moment';
import styles                               from './preRegisterModal.module.scss';
import Checkbox                             from 'components/inputs/checkbox/checkbox';



const Body = ({
    signInForm, setSignInForm,
    form, setForm,
    loading, setLoading,
    visitorName, setVisitorName,
    tenants, setTenants,
    tenant, setTenant,
    date, setDate,
    visitTime, setVisitTime,
    closeCurrentModal,
    onTenantChange,
    sendEmail, setSendEmail,
    invitationEmail, setInvitationEmail,
    selectedPreRegUser,
    employees, selectedEmployee, onEmployeeSelect,
    preRegisterVisitor,
    error, setError,
    mode,
    errorMessages, setErrorMessages
}) => {

    const [openBulkImportModal, setOpenBulkImportModal] = useState(false);
    const [importedData, setImportedData]               = useState([]);
    const [file, setFile]                               = useState('');

    const inputFile = useRef();

    const setFieldValue = (e, index) => {
        const updatedForm = signInForm.fields.map((field, i) => {
            if(i === index) {
                if(field.type !== 'dropdown') {
                    return {
                        ...field,
                        required    : 'false',
                        value       : e.target.value
                    }
                } else {
                    return {
                        ...field,
                        required : 'false',
                        value : e.value
                    }
                }
            }
            return field;
        });

        setSignInForm(form => ({
            ...form,
            fields : updatedForm
        }));
    }

    const closeBulkImportModal = () => {
        setOpenBulkImportModal(false)
    }

    // Checking if visitor name has full name
    const onVisitorBlur = (e) => {
        let visitorName = e.target.value;

        let errorObj = {
            ...error,
            visitorName : false
        }

        let errorMessageObj = {
            ...errorMessages,
            visitorName : ''
        }

        // Checking if visitor name is a valid full name
        const {valid, message } = checkIfNameIsValid(visitorName);
        if(checkIfEmpty(visitorName)) {
            errorObj.visitorName = true;
            errorMessageObj.visitorName = 'Visitor Name is mandatory'
        } else if(!valid) {
            errorObj.visitorName = true;
            errorMessageObj.visitorName = message == 'not-full-name' ? 'Please enter full name of visitor' : 'Name can only contain alphabetic characters and spaces'
        }

        setError(errorObj);
        setErrorMessages(errorMessageObj);
    }

    // Checking if the given field value is emplty or not
    const checkEmpty = (e, field) => {
        let value = e.target.value;

        let errorObj = {
            ...error,
            [field] : false
        }

        let errorMessageObj = {
            ...errorMessages,
            [field] : ''
        }

        if(checkIfEmpty(value)) {
            errorObj[field] = true;
            errorMessageObj[field] = `${capitalizeWords(field)} is mandatory`
        }

        setError(errorObj);
        setErrorMessages(errorMessageObj);
    }

    // Checkinng the given field is a valid email
    const checkEmail = (e, field) => {
        let value = e.target.value;

        let errorObj = {
            ...error,
            [field] : false
        }

        let errorMessageObj = {
            ...errorMessages,
            [field] : ''
        }

        if(!isValidEmail(value)) {
            errorObj[field] = true;
            errorMessageObj[field] = `Enter Valid Email Address`
        }

        setError(errorObj);
        setErrorMessages(errorMessageObj);
    }

    const onBlur = (field) => {
        let errorObj = {
            ...error,
            [field.name] : false
        }

        let errorMessageObj = {
            ...errorMessages,
            [field.name] : ''
        }

        if(!errorObj[field.name] && field.type === 'email') {
            if(!isValidEmail(field.value)) {
                errorObj[field.name] = true;
                errorMessageObj[field.name] = 'Enter Valid Email Address'
            }
        }

        setError(errorObj);
        setErrorMessages(errorMessageObj)
    }

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
            setOpenBulkImportModal(true);
            setImportedData(results.data);
            setFile(file);
            inputFile.current.value = null;
        },
        });
    };

    const invitationEmailClick = () => {
        setSendEmail(!sendEmail);

        // Scroll to the field
        const fieldElement = document.getElementById('invitationEmailField'); // Replace 'fieldId' with the actual ID of your field
        if (fieldElement) {
            //fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            window.scrollTo(0, fieldElement.offsetTop - document.getElementsByClassName('modal_modalBody__Bls6Y')[0].offsetHeight);
        }
    }

    const onFileUploadClick = () => {
        inputFile.current.click();
    }

    const getDropdownOptions = (field) => {
        if (!Array.isArray(field.dropdownOptions)) {
            return [];
        }
    
        return field.dropdownOptions.map((item) => {
            if (typeof item === 'string') {
                // Convert string to an object with 'label' and 'value'
                return {
                    'label': item,
                    'value': item
                };
            } else if (typeof item === 'object' && item !== null && 'label' in item && 'value' in item) {
                // If it's already an object with 'label' and 'value', return it as is
                return item;
            } else {
                // Handle unexpected item formats (e.g., skip or transform)
                return null;  // or you can choose to skip or handle differently
            }
        }).filter(item => item !== null); // Filter out any nulls from the list
    }
    
    return (
        
        <>
            {!loading ?
            <>  
                <form onSubmit={preRegisterVisitor}>
                    {mode == 'add' &&
                    <div className={styles.bulkImportContainer}>
                        <p>Do you want to pre-register visitors in bulk ? <a href={PreRegisterVisitorTemplate} download='PreRegisterVisitorTemplate' className='text-blue-600 underline'>Download the template</a> and upload the file to import data.</p>
                        <div className={styles.btn} onClick={onFileUploadClick}>
                            <p>Bulk Import</p>
                        </div>
                        <input type="file" ref={inputFile} accept=".csv" hidden onChange={handleFileUpload} />
                    </div> }
                    <div className={styles.signInForm}>
                        <TextInput 
                            label="Visitor Name" 
                            placeholder="Enter Visitor Name"
                            value={visitorName} 
                            onChange={(e) => setVisitorName(e.target.value)}
                            onBlur={onVisitorBlur}
                            isError={error.visitorName}
                            errorMessage={errorMessages.visitorName}
                            required 
                        />
                        <Row gap="1rem">
                            {/* <SelectInput showError={true} label="Visit Date" placeholder="Select Visit Date" /> */}
                            <RDatePicker 
                                label="Visit Date" 
                                value={date}
                                required={true}
                                onChange={(e) => setDate(e.target.value)}
                                onBlur={(e) => checkEmpty(e, 'date')}
                                isError={error.date}
                                errorMessage={errorMessages.date} 
                            />
                            <TimePick 
                                label="Expected Visit Time" 
                                value={visitTime}
                                required={true} 
                                onChange={(e) => setVisitTime(e.target.value)}
                                onBlur={(e) => checkEmpty(e, 'time')}
                                isError={error.time}
                                errorMessage={errorMessages.time}  
                            />
                        </Row>
                        <SelectInput 
                            showError={true} 
                            label="Company Visiting" 
                            placeholder="Select Company" 
                            required
                            options={tenants}
                            isError={error.tenant}
                            errorMessage={errorMessages.tenant}
                            defaultValue={tenant}
                            onChange={onTenantChange} 
                        />
                        {form?.employees?.enabled === 'true' && employees.length !== 0 && <SelectInput showError={true} options={employees} isClearable required={false} defaultValue={selectedEmployee} onChange={onEmployeeSelect} label={form.employees.label} placeholder={form.employees.label} />}
                        {signInForm && signInForm?.fields?.map((field, index) => {
                            return (
                                field.type === 'string' || field.type === 'email' || field.type === 'numeric' ? 
                                <TextInput 
                                    label={field.name} 
                                    placeholder={field.label}
                                    required={false}
                                    type={field.type === 'numeric' ? 'number' : 'text'}
                                    value={field.value}
                                    onBlur={() => onBlur(field)}
                                    onChange={(e) => setFieldValue(e, index)}
                                    isError={error[field.name]}
                                    errorMessage={errorMessages[field.name]}  
                                /> : field.type === 'dropdown' ? 
                                <SelectInput 
                                    label={field.label} 
                                    showError={true}
                                    menuPlacement="auto"
                                    defaultValue={field.value ? {label : field.value, value : field.value} : ''}
                                    isError={error[field.name]}
                                    errorMessage={errorMessages[field.name]}  
                                    onChange={(e) => setFieldValue(e, index)} 
                                    options={getDropdownOptions(field)} 
                                    required={false} 
                                /> : ''
                                
                            )
                        })}
                        <div>
                            <Checkbox classNames="mt-1" label={`${mode == 'edit' ? selectedPreRegUser.invitation_email ? 'Resend' : 'Send' : 'Send'} Invitation Email to Visitor`} checked={sendEmail} onChange={invitationEmailClick} />
                        </div>
                        {sendEmail && 
                            <div id="invitationEmailField">
                            <TextInput 
                                placeholder="Enter Invitation Email Address" 
                                onBlur={(e) => checkEmail(e, 'invitation email')} 
                                value={invitationEmail}
                                isError={error['invitation email']}
                                errorMessage={errorMessages['invitation email']} 
                                onChange={(e) => setInvitationEmail(e.target.value)} 
                            />
                            </div> 
                        }
                    </div>
                    <button hidden type='submit'></button>
                </form>
            </>
            : 
                <div>
                    <div>
                        <Skeleton width="20%" />
                        <Skeleton height={40} />
                    </div>
                    <div className="mt-2">
                        <Skeleton width="20%" />
                        <Skeleton height={40} />
                    </div>
                    <div className="mt-2">
                        <Skeleton width="20%" />
                        <Skeleton height={40} />
                    </div>
                </div>
            }
            {openBulkImportModal && <BulkPreRegVisitorModal isModalOpen={openBulkImportModal} closeModal={closeBulkImportModal} closeParentModal={closeCurrentModal} importedData={importedData} file={file} /> }
        </>
    )
}

const Footer = ({preRegisterVisitor, deletePreRegisteredVisitor, sendEmail, checkSignInVisitor, mode}) => {
    return (
        <div className={`${styles.footerContainer}`} style={{ justifyContent : `${mode == 'edit' ? 'space-between' : 'flex-end'}` }}>
            {mode == 'edit' && <Button text="Delete" onClick={deletePreRegisteredVisitor} backgroundColor="#FD4848" /> }
            <div className={styles.footerBtnContainer}>
                {mode == 'edit' && <Button text="Sign In Visitor" onClick={checkSignInVisitor} backgroundColor="#eeedff" color="#342e8c" /> }
                <Button text={sendEmail ? 'Submit & Send Invitation' : 'Submit'} onClick={preRegisterVisitor} />
            </div>
        </div>
    )
}

/**
 * 
 * @param {Boolean} isModalOpen     - Shows or hides modal
 * @param {Function} closeModal     - Function to close the modal 
 */

const PreRegisterModal = ({isModalOpen, closeModal, mode="add", selectedPreRegUser, fetchPreRegisteredVisitors}) => {

    console.log(selectedPreRegUser);
    const [loading, setLoading]                     = useState(true);
    const [form, setForm]                           = useState([]);
    const [signInForm, setSignInForm]               = useState([]);
    const [visitorName, setVisitorName]             = useState('');
    const [date, setDate]                           = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [visitTime, setVisitTime]                 = useState('12:00');
    const [tenants, setTenants]                     = useState([]);
    const [employees, setEmployees]                 = useState([]);
    const [selectedEmployee, setSelectedEmployee]   = useState('');
    const [tenant, setTenant]                       = useState('');
    const [sendEmail, setSendEmail]                 = useState(false);
    const [invitationEmail, setInvitationEmail]     = useState('');
    const [error, setError]                         = useState('');
    const [errorMessages, setErrorMessages]         = useState('');
    const [confirmationModalOpen, setConfirmationModalOpen] = useState({
        delete : false,
        signIn : false,
      })
    const [signInModal, setSignInModal] = useState(false);

    const {get, post} = useApi();
    const {currentBuilding} = useAuth();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true)
        const tenantsResponse = await fetchTenantData();
        if(mode == 'edit') await setPreRegisteredData(tenantsResponse);
        setLoading(false)
    }

    const setPreRegisteredData = async (tenantsResponse) => {
        setVisitorName(selectedPreRegUser.visitor_name);
        setDate(selectedPreRegUser.arrival_date);
        setVisitTime(selectedPreRegUser.arrival_time);
        setTenant(tenantsResponse.filter((tenant) => tenant.tenant_id === selectedPreRegUser.tenant_id)[0])

        if(selectedPreRegUser.tenant_id) {
            let employeeOptions = await fetchTenantEmployees(selectedPreRegUser.tenant_id);
            console.log(employeeOptions);
            let selectedEmployee = employeeOptions.filter((employee) => employee.employee_id === selectedPreRegUser.employee_id);
            setSelectedEmployee(selectedEmployee[0]);
        }

        selectedPreRegUser?.form_data?.fields?.forEach((field) => {
            if (field.type === "dropdown" && Array.isArray(field.dropdownOptions)) {
                // Check if dropdownOptions is an array of objects with 'label' and 'value' keys
                if (field.dropdownOptions.every(item => typeof item === 'object' && 'value' in item)) {
                    // Create a new key to store array of strings from the 'value' keys of each object
                    field.dropdownOptions = field.dropdownOptions.map(item => item.value);
                }
            }
        });

        console.log(selectedPreRegUser.form_data)

        
        
        // setTenant(selectedTenant[0]);
        setForm(selectedPreRegUser.form_data);
        setSignInForm(selectedPreRegUser.form_data);

        // Set Invitation Email if Already Added
        if(!checkIfEmpty(selectedPreRegUser.invitation_email)) {
            setInvitationEmail(selectedPreRegUser.invitation_email);
        }

    }

    const toggleDeleteConfirmModal = (status) => {
        setConfirmationModalOpen({
          ...confirmationModalOpen,
          delete : status
      })
    }

    const toggleSignInConfirmModal = (status) => {
        setConfirmationModalOpen({
          ...confirmationModalOpen,
          signIn : status
      })
    }


    const checkSignInVisitor = () => {
        const today = new Date().toISOString().split('T')[0];
        const expectedDate = new Date(selectedPreRegUser.arrival_date).toISOString().split('T')[0];

        if (expectedDate !== today) {
            setConfirmationModalOpen({
                ...confirmationModalOpen,
                signIn : true
            });
        } else {
            setSignInModal(true);
        }
    }


    // Fetch All Data Once the Modal is Loaded
    const fetchTenantData = async () => {
        
        try {
            const response = await get(`buildings/${currentBuilding.attributes.building_id}/tenants`);
            const data = response.data.data;

            let tenantOptions = [];
            data.forEach(tenant => {
                if(tenant.attributes.is_active === 1) {
                    tenantOptions.push({
                        ...tenant.attributes,
                        label : tenant.attributes.name,
                        value : tenant.attributes.tenant_id,
                    })
                }
            });

            setTenants(tenantOptions);

            return tenantOptions;

        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }


    // When tenant is selected from the dropdown
    const onTenantChange = async (e) => {
        setLoading(true)
        setTenant(e);
        setSelectedEmployee('');
        await Promise.all([getTenantTemplate(e.template_id), fetchTenantEmployees(e.tenant_id)])
        setLoading(false);
    }

    // When Employee is Selected
    const onEmployeeSelect = (e) => {
        setSelectedEmployee(e);
    }

    // Fetch Template
    const getTenantTemplate = async(templateId) => {
        try {
            const res = await get(`form-templates/${templateId}`);
            let formObj = [];
            res.data.data?.form?.fields?.forEach((field) => {
                let obj = {
                    ...field,
                    value : '',
                };

                if(field.type === 'dropdown') {
                    let array = [];
                    field.dropdownOptions.forEach((item) => {
                        array.push(item)
                    });

                    obj['dropdownOptions'] = array;
                }
                formObj.push(obj);
            })

            setForm(res.data.data.form);
            setSignInForm({
                ...res.data.data.form,
                fields : formObj
            })
            
        } catch(err) {
            console.error(err)
            showErrorToast('Something Went Wromg');
        }
    }

    // Fetch Tenant Employees
    const fetchTenantEmployees = async (tenantId) => {
        try {
            const response = await get(`tenants/${tenantId}/employees`);
            const data = response.data.data;

            let employeeOptions = [];

            data.forEach((employee) => {
                if(employee.attributes.is_active === 1) {
                    employeeOptions.push({
                        ...employee.attributes,
                        label : employee.attributes.name,
                        value : employee.attributes.employee_id
                    })
                }
            })

            setEmployees(employeeOptions)
            return employeeOptions;

        } catch(err) {
            console.error(err)
            showErrorToast('Something Went Wrong')
        }
    }

    // Function to delete a pre-registered visitor
    const deletePreRegisteredVisitor = async () => {
        setLoading(true);
        try {
            await post(`preregistered_visitors/${selectedPreRegUser.pre_registration_id}/delete`);
            showSuccessToast('Pre-Registered Visitor Deleted Successfully');
            setLoading(false);
            fetchPreRegisteredVisitors();
            closeModal();

        } catch(err) {
            showErrorToast('Something Went Wrong');
        }

        setLoading(false);
    }


    // Function to pre register new vistor
    const preRegisterVisitor = async (e) => {
        console.log('test');
        e.preventDefault();
        setLoading(true);
        const now = moment();

        let errorObj = {
            ...error,
            'visitor name' : false,
            'tenant' : false
        };

        let errorMessageObj = {
            ...errorMessages,
            'visitor name' : '',
            'tenant' : ''
        }

        // Checking if visitor name is a valid full name
        const {valid, message } = checkIfNameIsValid(visitorName);
        if(checkIfEmpty(visitorName)) {
            errorObj.visitorName = true;
            errorMessageObj.visitorName = 'Visitor Name is mandatory'
        } else if(!valid) {
            errorObj.visitorName = true;
            errorMessageObj.visitorName = message == 'not-full-name' ? 'Please enter full name of visitor' : 'Name can only contain alphabetic characters and spaces'
        }

        if(checkIfEmpty(date)) {
            errorObj['date'] = true;
            errorMessageObj['date'] = 'Please enter a valid date'
        }

        if(sendEmail) {
            if(checkIfEmpty(invitationEmail)) {
                errorObj['invitation email'] = true;
                errorMessageObj['invitation email'] = 'Please enter invitation email address'
            } else if(!isValidEmail(invitationEmail)) {
                errorObj['invitation email'] = true;
                errorMessageObj['invitation email'] = 'Enter Valid Email Address'
            }
        }

        if(!tenant) {
            errorObj['tenant'] = true;
            errorMessageObj['tenant'] = 'Please select a company'
        }

        signInForm?.fields?.forEach((field) => {
            errorObj = {
                ...errorObj,
                [field.name] : false
            }
    
            errorMessageObj = {
                ...errorMessageObj,
                [field.name] : ''
            }            
    
            if(!errorObj[field.name] && field.type === 'email' && field.value != '' && field.value != null) {
                if(!isValidEmail(field.value)) {
                    errorObj[field.name] = true;
                    errorMessageObj[field.name] = 'Enter Valid Email Address'
                }
            }
        })

        const hasError = Object.values(errorObj).some(value => value === true);
        if(!hasError) {
            let data = {
                visitor_name            : visitorName,
                tenant_id               : tenant.value,
                form_data               : JSON.stringify(signInForm),
                arrival_date            : date,
                arrival_time            : visitTime,
                registered_admin_user   : 1
            };

            if(selectedEmployee) {
                data['employee_id'] = selectedEmployee.employee_id;
            } else {
                data['employee_id'] = '';
            }

            if(sendEmail) {
                data['send_invitation'] = true;
            }

            if(sendEmail && invitationEmail) {
                data['invitation_email'] = invitationEmail;
            }

            console.log(data);
            if(mode == 'add') {
                try {
                    await post(`buildings/${currentBuilding.attributes.building_id}/pre-register`, data);
                    showSuccessToast('New Visitor Pre-Registered Successfully');
                    setLoading(false)
                    closeModal();
                    
                } catch(err) {
                    showErrorToast('Something Went Wrong');
                }
            } else {
                try {
                    await post(`preregistered_visitors/${selectedPreRegUser.pre_registration_id}/update`, data);
                    showSuccessToast('Pre-Registered Visitor Details Updated Successfully');
                    setLoading(false);
                    fetchPreRegisteredVisitors();
                    closeModal();
                    
                } catch(err) {
                    showErrorToast('Something Went Wrong');
                }
            }
        } else {
            setError(errorObj);
            setErrorMessages(errorMessageObj);
            setLoading(false);
        }
        
    }

    const closeSignInModal = () => {
        fetchPreRegisteredVisitors();
        setSignInModal(false);
        closeModal();
    }


    
    return (
        <>
        <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} width="40%" minHeight="70%">
            {{ 
                header : mode == 'add' ? 'Pre-Register New Visitor' : 'Edit Pre-Registered Visitor',
                body :  <Body 
                            signInForm={signInForm}
                            mode={mode} 
                            setSignInForm={setSignInForm}
                            form={form} setForm={setForm}
                            selectedPreRegUser={selectedPreRegUser}
                            visitorName={visitorName}
                            setVisitorName={setVisitorName}
                            closeCurrentModal={closeModal}
                            tenant={tenant}
                            setTenant={setTenant}
                            date={date}
                            setDate={setDate}
                            visitTime={visitTime}
                            setVisitTime={setVisitTime}
                            tenants={tenants}
                            sendEmail={sendEmail} setSendEmail={setSendEmail}
                            invitationEmail={invitationEmail} setInvitationEmail={setInvitationEmail}
                            employees={employees}
                            onEmployeeSelect={onEmployeeSelect}
                            selectedEmployee={selectedEmployee}
                            setTenants={setTenants}
                            onTenantChange={onTenantChange}
                            error={error} setError={setError}
                            errorMessages={errorMessages} setErrorMessages={setErrorMessages}
                            preRegisterVisitor={preRegisterVisitor}
                            loading={loading}
                            setLoading={setLoading}
                        />,
                footer :    <Footer 
                                preRegisterVisitor={preRegisterVisitor}
                                checkSignInVisitor={checkSignInVisitor}
                                deletePreRegisteredVisitor={() => toggleDeleteConfirmModal(true)}
                                sendEmail={sendEmail}
                                mode={mode}
                            />
             }}
        </CustomModal>
        <ConfirmationModal 
            open={confirmationModalOpen.delete} 
            handleClose={() => toggleDeleteConfirmModal(false)} 
            title="Delete Pre-Registered Visitor"
            body={<p>Are you sure you want to delete pre-registered visitor ?</p>}
            confirmButtonTitle="Delete"
            onConfirm={deletePreRegisteredVisitor}
        />
        <ConfirmationModal 
            open={confirmationModalOpen.signIn} 
            handleClose={() => toggleSignInConfirmModal(false)} 
            title="Please Note!"
            body={<p>This visitor is expected to sign in to the building on another day. Are you sure you still want to sign in the visitor now ?</p>}
            confirmButtonTitle="Yes, Sign In"
            onConfirm={() => setSignInModal(true)}
        />
        <SignInModal isModalOpen={signInModal} closeModal={closeSignInModal} selectedPreRegUser={selectedPreRegUser} getCurrentVisitorsData={fetchData} />
        </>
    )
}

export default PreRegisterModal