import { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from 'rsuite';
import CustomButton from "components/buttons/button";
import { FaCloudUploadAlt } from "react-icons/fa";
import EmployeeImportTemplate from 'assets/Employee CSV Import Template.csv';
import CustomModal from "../modal";
// import Button from "components/buttons/button";
import DataTable from 'react-data-table-component';
import Row from "layouts/row/row";
import Col from "layouts/col/col";
import useApi from "hooks/useApi";
import axios from "axios";
import { APP_CONFIG } from "constants/config";
import { showErrorToast, showSuccessToast } from "functions/toasts";
import { useAuth } from "contexts/authContext";
import Papa from 'papaparse';
import styles from './bulkImportEmployeeModal.module.scss';
import { checkIfEmpty, isValidEmail } from "functions/functions";

const Body = ({
    importedData, setImportedData,
    file, setFile,
    CSVData, setCSVData,
    fileHasError, setFileHasError
}) => {

    useEffect(() => {
        formatData();
    },[importedData]);

    const inputFile = useRef();

    // Conditional Row Style For Invalid Data Rows
    const conditionalRowStyles = [
        {
          when: row => row.error === true, // Condition for the row
          style: {
            backgroundColor: '#fff2f2', // Background color if condition is met
          },
        },
    ];

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
        },
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'Phone',
            selector: row => row.phone,
        },                                        
    ];

    // Function to open file manager
    const onFileUploadClick = () => {
        inputFile.current.click();
    }

    // Function when a file is selected
    const handleFileUpload = (e) => {
        console.log('Changed')
        const file = e.target.files[0];
        Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
            // setData(results.data);
            console.log(results);
            // setOpenBulkImportModal(true);
            setImportedData(results.data);
            setFile(file);
            inputFile.current.value = null;
        },
        });
    };


    // Funtion to format and validate csv data
    const formatData = () => {
        let formattedDataArray = [];
        importedData.forEach(data => {
            let formattedData = {
                name : data['Name']?.trim(),
                email : data['Email']?.trim(),
                phone : data['Phone']?.trim(),
            }

            let errorObj = {
                name : false,
                email : false,
                phone : false
            };

            if(checkIfEmpty(formatData.name)) {
                errorObj.name = true;
            }

            if(formattedData.email && (!isValidEmail(formattedData.email))) {
                errorObj.email = true;
            }

            const hasError = Object.values(errorObj).some(value => value === true);

            if(hasError) {
                formattedData['error'] = true;
            }

            formattedDataArray.push(formattedData);
        });

        const fileError = formattedDataArray.some(obj => obj.error === true);
        setFileHasError(fileError);
        setCSVData(formattedDataArray);
    }

    return (
        <div className={styles.content}>
            <p className="text-bold mb-1">Upload multiple employees at single click by importing CSV File.</p>
            <div className={styles.steps}>
                <p><span className="text-bold">Step 1 : </span>Download CSV Template by <span className="link"><a href={EmployeeImportTemplate} download="EmployeeImportTemplate">clicking here.</a></span></p>
                <p><span className="text-bold">Step 2 : </span>Keep the headers as it is and add employees row by row.</p>
                <p><span className="text-bold">Step 3 : </span>Save and upload the CSV File below.</p>
            </div>
            <input type="file" hidden ref={inputFile} accept=".csv" onChange={handleFileUpload} />
            <Row align="center" gap="2rem" classNames="mt-2">
                {/* <Button text="Upload CSV File" backgroundColor="#037093" onClick={onFileUploadClick} /> */}
                <Button startIcon={<FaCloudUploadAlt />} onClick={onFileUploadClick}>Upload CSV File</Button>
            </Row>
            <div className="mt-2">
                {CSVData.length > 0 &&
                <DataTable
                    columns={columns}
                    fixedHeader={true}
                    fixedHeaderScrollHeight="40vh"
                    conditionalRowStyles={conditionalRowStyles}
                    data={CSVData}
                    dense
                /> }
            </div>
        </div>
    )
}

const Footer = ({onClick, fileHasError, CSVData, importing}) => {

   
    return (
        <Row justify="flex-end">
            <CustomButton loading={importing} loadingText="Importing..." disabled={fileHasError || CSVData.length === 0 || importing} text="Import Employees" onClick={onClick} />
        </Row>
    )
}

const BulkImportEmployeeModal = ({isModalOpen, closeModal, fetchEmployeeData}) => {
    const [importedData, setImportedData]   = useState([]);
    const [CSVData, setCSVData] = useState([]);
    const [file, setFile] = useState('');
    const [fileHasError, setFileHasError] = useState(false);
    const [importing, setImporting] = useState(false);

    const {tenantId} = useParams();

    // Calling Bulk Import API
    const importEmployees = async () => {
        setImporting(true);
        try {
            const formData = new FormData();

            formData.append("csv", file);

            const config = {
                headers : {'Content-Type' : 'multipart/form-data'},
                withCredentials : true
            }

            await axios.post(`${APP_CONFIG.BASE_URL}tenants/${tenantId}/employees/bulkImport`, formData, config);
            showSuccessToast('Employees Imported Successfully');
            fetchEmployeeData();
            closeImoprtModal();
            setImporting(false);

        } catch(err) {
            console.log(err);
        }
    }
    
    const closeImoprtModal = async() => {
        setFile('');
        setCSVData([]);
        setImportedData([]);
        closeModal();  
    }

    return (
        <CustomModal isModalOpen={isModalOpen} closeModal={closeImoprtModal} width="50%" maxHeight="30%" minHeight="70%" >
            {{ 
                header : 'Bullk Import Employees',
                body : <Body
                    importedData={importedData}
                    setImportedData={setImportedData}
                    file={file}
                    setFile={setFile}
                    CSVData={CSVData}
                    setCSVData={setCSVData}
                    fileHasError={fileHasError}
                    setFileHasError={setFileHasError} 
                />,
                footer : <Footer onClick={importEmployees} fileHasError={fileHasError} importing={importing} CSVData={CSVData} />
            }}
        </CustomModal>
    )
}

export default BulkImportEmployeeModal