import { useState, useEffect } from "react";
import useApi from "hooks/useApi";
import { useParams }            from "react-router-dom";
import { showErrorToast, showSuccessToast } from "functions/toasts";
import { checkIfEmpty } from "functions/functions";

const useDevices = () => {

    const [devices, setDevices] = useState([]);
    const [addNewDeviceModalStatus, setAddNewDeviceModalStatus] = useState(false);
    const [deleteDeviceModalStatus, setDeleteDeviceModalStatus] = useState(false);
    const [editDeviceModalStatus, setEditDeviceModalStatus] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState('');
    const [selectedEditDevice, setSelectedEditDevice] = useState('');
    const [deviceName, setDeviceName] = useState('');
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [editError, setEditError] = useState('');
    const [editErrorMessages, setEditErrorMessages] = useState('');
    const [editLoading, setEditLoading] = useState(false);

    const {id} = useParams();

    const { get, del, post } = useApi();

    useEffect(() => {
        fetchDevicesData();
    }, [])

    const fetchDevicesData = async() => {
        setLoading(true);
        try {
            const response = await get(`buildings/${id}/devices`);
            setDevices(response.data.data);
            setLoading(false);
        } catch(err) {
            console.log(err)
        }
    }

    const deleteDevice = async (id) => {
        setDeleteLoading(true);

        try {
            await del(`devices/${id}`);
            fetchDevicesData();
            showSuccessToast('Device Deleted Successfully');
            setDeleteLoading(false);
            setDeleteDeviceModalStatus(false);

        } catch(err) {
            console.log(err);
            showErrorToast('Something Went Wrong');
        }
    }

    const editDevice = async (id) => {
        setEditLoading(true);

        let trimmedDeviceName = deviceName.trim();

        let errorObj = {
            name : false
        }

        let errorMessageObj = {
            name : ''
        }


        if(checkIfEmpty(trimmedDeviceName)) {
            errorObj.name = true;
            errorMessageObj.name = 'Device Name is Mandatory'
        }

        if(!errorObj.name) {
            try {
                const response = await post(`devices/${id}`, {
                    name : deviceName
                });
    
                console.log(response);
                await fetchDevicesData();
                showSuccessToast('Device Updated Successfully');
                setEditLoading(false);
                setEditDeviceModalStatus(false);
    
            } catch(err) {
                console.log(err);
                showErrorToast('Something Went Wrong')
            }
        }

        setEditError(errorObj);
        setEditErrorMessages(errorMessageObj);

        setEditLoading(false);
    }

    return {

        devices, setDevices,
        addNewDeviceModalStatus, setAddNewDeviceModalStatus,
        deleteDeviceModalStatus, setDeleteDeviceModalStatus,
        editDeviceModalStatus, setEditDeviceModalStatus,
        selectedDevice, setSelectedDevice,
        selectedEditDevice, setSelectedEditDevice,
        deviceName, setDeviceName,
        id,
        deleteDevice,
        fetchDevicesData,
        editDevice,
        editError, editErrorMessages,
        loading, setLoading,
        deleteLoading, setDeleteLoading

    }
}

export default useDevices;
