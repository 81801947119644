// This is the permission or privileges list of the web Portal. This list is used to render the privileges in the user roles screen.

export const FEATURES = [
    {
        feature : 'Visitors',
        permissions : [
            {
                label : 'Sign In Visitor',
                permission : 'visitors_sign_in'
            },
            {
                label : 'Sign Out Visitor',
                permission : 'visitors_sign_out'
            },
            {
                label : 'Sign Out All Visitors',
                permission : 'visitors_sign_out_all'
            },
            {
                label : 'View Today\'s Visitors',
                permission : 'visitors_view_todays_visitors'
            },
        ]
    },
    {
        feature : 'Visitor Pre-Registration',
        permissions : [
            {
                label : 'View Pre-Registered Visitors',
                permission : 'pre_reg_view'
            },
            {
                label : 'Pre-Register New Visitor',
                permission : 'pre_reg_add_new'
            },
        ]
    },
    {
        feature : 'Deliveries',
        permissions : [
            {
                label : 'View Deliveries Page',
                permission : 'deliveries_show_page'
            },
            {
                label : 'Add New Delivery',
                permission : 'deliveries_add_new_delivery'
            },
            {
                label : 'Assign Delivery',
                permission : 'deliveries_assign_delivery'
            },
        ]
    },
    {
        feature : 'Manage Sites',
        permissions : [
            {
                label : 'View Sites',
                permission : 'sites_view',
                mustDisableOnFalse : ["sites_add_edit"]
            },
            {
                label : 'Add & Edit Site',
                permission : 'sites_add_edit',
                mustEnable : ["sites_view"]
            },
        ]
    },
    {
        feature : 'Manage Tenants',
        permissions : [
            {
                label : 'View Tenants',
                permission : 'tenants_view',
                mustDisableOnFalse : ["tenants_add_edit"]
            },
            {
                label : 'Add & Edit Tenant',
                permission : 'tenants_add_edit',
                mustEnable : ["tenants_view"]
            },
        ]
    },
    {
        feature : 'Manage Users',
        permissions : [
            {
                label : 'View Portal Users',
                permission : 'portal_users_view',
                mustDisableOnFalse : ["portal_users_edit", "portal_users_invite"]
            },
            {
                label : 'Invite New User',
                permission : 'portal_users_invite',
                mustEnable : ["portal_users_view"]
            },
            {
                label : 'Edit User Details',
                permission : 'portal_users_edit',
                mustEnable : ["portal_users_view"]
            },
        ]
    },
    {
        feature : 'Reports',
        permissions : [
            {
                label : 'View Visitor Reports',
                permission : 'visitor_reports_view',
            },
            {
                label : 'View Delivery Reports',
                permission : 'delivery_reports_view',
            }
        ]
    }  
];