import styles from './col.module.scss';

const Col = ({children, gap, classNames}) => {
  return (
    <div className={`${styles.col} ${classNames}`}
        style={{ 
            gap : gap || 0
         }}
    >
        {children}
    </div>
  )
}

export default Col