import Switch from "react-switch";
import styles from './switch.module.scss';

const RSwitch = ({status, onChange, label, disabled = false}) => {
    return (
        <>
            <div className={styles.switchContainer}>    
                <Switch 
                    checked={status} 
                    onChange={onChange}
                    onColor="#45BB7A"
                    disabled={disabled}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    className="react-switch"   
                />
                <label>{label}</label>
            </div>
        </>
    )
}

export default RSwitch