import React    from 'react'
import styles   from './textInput.module.scss';

/**
 * Text Input component.
 * @param {Number} flex           - Adjust width of the input. By default it is 1.
 * @param {String} placeholder    - Placeholder text to show in the input. By default it is ''.
 * @param {String} label          - Label to show with the input.
 * @param {String} type           - Type of input, text/password. By default it is text
 * @param {Boolean} required      - Shows required icon
 */


const TextInput = ({flex, placeholder, size="md", prefix="", label, required, disabled, type="text", value, onBlur, onChange, onKeyPress, maxWidth, classNames = '', isError, errorMessage, showError = true}) => {
  return (
    <div
        style={{ 
            flex : flex || 1,
            maxWidth : maxWidth || ''
         }} 
        className={`${styles.inputContainer} ${classNames}`}
    >
        {label && <label>{label} {required && <span className={styles.required}>*</span> }</label> }
        <div style={{ position : 'relative' }}>
          <input 
            style={{ 
              padding : size === 'sm' ? '0.7rem 1rem' : size === 'md' ? '1.1rem 1rem' : '',
              height : size === 'sm' ? '38px' : size === 'md' ? '46px' : '',
              paddingLeft : prefix ? '45px' : '' 
            }} 
            disabled={disabled} 
            className= {isError ? 'error-outline' : ''} 
            onChange={onChange} 
            onKeyDown={onKeyPress} 
            onBlur={onBlur} 
            placeholder={placeholder || ''} 
            type={type == 'password' ? 'password' : type} 
            value={value || ''} 
          />
          {prefix && <div className={styles.prefix}><p>#</p></div>}
        </div>
        {showError && <p className={`errorMessage ${isError ? 'active' : ''}`}>{errorMessage || ''}&nbsp;</p> }
    </div>
  )
}

export default TextInput