import { useNavigate } from "react-router-dom"
import Lottie               from "lottie-react";
import Error from 'assets/animations/error.json';
import styles from './noAccess.module.scss';
import Button from "components/buttons/button";

const NoAccessScreen = () => {

    const navigate = useNavigate();

  return (
    <div className={styles.container}>
        <div className={styles.error_message}>
            <div className={styles.error_message__left}>
                <Lottie animationData={Error} loop={true} style={{ width : 150, height : 150 }} />
            </div>
            <div className={styles.error_message__right}>
                <h3>Access Denied</h3>
                <p>Sorry. You don't have access to this page. Please contact your Administrator.</p>
                <Button text="Go to Dashboard" classNames="mt-1" onClick={() => navigate('/dashboard')} />
            </div>
        </div>
    </div>
  )
}

export default NoAccessScreen