import { useNavigate }                from 'react-router-dom';
import { MdOutlineArrowBackIosNew }   from 'react-icons/md';
import TextInput                      from 'components/inputs/textInput/textInput';
import Col                            from 'layouts/col/col';
import Button                         from 'components/buttons/button';
import Icon                           from "components/icon/icon"
import Logo                           from 'assets/images/logo-theme.png';
import useResetPassword               from './useResetPassword';
import styles                         from './resetPassword.module.scss';


const ResetPasswordScreen = () => {

  const {
    email,
    setEmail,
    error,
    errorMessages,
    onSendResetLinkClick,
    showSuccess,
    loading
  } = useResetPassword();


  const navigate = useNavigate();

  return (
    <div className="authContainer">
        <div className='left'>
            <div className='background'></div>
        </div>
        <div className='right'>
            <div className="content">
              <div className="logo">
                <img src={Logo} />
              </div>
              <div className='back' onClick={() => navigate('/login')}>
                <Icon icon={<MdOutlineArrowBackIosNew />} />
                <p>Go Back</p>
              </div>
              {showSuccess &&
              <div className='success-alert mt-2'>
                <p>Please check your inbox for the password reset link.</p>
              </div> }

              <div className="head">
                <h3 className='heading'>Reset Password</h3>
                <p className='info'>Enter your registered email address and we will sent you the details to reset the password</p>
              </div>

                <form>
                  <Col gap="0.5rem">
                    <TextInput 
                      placeholder="Enter Email Address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)} 
                      label="Email Address"
                      isError={error.email}
                      errorMessage={errorMessages.email} 
                      required />
                    <Button 
                      disabled={loading} 
                      loading={loading} 
                      loadingText="Sending Email..." 
                      text="Send Reset Link" 
                      classNames="mt-1" 
                      onClick={onSendResetLinkClick} 
                    />
                  </Col>
                </form> 
            </div>
        </div>
    </div>
  )
}

export default ResetPasswordScreen