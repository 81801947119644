import Skeleton         from "react-loading-skeleton";
import DashboardLayout  from "layouts/dashboardLayout/dashboard.jsx";
import SubMenuLayout    from "layouts/subMenuLayout/subMenuLayout";
import PageHeader       from "components/pageHeader/pageHeader";
import TextArea         from "components/inputs/textarea/textarea";
import RSwitch          from "components/switch/switch";
import Button           from "components/buttons/button";
import Row              from "layouts/row/row";
import { buttonColors } from "constants/colors";
import styles           from './health&Safety.module.scss';
import SelectInput      from "components/inputs/selectInput/selectInput";
import { CgAttachment } from "react-icons/cg";
import useHealthSafety  from "./useHealth&Safety";
import { IoMdClose } from "react-icons/io";
import CheckPermission from "components/checkPermission/checkPermission";
import Icon from "components/icon/icon";
import Checkbox from "components/inputs/checkbox/checkbox";

const SiteHealthAndSafetyScreen = () => {

    const {
        message, setMessage,
        loading, setLoading,
        showMessage, setShowMessage,
        signatureRequired, setSignatureRequired,
        isRequired, setIsRequired,
        onSaveChanges,
        attachmentUploadClick,
        attachmentFile,
        onFileSelected,
        onFileRemoved,
        attachedFile, setAttachedFile,
        error, errorMessages,
        showErrorAlert, setShowErrorAlert,
    } = useHealthSafety();


    const actionOptions = [
        {
            label : 'Make Visitor Signature Required',
            value : true
        },
        {
            label : 'Visitor Signature is Not Required',
            value : false
        }
    ]

    const getFileName = (url) => {
        const parts = url.split('/');
        const filenameWithExtension = parts[parts.length - 1];
        const filename = filenameWithExtension.split('_filename=').pop();
        return filename;
    }

  return (
    <DashboardLayout>
        
        <SubMenuLayout subMenuType="site" currentMenu="Health & Safety">
            <p className="gb_title">Health & Safety</p>
            <p className="gb_subtitle">Health & Safety message is showed at the end of sign in flow to the visitors. Customize the message as per your needs, make it required or optional here.</p>
            {!loading ?
            <div className={styles.form}>
                <RSwitch status={showMessage} onChange={() => setShowMessage(!showMessage)} label="Show Site Health & Safety Message" />
                {showErrorAlert && <p className="error-alert">Please add a message or upload health & safety attachment</p> }
                {showMessage ?
                    <>
                        <TextArea isError={error.message} errorMessage={errorMessages.message} label="Site Health & Safety Message" value={message} onChange={(e) => setMessage(e.target.value)} />
                        <Row align="center" gap="2rem">
                            <input
                                type="file"
                                accept="image/png, image/jpeg, image/jpg, application/pdf"
                                ref={attachmentFile}
                                style={{ display : 'none' }}
                                onChange={(e) => onFileSelected(e)}
                            />
                            <Button icon={<CgAttachment />} backgroundColor={buttonColors.UPLOAD_BTN_COLOR} onClick={attachmentUploadClick} color="#545454" text="Upload Attachment" />
                            {attachedFile ? <Row align="center" gap="2rem" classNames={styles.uploadedFile}>
                                {typeof attachedFile === 'object' ? 
                                <p>{attachedFile.name}</p> :
                                <p>{getFileName(attachedFile)}</p> }
                                {/* <p>{typeof attachedFile}</p> */}
                                <Icon icon={<IoMdClose />} size="1.7rem" onClick={onFileRemoved} />
                            </Row> : <p>No Attachments</p>}
                        </Row>
                        <Checkbox classNames="mt-2" label="Visitor Signature is Required" checked={signatureRequired} onChange={() => setSignatureRequired(!signatureRequired)} />
                    </> : ''
                }
                <div className="mt-3 gb-flex-end">
                    <CheckPermission permission="sites_add_edit">
                        <Button text="Save Changes" onClick={onSaveChanges} />
                    </CheckPermission>
                </div>
            </div> : 
            <div className="mt-3">
                <div>
                    <Skeleton width="20%" />
                    <Skeleton height={40} />
                </div>
                <div className="mt-2">
                    <Skeleton width="20%" />
                    <Skeleton height={100} />
                </div>
                <div className="mt-2">
                    <Skeleton width="20%" height={30} />
                </div>
                <div className="mt-2">
                    <Skeleton width="20%" height={30} />
                </div>
            </div>
            }
        </SubMenuLayout>
    </DashboardLayout>
  )
}

export default SiteHealthAndSafetyScreen