import React from 'react';
import { Modal, Button, ButtonToolbar, Placeholder } from 'rsuite';

const ConfirmationModal = ({open, title, body, confirmButtonTitle, onConfirm, handleClose}) => {
    // const [open, setOpen] = React.useState(true);
    // const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);

    const handleConfirm = async() => {
        onConfirm();
        handleClose();
    }   
    
    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <Placeholder.Paragraph /> */}
                    {body}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleConfirm} appearance="primary">
                        {confirmButtonTitle}
                    </Button>
                    <Button onClick={handleClose} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ConfirmationModal