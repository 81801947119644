import React    from 'react'
import styles   from './card.module.scss';

/**
 * Card Component.
 * @param {Element} children  - Content to display inside card
 */


const Card = ({children, flex, classNames}) => {
  return (
    <div className={`${styles.cardContainer} ${classNames || ''}`}
      style={{ 
        flex : flex || 1
       }}
    >
        {children}
    </div>
  )
}

export default Card