export const fieldLabelColors = {
    text : '#42BA78',
    numeric : '#ff5d52',
    email : '#ffa436',
    required : '#D63F3F',
    notRequired : '#037093',
}

export const buttonColors = {
    UPLOAD_BTN_COLOR : '#dbdbdb'
}