import DashboardLayout  from "layouts/dashboardLayout/dashboard.jsx"
import Skeleton         from "react-loading-skeleton";
import PageHeader       from "components/pageHeader/pageHeader"
import SubMenuLayout    from "layouts/subMenuLayout/subMenuLayout"
import Col              from "layouts/col/col"
import Row              from "layouts/row/row"
import { IoMdClose } from "react-icons/io";
import { buttonColors } from "constants/colors";
import { CgAttachment } from "react-icons/cg";
import Icon from "components/icon/icon";
import TextArea         from "components/inputs/textarea/textarea"
import RSwitch          from "components/switch/switch"
import Button           from "components/buttons/button"
import Checkbox from "components/inputs/checkbox/checkbox";
import useHealthSafety  from "./useHealthSafety"
import styles           from './healthAndSafety.module.scss'
import CheckPermission from "components/checkPermission/checkPermission";

const TenantHealthAndSafetyScreen = () => {

    const {
        showMessage, setShowMessage,
        message, setMessage,
        actionRequired, setActionRequired,
        signatureRequired, setSignatureRequired,
        onSaveChanges,
        attachmentFile, 
        attachmentUploadClick,
        onFileSelected,
        attachedFile, setAttachedFile, 
        onFileRemoved,
        error, setError,
        errorMessages, setErrorMessages,
        showErrorAlert, setShowErrorAlert,
        isLoading, setLoading
    } = useHealthSafety();

    const getFileName = (url) => {
        const parts = url.split('/');
        const filenameWithExtension = parts[parts.length - 1];
        const filename = filenameWithExtension.split('_filename=').pop();
        return filename;
    }

     return (
        <DashboardLayout>
            
            <SubMenuLayout menu="tenant" subMenuType="tenant" currentMenu="Health & Safety">
                <p className="gb_title">Health & Safety</p>
                <p className="gb_subtitle">An additional health & safety agreement could be added along with the site.</p>
                {!isLoading ?
                <Col gap="3rem" classNames="mt-3">
                    <RSwitch status={showMessage} onChange={() => setShowMessage(!showMessage)} label="Show Tenant Health & Safety Message" />
                    {showErrorAlert && <p className="error-alert">Please add a message or upload health & safety attachment</p> }
                    {showMessage ?
                    <>
                        <TextArea 
                            label="Tenant Health & Safety Message" 
                            value={message} 
                            onChange={(e) => setMessage(e.target.value)}
                            isError={error.message}
                            showError={true}
                            errorMessage={errorMessages.message} 
                        />
                        <Row align="center" gap="2rem">
                            <input
                                type="file"
                                accept="image/png, image/jpeg, image/jpg, application/pdf"
                                ref={attachmentFile}
                                style={{ display : 'none' }}
                                onChange={(e) => onFileSelected(e)}
                            />
                            <Button icon={<CgAttachment />} backgroundColor={buttonColors.UPLOAD_BTN_COLOR} onClick={attachmentUploadClick} color="#545454" text="Upload Attachment" />
                            {attachedFile ? <Row align="center" gap="2rem" classNames={styles.uploadedFile}>
                                {typeof attachedFile === 'object' ? 
                                <p>{attachedFile.name}</p> :
                                <p>{getFileName(attachedFile)}</p> }
                                {/* <p>{typeof attachedFile}</p> */}
                                <Icon icon={<IoMdClose />} size="1.7rem" onClick={onFileRemoved} />
                            </Row> : <p>No Attachments</p>}
                        </Row>
                        <div>
                        <Checkbox classNames="mt-2" label="Visitor Signature is Required" checked={signatureRequired} onChange={() => setSignatureRequired(!signatureRequired)} />
                        </div>
                        {/* <RSwitch status={actionRequired} onChange={() => setActionRequired(!actionRequired)} label="Visitor Action Required" />
                        {actionRequired ? <p>Visitor would tick a checkbox to confirm they have read the tenant health & safety message.</p> : '' }
                        {actionRequired ? <RSwitch status={signatureRequired} onChange={() => setSignatureRequired(!signatureRequired)} label="Require Visitor Signature" /> : '' } */}
                    </> : ''}
                 </Col> : 
                 <div className="mt-3">
                    <div>
                        <Skeleton width="20%" />
                        <Skeleton height={40} />
                    </div>
                    <div className="mt-2">
                        <Skeleton width="20%" />
                        <Skeleton height={100} />
                    </div>
                    <div className="mt-2">
                        <Skeleton width="20%" height={30} />
                    </div>
                    <div className="mt-2">
                        <Skeleton width="20%" height={30} />
                    </div>
                </div>
                 }

                <div className={styles.bottom}>
                    <CheckPermission permission="tenants_add_edit">
                        <Row classNames="mt-5" justify="right">
                            <Button text="Save Changes" onClick={onSaveChanges} />    
                        </Row> 
                    </CheckPermission>
                </div>
            </SubMenuLayout>
        </DashboardLayout>
    )
}

export default TenantHealthAndSafetyScreen