import React            from 'react'
import { IconContext }  from 'react-icons/lib'

/**
 * Icon Component
 * @param {Component} icon    - Icon to display.
 * @param {String} color      - Color of Icon.
 * @param {String} size       - Size of Icon.
 * @param {Function} onClick  - Function to execute when icon is clicked
 */


const Icon = ({icon, color, size, onClick}) => {

  return (
    <IconContext.Provider value={{ color: color, size : size }}>
        {React.cloneElement(icon, { onClick: onClick })}
    </IconContext.Provider>
  )
}

export default Icon