import React    from 'react'
import styles   from './textarea.module.scss';

/**
 * Text Area component.
 * @param {Number} flex           - Adjust width of the input. By default it is 1.
 * @param {String} placeholder    - Placeholder text to show in the input. By default it is ''.
 * @param {String} label          - Label to show with the input.
 * @param {String} type           - Type of input, text/password. By default it is text
 * @param {Boolean} required      - Shows required icon
 */


const TextArea = ({flex, placeholder, onBlur, label, required, type, value, onChange, isError, errorMessage, showError = true}) => {
  return (
    <div
        style={{ 
            flex : flex || 1
         }} 
        className={styles.inputContainer}
    >
        {label && <label>{label} {required && <span className={styles.required}>*</span> }</label> }
        <textarea onBlur={onBlur} className= {isError ? 'error-outline' : ''} onChange={onChange} cols={10}>{value || ''}</textarea>
        {showError && <p className={`errorMessage ${isError ? 'active' : ''}`}>{errorMessage || ''}&nbsp;</p> }
    </div>
  )
}

export default TextArea