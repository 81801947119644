import { useState, useEffect } from 'react'
import useApi from 'hooks/useApi';
import moment from 'moment';
import { useAuth } from 'contexts/authContext';
import UserPlaceholder from 'assets/images/user-placeholder.png';
import { showErrorToast } from 'functions/toasts';
import { getImageURL } from 'functions/functions';

const useTodaysVisitors = () => {

    const [modalOpen, setModalOpen] = useState(false);
    const [visitorName, setVisitorName] = useState('');
    const [selectedTenant, setSelectedTenant] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedVisitor, setSelectedVisitor] = useState('');
    const [tenantOptions, setTenantOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    const {get} = useApi();
    const {currentBuilding} = useAuth();

    useEffect(() => {
        fetchData();
    },[currentBuilding]);

    const fetchData = async () => {
        setLoading(true)
        try {
            await Promise.all([fetchTodaysVisitors(), fetchTenantsOptions()]);
            setLoading(false);
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }

    const fetchTodaysVisitors = async () => {
        try{
            const response = await get(`buildings/${currentBuilding.attributes.building_id}/visitors?data=signed_out&date=today`);
            setData(response.data.data);
            setFilteredData(response.data.data);
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
        
    }

    const fetchTenantsOptions = async () => {
        try {
            const response = await get(`buildings/${currentBuilding.attributes.building_id}/tenants`);
            const data = response.data.data;

            let tenantArray = [{
                label : 'All Tenants',
                value : 'all'
            }];

            data.forEach(tenant => {
                if(tenant.attributes.is_active === 1) {
                    tenantArray.push({
                        ...tenant.attributes,
                        label : tenant.attributes.name,
                        value : tenant.attributes.tenant_id,
                    })
                }
            });

            setTenantOptions(tenantArray);

            setSelectedTenant({
                label : 'All Tenants',
                value : 'all'
            })

        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }

    const onTenantChange = (e) => {
        console.log(e);

        let filteredData;

        if(e.value !== 'all') {
            filteredData = data.filter((data) => {
                return data.tenant_id === e.value
            })
        }

        if(e.value === 'all') filteredData = data;

        setFilteredData(filteredData);
    }

    const columns = [
        {
            name : "Visitor Name", 
            width: '250px', 
            cell : (row) => (
            <div style={{ display : 'flex', justifyContent : 'flex-start', alignItems : 'center', gap : '1rem'}}>
                <img src={row.photo ? getImageURL(row.photo) : UserPlaceholder} style={{ borderRadius : '50%', width : '35px' }} />
                <p style={{ fontWeight : '500' }}>{row.visitor_name}</p>
            </div>
            )
        },
        {
            name: 'Tenant',
            selector: row => row.tenant_name,
        },
        {
            name: 'Sign In Time',
            selector: row => row.sign_in_time,
        },
        {
            name: 'Sign Out Time',
            selector: row => row.sign_out_time,
        },
    ];

     // Columns of CSV File
     const header = [
        {label : "Visitor Name",    key : "visitor_name"},
        {label : "Tenant",          key : "tenant_name"},
        {label : "Sign In TIme",    key : "sign_in_time"},
        {label : "Sign Out Time",   key : "sign_out_time"},
      ];

    
    // const tenantOptions = [
    //     { value: 'ViPower Limited', label: 'ViPower Limited' },
    //     { value: 'PC Power International Ltd', label: 'PC Power International Ltd' },
    // ];
    
    const statusOptions = [
        { value: 'All Status', label: 'All Status' },
        { value: 'Active', label: 'Active' },
        { value: 'Inactive', label: 'Inactive' },
    ];

    const rowClick = (e) => {
        console.log(e)
        setSelectedVisitor(e);
        setModalOpen(true)
    }

    const closeModal = () => {
        setModalOpen(false);
    }

    return {
        modalOpen,
        setModalOpen,
        columns,
        data,
        filteredData, setFilteredData,
        tenantOptions, setTenantOptions,
        statusOptions,
        visitorName, setVisitorName,
        selectedTenant, setSelectedTenant,
        rowClick,
        closeModal,
        selectedVisitor, setSelectedVisitor,
        fetchTodaysVisitors,
        onTenantChange,
        header,
        loading, setLoading
    }
}

export default useTodaysVisitors