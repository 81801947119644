import React from 'react'
import styles from './container.module.scss';

const Container = ({children, backgroundColor, width, height, margin, justify, align, padding, paddingTop}) => {
  return (
    <div style={{ 
        backgroundColor: 'red',
        backgroundColor : backgroundColor ? backgroundColor : '',
        maxWidth : width ? width : '',
        height : height ? height : '', 
        margin : margin ? margin : '',
        justifyContent : justify ? justify : 'left',
        alignItems : align ? align : 'flex-start',
        padding : padding ? padding : '',
        paddingTop : paddingTop ? paddingTop : ''
    }}>
        {children}
    </div>
  )
}

export default Container