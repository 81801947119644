import { useState, useEffect }              from "react";
import { useParams }                        from "react-router-dom";
import { showSuccessToast, showErrorToast } from "functions/toasts";
import useApi from "hooks/useApi";
import moment from "moment/moment";


const useSettings = () => {

    const [deliveriesStatus, setDeliveriesStatus]       = useState(false);
    const [preRegStatus, setPreRegStatus]               = useState(false);
    const [buddyAppStatus, setBuddyAppStatus]           = useState(false);
    const [signOutStatus, setSignOutStatus]             = useState(false);
    const [signOutTime, setSignOutTime]                 = useState('');
    const [badgePrintingStatus, setBadgePrintingStatus] = useState(false);
    const [error, setError]                             = useState('');
    const [errorMessage, setErrorMessage]               = useState('');
    const [loading, setLoading]                         = useState(true);
    const [selectedBadge, setSelectedBadge]             = useState('template-02');

    const {id} = useParams();

    const { get, post } = useApi();

    const moment = require('moment');

    useEffect(() => {
        fetchSiteSettings()
    }, []);


    const fetchSiteSettings = async () => {
        setLoading(true);

        try {
            const response = await get(`buildings/${id}`);
            console.log(response);
            const data = response.data.data.attributes.settings;

            console.log(data);

            setBadgePrintingStatus(data.badge_printing_enabled);
            setBuddyAppStatus(data.buddy_app_enabled);
            setDeliveriesStatus(data.deliveries_enabled);
            setPreRegStatus(data.pre_registration_enabled);
            setSignOutStatus(data.sign_out_all);
            setSignOutTime(data.default_signout_time || '12:00');
            setSelectedBadge(data.badge_template)


            setLoading(false);

        } catch(err) {
            console.log(err);
        }
    }

    const saveChanges = async () => {
        
        const inputTime = signOutTime ? moment(signOutTime, "HH:mm:ss").format("HH:mm") : '';

        console.log(inputTime);

        let errorObj = {
            time : false
        }

        let errorMessageObj = {
            time : ''
        }

        if(signOutStatus) {
            if(!signOutTime || signOutTime == '' || signOutTime == null) {
                errorObj.time = true;
                errorMessageObj.time = 'Enter a valid time in 24hr format';
            }
        }

        if(!errorObj.time) {
            try {

                const data = {
                    deliveries_enabled : deliveriesStatus ? 1 : 0,
                    pre_registration_enabled : preRegStatus ? 1 : 0,
                    buddy_app_enabled : buddyAppStatus ? 1 : 0,
                    sign_out_all : signOutStatus ? 1 : 0,
                    badge_printing_enabled : badgePrintingStatus ? 1 : 0,
                    badge_template : selectedBadge
                }

                if(signOutStatus) {
                    data['default_signout_time'] = inputTime
                }

                await post(`buildings/${id}`, data);
                
                await fetchSiteSettings();
                showSuccessToast('Site Updated Successfully');

            } catch(err) {
                showErrorToast('Something Went Wrong');
            }
        }

        setError(errorObj);
        setErrorMessage(errorMessageObj);
        
    }
    

    return {
        deliveriesStatus, setDeliveriesStatus,
        preRegStatus, setPreRegStatus,
        buddyAppStatus, setBuddyAppStatus,
        signOutStatus, setSignOutStatus,
        signOutTime, setSignOutTime,
        badgePrintingStatus, setBadgePrintingStatus,
        error, setError,
        errorMessage, setErrorMessage,
        loading, setLoading,
        selectedBadge, setSelectedBadge,
        saveChanges
    }

}

export default useSettings