
export const DEFAULT_TABLE_COLUMNS = [
    {
        title           : 'Delivery Id',
        dataIndex       : 'delivery_id',
        key             : 'delivery_id',
        render          : item => item.toString().padStart(3, '0')
    },
    {
        title           : 'Tenant',
        dataIndex       : ['tenant', 'name'],
        key             : 'tenant_name',
    },
    {
        title           : 'Employee',
        dataIndex       : ['employee', 'name'],
        key             : 'employee_name',
    },
    {
        title           : 'Arrival Time',
        dataIndex       : 'arrival_time',
        key             : 'arrival_time',
    },
    {
        title           : 'Collection Time',
        dataIndex       : 'collection_time',
        key             : 'collection_time',
    }
];

export const DEFAULT_TABLE_PARAMS = {
    pagination: {
        pageSizeOptions : ['10', '20', '30', '50'],
        showSizeChanger : true,
        current         : 1,
        pageSize        : 10,
        defaultPageSize : 10,
    },
}

export const DEFAULT_TENANT_OPTIONS = [
    {
        label : 'All Tenants',
        value : 'all'
    }
];

export const DEFAULT_LOADER_PARAMS = {
    sites       : true,
    tenants     : true,
    visitors    : true
}