import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { showErrorToast, showSuccessToast } from "functions/toasts";
import useApi from "hooks/useApi"


const useSignInForm = () => {

    const [selectedOption, setSelectedOption] = useState('');
    const [tenantTemplates, setTenantTemplates] = useState([]);
    const [globalTemplates, setGlobalTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [templateMode, setTemplateMode] = useState('add');
    const [templateModalStatus, setTemplateModalStatus] = useState(false);
    const [activeTemplate, setActiveTemplate] = useState('');
    const [loading, setLoading] = useState(true);

    // Get Paramter Values for Site Id and Tenant Id
    const {id, tenantId} = useParams();

    const {get, post} = useApi();

    // Fetch Data on Page Load
    useEffect(() => {
        fetchTemplates();
    },[]);

    // Function to fetch all templates
    const fetchTemplates = async () => {
        setLoading(true);

        try {
            const activeTemplate = await getTenantActiveTemplate();
            
            const [tenantTemplates, globalTemplates] = await Promise.all([getTenantTemplates(), getGlobalTemplates()]);

            
            console.log("ACTIVE", activeTemplate);
            console.log("TENANT", tenantTemplates);
            console.log("GLOBAL", globalTemplates);

            globalTemplates.forEach(template => {
                if(template.template_id == activeTemplate.template_id) {
                    console.log('HERE')
                    setSelectedOption('global');
                    return;
                }
            });

            tenantTemplates.forEach(template => {
                if(template.template_id == activeTemplate.template_id) {
                    console.log('HERE')
                    setSelectedOption('tenant');
                    return;
                }
            })
            

        } catch(err) {
            showErrorToast('Something Went Wrong');
        } finally {
            setLoading(false);
        }
    }

    // Funtion to get Tenant Templates
    const getTenantTemplates = async () => {
        const response = await get(`tenants/${tenantId}/form-templates`);
        setTenantTemplates(response.data.data);
        return response.data.data;
    }

    // Function to get Global Templates
    const getGlobalTemplates = async () => {
        const response = await get(`buildings/${id}/form-templates`);
        setGlobalTemplates(response.data.data);
        return response.data.data;
    }

    // Function to get tenant active template
    const getTenantActiveTemplate = async () => {
        try {
            const response = await get(`tenants/${tenantId}`);
            const templateId = response.data.data.attributes.template_id;

            // Get Active Template Details
            try {
                const templateResponse = await get(`form-templates/${templateId}`);
                setActiveTemplate(templateResponse.data.data);
                return templateResponse.data.data;
                // console.log(templateResponse.data.data);
            } catch(err) {
                showErrorToast('Somethinh Went Wrong')
            }
        } catch(err) {
            showErrorToast('Somethinh Went Wrong');
        }
    }

    // On Add New Button Click
    const addNewTemplateClick = () => {
        console.log('here');
        setTemplateMode('add');
        setTemplateModalStatus(true);
    }

    // On template modal is closed
    const closeTemplateModal = async () => {
        setSelectedTemplate('');
        setTemplateModalStatus(false)
        await Promise.all([getTenantTemplates(), getGlobalTemplates()]);
    }

    // Function to assign a template
    const onTemplateSelect = async (template) => {
        console.log(template);
        try {
            await post(`tenants/${tenantId}/sign-in-form`, {
                template_id : template.template_id
            });
            showSuccessToast('Template Assigned Successfully');
            fetchTemplates(); 
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }

    // On Template Row Edit is Clicked
    const onEditClick = (template) => {
        setSelectedTemplate(template);
        setTemplateMode('edit');
        setTemplateModalStatus(true)
    }

    return {
        selectedOption, setSelectedOption,
        tenantTemplates,
        fetchTemplates,
        globalTemplates,
        addNewTemplateClick,
        templateMode, setTemplateMode,
        templateModalStatus, setTemplateModalStatus,
        id, tenantId,
        closeTemplateModal,
        selectedTemplate, setSelectedTemplate,
        onTemplateSelect,
        activeTemplate, setActiveTemplate,
        onEditClick,
        loading, setLoading
    }
}

export default useSignInForm;