import { useState, useEffect } from "react"
import useApi from "hooks/useApi";
import { showErrorToast } from "functions/toasts";
import { dateTimeFormat } from "functions/functions";
import { useAuth } from "contexts/authContext";
import ParcelAssigned from 'assets/images/parcel-assigned.png';
import ParcelUnAssigned from 'assets/images/parcel-unassigned.png';


// Custom Hook for for the deliveries page.
const useDeliveries = () => {

    const [confirmDeliveryModalOpen, setConfirmDeliveryModalOpen] = useState(false);
    const [deliveryDetailsModalOpen, setDeliveryDetailsModalOpen] = useState(false);
    const [unassignedDeliveries, setUnAssignedDeliveries] = useState([]);
    const [selectedTenant, setSelectedTenant] = useState('');
    const [tenantOptions, setTenantOptions] = useState([]);
    const [assignedDeliveries, setAssignedDeliveries] = useState([]);
    const [filteredAssignedDeliveries, setFilteredAssignedDeliveries] = useState([]);
    const [selectedUnAssignedDelivery, setSelectedUnAssignedDelivery] = useState('');
    const [selectedAssignedDelivery, setSelectedAssignedDelivery] = useState('');
    const [showCollectionDetailsModal, setShowCollectionDetailsModal] = useState(false);
    const [confirmModalMode, setConfirmModalMode] = useState('');
    const [loading, setLoading] = useState(true);

    const {get} = useApi();
    const {currentBuilding} = useAuth();

    useEffect(() => {
        fetchData();
    } ,[currentBuilding]);

    const fetchData = async () => {
        try {
            setLoading(true);
            await Promise.all([fetchBuildingDeliveries(), fetchUnAssignedDeliveries(), fetchTenantsOptions()]);
            setLoading(false);
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
        // await fetchBuildingDeliveries();
        // await fetchUnAssignedDeliveries();
    }

    const fetchUnAssignedDeliveries = async() => {
        try {
            const response = await get(`buildings/${currentBuilding.attributes.building_id}/unassigned-deliveries`);
            console.log('Unassigned',response);
            setUnAssignedDeliveries(response.data.data);
        }catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }

    const fetchBuildingDeliveries = async () => {

        try {   
            const response = await get(`buildings/${currentBuilding.attributes.building_id}/deliveries`);
            const data = response.data.data;
            let collectionArray=[];
            

            for(const[key, value] of Object.entries(data)) {
                
                let obj = {
                    collection_id   : key,
                    deliveries      : value,
                    tenantName      : value[0].tenant.name,
                    tenantId        : value[0].tenant.tenant_id,
                    noOfParcel      : value.length,
                    arrival_time    : value[0].arrival_time
                }

                if(value[0].employee) {
                    obj['employeeName'] = value[0].employee.name
                }

                collectionArray.push(obj);
            }

            setAssignedDeliveries(collectionArray);
            setFilteredAssignedDeliveries(collectionArray);
        }catch(err) {
            console.log(err);
            showErrorToast('Something Went Wrong');
        }
    }

    const fetchTenantsOptions = async () => {
        try {
            const response = await get(`buildings/${currentBuilding.attributes.building_id}/tenants`);
            const data = response.data.data;

            let tenantArray = [{
                label : 'All Tenants',
                value : 'all'
            }];

            data.forEach(tenant => {
                if(tenant.attributes.is_active === 1) {
                    tenantArray.push({
                        ...tenant.attributes,
                        label : tenant.attributes.name,
                        value : tenant.attributes.tenant_id,
                    })
                }
            });

            setTenantOptions(tenantArray);

            setSelectedTenant({
                label : 'All Tenants',
                value : 'all'
            })

        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }

    const onTenantChange = (e) => {
        console.log(e);

        let filteredData;

        if(e.value !== 'all') {
            filteredData = assignedDeliveries.filter((data) => {
                return data.tenantId === e.value
            })
        }

        if(e.value === 'all') filteredData = assignedDeliveries;

        setFilteredAssignedDeliveries(filteredData);
    }

    const columns = [
        {
            name : "Assigned Delivery", 
            width: '150px', 
            cell : (row) => {
                return(
                <div style={{ display : 'flex', justifyContent : 'flex-start', alignItems : 'center', gap : '0.5rem'}} onClick={() => onAssignedDeliveryClick(row)}>
                    <img src={ParcelAssigned} style={{ width : '30px' }} />
                    <p style={{ fontWeight : '500' }}>AD-{row.collection_id.toString().padStart(3, '0')}</p>
                </div>
                )
            }
        },
        {
            name: 'Recipient',
            cell : (row) => (
                <div style={{ display : 'flex', flexDirection : 'column'}} onClick={() => onAssignedDeliveryClick(row)}>
                    <p style={{ fontWeight : '500' }}>{row.tenantName}</p>
                    <p style={{ fontWeight : '400', fontSize : '1rem' }}>{row.employeeName || ''}</p>
                </div>
            ),
        },
        {
            name: 'No.of Deliveries',
            cell : (row) => (
                <p onClick={() => onAssignedDeliveryClick(row)}>{row.noOfParcel}</p>
            )
        },
        {
            name: 'Arrival Time',
            cell : (row) => (
                <p onClick={() => onAssignedDeliveryClick(row)}>{dateTimeFormat(row.arrival_time)}</p>
            )
        },
    ];
    

    const unassignedColumns = [
        {
            name : "Unassigned Delivery", 
            width: '200px', 
            cell : (row) => (
            <div style={{ display : 'flex', justifyContent : 'flex-start', alignItems : 'center', gap : '0.5rem'}} onClick={()=> onUnAssignedDeliveryClick(row)}>
                <img src={ParcelUnAssigned} style={{ width : '30px' }} />
                <p style={{ fontWeight : '500' }}>UD-{row.un_delivery_id.toString().padStart(3, '0')}</p>
            </div>
            )
        },
        {
            name: 'Arrival Time',
            cell : (row) => (
                <p onClick={()=> onUnAssignedDeliveryClick(row)}>{dateTimeFormat(row.arrival_time)}</p>
            )
        },
    ]

    const onUnAssignedDeliveryClick = (delivery) => {
        setConfirmModalMode('edit');
        setSelectedUnAssignedDelivery(delivery);
        setConfirmDeliveryModalOpen(true);
    }

    const onAssignedDeliveryClick = (delivery) => {
        setSelectedAssignedDelivery(delivery);
        setShowCollectionDetailsModal(true);
    }

    // Function to close Confirm Delivery Modal
    const closeModal = () => {
        setConfirmModalMode('');
        setConfirmDeliveryModalOpen(false);
    }

    // Function to close Delivery Details Modal
    const closeDeliveryDetailsModal = () => {
        setDeliveryDetailsModalOpen(false);
    }

    const addDeliveryClick = () => {
        setConfirmModalMode('add');
        setConfirmDeliveryModalOpen(true); 
    }

    return {
        columns,
        unassignedColumns,
        assignedDeliveries,
        unassignedDeliveries,
        confirmDeliveryModalOpen,
        setConfirmDeliveryModalOpen,
        closeModal,
        deliveryDetailsModalOpen, 
        setDeliveryDetailsModalOpen,
        closeDeliveryDetailsModal,
        onUnAssignedDeliveryClick,
        onAssignedDeliveryClick,
        selectedUnAssignedDelivery, setSelectedUnAssignedDelivery,
        selectedAssignedDelivery, setSelectedAssignedDelivery,
        showCollectionDetailsModal, setShowCollectionDetailsModal,
        fetchData,
        addDeliveryClick,
        selectedTenant, setSelectedTenant,
        onTenantChange,
        filteredAssignedDeliveries,
        tenantOptions, setTenantOptions,
        confirmModalMode,
    }
}

export default useDeliveries;