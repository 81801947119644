import Header   from 'components/header/header';
import { useState, useEffect } from 'react';
import Sidebar  from 'components/sidebar/sidebar';
import styles   from './dashboard.module.scss';
import { useAuth } from 'contexts/authContext';

/**
 * Dashboard Layout.
 * Main layout that renders the header, sidebar and container section of the page.
 * 
 * @param {Element} children      - Content to show inside the container
 */

const DashboardLayout = ({children}) => {

  const [seconds, setSeconds] = useState(60); 
  const {showSessionWarning} = useAuth();

  useEffect(() => {
    if(showSessionWarning) {
      const timer = setInterval(() => {
        setSeconds(prevSeconds => {
          if (prevSeconds === 1) {
            clearInterval(timer);
            // Handle timer completion here, such as triggering an action or updating state
            return 0; // Reset the timer to 0 or any other value
          } else {
            return prevSeconds - 1;
          }
        });
      }, 1000);

      return () => clearInterval(timer); // Cleanup function to clear the interval on unmount
    }
  }, [showSessionWarning]);
  
    return (
        <>
          <Header />
          <Sidebar />
          <div className={styles.container}>
            {showSessionWarning && <div className={styles.container__warning}>
              <p>Your Session is about to expire and would be logged out automatically after {seconds} seconds.</p>
            </div> }
            <div className={styles.container__content} style={{ marginTop : showSessionWarning ? '7rem' : '' }}>
              {children}
            </div>
          </div>
        </>
      )
}

export default DashboardLayout