import { useState, useEffect }              from "react";
import { showErrorToast, showSuccessToast } from "functions/toasts";
import { useAuth }                          from "contexts/authContext";
import { DEFAULT_TENANT_OPTIONS, DEFAULT_TABLE_PARAMS, DEFAULT_SEARCH_PARAMS, DEFAULT_TABLE_COLUMNS, DEFAULT_SEARCH_OPTIONS, DEFAULT_LOADER_PARAMS } 
                                            from "./constants";
import useApi                               from "hooks/useApi";
import useTenantFunctions                   from "functions/useTenantFunctions";
import UserPlaceholder                      from 'assets/images/user-placeholder.png';
import moment                               from "moment";

const useVisitorReports = () => {

    const {currentBuilding, user} = useAuth();

    const [loading, setLoading]                 = useState(true);
    const [dataLoading, setDataLoading]         = useState(false);
    const [filterLoading, setFilterLoading]     = useState(false);
    const [visitorName, setVisitorName]         = useState('');
    const [data, setData]                       = useState([]);
    const [tenants, setTenants]                 = useState(DEFAULT_TENANT_OPTIONS);
    const [sites, setSites]                     = useState([]);
    const [selectedSite, setSelectedSite]       = useState(currentBuilding.attributes.building_id);
    const [selectedTenant, setSelectedTenant]   = useState('all');
    const [filteredData, setFilteredData]       = useState([]);
    const [searchValue, setSearchValue]         = useState('');
    const [state, setState]                     = useState();
    const [date, setDate]                       = useState();
    const [selectedUnit, setSelectedUnit]       = useState('week');
    const [lineChartData, setLineChartData]     = useState([]);
    const [chartLoading, setChartLoading]       = useState(false);
    const [isModalOpen, setIsModalOpen]         = useState(false);
    const [tableParams, setTableParams]         = useState(DEFAULT_TABLE_PARAMS);
    const [search, setSearch]                   = useState(DEFAULT_SEARCH_PARAMS);                                            
    const [columns, setColumns]                 = useState(DEFAULT_TABLE_COLUMNS);
    const [searchOptions, setSearchOptions]     = useState(DEFAULT_SEARCH_OPTIONS);
    const [loader, setLoader]                   = useState(DEFAULT_LOADER_PARAMS);
    
    const {getAllTenants} = useTenantFunctions();

    const {get} = useApi();



    // Fetch Data on Page Load
    useEffect(() => {
        fetchData();
    },[currentBuilding]);

    // Fetch Visitors Data on Page Load and when pagination parameters are changed
    useEffect(() => {
        fetchVisitors();
    }, [tableParams.pagination.current, tableParams.pagination.pageSize]);


    // Fetch Site & Tenant Data
    const fetchData = async () => {
        setSiteOptions();
        setTenantOptions(selectedSite);
    };

    const onChartUnitChange = async (unit) => {
        const lineData = await getLineChartData(unit);
        console.log(lineData);
        setLineChartData(lineData);
        setSelectedUnit(unit);
    }

    // Function to build query params for visitor API
    const buildQueryParams = ({ tenant, date, pagination }) => {
        const params = new URLSearchParams();
        
        if (tenant) {
            params.append('tenant', tenant);
        }
        
        if (date?.startDate) {
            params.append('start_date', date.startDate);
        }
    
        if (date?.endDate) {
            params.append('end_date', date.endDate);
        }
    
        if (pagination?.current) {
            params.append('page', pagination.current);
        }
    
        if (pagination?.pageSize) {
            params.append('page_size', pagination.pageSize);
        }

        if(search.searchBy && search.searchByValue) {
            params.append('searchBy', search.searchBy);
            params.append('searchByValue', search.searchByValue)
        }
    
        return params.toString();
    };

    const queryParams = buildQueryParams({
        tenant: selectedTenant,
        date,
        pagination: tableParams.pagination
    });

    // Function to fetch visitors data
    const fetchVisitors = async () => {
      try {
        const buildingId = selectedSite || currentBuilding.attributes.building_id;
        const response = await get(`buildings/${buildingId}/visitors?${queryParams}`);

        // Adding key parameter to the visitor response
        const visitorData = response.data.data.map((data, index) => ({
          ...data,
          key: index,
        }));

        setData(visitorData);

        // Updating Table pagination paramter with total number of records
        setTableParams((prev) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            total: response.data.total,
          },
        }));

        // Updating Visitor Loader to FALSE
        setLoader(prevLoader => ({
            ...prevLoader,
            visitors: false,
        }));

        return response.data.data;
      } catch (err) {
        showErrorToast("Something Went Wrong");
      }
    };

    // Function to get tenants based on selected site
    const setTenantOptions = async (siteId) => {
        try {
            const response = await getAllTenants(siteId);

            let tenantOptions = [{
                label: 'All Tenants',
                value: 'all'
            }];

            // Adding all active tenants to tenant options
            response.forEach(tenant => {
                if (tenant.attributes.is_active === 1) {
                    tenantOptions.push({
                        ...tenant.attributes,
                        label: tenant.attributes.name,
                        value: tenant.attributes.tenant_id,
                    })
                }
            });

            setTenants(tenantOptions);

        } catch (err) {
            showErrorToast('Something Went Wrong')
        }

        // Updating Tenant Loader to FALSE state
        setLoader(prevLoader => ({
            ...prevLoader,
            tenants: false,
        }));
    }

    // Function to set site options
    const setSiteOptions = async () => {
        
        let siteOptions = [];
        user.approved_buildings.buildings.forEach((building) => {
            siteOptions.push({
                label : building.attributes.name,
                value : building.attributes.building_id
            })
        });

        setSites(siteOptions);
        setLoader(prevLoader => ({
            ...prevLoader,
            sites: false,
        }));
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
          pagination,
          filters,
        });
    
        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
          setData([]);
        }
      };

    

    const getLineChartData = async (unit) => {
        setChartLoading(true)
        const response = await get(`buildings/${selectedSite.value || currentBuilding.attributes.building_id}/visitors?data=signed_out`);
        console.log(response)
        const data = response.data.data;

        const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        const startOfWeek = moment().startOf('isoWeek');
        const endOfWeek = moment().endOf('isoWeek');
        const daysDataSignedIn = Array(7).fill(0);                          // Array to track days signed in data
        const monthsDataSignedIn = Array(moment().month() + 1).fill(0);     // Array to track monthly signed in data

        console.log(startOfWeek, endOfWeek);

        data.forEach((item) => {
            const signInTime = moment(item.sign_in_time, "YYYY-MM-DD hh:mm a");
            console.log(item.sign_in_time);
            if(signInTime.isBetween(startOfWeek, endOfWeek, 'day', '[]')) {
                
                const dayIndex = daysOfWeek.indexOf(signInTime.format('dddd'));
                daysDataSignedIn[dayIndex]++;
            }

            const monthIndex = signInTime.month();
            monthsDataSignedIn[monthIndex]++;
        })

        console.log('DAYS DATA', daysDataSignedIn);
        console.log('MONTHS DATA', monthsDataSignedIn.length);

        if(unit == "this-month") {
            const startOfMonth = moment().startOf('month');
            const weeksDataSignedIn = Array(startOfMonth.clone().endOf('month').isoWeek() - startOfMonth.isoWeek() + 1).fill(0);

            data.forEach((item) => {
                const signInTime = moment(item.sign_in_time, "YYYY-MM-DD hh:mm a");
                if(signInTime.isBetween(startOfMonth, startOfMonth.clone().endOf('month'), 'day', '[]')) {
                    const weekNumber = signInTime.isoWeek() - startOfMonth.isoWeek();
                    weeksDataSignedIn[weekNumber]++;
                }
            })
            setChartLoading(true)
            return {
                labels: Array(weeksDataSignedIn.length).fill(0).map((_, index) => `Week ${index + 1}`),
                datasets: [
                  {
                    label: 'Visitors',
                    data: weeksDataSignedIn,
                    backgroundColor: '#00B9AE',
                  },
                ],
              };
        }
        setChartLoading(true)
        return {
            labels: unit === 'week' ? daysOfWeek : moment.monthsShort(), // Use short month names
            datasets: [
              {
                label: 'Visitors',
                data: unit === 'week' ? daysDataSignedIn : monthsDataSignedIn,
                backgroundColor: '#00B9AE',
              },
            ],
          };
        
    }


    // Apply Filters
    const applyFilters = async () => {

        setLoader(prevLoader => ({
            ...prevLoader,
            visitors : true
        }));

        // Reset pagination to the first page locally
        const updatedPagination = {
            ...tableParams.pagination,
            current: 1, // Reset to the first page
        };

        // Build query params with the updated pagination
        const queryParams = buildQueryParams({
            tenant: selectedTenant,
            date,
            pagination: updatedPagination, // Use the updated pagination
        });

    
        try{
            const buildingId = selectedSite || currentBuilding.attributes.building_id;
            const response = await get(`buildings/${buildingId}/visitors?${queryParams}`);
            const visitorData = response.data.data.map((data, index) => (
                {
                    ...data,
                    key : index
                }
            ));

            setData(visitorData);

            setTableParams({
                ...tableParams,
                pagination: {
                  ...tableParams.pagination,
                  total: response.data.total,
                  current: 1,
                },
            });
            
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }

        setLoader(prevLoader => ({
            ...prevLoader,
            visitors : false
        }));

    }

    // When a site is changed
    const onSiteChange = (e) => {
        console.log(e);
        setSelectedTenant('all');
        setTenantOptions(e)
    }

    // When tenant option is changed
    const onTenantChange = async (e) => {
        console.log(e)
        setSelectedTenant(e); 

    }
    
    const onDateChange = (dates) => {
        const formattedDates = dates ? dates.map(date => date.format('YYYY-MM-DD HH:mm:ss')) : [];
        console.log(formattedDates);
        setDate({
            startDate : formattedDates[0],
            endDate : formattedDates[1]
        });
    }

    const closeExportModal = () => {
        setIsModalOpen(false);
    }

    return {
      loading,
      setLoading,
      dataLoading,
      setDataLoading,
      filterLoading,
      setFilterLoading,
      data,
      setData,
      tenants,
      setTenants,
      selectedTenant,
      setSelectedTenant,
      sites,
      setSites,
      selectedSite,
      setSelectedSite,
      filteredData,
      setFilteredData,
      columns,
      applyFilters,
      onSiteChange,
      onTenantChange,
      state,
      setState,
      visitorName,
      setVisitorName,
      selectedUnit,
      setSelectedUnit,
      lineChartData,
      onChartUnitChange,
      handleTableChange,
      onDateChange,
      chartLoading,
      setChartLoading,
      tableParams,
      date,
      search,
      setSearch,
      searchOptions,
      setSearchOptions,
      searchValue,
      setSearchValue,
      loader,
      isModalOpen,
      setIsModalOpen,
      closeExportModal,
    };
}

export default useVisitorReports;