import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import Lottie               from "lottie-react";
import Error from 'assets/animations/error.json';
import { useAuth } from "contexts/authContext";
import styles from './noSites.module.scss';
import Cookies from "js-cookie";
import Button from "components/buttons/button";

const NoSitesScreen = () => {

    const navigate = useNavigate();
    const {user, setApprovedBuildings, setCurrentBuilding} = useAuth();

    useEffect(() => {
        if(user.approved_buildings.buildings.length > 0) {
            setApprovedBuildings(user.approved_buildings.buildings);
            setCurrentBuilding(user.approved_buildings.buildings[0]);
            Cookies.set('currentBuilding', JSON.stringify(user.approved_buildings.buildings[0]));

            navigate('/dashboard');
        }
    },[]);

  return (
    <div className={styles.container}>
        <div className={styles.error_message}>
            <div className={styles.error_message__left}>
                <Lottie animationData={Error} loop={true} style={{ width : 150, height : 150 }} />
            </div>
            <div className={styles.error_message__right}>
                <h3>No Access to Sites</h3>
                <p>Sorry. You don't have access any of the active sites. Please contact your administrator.</p>
                <Button text="Logout" classNames="mt-1" onClick={() => navigate('/dashboard')} />
            </div>
        </div>
    </div>
  )
}

export default NoSitesScreen