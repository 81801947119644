import React            from 'react'
import CustomModal      from '../modal'
import Row from 'layouts/row/row';
import Button           from 'components/buttons/button';

const Body = () => {
    return (
        <div>
            <p>Are you sure you want to delete this tenant ?</p>
        </div>
    )
}

const Footer = ({deleteTenant, closeModal}) => {
    return (
        <Row justify="flex-end" gap="1rem">
            <Button text="No, Cancel" onClick={closeModal} backgroundColor="#ebebeb" color="#575757" />
            <Button text="Yes Delete" onClick={deleteTenant} />
        </Row>
    )
}

/**
 * 
 * @param {Boolean} isModalOpen     - Shows or hides modal
 * @param {Function} closeModal     - Function to close the modal 
 */

const DeleteTenantModal = ({isModalOpen, closeModal, deleteTenant}) => {
  return (
    <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} width="40%" minHeight="30%">
        {{ 
            header : 'Delete Tenant',
            body : <Body />,
            footer : <Footer closeModal={closeModal} deleteTenant={deleteTenant} />
         }}
    </CustomModal>
  )
}

export default DeleteTenantModal