import DashboardLayout      from "layouts/dashboardLayout/dashboard.jsx";
import Card from "components/card/card";
import useRolesAndPermissions from "./useRolesAndPermissions";
import { FEATURES } from "constants/features";
import Row from "layouts/row/row";
import AddNewRoleModal from "components/modal/addNewRoleModal/addNewRoleModal";
import DeleteRoleModal from "components/modal/deleteRoleModal/deleteRoleModal";
import Button from "components/buttons/button";
import Checkbox from "components/inputs/checkbox/checkbox";
import Icon from "components/icon/icon";
import { FaTrash } from "react-icons/fa";
import styles from './rolesAndPermissions.module.scss';

const RolesPermissionsScreen = () => {

  const {
    addRoleModal, setAddRoleModal,
    roles, setRoles,
    deleteRoleModal, setDeleteRoleModal,
    selectedRole, setSelectedRole,
    onDeleteRoleClick,
    closeDeleteRoleModal,
    deleteRole,
    onPermissionChange,
    fetchRoles,
    closeAddRoleModal,
    loading, setLoading,
  } = useRolesAndPermissions();

  const standard_user_privileges = [
    'visitors_sign_in',
    'visitors_sign_out',
    'visitors_sign_out_all',
    'visitors_view_todays_visitors',
    'pre_reg_view',
    'pre_reg_add_new',
    'deliveries_show_page',
    'deliveries_add_new_delivery',
    'deliveries_assign_delivery',
];

  return (
    <>
    <DashboardLayout>
        <div className="gb-head">
            <h2 className='font-semibold'>Roles & Permissions</h2>
            <div className="gb-head__right">
                <Button text="Add New Role" onClick={() => setAddRoleModal(true)} />
            </div>
        </div>
        <Card>
        <div className="pad-x-2 mt-2">
            <p>Here you can set up different user roles and the permissions associated with those roles.</p>
            {/* <Row classNames="mt-1" justify="flex-end">
                <Button text="Add New Role" onClick={() => setAddRoleModal(true)} />
            </Row> */}
            {!loading ?
            <div className={`${styles.rolesTable} mt-4`}>
                <div className={styles.stickySidebar}>
                    <div className={styles.corner}>
                        
                    </div>
                    {FEATURES.map((feature) => {
                        return (
                            <div className={styles.feature}>
                                <p className={styles.feature__header}>{feature.feature}</p>
                                {feature.permissions.map((permission) => {
                                    return (
                                        <div className={styles.feature__permission}>
                                            <p className={styles.feature__permission__line1}>{permission.label}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
                <div className={styles.roles}>
                    <div className={`${styles.permissions_role} ${styles.admin}`}>
                        <div className={styles.permissions_role__header}>
                            Administrator
                        </div>
                        <p style={{ opacity : 0 }}>Delete</p>
                        {FEATURES.map((feature) => {
                            return (
                                <div className={styles.permissions_role__section}>
                                    {feature.permissions.map((permission) => {
                                        return (
                                            <div className={styles.permissions_role__section__checkbox}>
                                                <Checkbox checked={true} />
                                            </div>
                                        )
                                    })}
                                    
                                </div>
                            )
                        })}
                    </div>
                    <div className={`${styles.permissions_role} ${styles.standard}`}>
                        <div className={styles.permissions_role__header}>
                            Standard User
                        </div>
                        <p style={{ opacity : 0 }}>Delete</p>
                        {FEATURES.map((feature) => {
                            return (
                                <div className={styles.permissions_role__section}>
                                    {feature.permissions.map((permission) => {
                                        return (
                                            <div className={styles.permissions_role__section__checkbox}>
                                                <Checkbox checked={standard_user_privileges.includes(permission.permission)} />
                                            </div>
                                        )
                                    })}
                                    
                                </div>
                            )
                        })}
                    </div>
                    {roles && roles.map((role) => {
                        return (
                        <div className={`${styles.permissions_role} ${styles.standard}`}>
                            <div className={styles.permissions_role__header}>
                                {role.display_name}
                            </div>
                            <p className={styles.delete} onClick={() => onDeleteRoleClick(role)}><span><Icon icon={<FaTrash />} /></span>Delete</p>
                            {FEATURES.map((feature) => {
                                return (
                                    <div className={styles.permissions_role__section}>
                                        {feature.permissions.map((permission) => {
                                            return (
                                                <div className={styles.permissions_role__section__checkbox}>
                                                    <Checkbox 
                                                        checked={role.permissions[permission.permission]} 
                                                        onChange={() => onPermissionChange(role, permission.permission, permission.mustEnable, permission.mustDisableOnFalse)} 
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div> 
                        )
                    })}
                    
                </div>
            </div> : '' }
        </div>
        </Card>
        {addRoleModal && <AddNewRoleModal isModalOpen={addRoleModal} closeModal={closeAddRoleModal} /> }
        {deleteRoleModal && <DeleteRoleModal isModalOpen={deleteRoleModal}  closeModal={closeDeleteRoleModal} selectedRole={selectedRole} /> }
    </DashboardLayout>
    </>
  )
}

export default RolesPermissionsScreen