import React, { useState }  from 'react'
import { darken }           from 'polished';
import styles               from './button.module.scss';
import Icon                 from 'components/icon/icon';
import PulseLoader  from "react-spinners/PulseLoader";

/**
 * A reusable button component.
 * @param {string} text             - The text displayed on the button.
 * @param {function} onClick        - The function to be called when the button is clicked.
 * @param {string} backgroundColor  - Custom Background Color for Button.
 * @param {string} color            - Custom Button Text Color.
 * @param {Commponent} icon         - Icon component to display in the button.
 * @param {Number} flex             - Adjust the width of the button.
 */

const Button = ({backgroundColor, color, icon, text, flex, onClick, classNames, disabled, loading = false, loadingText = "Loading..."}) => {

    const [isHovered, setIsHovered] = useState(false);

    return (
        <button 
            style={{ 
                backgroundColor : disabled ? '#ededed' : backgroundColor ? isHovered ? darken(0.05, backgroundColor) : backgroundColor : '',
                flex : flex ? flex : '',
                color : disabled ? '#1a1a1a' : color ? color : '',
                cursor : disabled ? 'not-allowed' : ''
            }}
            disabled={disabled}
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={`${styles.button} ${classNames || ''}`}
        >
            <PulseLoader
                color={disabled ? '#1a1a1a' : color ? color : '#fff'}
                loading={loading}
                size={7}
            />
            {icon ? <Icon icon={icon} size="1.6rem" color={color} /> : ''}
            {!loading ? text : loadingText}
        </button>
    )
}

export default Button