import { useState, useEffect, useContext }  from 'react';
import { Route, Routes, BrowserRouter, Navigate, useNavigate }     from 'react-router-dom';
import { SidebarProvider }                  from 'contexts/sidebarContext';
import { DropdownProvider }                 from 'contexts/dropdownContext';
import { CustomProvider, Container } from 'rsuite';
import { BreadCrumbProvider }               from 'contexts/breadcrumbContext';
import 'rsuite/dist/rsuite.min.css';
import { AuthProvider, AuthContext, useAuth }            from 'contexts/authContext';
import { ToastContainer, toast }            from 'react-toastify';
import { ConfigProvider }                   from 'antd';
import axios from 'axios';

import RedirectRoute                        from 'redirectRoute';
import PrivateRoute                         from 'privateRoute';
import ProtectedRoute                       from 'protectedRoute';

import LoginScreen                          from 'pages/login/login';
import ResetPasswordScreen                  from 'pages/resetPassword/resetPassword';
import UpdatePasswordScreen                 from 'pages/resetPassword/updatePassword/updatePassword';
import RegisterScreen                       from 'pages/register/register';
import InvitedRegisterScreen                from 'pages/invitedRegister/invitedRegister.jsx'

import DashboardScreen                      from "pages/dashboard/dashboardScreen";

import TodaysVisitors                       from 'pages/visitors/todays-visitors/todaysVisitors';
import PreRegisteredVisitors                from 'pages/visitors/preRegisteredVisitors/preRegisteredVisitors';

import DeliveriesScreen                     from 'pages/deliveries/deliveries';

import AllSitesScreen                       from 'pages/manage/sites/allSites/allSites';
import SiteScreen                           from 'pages/manage/sites/site/site';
import SiteDetailsScreen                    from 'pages/manage/sites/site/details/details';
import SiteBrandingScreen                   from 'pages/manage/sites/site/branding/branding';
import SiteTemplatesScreen                  from 'pages/manage/sites/site/templates/templates';
import SiteHealthAndSafetyScreen            from 'pages/manage/sites/site/healthAndSafety/healthAndSafety';
import SiteNotificationsScreen              from 'pages/manage/sites/site/notifications/notifications';
import SiteSettingsScreen                   from 'pages/manage/sites/site/settings/settings';
import DevicesScreen                        from 'pages/manage/sites/site/devices/devices';

import AllTenantsScreen                     from 'pages/manage/sites/tenants/allTenants/allTenants';
import TenantDetailsScreen                  from 'pages/manage/sites/tenants/tenant/details/details';
import TenantBrandingScreen                 from 'pages/manage/sites/tenants/tenant/branding/branding';
import TenantSignInFormScreen               from 'pages/manage/sites/tenants/tenant/signInForm/signInForm';
import TenantSignInFormScreenNew            from 'pages/manage/sites/tenants/tenant/signInFormNew/signInForm';
import TenantHealthAndSafetyScreen          from 'pages/manage/sites/tenants/tenant/healthAndSafety/healthAndSafety';
import TenantEmployeesScreen                from 'pages/manage/sites/tenants/tenant/employees/employees';
import TenantNotificationsScreen            from 'pages/manage/sites/tenants/tenant/notifications/notifications';
import TenantSettingsScreen                 from 'pages/manage/sites/tenants/tenant/settings/settings';

import UsersScreen                          from 'pages/manage/users/users';

import RolesPermissionsScreen               from 'pages/manage/rolesAndPermissions/rolesAndPermission';

import VisitorReportsScreen                 from 'pages/reports/visitorReports/visitorReports';
import DeliveryReportsScreen                from 'pages/reports/deliveryReports/deliveryReports';

import ComponentScreen                      from 'pages/components/components';
import NoAccessScreen                       from 'pages/noAccess/noAccess';
import NoSitesScreen                        from 'pages/noSites/noSites';

import 'react-toastify/dist/ReactToastify.css';
import './styles/main.scss';
import SessionModal from 'components/modal/sessionModal/sessionModal';



function App() {

  // const navigate = useNavigate();


  const {isAuthenticated, loading, user, tokenExpired} = useAuth(AuthContext);

 

  // useEffect(() => {
  //   if(tokenExpired) {
  //     console.log('APP.JS ERROR');
  //   }
  // }, [tokenExpired]);

  return (
    <CustomProvider theme="light">
      <Container className="app">
        <BrowserRouter basename='/'>
          <ConfigProvider
            theme={{ 
                "token": {
                  "colorPrimary": "#42ba78",
                  "colorInfo": "#42ba78"
                },
                "components": {
                  "Button": {
                    "colorPrimary": "rgb(66,186,120)"
                  }
                }
             }}
          >
            <SidebarProvider>
              <DropdownProvider>
              
                <Routes>
                  <Route path="/" element={<Navigate replace to="/login" />} />
                  <Route path="/no-sites" element={<NoSitesScreen />} />

                  <Route element={<RedirectRoute isAuthenticated={isAuthenticated} user={user} loading={loading} />}>
                    <Route path='/register-34e384293-34234gjas8578asd-2342345-cxcv57865' element={<RegisterScreen />} />
                    <Route path="/login" element={<LoginScreen />} />
                    <Route path='/reset-password' element={<ResetPasswordScreen />} />
                    <Route path='/do-reset-password/:token' element={<UpdatePasswordScreen />} />
                    <Route path='/register-invited/:token' element={<InvitedRegisterScreen />} />
                  </Route>
                </Routes>

                <BreadCrumbProvider>
                  <Routes>
                    <Route element={<PrivateRoute isAuthenticated={isAuthenticated} loading={loading} user={user} />}>
                          <Route path="/dashboard" element={<DashboardScreen />} />

                          <Route element={<ProtectedRoute requiredPermissions={["visitors_view_todays_visitors"]} />}>
                            <Route path="/todays-visitors" element={<TodaysVisitors />} />
                          </Route>

                          <Route element={<ProtectedRoute requiredPermissions={["pre_reg_view"]} />}>
                            <Route path="/pre-registered-visitors" element={<PreRegisteredVisitors />} />
                          </Route>

                          <Route element={<ProtectedRoute requiredPermissions={["deliveries_show_page"]} />}>
                            <Route path="/deliveries" element={<DeliveriesScreen />} />
                          </Route>

                          <Route element={<ProtectedRoute requiredPermissions={["sites_view"]} />}>
                            <Route path="/manage/sites" element={<AllSitesScreen />} />
                            <Route path="/manage/sites/:id" element={<SiteScreen />} />
                            <Route path="/manage/sites/:id/details" element={<SiteDetailsScreen />} />
                            <Route path="/manage/sites/:id/branding" element={<SiteBrandingScreen />} />
                            <Route path="/manage/sites/:id/templates" element={<SiteTemplatesScreen />} />
                            <Route path="/manage/sites/:id/health-and-safety" element={<SiteHealthAndSafetyScreen />} />
                            <Route path="/manage/sites/:id/notifications" element={<SiteNotificationsScreen />} />
                            <Route path="/manage/sites/:id/settings" element={<SiteSettingsScreen />} />
                            <Route path="/manage/sites/:id/devices" element={<DevicesScreen />} />
                          </Route>

                          <Route element={<ProtectedRoute requiredPermissions={["tenants_view"]} />}>
                            <Route path="/manage/sites/:id/tenants" element={<AllTenantsScreen />} />
                            <Route path="/manage/sites/:id/tenants/:tenantId/details" element={<TenantDetailsScreen />} />
                            <Route path="/manage/sites/:id/tenants/:tenantId/branding" element={<TenantBrandingScreen />} />
                            <Route path="/manage/sites/:id/tenants/:tenantId/sign-in-form" element={<TenantSignInFormScreen />} />
                            <Route path="/manage/sites/:id/tenants/:tenantId/sign-in-form-new" element={<TenantSignInFormScreenNew />} />
                            <Route path="/manage/sites/:id/tenants/:tenantId/health-and-safety" element={<TenantHealthAndSafetyScreen />} />
                            <Route path="/manage/sites/:id/tenants/:tenantId/employees" element={<TenantEmployeesScreen />} />
                            <Route path="/manage/sites/:id/tenants/:tenantId/notifications" element={<TenantNotificationsScreen />} />
                            <Route path="/manage/sites/:id/tenants/:tenantId/settings" element={<TenantSettingsScreen />} />
                          </Route>
                          
                          <Route element={<ProtectedRoute requiredPermissions={["portal_users_view"]} />}>
                            <Route path="/manage/users" element={<UsersScreen />} />
                          </Route>
                          
                          <Route path="/manage/roles-permissions" element={<RolesPermissionsScreen />} />

                          <Route element={<ProtectedRoute requiredPermissions={["visitor_reports_view"]} />}>
                            <Route path="/reports/visitors" element={<VisitorReportsScreen />} />
                          </Route>

                          <Route element={<ProtectedRoute requiredPermissions={["delivery_reports_view"]} />}>
                            <Route path="/reports/deliveries" element={<DeliveryReportsScreen />} />
                          </Route>

                          <Route path="/access-denied" element={<NoAccessScreen />} />
                          <Route path="/components" element={<ComponentScreen />} />
                    </Route>
                  </Routes>
                </BreadCrumbProvider>
        
                <ToastContainer />
              </DropdownProvider>
            </SidebarProvider>
          </ConfigProvider>
        </BrowserRouter>
      </Container>
    </CustomProvider>
  );
}

export default App;
