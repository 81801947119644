import { useState }     from "react"
import DashboardLayout  from "layouts/dashboardLayout/dashboard.jsx"
import PageHeader       from "components/pageHeader/pageHeader"
import SubMenuLayout    from "layouts/subMenuLayout/subMenuLayout"
import Col              from "layouts/col/col"
import Row              from "layouts/row/row";
import Skeleton         from "react-loading-skeleton"
import PCPLogo          from 'assets/images/PCP Logo.png';
import TextInput        from "components/inputs/textInput/textInput"
import TextArea         from "components/inputs/textarea/textarea"
import RSwitch          from "components/switch/switch"
import Button           from "components/buttons/button"
import useBranding      from "./useBranding"
import styles           from './branding.module.scss'
import CheckPermission from "components/checkPermission/checkPermission"
import { getImageURL } from "functions/functions"

const TenantBrandingScreen = () => {

    
    const {
        loading,
        inputFile,
        onFileUploadClick,
        newImage, setNewImage,
        logoUrl,
        onFileSelected,
        preview,
        saveChanges,
        errorMessage, showError
    } = useBranding();

    function Box({ children }) {
        return (
          <div
            style={{
              border: '0.1px solid #ebebeb',
              borderRadius : '5px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent : 'center',
              lineHeight: 2,
              padding: '1rem',
              marginBottom: '0.5rem',
              width: 200,
              height: 200,
              marginTop : '3rem'
            }}
          >
            {children}
          </div>
        );
      }


     return (
        <DashboardLayout>
            
            <SubMenuLayout menu="tenant" subMenuType="tenant" currentMenu="Tenant Branding">
                <div className={styles.brandingContainer}>
                    <p className="gb_title">Tenant Logo</p>
                    <p className="gb_subtitle">Update the tenant logo which would be displayed to the visitors.</p>  
                    {!loading ?
                    <Row classNames="mt-3">
                        <Col classNames={styles.brandingContainer__content}>
                            <div className={styles.logoContainer}>
                                {logoUrl ? <img src={preview ? preview : getImageURL(logoUrl)} alt="Logo" /> : preview ? <img src={preview} alt="Logo" /> : <p>Logo Not Available. Upload New Logo</p> }
                            </div>
                            {showError && <p className={styles.error}>{errorMessage}</p> }
                            <input type="file" accept="image/png, image/jpeg, image/jpg" id='file' ref={inputFile} style={{display: 'none'}} onChange={onFileSelected} />
                            <CheckPermission permission="tenants_add_edit">
                                <Button text="Upload New Logo" onClick={onFileUploadClick} backgroundColor="#E6E6E6" color="#4B4B4B" />
                            </CheckPermission>
                        </Col>
                    </Row> : 
                    <Box>
                        <Row justify="center">
                            <Skeleton circle width={70} height={70} />
                        </Row>
                        <div className="mt-2">
                            <Skeleton count={2} />
                        </div>
                    </Box>}
                    <div className={styles.bottom}>
                        <CheckPermission permission="tenants_add_edit">
                            <Row classNames="mt-5" justify="right">
                                <Button text="Save Changes" disabled={newImage ? false : true} onClick={saveChanges} />    
                            </Row> 
                        </CheckPermission>
                    </div>
                </div>
            </SubMenuLayout>
        </DashboardLayout>
    )
}

export default TenantBrandingScreen