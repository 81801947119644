import { useState }                         from "react";
import { useNavigate }                      from "react-router-dom";
import { checkIfEmpty, isValidEmail }       from "functions/functions";
import { showErrorToast, showSuccessToast } from "functions/toasts";
import { useAuth } from "contexts/authContext";
import axios                                from "axios";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { APP_CONFIG } from "constants/config";

// Custom Hook for Login Screen
const useLogin = () => {

    const [email, setEmail]                 = useState('');
    const [password, setPassword]           = useState('');
    const [error, setError]                 = useState('');
    const [errorMessages, setErrorMessages] = useState('');
    const [loading, setLoading]             = useState(false);
    const [message, setMessage]             = useState('')

    const {setIsAuthenticated, setEmailVerified, setEmailVerificationModal, setUser, setApprovedBuildings, setCurrentBuilding, setShowSessionWarning} = useAuth();

    const navigate = useNavigate();

    useEffect(() => {
        const storedMessage = localStorage.getItem('loginMessage');
        if (storedMessage) {
            setMessage(storedMessage);
            // Clear the message from localStorage to ensure it's displayed only once
            localStorage.removeItem('loginMessage');

            const hideMessageTimeout = setTimeout(() => {
                setMessage('');
            }, 5000);

            return () => clearTimeout(hideMessageTimeout);
        }
    }, [])

    // Login Function
    const onLoginClick = (e) => {

        e.preventDefault();

        // Removing spaces from the input at the end
        let trimmedEmail    = email.trim();
        let trimmedPassword = password.trim();

        // Initialising Error Object Values
        const errorObj = {
            email       : false,
            password    : false
        }

        const errorMessageObj = {
            email       : '',
            password    : ''
        }

        // Validating Email
        if(checkIfEmpty(trimmedEmail)) {
            errorObj.email = true;
            errorMessageObj.email = 'Email Address is mandatory'
        } else if(!isValidEmail(trimmedEmail)) {
            errorObj.email = true;
            errorMessageObj.email = 'Enter a valid email address'
        }

        // Validating Password
        if(checkIfEmpty(trimmedPassword)) {
            errorObj.password = true;
            errorMessageObj.password = 'Password is mandatory'
        }

        // If all inputs are valid
        if(!errorObj.email && !errorObj.password) {
            // navigate('/')
            const data = {
                email : trimmedEmail,
                password : trimmedPassword
            }

            loginUser(data);
        }

        // Setting Error Values
        setError(errorObj);
        setErrorMessages(errorMessageObj)
    }


    const loginUser = async (data) => {

        setLoading(true);

        const headers = {
            'Content-Type': 'application/json',
            'Accept' : 'application/json'
        }
        
        try {

            const response = await axios.post(`${APP_CONFIG.BASE_URL}login`, data, {headers, withCredentials : true});

            const cookies = response.headers['Set-Cookie'];
  
            // Verify that the cookies are available
            console.log(response.headers);

            console.log(response)

            if(response.status === 200) {
                if(response.data.data.is_active) {
                        console.log(response.data.data);
                        showSuccessToast('Login Successful');
                        const loginTime = new Date().toISOString();
                        localStorage.setItem("loginTime", loginTime);
                        setIsAuthenticated(true);
                        setShowSessionWarning(false);
                        setUser(response.data.data);
                        if(response.data.data.approved_buildings.buildings.length > 0) {
                            setApprovedBuildings(response.data.data.approved_buildings.buildings);
                            setCurrentBuilding(response.data.data.approved_buildings.buildings[0]);
                            Cookies.set('currentBuilding', JSON.stringify(response.data.data.approved_buildings.buildings[0]));
                        }
                        if(!response.data.data.email_verified_at) {
                            console.log(response.data.data.email_verified_at);
                            setEmailVerified(false);
                            setEmailVerificationModal(true);
                        } else {
                            console.log(response.data.data.email_verified_at);
                            setEmailVerified(true);
                            setEmailVerificationModal(false)
                        }
                        // navigate('/dashboard');
                    
                    
                } else {
                    showErrorToast('Your Account is Not Active');
                }
            }

            setLoading(false)

        } catch(err) {
            console.log(err);

            const errorObj = {
                email : false,
                password : false,
            }
    
            const errorMessageObj = {
                email : '',
                password : '',
            }

            if(err.response?.status === 401) {
                
                errorObj.email = true;
                errorObj.password = true;

                setError(errorObj);
                // setErrorMessages(errorMessageObj);

                if(err.response?.data.message === "account-disabled") {
                    showErrorToast("Your Account is Disabled. Please contact your administrator.");
                } else if(err.response?.data.message === "credentials-do-not-match") {
                    showErrorToast(`Invalid Credentials ${err.response?.data.data ? `(${err.response?.data.data} attempts more)` : ''}`);
                } else {
                    showErrorToast('Something Went Wrong');
                }

            } else {
                showErrorToast('Something Went Wrong');
            }

            setLoading(false)
        }
    }


    // Returning Hook Values
    return {
        email,
        setEmail,
        password,
        setPassword,
        error,
        errorMessages,
        onLoginClick,
        loading,
        message
    }
}

export default useLogin;