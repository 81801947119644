import useApi from "hooks/useApi";

const useSiteFunctions = () => {

    const { get } = useApi();
    
    const getAllAccountSites = async () => {
        try {
            const response = await get(`buildings`);
            return response.data.data;
        } catch(err) {
            throw err;
        }
        
    }

    return {
        getAllAccountSites
    }
}

export default useSiteFunctions;