import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Custom CSS class with increased font size
const customToastClassName = {
    fontSize: '2rem',
};
  

// Function to display success toast
export const showSuccessToast = (message) => {
    toast.success(message, {
      // Success toast configuration
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: customToastClassName,
      theme : "colored"
      // Add any other success-specific configurations
    });
  };
  
  // Function to display error toast
  export const showErrorToast = (message) => {
    toast.error(message, {
      // Error toast configuration
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastClassName: customToastClassName,
      // Add any other error-specific configurations
    });
}