import { useState, useEffect }              from "react"
import { checkIfEmpty }                     from "functions/functions";
import { showErrorToast, showSuccessToast } from "functions/toasts";
import useApi                               from "hooks/useApi";
import { useAuth } from "contexts/authContext";

// Custom hook for All Sites Screen
const useAllSites = () => {

    const [addNewSiteModal, setAddNewSiteModal] = useState(false);
    const [sites, setSites]                     = useState([]);
    const [searchBy, setSearchBy]               = useState('');
    const [multiTenant, setMultiTenant]         = useState(true);
    const [siteName, setSiteName]               = useState('');
    const [error, setError]                     = useState('');
    const [errorMessages, setErrorMessages]     = useState('');
    const [siteAddLoading, setSiteAddLoading]   = useState(false);
    const [loading, setLoading]                 = useState(false);

    const { get, post } = useApi();
    const {user, setUser} = useAuth();

    useEffect(() => {
        fetchAllSites();
    }, []);


    // Function to get all site details
    const fetchAllSites = async () => {
        setLoading(true);

        try {
            
            const response = await get('buildings');
            // Sorting Response based on site name
            response.data.data.sort((a,b) => {
                if(a.attributes.name < b.attributes.name) {
                    return -1;
                }
                if(a.attributes.name > b.attributes.name) {
                    return 0;
                }
            });
            setSites(response.data.data);

            setTimeout(() => {
                setLoading(false);
            }, 300);

        } catch(err) {
            showErrorToast('Something Went Wrong')
        }
        
    }

    // When button is pressed
    const onButtonClick = async () => {

        setSiteAddLoading(true);

        let trimmedSiteName = siteName.trim();

        const errorObj = {
            siteName : false
        }

        const errorMessageObj = {
            siteName : ''
        }

        if(checkIfEmpty(trimmedSiteName)) {
            errorObj.siteName = true;
            errorMessageObj.siteName = "Site Name is required";
        }

        if(!errorObj.siteName) {
            try {

                // Calling API to add new site
                await post('buildings', {
                    name : siteName, 
                    has_multiple_tenants : multiTenant ? 1 : 0
                });

                await fetchAllSites();

                const response = await get(`admin-user/${user.user_id}`);
                console.log(response)
                setUser(response.data.data);

                closeAddNewSiteModal();

                showSuccessToast('New Site Added Successfully');

    
            } catch(err) {

                console.log(err);
                showSuccessToast('Something Went Wrong');
            }
        }

        setError(errorObj);
        setErrorMessages(errorMessageObj);

        setSiteAddLoading(false);
    }
    
    // Function to close Add New Site Modal
    const closeAddNewSiteModal = () => {
        setSiteName('');
        setMultiTenant(true);
        setAddNewSiteModal(false);
    }

    return {    
        addNewSiteModal,
        setAddNewSiteModal,
        closeAddNewSiteModal,
        loading, setLoading,
        sites,
        searchBy, setSearchBy,
        fetchAllSites,
        multiTenant, setMultiTenant,
        siteName, setSiteName,
        error, setError,
        errorMessages, setErrorMessages,
        onButtonClick,
        siteAddLoading, setSiteAddLoading
    }
}

export default useAllSites