import { useState, useEffect } from "react";
import useApi from "hooks/useApi";
import { showErrorToast } from "functions/toasts";
import { useAuth } from "contexts/authContext";
import useTenantFunctions from "functions/useTenantFunctions";
import { dateTimeFormat, getImageURL } from "functions/functions";
import UserPlaceholder from 'assets/images/user-placeholder.png';
import { DEFAULT_TABLE_COLUMNS, DEFAULT_TABLE_PARAMS, DEFAULT_LOADER_PARAMS, DEFAULT_TENANT_OPTIONS } from "./constants";

const useDeliveryReports = () => {

    const {currentBuilding, user} = useAuth();

    const [loading, setLoading]                 = useState(true);
    const [dataLoading, setDataLoading]         = useState(false);
    const [filterLoading, setFilterLoading]     = useState(false);
    const [visitorName, setVisitorName]         = useState('');
    const [data, setData]                       = useState([]);
    const [tenants, setTenants]                 = useState(DEFAULT_TENANT_OPTIONS);
    const [sites, setSites]                     = useState([]);
    const [selectedSite, setSelectedSite]       = useState(currentBuilding.attributes.building_id);
    const [selectedTenant, setSelectedTenant]   = useState('all');
    const [filteredData, setFilteredData]       = useState([]);
    const [loader, setLoader]                   = useState(DEFAULT_LOADER_PARAMS);
    const [isModalOpen, setIsModalOpen]         = useState(false);
    const [date, setDate]                       = useState();
    const [tableParams, setTableParams]         = useState(DEFAULT_TABLE_PARAMS);
    const [columns, setColumns]                 = useState(DEFAULT_TABLE_COLUMNS);

    
    const {getAllTenants} = useTenantFunctions();


    const {get} = useApi();

    useEffect(() => {
        fetchData();
    },[currentBuilding]);

    // Fetch Visitors Data on Page Load and when pagination parameters are changed
    useEffect(() => {
        fetchDeliveries();
    }, [tableParams.pagination.current, tableParams.pagination.pageSize]);

    const fetchData = async () => {
        setSiteOptions();
        setTenantOptions(selectedSite);
    };

    // Function to build query params for visitor API
    const buildQueryParams = ({ tenant, date, pagination }) => {
        console.log(date)
        const params = new URLSearchParams();
        
        if (tenant) {
            params.append('tenant', tenant);
        }
        
        if (date?.startDate) {
            params.append('start_date', date.startDate);
        }
    
        if (date?.endDate) {
            params.append('end_date', date.endDate);
        }
    
        if (pagination?.current) {
            params.append('page', pagination.current);
        }
    
        if (pagination?.pageSize) {
            params.append('page_size', pagination.pageSize);
        }
    
        return params.toString();
    };
    
    const queryParams = buildQueryParams({
        tenant: selectedTenant,
        date,
        pagination: tableParams.pagination
    });

    // Function to fetch visitors data
    const fetchDeliveries = async () => {
        setLoader(prevLoader => ({
            ...prevLoader,
            deliveries : true
        }));
        try{
            const buildingId = selectedSite || currentBuilding.attributes.building_id;
            const response = await get(`buildings/${buildingId}/all-deliveries?${queryParams}`);
            console.log(response)
            // setData(response.data.data);
            

            // const filteredResult = response.data.data.filter((item) => {
            //     if(selectedTenant.value === 'all') return item;
            //     if(selectedTenant.value === item.tenant.tenant_id) return item;
            // })

            // setFilteredData(filteredResult);

            const deliveriesData = response.data.data.map((data, index) => ({
                ...data,
                key : index
            }))

            setData(deliveriesData);


            // Updating Table pagination paramter with total number of records
            setTableParams((prev) => ({
                ...prev,
                pagination: {
                    ...prev.pagination,
                    total: response.data.total,
                },
            }));
            
            setLoader(prevLoader => ({
                ...prevLoader,
                deliveries : false
            }));

        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }

    // Function to get tenants
    const setTenantOptions = async (siteId) => {
        console.log('here');
        console.log(selectedTenant);
        setSelectedTenant('all');
        setLoader(prevLoader => ({
            ...prevLoader,
            tenants : true
        }));
        try {
            const response = await getAllTenants(siteId);
            let tenantOptions = [{
                label : 'All Tenants',
                value : 'all'
            }];
            response.forEach(tenant => {
                if(tenant.attributes.is_active === 1) {
                    tenantOptions.push({
                        ...tenant.attributes,
                        label : tenant.attributes.name,
                        value : tenant.attributes.tenant_id,
                    })
                }
            });

            setTenants(tenantOptions);
            
        } catch(err) {
            showErrorToast('Something Went Wrong')
        }

        setLoader(prevLoader => ({
            ...prevLoader,
            tenants : false
        }));
    }

    // Function to set site options
    const setSiteOptions = async () => {
        setLoader(prevLoader => ({
            ...prevLoader,
            sites : true
        }));

        let siteOptions = [];
        user.approved_buildings.buildings.forEach((building) => {
            siteOptions.push({
                label : building.attributes.name,
                value : building.attributes.building_id
            })
        });

        setSites(siteOptions);

        setLoader(prevLoader => ({
            ...prevLoader,
            sites : false
        }));
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
          pagination,
          filters,
        });
    
        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
          setData([]);
        }
      };


    // Columns of CSV File
    const header = [
        {label : "Delivery Id",         key : "delivery_id"},
        {label : "Tenant",              key : 'tenant.name'},
        {label : "Employee",            key : 'employee.name'},
        {label : "Arrival Time",        key : "arrival_time"},
        {label : "Collection Time",     key : "collection_time"},
      ];

    // Apply Filters
    const applyFilters = async () => {
        setLoader(prevLoader => ({
            ...prevLoader,
            deliveries : true
        }));

        // Reset pagination to the first page locally
        const updatedPagination = {
            ...tableParams.pagination,
            current: 1, // Reset to the first page
        };

        // Build query params with the updated pagination
        const queryParams = buildQueryParams({
            tenant: selectedTenant,
            date,
            pagination: updatedPagination, // Use the updated pagination
        });

    
        try{
            const buildingId = selectedSite || currentBuilding.attributes.building_id;
            const response = await get(`buildings/${buildingId}/all-deliveries?${queryParams}`);
            const deliveriesData = response.data.data.map((data, index) => (
                {
                    ...data,
                    key : index
                }
            ));

            setData(deliveriesData);

            setTableParams({
                ...tableParams,
                pagination: {
                  ...tableParams.pagination,
                  total: response.data.total,
                  current: 1,
                },
            });
            
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }

        setLoader(prevLoader => ({
            ...prevLoader,
            deliveries : false
        }));
    }

    // When a site is changed
    const onSiteChange = async (e) => {
        await setTenantOptions(e);
        setSelectedTenant('all');
    }

    const onDateChange = (dates) => {
        const formattedDates = dates ? dates.map(date => date.format('YYYY-MM-DD HH:mm:ss')) : [];
        console.log(formattedDates);
        setDate({
            startDate   : formattedDates[0],
            endDate     : formattedDates[1]
        });
    }

    const closeExportModal = () => {
        setIsModalOpen(false);
    }

    return {
        loading, setLoading,
        dataLoading, setDataLoading,
        filterLoading, setFilterLoading,
        data, setData,
        tenants, setTenants,
        selectedTenant, setSelectedTenant,
        sites, setSites,
        tableParams,
        selectedSite, setSelectedSite,
        filteredData, setFilteredData,
        columns,
        onDateChange,
        handleTableChange,
        applyFilters,
        onSiteChange,
        isModalOpen, setIsModalOpen,
        visitorName, setVisitorName,
        header,
        loader,
        date,
        closeExportModal,
    }
}

export default useDeliveryReports;