import { createContext, useState, useRef, useContext, useEffect } from "react";

export const DropdownContext = createContext();

export const useDropdown = () => useContext(DropdownContext);

export const DropdownProvider = ({ children }) => {
  const [dropdownStatusMap, setDropdownStatusMap] = useState({});

  const dropdownRefsMap = useRef({});

  // Function to handle clicks outside the dropdowns, closing them
  function handleClickOutside(event) {
    Object.keys(dropdownRefsMap.current).forEach((dropdownId) => {
      const dropdownRef = dropdownRefsMap.current[dropdownId];
      if (dropdownRef && !dropdownRef.contains(event.target)) {
        setDropdownStatusMap((prevStatus) => ({
          ...prevStatus,
          [dropdownId]: false,
        }));
      }
    });
  }

  const registerDropdown = (id, ref) => {
    dropdownRefsMap.current[id] = ref;
    setDropdownStatusMap((prevStatus) => ({
      ...prevStatus,
      [id]: false,
    }));
  };

  const setDropdownStatus = (id, status) => {
    setDropdownStatusMap((prevStatus) => ({
      ...prevStatus,
      [id]: status,
    }));
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <DropdownContext.Provider
      value={{
        dropdownStatusMap,
        registerDropdown,
        setDropdownStatus,
      }}
    >
      {children}
    </DropdownContext.Provider>
  );
};