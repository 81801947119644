import { useNavigate } from "react-router-dom";
import CustomModal from "../modal";
import Button from "components/buttons/button";
import Row from "layouts/row/row";
import axios from "axios";
import { showErrorToast } from "functions/toasts";
import { useAuth } from "contexts/authContext";
import { APP_CONFIG } from "constants/config";

const Body = () => {
    return (
        <div>
            <p>Are you sure you want to logout ?</p>
        </div>
    )
}

const Footer = () => {

    const navigate = useNavigate();

    const {setIsAuthenticated} = useAuth();

    const logout = async () => {

        const headers = {
            'Content-Type': 'application/json',
            'Accept' : 'application/json'
        }

        try {

            const response = await axios.post(`${APP_CONFIG.BASE_URL}logout`, null, {headers, withCredentials : true});

            console.log(response)

            if(response.status === 200) {
                setIsAuthenticated(false);
                navigate('/login')
            }

        } catch(err) {
            
            showErrorToast('Something Went Wrong');
        }
    }

    return (
        <Row justify="flex-end">
            <Button text="Logout" onClick={logout} />
        </Row>
    )
}

const LogoutModal = ({isModalOpen, closeModal}) => {
    return (
        <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} width="30%" maxHeight="30%" minHeight="30%" >
            {{ 
                header : 'Logout',
                body : <Body />,
                footer : <Footer />
            }}
        </CustomModal>
    )
}

export default LogoutModal