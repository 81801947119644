import { useState, useRef, useEffect } from 'react';
import DashboardLayout      from 'layouts/dashboardLayout/dashboard.jsx';
import { CSVLink } from "react-csv";
import { CiExport } from "react-icons/ci";
import { BiExport } from "react-icons/bi";
import { Select, Form, Table, DatePicker, Collapse, Input, Button, Card }           from 'antd';
// import Card from 'components/card/card';
import styles from './deliveryReports.module.scss';
import DataTable            from 'react-data-table-component';
import SelectInput from 'components/inputs/selectInput/selectInput';
// import Button from 'components/buttons/button';
import useDeliveryReports from './useDeliveryReports';
import DateRange from 'components/inputs/dateRangePicker/dateRangePicker';
import Row from 'layouts/row/row';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { dateTimeFormat } from 'functions/functions';
import TableSkeleton from 'layouts/skeletons/tableSkeleton/tableSkeleton';
import TextInput from 'components/inputs/textInput/textInput';
import DeliveryExportModal from './deliveryExportModal/deliveryExportModal';

const { RangePicker } = DatePicker;

const DeliveryReportsScreen = () => {

    const {
        loading, setLoading,
        dataLoading, setDataLoading,
        filterLoading, setFilterLoading,
        data, setData,
        tenants, setTenants,
        selectedTenant, setSelectedTenant,
        sites, setSites,
        selectedSite, setSelectedSite,
        filteredData, setFilteredData,
        columns,
        tableParams,
        applyFilters,
        handleTableChange,
        onDateChange,
        loader,
        onSiteChange,
        isModalOpen, setIsModalOpen,
        state, setState,
        visitorName, setVisitorName,
        closeExportModal,
        date,
        header
    } = useDeliveryReports();

    const [form] = Form.useForm();

  return (
    <>
        <DashboardLayout>
            <div className="gb-head">
                <h2 className='font-semibold'>Delivery Reports</h2>
            </div>
            <div className={styles.reportsContainer}>
                <div className={styles.reportsContainer__filter}>
                    <Card>
                        <div className={styles.reportsContainer__filter__content}>
                            <h3>Apply Filters</h3>
                                <Form className='mt-5' form={form} layout='vertical' onFinish={applyFilters}>
                                    <Form.Item
                                        label   = "Select Site" 
                                        name    = "site"
                                    >
                                        <Select
                                            size            = "large"
                                            defaultValue    = {selectedSite}
                                            onChange        = {(e) => { setSelectedSite(e); onSiteChange(e); form.setFieldsValue({tenant: 'all'}) }}
                                            options         = {sites}
                                            className       = 'w-full'
                                            disabled        = {loader.sites} 
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label   = "Select Tenant" 
                                        name    = "tenant"
                                    >
                                        <Select
                                            size            = "large"
                                            defaultValue    = {selectedTenant}
                                            onChange        = {(e) => setSelectedTenant(e)}
                                            options         = {tenants}
                                            className       = 'w-full'
                                            disabled        = {loader.sites} 
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Select Date Range"
                                        name="date"
                                    >
                                        <RangePicker 
                                            size        = 'large'
                                            onChange    = {onDateChange}
                                            className   =  "w-full"
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            block 
                                            className   = 'mt-10' 
                                            type        = "primary" 
                                            size        = "large" 
                                            htmlType    = 'submit'
                                        >
                                            Apply Filters
                                        </Button>
                                    </Form.Item>
                                </Form>
                                {/* <SelectInput label="Site" options={sites} defaultValue={selectedSite} onChange={(e) => { setSelectedSite(e); onSiteChange(e) }} /> */}
                                {/* <SelectInput label="Tenant" options={tenants} defaultValue={selectedTenant} onChange={(e) => setSelectedTenant(e)} /> */}
                                {/* <div>
                                    <p>Arrival Date</p>
                                    <div className={styles.dates} onClick={onDateClick} ref={dateRef}>
                                        <div className={styles.date}>
                                            <p>{dateTimeFormat(state[0]?.startDate, "DD/MM/YYYY")}</p>
                                        </div>
                                        <p>-</p>
                                        <div className={styles.date}>
                                            <p>{dateTimeFormat(state[0]?.endDate, "DD/MM/YYYY")}</p>
                                        </div>
                                    </div>
                                    {showDatePicker && <div className={styles.dateRange} ref={datePickerRef}>
                                        <DateRange state={state} onChange={(item) => onDateSelect(item)} />
                                    </div> }
                                </div> */}
                            {/* <Row classNames="mt-4">
                                <Button text="Apply Filters" flex="1" onClick={applyFilters} />
                            </Row> */}
                        </div>
                    </Card>
                </div>
                <div className={styles.reportsContainer__data}>
                    <Card>
                        <div className='flex justify-end'>
                                <Button
                                    size='large' 
                                    icon={<CiExport />}
                                    onClick={() => setIsModalOpen(true)}
                                >
                                        Export to CSV
                                </Button>
                        </div>
                        <div className={styles.reportsContainer__data__table}>
                            <Table 
                                columns     = {columns} 
                                dataSource  = {data}
                                className   = 'mt-5' 
                                pagination  = {tableParams.pagination}
                                loading     = {loader.deliveries}
                                onChange    = {handleTableChange}
                            />
                        </div> 
                    </Card>
                </div>

            </div>
            {isModalOpen &&
            <DeliveryExportModal 
                isModalOpen         = {isModalOpen}
                setIsModalOpen      = {setIsModalOpen} 
                closeExportModal    = {closeExportModal}
                selectedDate        = {date}
                selectedSite        = {selectedSite}
                siteOptions         = {sites}
                selectedTenant      = {selectedTenant}
                tenantOptions       = {tenants} 
            />}
        </DashboardLayout>
    </>
  )
}

export default DeliveryReportsScreen