import { MdModeEdit }       from "react-icons/md";
import { FaTrash }          from "react-icons/fa";
import { fieldLabelColors } from "constants/colors";
import DashboardLayout      from "layouts/dashboardLayout/dashboard.jsx"
import Row                  from "layouts/row/row"
import SubMenuLayout        from "layouts/subMenuLayout/subMenuLayout"
import AddNewTemplateModal  from "components/modal/addNewTemplateModal/addNewTemplateModal";
import Icon                 from "components/icon/icon";
import useSignInForm        from "./useSignInForm";
import DataTable            from 'react-data-table-component';
import styles               from './signInForm.module.scss';
import Col                  from "layouts/col/col";


const TenantSignInFormScreenNew = () => {

    const {
        selectedOption, setSelectedOption,
        tenantTemplates,
        globalTemplates,
        templateMode,
        templateModalStatus, setTemplateModalStatus,
        addNewTemplateClick,
        id, tenantId,
        fetchTemplates,
        closeTemplateModal,
        selectedTemplate, setSelectedTemplate,
        onTemplateSelect,
        activeTemplate, setActiveTemplate,
        onEditClick,
        loading
    } = useSignInForm();

    console.log(selectedOption)

    // Tenant Table Columns
    const columns = [
        {
            name : "Template Name",
            width : '200px',
            selector : row => row.name
        },
        {
            name : "",
            cell : (row) => (
                <>
                    {row.template_id === activeTemplate.template_id &&<p className={styles.activeLabel}>Active</p> }
                </>
            )
        },
        {
            name: 'Action',
            cell : (row) => (
                <Row gap="2rem" align="center">
                    <Row align="center" gap="0.5rem">
                        <Icon icon={<MdModeEdit />} color="#787878" />
                        <p style={{ color : '#787878'}} onClick={() => onEditClick(row)}>Edit</p>
                    </Row>
                    {/* <Row align="center" gap="0.5rem">
                        <Icon icon={<FaTrash />} color="#ff5c5c" />
                        <p style={{ color : '#787878' }}>Delete</p>
                    </Row> */}
                    <button className={styles.roundedBtn} onClick={() => onTemplateSelect(row)}>
                        Assign
                    </button>
                </Row>
            )
        },
    ];
    

  return (
    <>
    <DashboardLayout>
        <SubMenuLayout menu="tenant" subMenuType="tenant" currentMenu="Sign In Form">
            <p className="gb_title">Sign in Form</p>
            <p className="gb_subtitle">Manage the sign in form specifically for the tenant.</p>
            <Col classNames="mt-2">
                <div className={styles.active_signin_form}>
                    <Row gap="2rem" align="center" classNames={styles.active_signin_form__header}>
                        <h3 className={styles.active_signin_form__title}>Active Sign in Form</h3>
                        <div className={styles.active_signin_form__label}>
                            <p>{activeTemplate.name}</p>   
                        </div>
                    </Row>
                    {!loading &&
                    <div className={styles.active_signin_form__fields}>
                        <div className={styles.active_signin_form__fields__field}>
                            <p>Visitor Name<span className={styles.active_signin_form__fields__field__required}>*</span></p>
                        </div>
                        {activeTemplate?.form?.fields?.map((field) => (
                             <div className={styles.active_signin_form__fields__field}>
                                <p>{field.name}<span className={styles.active_signin_form__fields__field__required}>{field.required === 'true' ? '*' : ''}</span></p>
                            </div>
                        ))}
                    </div> }
                    <p className={styles.active_signin_form__infoBottom}><span className={styles.required}>*</span> denotes required field</p>
                </div>
                {!loading ?
                <div className={styles.templatesContainer}>
                    <div className={styles.templatesContainer__options}>
                        <div className={`${styles.templatesContainer__options__option} ${selectedOption === 'tenant' && styles.tenant}`} onClick={() => setSelectedOption('tenant')}>
                            Tenant Templates
                        </div>
                        <div className={`${styles.templatesContainer__options__option} ${selectedOption === 'global' && styles.global}`} onClick={() => setSelectedOption('global')}>
                            Global Templates
                        </div>
                    </div>
                    {/* Tenant Template Content */}
                    {selectedOption === 'tenant' &&
                    <div className={styles.templatesContainer__content}>
                        <p className={styles.templatesContainer__info}>Tenant templates are specific to tenants. Changes made to a tenant template would only affect the assigned tenant.</p>
                        <Row justify="flex-start" classNames="mt-1 mb-2">
                            <button className={styles.templatesContainer__content__addNewBtn} onClick={addNewTemplateClick}>+ Add New Tenant Template</button>
                        </Row>
                        <DataTable
                            columns={columns}
                            data={tenantTemplates}
                            highlightOnHover
                            pagination
                            expandOnRowClicked
                            expandableRowsComponent={ExpandComponent}
                            expandableRows
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[3, 5, 10, 15, 20]}
                        />
                    </div> }
                    {/* Global Template Content */}
                    {selectedOption === 'global' &&
                    <div className={styles.templatesContainer__content}>
                        <p className={`${styles.templatesContainer__info} mb-2`}>Global Templates are accessible by all tenants. Create global templates so as to share template with multiple tenants. Changes made to global template would affect all tenants using the template.</p>
                        <DataTable
                            columns={columns}
                            data={globalTemplates}
                            highlightOnHover
                            pagination
                            expandOnRowClicked
                            expandableRowsComponent={ExpandComponent}
                            expandableRows
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[3, 5, 10, 15, 20]}
                        />
                    </div> }
                </div> : '' }
            </Col>
        </SubMenuLayout>
    </DashboardLayout>
    {templateModalStatus &&
    <AddNewTemplateModal
        fetchTemplates={() => {}}
        mode={templateMode}
        isModalOpen={templateModalStatus}
        closeModal={closeTemplateModal}
        templateType={selectedOption}
        tenantId={selectedOption === 'tenant' ? tenantId : ''}
        selectedTemplate={selectedTemplate}
    /> }
    </>
  )
}


// Expandable Component when table row is clicked
const ExpandComponent = ({data}) => {
    return (
        <div className={styles.expandRowContainer}>
            <div className={styles.expandRowContainer__field}>
                    <p>Visitor Name<span className={styles.expandRowContainer__field__required}>*</span></p>
                    
            </div>
            {data?.form?.fields?.map((field) => (
                <div className={styles.expandRowContainer__field}>
                    <p>{field.name}<span className={styles.expandRowContainer__field__required}>{field.required === 'true' ? '*' : ''}</span></p>
                </div>
            ))}
        </div>
    )
}

export default TenantSignInFormScreenNew