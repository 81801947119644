import { useState, useEffect }                     from "react";
import { useNavigate, useParams }                  from "react-router-dom";
import { checkIfEmpty, isValidEmail, checkPasswordComplexity }   from "functions/functions";
import axios from "axios";
import { showErrorToast, showSuccessToast } from "functions/toasts";
import { APP_CONFIG } from "constants/config";

// Custom Hook for Login Screen
const useUpdatePassword = () => {

    const [step, setStep]                       = useState(1);
    const [email, setEmail]                     = useState('');
    const [password, setPassword]               = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordStatus, setPassordStatus]        = useState('');
    const [error, setError]                     = useState('');
    const [errorMessages, setErrorMessages]     = useState('');
    const [tokenValidity, setTokenValidity]     = useState(false);
    const [userId, setUserId]                   = useState('');
    const [apiCalled, setApiCalled]             = useState(false);
    const [loading, setLoading]                 = useState(false);

    const navigate = useNavigate();

    const {token} = useParams();


    useEffect(() => {
        if (!apiCalled) {
            checkTokenValidity();
            setApiCalled(true)
        }
    }, [apiCalled]);


    const checkTokenValidity = async () => {
        

        const headers = {
            'Content-Type': 'application/json',
            'Accept' : 'application/json'
        }

        const data = {
            token : token
        }
        
        try {

            const response = await axios.post(`${APP_CONFIG.BASE_URL}reset-password`, data, {headers});
            console.log(response)
            if(response.status === 201) {
                setTokenValidity(true);
                setUserId(response.data.data.user_id);
            }


        } catch(err) {
            showErrorToast('Invalid Token. Please Try Again');
            navigate('/reset-password');
        }

       
    }
    

    const onPasswordChange = (e) => {
        setPassword(e.target.value);

        console.log(e.target.value);
    
        const errorObj = {
            password           : false
        }

        const errorMessageObj = {
            password           : ''
        }

        const passwordComplexity = checkPasswordComplexity(e.target.value);

        if(passwordComplexity.status == false) {
            console.log(passwordComplexity)
            errorObj.password = true;
            errorMessageObj.password = 'Password is not strong enough'
        }

        setPassordStatus(passwordComplexity);

        setError(errorObj);
        setErrorMessages(errorMessageObj)
        
    }


    const onPasswordBlur = (e) => {
        const password = e.target.value;

        const errorObj = {
            password           : false
        }

        const errorMessageObj = {
            password           : ''
        }

        const passwordComplexity = checkPasswordComplexity(e.target.value);

        if(passwordComplexity.status == false) {
            errorObj.password = true;
            errorMessageObj.password = 'Password is not strong enough'
        }

        setPassordStatus(passwordComplexity);

        setError(errorObj);
        setErrorMessages(errorMessageObj)
    }


    // Reset Password Function
    const onResetClick = async (e) => {
        setLoading(true);
        e.preventDefault();

        // Removing spaces from the input at the end
        let trimmedPassword         = password.trim();
        let trimmedConfirmPassword  = confirmPassword.trim();

        // Initialising Error Object Values
        const errorObj = {
            password        : false,
            confirmPassword : false
        }

        const errorMessageObj = {
            password        : '',
            confirmPassword : ''
        }

        if(checkIfEmpty(trimmedPassword)) {
            errorObj.password = true;
            errorMessageObj.password = 'Password is mandatory'
        } else if(!checkPasswordComplexity(trimmedPassword)) {
            errorObj.password = true;
            errorMessageObj.password = 'Enter a strong password'
        }

        if(checkIfEmpty(trimmedConfirmPassword)) {
            errorObj.confirmPassword = true;
            errorMessageObj.confirmPassword = 'Confirm your password'
        } else if(trimmedPassword !== trimmedConfirmPassword) {
            errorObj.confirmPassword = true;
            errorMessageObj.confirmPassword = 'Passwords do not match'
        }

        if(!errorObj.password && !errorObj.confirmPassword) {
            const headers = {
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            }
    
            const data = {
                user_id : userId,
                password : trimmedPassword,
                confirmPassword : trimmedConfirmPassword,
            }

            console.log(data);
            
            try {
    
                const response = await axios.post(`${APP_CONFIG.BASE_URL}update-password`, data, {headers});

                showSuccessToast('Password Updated Successfully. Please Login Again');
                localStorage.setItem('loginMessage', "Password Updated Successfully. Please Login Again.");
                navigate('/login');
                
            } catch(err) {
                showErrorToast('Something Went Wrong');
            }
        }
        

        setError(errorObj);
        setErrorMessages(errorMessageObj);
        setLoading(false);

    }


    // Returning Hook Values
    return {
        step,
        setStep,
        email,
        setEmail,
        password,
        setPassword,
        confirmPassword,
        setConfirmPassword,
        onPasswordBlur,
        onPasswordChange,
        passwordStatus,
        error,
        errorMessages,
        onResetClick,
        loading
    }
}

export default useUpdatePassword;