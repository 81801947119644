import { useNavigate }                from 'react-router-dom';
import { MdOutlineArrowBackIosNew }   from 'react-icons/md';
import { FiCheck }                    from 'react-icons/fi';
import { AiOutlineClose }             from 'react-icons/ai';
import TextInput                      from 'components/inputs/textInput/textInput';
import Col                            from 'layouts/col/col';
import Button                         from 'components/buttons/button';
import Icon                           from "components/icon/icon"
import useRegister                    from './useRegister';
import Logo                           from 'assets/images/logo-theme.png';
import styles                         from './register.module.scss';


// Register Screen
const RegisterScreen = () => {

  // Getting all hook values
  const {
        step,
        setStep,
        name,
        setName,
        onEmailBlur,
        email,
        setEmail,
        password,
        setPassword,
        onPasswordChange,
        onPasswordBlur,
        passwordStatus,
        confirmPassword,
        setConfirmPassword,
        code,
        setCode,
        onCreateAccountClick,
        error,
        errorMessages,
        onNextClick,
        loading
  } = useRegister();

  const navigate = useNavigate();

  return (
    <div className="authContainer">
        <div className='left'>
            <div className='background'></div>
        </div>
        <div className='right'>
            <div className="content">
              <div className="logo">
                <img src={Logo} />
              </div>
              <div className='back' onClick={() => step === 1 ? navigate('/login') : setStep(1)}>
                <Icon icon={<MdOutlineArrowBackIosNew />} />
                <p>Go Back</p>
              </div>
              <div className="head">
                <h3 className='heading'>{step === 1 ? 'Create New Account' : 'Verify Email'}</h3>
                <p className='info'>{step === 1 ? 'Create a new account to start using VisitorPower and manage your visitors and deliveries hassle-free.' : 'We have sent a unique 6-digit code to jaise@vipower.co.uk to verify your email. Please enter the code here.'}</p>
              </div>
              {step === 1 ?
              <form>
                <Col gap="0.5rem">
                  <TextInput 
                    placeholder="Enter Full Name" 
                    label="Full Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    isError={error.name}
                    errorMessage={errorMessages.name} 
                    required />
                  <TextInput 
                    placeholder="Enter Email Address" 
                    label="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={onEmailBlur}
                    isError={error.email}
                    errorMessage={errorMessages.email} 
                    required />
                  <TextInput 
                    placeholder="Enter Password" 
                    label="Password"
                    type="password"
                    value={password}
                    onChange={onPasswordChange}
                    onBlur={onPasswordBlur}
                    isError={error.password}
                    errorMessage={errorMessages.password} 
                    required />
                      <div className="passwordReq">
                        <p>Password Must</p>
                        <ul>
                          <li>be atleast 8 characters long<span><Icon icon={passwordStatus.length ? <FiCheck /> : <AiOutlineClose />} size="1.2rem" color={passwordStatus.length ? 'green' : 'red'} /></span></li>
                          <li>contain atleast one upper case letter<span><Icon icon={passwordStatus.hasUppercase ? <FiCheck /> : <AiOutlineClose />} size="1.2rem" color={passwordStatus.hasUppercase ? 'green' : 'red'} /></span></li>
                          <li>contain atleast one lower case letter<span><Icon icon={passwordStatus.hasLowercase === true ? <FiCheck /> : <AiOutlineClose />} size="1.2rem" color={passwordStatus.hasLowercase ? 'green' : 'red'} /></span></li>
                          <li>contain atleast one number<span><Icon icon={passwordStatus.hasNumber ? <FiCheck /> : <AiOutlineClose />} size="1.2rem" color={passwordStatus.hasNumber ? 'green' : 'red'} /></span></li>
                          <li>contain atleast one special character<span><Icon icon={passwordStatus.hasSpecialChar ? <FiCheck /> : <AiOutlineClose />} size="1.2rem" color={passwordStatus.hasSpecialChar ? 'green' : 'red'} /></span></li>
                        </ul>
                    </div>
                  <TextInput 
                    placeholder="Confirm Password" 
                    label="Confirm Password"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    isError={error.confirmPassword}
                    errorMessage={errorMessages.confirmPassword} 
                    required />
                  <Button classNames="mt-1" text="Next" onClick={onNextClick} disabled={loading} />
                  <p className='text-center mt-1'>Already have an Account ? <span><a className='link' onClick={() => navigate('/login')}>Login Now</a></span></p>
                </Col>
                </form> :
                <form>
                  <Col gap="0.5rem">
                    <TextInput 
                      placeholder="Enter Verification Code" 
                      label="Verification Code"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      isError={error.code}
                      errorMessage={errorMessages.code} 
                      required />
                    <Button classNames='mt-1' text="Verify & Create Account" onClick={onCreateAccountClick} />
                    <p className='text-center mt-1'>Already have an Account ? <span><a className='link' onClick={() => navigate('/login')}>Login Now</a></span></p>
                  </Col>
                </form> }
            </div>
        </div>
    </div>
  )
}

export default RegisterScreen