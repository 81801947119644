import { useEffect, useState } from "react";
import { useChannel } from "ably/react";
import useApi from "hooks/useApi";
import CustomModal from "../modal"
import Button from "components/buttons/button"
import styles from './addNewDeviceModal.module.scss';
import { showSuccessToast } from "functions/toasts";


const Body = ({loading, code, closeModal, fetchDevicesData}) => {

    const { channel } = useChannel(`public:device-pairing/${code}`, (message) => {
        console.log('Device Added Successfully')
        fetchDevicesData();
        showSuccessToast('Device Paired Successfully');
        closeModal();
    });

//     useEffect(() => {
//     window.Echo.channel('channel')
//       .listnen('NewVisitorEvent', (data) => {
//         console.log('Received message:', data);
//         // Handle received message data
//       })
//       .here((users) => {
//         console.log('Subscribed to private channel with users:', users);
//       })
//       .joining((user) => {
//           console.log('User joined private channel:', user);
//       })
//       .leaving((user) => {
//           console.log('User left private channel:', user);
//       });

//       // Clean up the subscription when component unmounts
//       return () => {
//         window.Echo.leave('message');
//     };
//   },[]);

    return (
        <div className={styles.container}>
            <p>Download TheVisitorPower App from the App Store or Google Play Store and add the authorization code shown below to connect the device with the site.</p>
            <div className={styles.authorizationContainer}>
                <h4>Authorization Code</h4>
                {!loading ? <p className={styles.authCode}>{code}</p> : ''}
                <p>A new code would be generated each 2 min.</p>
            </div>
        </div>
    )
}

const Footer = ({closeModal}) => {
    return (
        <div className="gb-flex-end">
            <Button text="Cancel" backgroundColor="#D3D3D3" color="#3A3A3A" onClick={closeModal} />
        </div>
    )
}

const AddNewDeviceModal = ({status, closeModal, fetchDevicesData, buildingId}) => {

    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(true);

    const { post } = useApi();

    useEffect(() => {
        fetchDevicePairingCode();

        const intervalId = setInterval(fetchDevicePairingCode, 120000);

        return () => clearInterval(intervalId);

    }, [])

    const fetchDevicePairingCode = async () => {
        setLoading(true);

        try {
            const response = await post(`buildings/${buildingId}/new-device`, {});
            setCode(response.data.data.code);
            setLoading(false);

        } catch(err) {
            console.log(err);
        }
    }

  return (
    <CustomModal isModalOpen={status} closeModal={closeModal} width="40%">
        {{ 
            header : 'Add New Device',
            body : <Body loading={loading} code={code} closeModal={closeModal} fetchDevicesData={fetchDevicesData} />,
            footer : <Footer
                        closeModal={closeModal}
                    />
        }}
    </CustomModal>
  )
}

export default AddNewDeviceModal