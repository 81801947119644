import CustomModal from "../modal"
import styles from './addNewTenantModal.module.scss';
import TextInput from "components/inputs/textInput/textInput";
import Button from "components/buttons/button";

const Body = ({error, errorMessages, tenantName, setTenantName}) => {
    return (
        <div className={styles.bodyContainer}>
            <TextInput 
                label="Tenant Name" 
                placeholder="Enter Tenant Name"
                value={tenantName}
                onChange={(e) => setTenantName(e.target.value)}
                isError={error.tenantName}
                errorMessage={errorMessages.tenantName} 
                required 
            />
        </div>
    )
}

const Footer = ({buttonClick, tenantAddLoading}) => {
    return (
        <div className={styles.footer}>
            <Button disabled={tenantAddLoading} loading={tenantAddLoading} loadingText="Adding Tenant" text="Add Tenant" onClick={buttonClick} />
        </div>
    )
}

const AddNewTenantModal = ({isModalOpen, closeModal, properties}) => {


    return (
        <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} width="50%" minHeight="40%">
            {{ 
                header : 'Add New Tenant',
                body : <Body 
                    error                   = {properties.error}
                    errorMessages           = {properties.errorMessages}
                    tenantName              = {properties.tenantName}
                    setTenantName           = {properties.setTenantName}
                    tenantAddLoading        = {properties.tenantAddLoading}
                    setTenantAddLoading     = {properties.setTenantAddLoading}
                />,
                footer : <Footer
                    buttonClick             = {properties.onButtonClick} 
                    tenantAddLoading      = {properties.tenantAddLoading}
                    setTenantAddLoading   = {properties.setTenantAddLoading}
                />
            }}
        </CustomModal>
    )
}

export default AddNewTenantModal