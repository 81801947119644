import { useState }                                 from "react"
import { useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable }    from "react-beautiful-dnd"
import {BiSolidPencil}                              from 'react-icons/bi';
import {RxHamburgerMenu}                            from 'react-icons/rx';
import { fieldLabelColors }                         from 'constants/colors'
import DashboardLayout                              from "layouts/dashboardLayout/dashboard.jsx"
import PageHeader                                   from "components/pageHeader/pageHeader"
import SubMenuLayout                                from "layouts/subMenuLayout/subMenuLayout"
import SelectInput                                  from "components/inputs/selectInput/selectInput"
import AddNewTemplateModal                          from "components/modal/addNewTemplateModal/addNewTemplateModal"
import AddNewFieldModal                             from "components/modal/addNewFieldModal/addNewFieldModal"
import Icon                                         from "components/icon/icon"
import Row                                          from "layouts/row/row"
import RadioInput                                   from "components/inputs/radio/radio"
import RSwitch                                      from "components/switch/switch"
import Button                                       from "components/buttons/button"
import useSignInForm                                from "./useSignInForm";
import styles                                       from './signInForm.module.scss'
import CheckPermission from "components/checkPermission/checkPermission";

const TenantSignInFormScreen = () => {
    const [visitorPhotoRequired, setVisitorPhotoRequired] = useState(true);

    const [data, setData] = useState([
        {
            fieldId : 'item-1',
            name : 'Phone',
        },
        {
            fieldId : 'item-2',
            name : 'Visitor Company',
        },
    ]);
    const [addNewFieldVisible, setAddNewFieldVisible] = useState(false);
    const [field, setField] = useState('');
    const [required, setRequired] = useState('false');
    const [fieldModalMode, setFieldModalMode] = useState('add');
    const [showAddNewFieldModal, setShowAddNewFieldModal] = useState(false);
    
    const {
        templateType, setTemplateType,
        globalTemplates, setGlobalTemplates,
        tenantTemplates, setTenantTemplates,
        selectedTemplate, setSelectedTemplate,
        updateTenantTemplate,
        addNewGlobalTemplateModal,
        closeTemplateModal,
        fetchGlobalTemplates,
        templateModalType, 
        setAddNewTemplateModalType,
        modalMode, setModalMode,
        fetchTenantTemplates,
        selectedTemplateType, setSelectedTemplateType,
        addNewTemplateModalStatus, setAddNewTemplateModalStatus,
        templateModalStatus, setTemplateModalStatus,
        loading, setLoading
    } = useSignInForm();

    const {id, tenantId} = useParams();


    const templateModalClose = () => {
        setTemplateModalStatus(false);
    }

    const onGlobalTemplateClick = () => {
        console.log(selectedTemplate);
        console.log(selectedTemplateType)
        setTemplateModalStatus(true);
    }

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
          }
      
          const updatedList = Array.from(data);
          const [reorderedItem] = updatedList.splice(result.source.index, 1);
          updatedList.splice(result.destination.index, 0, reorderedItem);
      
          setData(updatedList);
    }

    const fieldNameOptions = [
        { value: 'email', label: 'Email' },
        { value: 'vehicle', label: 'Vehicle Registration No' },
        { value: 'add', label: '+ Create New Field' },
    ];

    const requireTypeOptions = [
        { value: 'true', label: 'Required' },
        { value: 'false', label: 'Not Required' },
    ];

    const closeAddNewFieldModal = () => {
        setShowAddNewFieldModal(false);
    }

    const onFieldSelected = (e) => {
        if(e.value == 'add') {
            setFieldModalMode('add');
            setShowAddNewFieldModal(true);
        } else {
            setField(e)
        }
    }

    const onEditClick = () => {
        setFieldModalMode('edit');
        setShowAddNewFieldModal(true);
    }

    const addNewField = () => {
        const len = data.length;
        setData([...data, {
            fieldId : `item-${len + 1}`,
            name : field.label
        }])
        setAddNewFieldVisible(false)
    }
    

     return (
        <>
            <DashboardLayout>
                
                <SubMenuLayout menu="tenant" subMenuType="tenant" currentMenu="Sign In Form">
                    <p className="gb_title">Sign in Form</p>
                    <p className="gb_subtitle">Manage the sign in form specifically for the tenant.</p>
                    {!loading ?
                    <>
                    <Row classNames="mt-5" gap="5rem">
                        {/* <RadioInput id="defaultTemplate" status={templateType == 'default'} onClick={() => setTemplateType('default')} label="Use Default Template" /> */}
                        <RadioInput id="globalTemplate" status={templateType == 'global'} onClick={() => setTemplateType('global')} label="Select from Global Template" />
                        <RadioInput id="customTemplate" status={templateType == 'tenant'} onClick={() => setTemplateType('tenant')} label="Select from Tenant Template" />
                    </Row>

                    {/* Default Template View */}
                    {templateType == 'default' &&
                    <div className={styles.templateContainer}>
                        <div className={styles.templateViewContainer}>
                            <div className={styles.field}>
                                <p className={styles.name}>Name</p>
                                <p className={`${styles.label} ${styles.req}`}>Req</p>
                            </div>
                            <div className={styles.field}>
                                <p className={styles.name}>Email Address</p>
                                <p className={`${styles.label} ${styles.req}`}>Req</p>
                            </div>
                            <div className={styles.field}>
                                <p className={styles.name}>Company</p>
                                <p className={`${styles.label} ${styles.notReq}`}>Not Req</p>
                            </div>
                        </div>
                    </div> }


                    {/* Global Template View */}
                    {templateType == 'global' &&
                    <div className={`${styles.templateContainer}`}>
                        <Row>
                            <SelectInput placeholder="Select template" flex={0.5} defaultValue={selectedTemplateType === 'global' ? selectedTemplate : ''} label="Select Template" options={globalTemplates} onChange={(e) => { setSelectedTemplate(e); setSelectedTemplateType('global') }} />
                        </Row>
                        {selectedTemplate && selectedTemplateType === 'global' && <div className={`${styles.templateViewContainer} mt-2`} onClick={onGlobalTemplateClick}>
                            {selectedTemplate  ? selectedTemplate.form.fields.map((field) => {
                                return (
                                    <div className={styles.field}>
                                        <p className={styles.name}>{field.name}</p>
                                        <p className={`${styles.label} ${Boolean(field.required) ? `${styles.req}` : `${styles.notReq}`}`}>{Boolean(field.required) ? 'Required' : 'Not Required'}</p>
                                    </div>
                                )
                            }) : ''}
                        </div> }
                    </div> }


                    {/* tenant Template View */}
                    {templateType == 'tenant' &&

                        <div className={`${styles.templateContainer}`}>
                            <Row>
                                <SelectInput placeholder="Select template" flex={0.5} defaultValue={selectedTemplateType === 'tenant' ? selectedTemplate : ''} label="Select Template" options={tenantTemplates} onChange={(e) => { setSelectedTemplate(e); setSelectedTemplateType('tenant') }} />
                            </Row>
                            {selectedTemplate && selectedTemplateType === 'tenant' && 
                            <div className={`${styles.templateViewContainer} mt-2`} onClick={onGlobalTemplateClick}>
                                {selectedTemplate  ? selectedTemplate.form.fields.map((field) => {
                                    return (
                                        <div className={styles.field}>
                                            <p className={styles.name}>{field.name}</p>
                                            <p className={`${styles.label} ${styles.req}`}>{Boolean(field.required) ? 'Required' : 'Not Required'}</p>
                                        </div>
                                    )
                                }) : ''}
                            </div> }
                        </div>
                    }


                    <div className="mt-5">
                        <RSwitch status={visitorPhotoRequired} onChange={() => setVisitorPhotoRequired(!visitorPhotoRequired)} label="Visitor Photo is Required" />
                    </div>

                    <div className={styles.bottom}>
                        <CheckPermission permission="tenants_add_edit">
                            <Row classNames="mt-5" justify="right">
                                <Button text="Save Changes" onClick={updateTenantTemplate} />    
                            </Row> 
                        </CheckPermission>
                    </div>

                    </> : '' }

                </SubMenuLayout>
            </DashboardLayout>


            {showAddNewFieldModal && <AddNewFieldModal isModalOpen={showAddNewFieldModal} closeModal={closeAddNewFieldModal} mode={fieldModalMode} data={{ fieldName : 'Email' }} /> }

            {templateModalStatus ?  
            <AddNewTemplateModal 
                fetchTemplates={fetchGlobalTemplates} 
                mode="edit" 
                selectedTemplate={selectedTemplate} 
                isModalOpen={templateModalStatus} 
                closeModal={closeTemplateModal} 
                templateType={selectedTemplateType}
                tenantId={tenantId} 
            /> : ''}
            
        </>
    )
}

export default TenantSignInFormScreen