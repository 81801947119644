// Dropdown.js
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./dropdown.module.scss";
import { useDropdown } from "contexts/dropdownContext";

const Dropdown = ({ children, id }) => {
  const { dropdownStatusMap, registerDropdown, setDropdownStatus } =
    useDropdown();

  const dropdownRef = useRef(null);

  useEffect(() => {
    registerDropdown(id, dropdownRef.current);
  }, []);

  const handleToggleDropdown = () => {
    setDropdownStatus(id, !dropdownStatusMap[id]);
  };

  return (
    <div className={styles.dropdownContainer} ref={dropdownRef}>
      <div className={styles.dropdownHeader} onClick={handleToggleDropdown}>
        {children[0]} {/* Assume the first child is the header content */}
      </div>
      {dropdownStatusMap[id] && (
        <div className={styles.dropdownContent}>
          {children.slice(1)}
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};

export default Dropdown;
