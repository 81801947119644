import React            from 'react'
import CustomModal      from '../modal'
import Row              from 'layouts/row/row';
import Button           from 'components/buttons/button';
import TextInput from 'components/inputs/textInput/textInput';

const Body = ({deviceName, setDeviceName, editError, editErrorMessages}) => {
    return (
        <div>
            <TextInput 
                label="Device Name" 
                placeholder="Enter Device Name" 
                value={deviceName} 
                onChange={(e) => setDeviceName(e.target.value)}
                isError={editError.name}
                errorMessage={editErrorMessages.name} 
            />
        </div>
    )
}

const Footer = ({editDevice, closeModal, loading}) => {

    return (
        <Row justify="flex-end" gap="1rem">
            <Button text="Cancel" onClick={closeModal} backgroundColor="#D3D3D3" color="#3A3A3A" />
            <Button disabled={loading} loading={loading} loadingText="Updating Device" text="Update" onClick={editDevice}  />
        </Row>
    )
}

/**
 * 
 * @param {Boolean} isModalOpen     - Shows or hides modal
 * @param {Function} closeModal     - Function to close the modal 
 */

const EditDeviceModal = ({isModalOpen, closeModal, editDevice, loading, deviceName, setDeviceName, editError, editErrorMessages}) => {
  return (
    <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} width="40%" minHeight="30%">
        {{ 
            header : 'Edit Device',
            body : <Body 
                    deviceName={deviceName} 
                    setDeviceName={setDeviceName}
                    editError={editError} 
                    editErrorMessages={editErrorMessages}
                />,
            footer : <Footer editDevice={editDevice} closeModal={closeModal} loading={loading} />
         }}
    </CustomModal>
  )
}

export default EditDeviceModal