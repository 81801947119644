import moment from "moment"
import validator from 'validator';
import { APP_CONFIG } from "constants/config"

// Function to check the input value is empty or not
export const checkIfEmpty = (input) => {
    if(input == '' || input == undefined || input == null) {
        return true
    }
    return false
}

// Function to check whether name has both first & last name
export const checkIfNameIsValid = (name) => {
   // Regular expression to allow only alphabetic characters and spaces
   const validNameRegex = /^[a-zA-Z\s]+$/;

   // Trim the name and split it by spaces
   const nameParts = name.trim().split(' ');
 
   // Check if the name contains at least two parts (first and last name)
   // and if it matches the valid name pattern
   const isValid = nameParts.length > 1 && validNameRegex.test(name);
 
   return {
    'valid' : isValid,
    'message' : nameParts.length <= 1 ? 'not-full-name' : 'not-valid-name',
    'validChar' : validNameRegex.test(name)
  }; 
}

// Function to check if email is valid or not
export const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
}

// Function to capitalize each word in a string
export const capitalizeWords = (inputString) => {
    // Split the input string into an array of words
    const wordsArray = inputString.split(' ');
  
    // Capitalize each word in the array using the map function
    const capitalizedWordsArray = wordsArray.map((word) => {
      // Capitalize the first letter of each word and keep the rest unchanged
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
  
    // Join the capitalized words back into a single string
    const capitalizedString = capitalizedWordsArray.join(' ');
  
    return capitalizedString;
}


/**
 * Function to check if the date is in DD/MM/YYYY format
 * @param {string} dateString 
 * @returns {boolean}
*/
export const isValidDateFormat = (dateString) => {
  // Define the regular expression pattern for DD/MM/YYYY format
  const regex = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;

  // Test if the dateString matches the pattern
  return regex.test(dateString);
}

/**
 * Function to check if the time is in HH:mm format
 * @param {string} timeString 
 * @returns {boolean}
*/
export const isValidTimeFormat = (timeString) => {
  // Define the regular expression pattern for hh:mm format
  const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;

  // Test if the timeString matches the pattern
  return regex.test(timeString);
}


/**
 * Function to check truncate string by replacing with 3 dots
 * @param {string} str
 * @param {number} maxLength  
 * @returns {string}
*/
export const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
  } else {
      return str;
  }
}

// Function to check the complexity of the password
export const checkPasswordComplexity = (password) => {
    // Define regular expressions to check for different criteria
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#\$%\^&\*()_+{}\[\]:;<>,.?~\\-]/.test(password);
  const hasSpaces = /^\s*$/.test(password); // Check for spaces at the beginning, middle, and end.

  // // Check the length and the presence of different character types
  // if (password.length < 8) {
  //   return false;
  // } else if (!(hasUppercase && hasLowercase && hasNumber && hasSpecialChar)) {
  //   return false;
  // } else if (!hasNoSpaces) {
  //   return false;
  // } else {
  //   return true;
  // }
  if(password.length < 8 || !hasUppercase || !hasLowercase || !hasNumber || !hasSpecialChar) {
    return {
      status : false,
      hasLowercase : hasLowercase,
      hasUppercase : hasUppercase,
      hasNumber : hasNumber, 
      hasSpecialChar : hasSpecialChar,
      length : !(password.length < 8)
    }
  } else {
    return {
      status : true,
      hasLowercase : true,
      hasUppercase : true,
      hasNumber : true, 
      hasSpecialChar : true,
      length : true
    }
  }
}

// Function to change the datetime format
export const dateTimeFormat = (dateTime, format="MMM Do YYYY, h:mm a") => {
  const moment = require('moment');
  return moment(dateTime).format(format);
}

export const timeFormat = (time) => {
  const inputTime = moment(time, 'HH:mm').format('hh:mm A');
  return inputTime;
}


export const getImageURL = (path) => {
  return `${APP_CONFIG.BASE_URL.slice(0,-5)}${path}`
}