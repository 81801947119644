import { useState } from "react"
import { useParams } from "react-router-dom";
import { TiDelete } from "react-icons/ti";
import Icon from "components/icon/icon";
import Skeleton from "react-loading-skeleton";
import CustomModal from "../modal"
import TextInput from "components/inputs/textInput/textInput";
import SelectInput from "components/inputs/selectInput/selectInput";
import RSwitch from "components/switch/switch";
import styles from './employeeModal.module.scss';
import Col from "layouts/col/col";
import Row from "layouts/row/row";
import useApi from 'hooks/useApi';
import Button from "components/buttons/button";
import { showErrorToast, showSuccessToast } from "functions/toasts";
import "react-loading-skeleton/dist/skeleton.css";
import { useEffect } from "react";
import { checkIfEmpty, isValidEmail } from "functions/functions";
import CheckPermission from "components/checkPermission/checkPermission";
import Checkbox from "components/inputs/checkbox/checkbox";
import ConfirmationModal from "../confirmationModal/confirmationModal";


const Body = (
  {
    tab,
    setTab,
    name,
    setName,
    contactEmails,
    setContactEmails,
    invitationEmail,
    setInvitationEmail,
    email,
    setEmail,
    phone,
    setPhone,
    arrivalNotification,
    setArrivalNotification,
    signOutNotification,
    setSignOutNotification,
    buddyAppStatus,
    setBuddyAppStatus,
    defaultNotifPolicy,
    onPolicyChange,
    error, setError,
    onStatusChange,
    notifOptions,
    errorMessages, setErrorMessages,
    options,
    loading
  }
) => {

  const [contactInputValue, setContactInputValue] = useState('');

  const showForm = (tab) => {
    setTab(tab)
  }

  const handleKeyPress = (e) => {
    if(e.key === 'Enter') {
      if(contactInputValue.trim() !== '') {
        setContactEmails((prev) => [...prev, contactInputValue]);
        setContactInputValue('');
      }
    }
  }

  const onIconClick = (emailToDelete) => {
    const updatedEmails = contactEmails.filter(email => email !== emailToDelete);
    setContactEmails(updatedEmails);
    console.log('icon click');
  }

  const onBlur = (type, e) => {

    const value = e.target.value;

    let errorObj = {
      ...error,
      [type] : false
    }

    let errorMessageObj = {
      ...errorMessages,
      [type] : ''
    }

    if(type === 'email') {
      if(value && !isValidEmail(value)) {
        errorObj[type] = true;
        errorMessageObj[type] = 'Enter valid email'
      }
    }

    if(type === "name") {
      if(checkIfEmpty(value)) {
        errorObj[type] = true;
        errorMessageObj[type] = 'Employee Name is Mandatory'
      }
    }

    setError(errorObj);
    setErrorMessages(errorMessageObj);
  }

  return(
    <>
      <div className={styles.body}>
        <div className={styles.options}>
          <ul>
            <li className={tab == 'details' ? styles.active : ''} onClick={() => showForm('details')}>Employee Details</li>
            <li className={tab == 'notifications' ? styles.active : ''} onClick={() => showForm('notifications')}>Notifications</li>
            {/* <li className={tab == 'buddyApp' ? styles.active : ''} onClick={() => showForm('buddyApp')}>Buddy App</li> */}
          </ul>
        </div>
        {!loading ?
        <div className={styles.form}>
          {tab == 'details' ?
              <Col gap="0.5rem">
                <h3 className="gb_title mb-2">Employee Details</h3>
                <TextInput 
                  isError={error.name} 
                  errorMessage={errorMessages.name} 
                  label="Full Name"
                  onBlur={(e) => onBlur('name', e)} 
                  value={name} 
                  onChange={(e) => setName(e.target.value)} 
                  placeholder="Enter Employee Name" 
                  required 
                />
                {/* COMMENTED BELOW CODE, FOR LATER USE IF REQUIRED */}
                {/* <TextInput 
                  label="Emails to be Notified" 
                  value={contactInputValue} 
                  onChange={(e) => setContactInputValue(e.target.value)} 
                  onKeyPress={(e) => handleKeyPress(e)} 
                  placeholder="Enter email address and click enter"  
                /> */}
                <TextInput
                  isError={error.email}
                  errorMessage={errorMessages.email} 
                  value={email}
                  onBlur={(e) => onBlur('email', e)}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter Employee Email"
                  label="Employee Email"
                />

                {contactEmails?.length > 0 &&
                <div className={styles.contactEmailContainer}>
                  {contactEmails?.map((email) => {
                    return (
                      <div className={styles.email}>
                        <p>{email}</p>
                        <Icon onClick={() => onIconClick(email)} icon={<TiDelete />} size="1.8rem" color="#363636" />
                      </div>
                    )
                  })}
                </div> }
                <TextInput label="Phone No" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Enter Phone No." />
              </Col> : tab =='notifications' ?
              <Col gap="0.5rem">
                <h3 className="gb_title mb-2">Notifications</h3>
                <RSwitch label="Follow Default Notification Policy" status={defaultNotifPolicy} onChange={onPolicyChange} />
                {!defaultNotifPolicy && <div className={styles.notificationOptions}>
                    <div className={styles.events}>
                        <Checkbox 
                            label="Visitor for Employee Signed In"
                            id="signIn"
                            name="signIn"
                            checked={notifOptions.employee_notify_employee_visitor_sign_in}
                            onChange={() => onStatusChange("employee_notify_employee_visitor_sign_in")}
                        />
                        <Checkbox 
                            label="Visitor for Employee Signed Out"
                            id="signIn"
                            name="signIn"
                            checked={notifOptions.employee_notify_employee_visitor_sign_out}
                            onChange={() => onStatusChange("employee_notify_employee_visitor_sign_out")}
                        />
                    </div>
                    <div className={styles.events}>
                      <Checkbox 
                          label="Delivery for Employee Arrived"
                          id="signIn"
                          name="signIn"
                          checked={notifOptions.employee_notify_employee_delivery_arrived}
                          onChange={() => onStatusChange("employee_notify_employee_delivery_arrived")}
                      />
                      <Checkbox 
                          label="Delivery for Employee Collected" 
                          id="signIn"
                          name="signIn"
                          checked={notifOptions.employee_notify_employee_delivery_collected}
                          onChange={() => onStatusChange("employee_notify_employee_delivery_collected")}
                      />
                    </div>
                    <div className={styles.events}>
                      <Checkbox 
                          label="Preregistering Visitor for Employee"
                          id="signIn"
                          name="signIn"
                          checked={notifOptions.employee_notify_employee_preregister_visitor}
                          onChange={() => onStatusChange("employee_notify_employee_preregister_visitor")}
                      />
                    </div>
                </div> }
              </Col> : tab == 'buddyApp' ?
              <Col gap="0.5rem">
                <h3 className="gb_title">Buddy App</h3>
                <p>Buddy App is an add-on application application for tenants/employees to easily manage their visitors and deliveries.</p>
                <Checkbox classNames="mt-2" disabled={email === ''} label="Invite Employee to Buddy App" status={buddyAppStatus} onChange={() => setBuddyAppStatus(!buddyAppStatus)} />
                {email === '' && <p className="warning-text">Add Employee Email to invite</p>}
              </Col> : '' }
        </div> : 
        <div className={styles.form}>
          <div>
              <Skeleton width="20%" />
              <Skeleton height={40} />
          </div>
          <div className="mt-2">
              <Skeleton width="20%" />
              <Skeleton height={100} />
          </div>
          <div className="mt-2">
              <Skeleton width="20%" height={30} />
          </div>
          <div className="mt-2">
              <Skeleton width="20%" height={30} />
          </div>
        </div>
        }
      </div>
    </>
  )
    
}


const Footer = ({buddyAppStatus, onClick, onDeleteUser, name, mode}) => {
  return (
    <CheckPermission permission="tenants_add_edit">
      <Row justify="flex-end" gap="1rem">
              {mode == 'edit' && <Button text='Delete User' onClick={onDeleteUser} backgroundColor="#FFEEEE" color="#B00000" /> }
              {mode == 'add' ? <Button disabled={name == ''} text={buddyAppStatus ? 'Add User & Send' : 'Add User'} onClick={onClick} /> :
              <Button text={buddyAppStatus ? 'Update User & Send' : 'Update User'} onClick={onClick} /> }
      </Row>
    </CheckPermission>
  )
}

const EmployeeModal = ({isModalOpen, closeModal, mode, fetchEmployeeData, selectedEmployee, setSelectedEmployee}) => {

  const [tab, setTab]     = useState('details');
  const [name, setName]   = useState('');
  const [contactEmails, setContactEmails] = useState([]);
  const [invitationEmail, setInvitationEmail] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [arrivalNotification, setArrivalNotification] = useState({
    label : "Notify",
    value : "notify"
  });
  const [signOutNotification, setSignOutNotification] = useState({
    label : "Dont't Notify",
    value : "no-notify"
  });

  const [notifOptions, setNotifOptions] = useState({
    employee_notify_employee_visitor_sign_in            : 1,
    employee_notify_employee_visitor_sign_out           : 0,
    employee_notify_employee_delivery_arrived           : 1,
    employee_notify_employee_delivery_collected         : 0,
    employee_notify_employee_preregister_visitor        : 1
});

  const [buddyAppStatus, setBuddyAppStatus]   = useState(false);
  const [error, setError]                     = useState('');
  const [errorMessages, setErrorMessages]     = useState('');
  const [loading, setLoading]                 = useState(mode == 'edit' ? true : false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState({
    delete : false
  })
  const [defaultNotifPolicy, setDefaultNotifPolicy] = useState(true);

  useEffect(() => {
    if(mode == 'edit') {
      setEditValues();
    }
  },[]);

  const { post, del }    = useApi();
  const {tenantId}  = useParams();

  const options = [
    {
      label : "Dont't Notify",
      value : "no-notify"
    },
    {
      label : "Notify",
      value : 'notify'
    }
  ]

  const setEditValues = () => {
    setLoading(true);
    console.log(selectedEmployee);
    setName(selectedEmployee.name);
    setEmail(selectedEmployee.contact_emails);
    setPhone(selectedEmployee.phone);
    setInvitationEmail(selectedEmployee.email);
    setDefaultNotifPolicy(selectedEmployee.follow_default_notification_policy);

    setNotifOptions({
      employee_notify_employee_visitor_sign_in         :   selectedEmployee.employee_notify_employee_visitor_sign_in ? 1 : 0,
      employee_notify_employee_visitor_sign_out        :   selectedEmployee.employee_notify_employee_visitor_sign_out ? 1 : 0,
      employee_notify_employee_delivery_arrived        :   selectedEmployee.employee_notify_employee_delivery_arrived ? 1 : 0,
      employee_notify_employee_delivery_collected      :   selectedEmployee.employee_notify_employee_delivery_collected ? 1 : 0,
      employee_notify_employee_preregister_visitor     :   selectedEmployee.employee_notify_employee_preregister_visitor ? 1 : 0,
    })
    setTimeout(() => {
      setLoading(false);
    }, 500)
    
  }

  const onStatusChange = async (event) => {
    setNotifOptions(options => ({...options, [event] : options[event] ? 0 : 1}));
}

  const onButtonClick = async () => {
    // setLoading(true)
    console.log(name, email, phone, arrivalNotification, signOutNotification, buddyAppStatus);

    let trimmedName = name.trim();
    let trimmedEmail = email?.trim();
    let trimmedPhone = phone?.trim();

    const contactEmailStrings = JSON.stringify(contactEmails)

    let errorObj = {
      name : false,
      email : false,
      phone : false
    }

    let errorMessageObj = {
      name : '',
      email : '',
      phone : '',
    }

    if(checkIfEmpty(trimmedName)) {
      errorObj.name = true;
      errorMessageObj.name = "Employee Name is Mandatory";
    }

    if(email) {
      if(!isValidEmail(trimmedEmail)) {
        errorObj.email = true;
        errorMessageObj.email = "Enter valid email";
      }
    }

    if(!errorObj.name && !errorObj.email) {
      const path = mode === 'add' ? `tenants/${tenantId}/employees` : `employees/${selectedEmployee.employee_id}`;
      try {

        let data = {
          name : name,
          buddy_app_email : email || null,
          // contact_emails : contactEmailStrings || null,
          add_to_buddy_app : buddyAppStatus ? 1 : 0,
          employee_type : 'user',
          phone : phone || null,
          follow_default_notification_policy : defaultNotifPolicy,
          ...notifOptions
        }

        if(email) {
          data['contact_emails'] = email
        }
        
        await post(path, data)

        fetchEmployeeData();
        setLoading(false)
        showSuccessToast('User Added Successfully');
        closeModal();

      } catch(err) {
        console.log(err)
      }
    }
    
    
    setError(errorObj);
    setErrorMessages(errorMessageObj);

  }

  const toggleDeleteConfirmModal = (status) => {
    setConfirmationModalOpen({
      ...confirmationModalOpen,
      delete : status
  })
  }

  const onDeleteUser = async() => {
    setLoading(true);
    try {
      await del(`employees/${selectedEmployee.employee_id}`);

      fetchEmployeeData();
      setLoading(false)
      showSuccessToast('User Deleted Successfully');
      closeModal();

    } catch(err) {
      console.log(err);
      showErrorToast('Something Went Wrong');
    }
  }

  const onPolicyChange = async () => {
    setDefaultNotifPolicy(defaultNotifPolicy ? 0 : 1)
}

  const resetFields = () => {
      setSelectedEmployee('');
      setTab('details');
      setName('');
      setEmail('');
      setPhone('');
      setArrivalNotification({
        label : "Notify",
        value : "notify"
      })
      setSignOutNotification({
        label : "Dont't Notify",
        value : "no-notify"
      })
      setBuddyAppStatus(false);
      setLoading(true);
      closeModal();
  }

  return (
    <>
    <CustomModal isModalOpen={isModalOpen} closeModal={resetFields} width="50%" minHeight="68%">
        {{ 
            header : 'Add New Employee',
            body : <Body 
              tab={tab} 
              setTab={setTab}
              name={name}
              setName={setName}
              contactEmails={contactEmails}
              setContactEmails={setContactEmails}
              invitationEmail={invitationEmail}
              setInvitationEmail={setInvitationEmail}
              email={email}
              setEmail={setEmail}
              phone={phone}
              setPhone={setPhone}
              arrivalNotification={arrivalNotification}
              setArrivalNotification={setArrivalNotification}
              signOutNotification={signOutNotification}
              setSignOutNotification={setSignOutNotification}
              buddyAppStatus={buddyAppStatus}
              setBuddyAppStatus={setBuddyAppStatus}
              notifOptions={notifOptions}
              onStatusChange={onStatusChange}
              defaultNotifPolicy={defaultNotifPolicy}
              onPolicyChange={onPolicyChange}
              error={error} setError={setError}
              errorMessages={errorMessages} setErrorMessages={setErrorMessages}
              options={options}
              loading={loading}
            />,
            footer : <Footer buddyAppStatus={buddyAppStatus} name={name} onClick={onButtonClick} onDeleteUser={() => toggleDeleteConfirmModal(true)} mode={mode} />
         }}
    </CustomModal>

    <ConfirmationModal 
      open={confirmationModalOpen.delete} 
      handleClose={() => toggleDeleteConfirmModal(false)} 
      title="Delete Employees"
      body={<p>Are you sure you want to delete employee ?</p>}
      confirmButtonTitle="Delete"
      onConfirm={onDeleteUser}
    />
    </>
  )
}

export default EmployeeModal