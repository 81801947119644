import { useNavigate }                from 'react-router-dom';
import { MdOutlineArrowBackIosNew }   from 'react-icons/md';
import { FiCheck }                    from 'react-icons/fi';
import { AiOutlineClose }             from 'react-icons/ai';
import TextInput                      from 'components/inputs/textInput/textInput';
import Col                            from 'layouts/col/col';
import Button                         from 'components/buttons/button';
import Icon                           from "components/icon/icon"
import Logo                           from 'assets/images/logo-theme.png';
import useUpdatePassword              from './useUpdatePassword';


const UpdatePasswordScreen = () => {

  const {
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    passwordStatus,
    onPasswordBlur,
    onPasswordChange,
    error,
    errorMessages,
    onResetClick,
    loading,
    message
  } = useUpdatePassword();

  const navigate = useNavigate();

  return (
    <div className="authContainer">
        <div className='left'>
            <div className='background'></div>
        </div>
        <div className='right'>
            <div className="content">
              <div className="logo">
                <img src={Logo} />
              </div>
              <div className='back' onClick={() => navigate('/login')}>
                <Icon icon={<MdOutlineArrowBackIosNew />} />
                <p>Go Back</p>
              </div>
              <div className="head">
                <h3 className='heading'>Reset Password</h3>
                <p className='info'>Please enter your new password to reset your account.</p>
              </div>
                <form>
                  <Col gap="0.5rem">
                    <TextInput 
                      placeholder="Enter New Password" 
                      label="New Password"
                      type="password"
                      value={password}
                      onChange={onPasswordChange}
                      onBlur={onPasswordBlur}
                      isError={error.password}
                      errorMessage={errorMessages.password} 
                      required />
                      <div className="passwordReq">
                        <p>Password Must</p>
                        <ul>
                          <li>be atleast 8 characters long<span><Icon icon={passwordStatus.length ? <FiCheck /> : <AiOutlineClose />} size="1.2rem" color={passwordStatus.length ? 'green' : 'red'} /></span></li>
                          <li>contain atleast one upper case letter<span><Icon icon={passwordStatus.hasUppercase ? <FiCheck /> : <AiOutlineClose />} size="1.2rem" color={passwordStatus.hasUppercase ? 'green' : 'red'} /></span></li>
                          <li>contain atleast one lower case letter<span><Icon icon={passwordStatus.hasLowercase === true ? <FiCheck /> : <AiOutlineClose />} size="1.2rem" color={passwordStatus.hasLowercase ? 'green' : 'red'} /></span></li>
                          <li>contain atleast one number<span><Icon icon={passwordStatus.hasNumber ? <FiCheck /> : <AiOutlineClose />} size="1.2rem" color={passwordStatus.hasNumber ? 'green' : 'red'} /></span></li>
                          <li>contain atleast one special character<span><Icon icon={passwordStatus.hasSpecialChar ? <FiCheck /> : <AiOutlineClose />} size="1.2rem" color={passwordStatus.hasSpecialChar ? 'green' : 'red'} /></span></li>
                        </ul>
                      </div>
                    <TextInput 
                      placeholder="Confirm New Password" 
                      label="Confirm New Password"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      isError={error.confirmPassword}
                      errorMessage={errorMessages.confirmPassword}  
                      required />
                    <Button 
                      text="Reset Password" 
                      classNames="mt-1" 
                      onClick={onResetClick}
                      disabled={loading}
                      loading={loading}
                      loadingText="Updating Password" 
                    />
                    <p className='text-center'>Didn't receive reset link ? <span><a className='link'>Send link again</a></span></p>
                  </Col>
                </form> 
            </div>
        </div>
    </div>
  )
}

export default UpdatePasswordScreen