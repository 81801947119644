import axios from "axios";
import { createContext, useContext, useState, useEffect } from "react";
import EmailVerificationModal from "components/modal/emailVerificationModal/emailVerificationModal";
import { showErrorToast } from "functions/toasts";
import Loader from "components/loader/loder";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { APP_CONFIG } from "constants/config";

export const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [showSessionWarning, setShowSessionWarning] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState([]);
    const [emailVerificatioModal, setEmailVerificationModal] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);
    const [approvedBuildings, setApprovedBuildings] = useState();
    const [currentBuilding, setCurrentBuilding] = useState(() => {
        // Check if the cookie exists
        const cookieValue = Cookies.get('currentBuilding');
    
        // Parse the cookie value if it exists, or provide a default value
        const parsedValue = cookieValue ? JSON.parse(cookieValue) : [];
    
        return parsedValue;
    });

    useEffect(() => {
        console.log('here');
        checkUserLoggedInStatus();
    }, []);
    
    const checkUserLoggedInStatus = async () => {
        setLoading(true)
        const headers = {
            'Content-Type': 'application/json',
            'Accept' : 'application/json'
        }
        
        try {

              const response = await axios.get(`${APP_CONFIG.BASE_URL}user-details`, {headers, withCredentials : true});

                console.log('USER AUTH CONTEXT',response);
                console.log('APPROVED BUILDINGS', approvedBuildings)
              if(response.status === 200) {
                      setUser(response.data.data);
                      if(response.data.data.approved_buildings.buildings.length > 0) {
                        setApprovedBuildings(response.data.data.approved_buildings.buildings);
                        setCurrentBuilding(currentBuilding || response.data.data.approved_buildings.buildings[0]);
                        Cookies.set('currentBuilding', JSON.stringify(currentBuilding || response.data.data.approved_buildings.buildings[0]));
                    }

                      setIsAuthenticated(true);
                      if(!response.data.data.email_verified_at) {
                            setEmailVerified(false);
                            setEmailVerificationModal(true);
                      } else {
                        setEmailVerified(true);
                      }
              }

        } catch(err) {
            console.log(err);
            setIsAuthenticated(false);
            if(err.response.status !== 401) {
                showErrorToast('Something Went Wrong');
            }
            
        }

        setTimeout(() => {
            setLoading(false);
        }, 1000)
        
    }
    
    const setBuilding = (building) => {
        Cookies.set("currentBuilding", JSON.stringify(building));
        setCurrentBuilding(building)
    }
    

    return (
        <AuthContext.Provider value={{ 
                isAuthenticated, setIsAuthenticated,
                showSessionWarning, setShowSessionWarning,
                user, setUser, 
                emailVerified, setEmailVerified, 
                setEmailVerificationModal, 
                loading, setLoading,
                approvedBuildings, setApprovedBuildings,
                currentBuilding, setCurrentBuilding, setBuilding 
            }}>
            {loading ? (
                // Render a loading indicator while checking authentication
                <Loader />
            ) : (
                // Render the children when authentication check is complete
                <>
                    {children}
                    {/* <Loader /> */}
                </>
            )}
            {!emailVerified && emailVerificatioModal && <EmailVerificationModal isModalOpen={emailVerificatioModal} closeModal={() => { }} />}
        </AuthContext.Provider>
    )
}