import React            from 'react'
import InnerImageZoom   from 'react-inner-image-zoom';
import CustomModal      from '../modal'
import SelectInput      from 'components/inputs/selectInput/selectInput'
import Button           from 'components/buttons/button';
import Parcel           from 'assets/images/parcel.jpg';
import styles           from './deliveryDetailsModal.module.scss';

import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

const Body = () => {
    return (
        <div className={styles.confirmDeliveryModalContainer}>
            <div className={styles.deliveryImageContainer}>
                <InnerImageZoom src={Parcel} zoomType='hover' zoomPreload={true} zoomScale={1} hideHint={true} />
            </div>
            <div className={styles.inputsContainer}>
                <p>Confirm the recipient details and assign the delivery to notify them.</p>
                <div className={styles.inputs}>
                    <SelectInput placeholder = "Select Tenant" label = "Recipient Tenant" />
                    <SelectInput placeholder = "Select Person" label = "Recipient Person" />
                </div>
                <div className={styles.otherInfo}>
                    <div className={styles.info}>
                        <h4>Arrived At</h4>
                        <p>Today at 12:34 PM</p>
                    </div>
                    <div className={styles.info}>
                        <h4>Assigned At</h4>
                        <p>Today at 12:34 PM</p>
                    </div>
                </div>
                <div className={styles.resendLink}>Resend Notification to the Recipient</div>
            </div>
            
        </div>
    )
}

const Footer = () => {
    return (
        <div className={styles.footerContainer}>
            <div className={styles.left}>
                <Button text="Delete Delivery" backgroundColor="#F9C8C8" color="#B00000" />
            </div>
            <div className={styles.right}>
                <Button text="Save Changes" backgroundColor="#002547" />
                <Button text="Mark as Collected" />
            </div>
        </div>
    )
}

/**
 * 
 * @param {Boolean} isModalOpen     - Shows or hides modal
 * @param {Function} closeModal     - Function to close the modal 
 */

const DeliveryDetailsModal = ({isModalOpen, closeModal}) => {
  return (
    <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} width="65%" minHeight="60%">
        {{ 
            header : 'Delivery Details - DL0876',
            body : <Body />,
            footer : <Footer />
         }}
    </CustomModal>
  )
}

export default DeliveryDetailsModal