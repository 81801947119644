import DashboardLayout from "layouts/dashboardLayout/dashboard.jsx";
import Container from "components/container/container";
import Row from "layouts/row/row"
import Button from "components/buttons/button";
import TextInput from "components/inputs/textInput/textInput";
import {BsPlusCircleFill} from 'react-icons/bs';

const ComponentScreen = () => {
  return (
    <DashboardLayout>
        <Container>
            <Container>
                <h2>Buttons</h2>
                <Row gap="1rem" width="55%" margin="1rem 0 0 0">
                    <Button flex={1} text="Default Button" />
                    <Button flex={2} text="Custom Color Button" backgroundColor="#F87171" />
                </Row>
            </Container>
            <Container>
                <Row gap="1rem" width="55%" height="100px" margin="1rem 0 0 0" justify="center" align="center">
                    <Button flex={1} text="Button" />
                    <Button backgroundColor="#F87171" icon={<BsPlusCircleFill />} text="Icon Button" />
                    <Button text="Button" backgroundColor="#589AD4" />
                    <Button text="Custom Text Color Button" backgroundColor="#F9C8C8" color="#B00000" />
                </Row>
            </Container>
            <Container>
                <h2>Input</h2>
                <Row width="50%" gap="1rem">
                    <TextInput label="Normal Input" placeholder="Enter Full Name" flex={1} />
                    <TextInput label="Normal Input" flex={2} />
                </Row>
                <Row margin="1rem 0 0 0" gap="1rem" width="35%">
                    <TextInput placeholder="Enter Email" />
                    <TextInput placeholder="Enter Email" />
                </Row>
                <Row>
                    <Button text="Submit" />
                </Row>
            </Container>
        </Container>
        
    </DashboardLayout>
  )
}

export default ComponentScreen