import { useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import SidebarContext from 'contexts/sidebarContext';
import LogoutModal from 'components/modal/logoutModal/logoutModal';
import Icon from 'components/icon/icon';
import styles from './menuItem.module.scss';


const CustomNavLink = ({to, icon, name, onClick}) => {
    const isActive = window.location.pathname === to;

    return (
        <NavLink onClick={onClick} to={to} className={`${styles.menuItem} ${isActive ? styles.menuItemActive : ''}`}>
            <Icon icon={icon} color={isActive ? '#002547' : '#252525'} size='18px' />
            <span>{name}</span>
        </NavLink>
    )
}

const MenuItem = (props) => {
    const { name, to, exact, subMenus, icon, id } = props;
    const [exapnd, setExpand] = useState(false);
    const [logoutModalStatus, setLogoutModalStatus] = useState(false);

    const {activeMenu, setActiveMenu} = useContext(SidebarContext);

    const path = window.location.pathname;

    const onMenuClick = (id) => {
        setActiveMenu(id);

        if(subMenus && subMenus.length > 0) {
            setExpand((exapnd) => !exapnd);
        }
    }

    const closeLogoutModal = () => {
        setLogoutModalStatus(false)
    }

    function isSubMenuActive(menu, path) {
        if (menu.to === path) {
          return true; // Exact match
        }
      
        const menuSegments = menu.to.split('/').filter(segment => segment !== ''); // Remove empty segments
        const pathSegments = path.split('/').filter(segment => segment !== ''); // Remove empty segments
      
        if (menuSegments.length <= pathSegments.length) {
          // Check if the current path starts with menu.to
          const isPrefixMatch = menuSegments.every((segment, index) => {
            if (segment === pathSegments[index] || segment.match(/^\d+$/)) {
              return true; // Matched segment or a dynamic segment
            }
            return false;
          });
      
          if (isPrefixMatch) {
            return true;
          }
        }
      
        return false;
      }

    return (
        <>
        <li>
            {to ? (
                <CustomNavLink to={to} icon={icon} name={name} onClick={() => onMenuClick(id)} />
            ) : 
            (
                <a onClick={() => id === 'logout' ? setLogoutModalStatus(true) : onMenuClick(id)} className={`${styles.menuItem} ${activeMenu === id ? styles.expandActive : ""}`}>
                    <Icon icon={icon} color={activeMenu === id ? '#002547' : '#252525'} size='18px' />
                    <span>{name}</span>
                </a> 
            )}

            { subMenus && subMenus.length > 0 ? (
            <ul className={`${styles.subMenu} ${(exapnd || subMenus.some(menu => isSubMenuActive(menu, path))) ? styles.active : ""}`}>
                {subMenus.map((menu, index) => {
                    const isActive = window.location.pathname.startsWith(menu.to);
                    return (
                        <li key={index}>
                            <NavLink onClick={() => onMenuClick(id)} to={menu.to} className={`${isActive ? styles.subMenuActive : ''}`}>{menu.name}</NavLink>
                        </li>
                    )
                }
                )}
            </ul> ) : null }
        </li>
        {logoutModalStatus && <LogoutModal isModalOpen={logoutModalStatus} closeModal={closeLogoutModal} /> }
        </>
    )
}

export default MenuItem