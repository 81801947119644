import { useState, useEffect }                     from "react";
import { useNavigate }                  from "react-router-dom";
import { checkIfEmpty, isValidEmail, checkPasswordComplexity }   from "functions/functions";
import axios                            from "axios";
import { showErrorToast, showSuccessToast } from "functions/toasts";
import { APP_CONFIG } from "constants/config";

// Custom Hook for Login Screen
const useResetPassword = () => {

    const [step, setStep]                       = useState(1);
    const [email, setEmail]                     = useState('');
    const [password, setPassword]               = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError]                     = useState('');
    const [errorMessages, setErrorMessages]     = useState('');
    const [showSuccess, setShowSuccess]         = useState(false);
    const [loading, setLoading]                 = useState(false);

    const navigate = useNavigate();

    // Send Reset Link Function
    const onSendResetLinkClick = async (e) => {

        e.preventDefault();

        setLoading(true);

        // Removing spaces from the input at the end
        let trimmedEmail    = email.trim();

        // Initialising Error Object Values
        const errorObj = {
            email       : false,
        }

        const errorMessageObj = {
            email       : '',
        }

        // Validating Email
        if(checkIfEmpty(trimmedEmail)) {
            errorObj.email = true;
            errorMessageObj.email = 'Email Address is mandatory'
        } else if(!isValidEmail(trimmedEmail)) {
            errorObj.email = true;
            errorMessageObj.email = 'Enter a valid email address'
        }


        // If all inputs are valid
        if(!errorObj.email) {

            const headers = {
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            }

            const data = {
                email : trimmedEmail
            }
            
            try {
    
                const response = await axios.post(`${APP_CONFIG.BASE_URL}forgot-password`, data, {headers});
    
                
                    setShowSuccess(true);
                    showSuccessToast('Password Reset Email Sent. Please Check your Inbox');

    
            } catch(err) {
                if(err.response.status === 400) {
                    errorObj.email = true;
                    errorMessageObj.email = 'Email is not registered.'
                } else {
                    showErrorToast('Something Went Wrong');
                }
            }
        }

        // Setting Error Values
        setError(errorObj);
        setErrorMessages(errorMessageObj)

        setLoading(false);
    }


    // Reset Password Function
    const onResetClick = (e) => {
        e.preventDefault();

        // Removing spaces from the input at the end
        let trimmedPassword         = password.trim();
        let trimmedConfirmPassword  = confirmPassword.trim();

        // Initialising Error Object Values
        const errorObj = {
            password        : false,
            confirmPassword : false
        }

        const errorMessageObj = {
            password        : '',
            confirmPassword : ''
        }

        if(checkIfEmpty(trimmedPassword)) {
            errorObj.password = true;
            errorMessageObj.password = 'Password is mandatory'
        } else if(!checkPasswordComplexity(trimmedPassword)) {
            errorObj.password = true;
            errorMessageObj.password = 'Enter a strong password'
        }

        if(checkIfEmpty(trimmedConfirmPassword)) {
            errorObj.confirmPassword = true;
            errorMessageObj.confirmPassword = 'Confirm your password'
        } else if(trimmedPassword !== trimmedConfirmPassword) {
            errorObj.confirmPassword = true;
            errorMessageObj.confirmPassword = 'Passwords do not match'
        }

        if(!errorObj.password && !errorObj.confirmPassword) {
            navigate('/login');
        }
        

        setError(errorObj);
        setErrorMessages(errorMessageObj);

    }


    // Returning Hook Values
    return {
        step,
        setStep,
        email,
        setEmail,
        password,
        setPassword,
        confirmPassword,
        setConfirmPassword,
        error,
        errorMessages,
        onSendResetLinkClick,
        onResetClick,
        showSuccess,
        loading
    }
}

export default useResetPassword;