import {useContext}             from 'react';
import { AuthContext } from 'contexts/authContext';
import { LuLayoutDashboard }    from 'react-icons/lu';
import { HiOutlineUsers }       from 'react-icons/hi2';
import { BsBoxSeam }            from 'react-icons/bs';
import { IoSettingsOutline }    from 'react-icons/io5';
import { TbReport }             from 'react-icons/tb';
import { AiOutlineLogin }       from 'react-icons/ai';
import MenuItem                 from './menuItem/menuItem';
import SidebarContext           from 'contexts/sidebarContext';
import styles                   from './sidebar.module.scss';


/**
 * Main Sidebar Component
 * 
 * Sidebar that shows at left side of all pages.
 */
const Sidebar = () => {
    const {user} = useContext(AuthContext);
    const {sidebarStatus, sidebarRef} = useContext(SidebarContext);

    /**
 * Menu Items to Show in the sidebar.
 * 
 * name : Display name.
 * id   : Unique value to identify the menu.
 * to   : Navigation Location.
 * icon : Icon to display along with the menu.    
 * 
 */
const menuItems = [
    {name : "Dashboard", id : "dashboard", exact: true, to: "/dashboard", icon : <LuLayoutDashboard />},
    ...(user.role.permissions.includes('visitors_view_todays_visitors') || user.role.permissions.includes('pre_reg_view') ?
    [{name : "Visitors", id : "visitors",  exact: true, icon : <HiOutlineUsers />, 
        subMenus: [
            ...(user.role.permissions.includes('visitors_view_todays_visitors') ? [{name : "Today's Visitors", id : 'todays-visitors', exact: true, to : "/todays-visitors"}] : []), 
            ...(user.role.permissions.includes('pre_reg_view') ? [{name : "Pre-Registered Visitors", id : 'pre-registered-visitors', to: "/pre-registered-visitors"}] : []),
        ]
    }] : []),
    ...(user.role.permissions.includes('deliveries_show_page') ? [{name : "Deliveries", id : "deliveries", exact: true, to: "/deliveries", icon : <BsBoxSeam />}] : []),
    ...(user.role.permissions.includes('sites_view') || user.role.permissions.includes('portal_users_view') ?
    [{name : "Manage", id : "manage", exact: true, icon : <IoSettingsOutline />, 
        subMenus: [
            ...(user.role.permissions.includes('sites_view') ? [{name : "Sites", to : "/manage/sites", childURLs : ['/manage/sites/:id', '/manage/sites/:id/details']}] : []), 
            ...(user.role.permissions.includes('portal_users_view') ? [{name : "Users", to: "/manage/users"}] : []),
            ...(user.role.permissions.includes('portal_users_view') ? [{name : "Roles & Permissions", to: "/manage/roles-permissions"}] : []),
        ]
    }] : []),
    ...(user.role.permissions.includes('visitor_reports_view') || user.role.permissions.includes('delivery_reports_view') ?
    [{name : "Reports", id : "reports",  exact: true, icon : <TbReport />, 
        subMenus: [
            ...(user.role.permissions.includes('visitor_reports_view') ? [{name : "Visitor Reports", to : "/reports/visitors"}] : []), 
            ...(user.role.permissions.includes('delivery_reports_view') ? [{name : "Delivery Reports", to: "/reports/deliveries"}] : []),
        ]
    }] : []),
    // {name : "Components", exact: true, to: "/components", icon : <AiOutlineLogin />},
    {name : "Logout", id : "logout", exact: true, icon : <AiOutlineLogin />},
]


    return (
        <div className={styles.sidebar} ref={sidebarRef}>
            <div className={styles.mainMenu}>
            <ul>
                {menuItems.map((menuItem, index) => (
                    <MenuItem
                        key={index}
                        name={menuItem.name}
                        id={menuItem.id}
                        to={menuItem.to}
                        exact={menuItem.exact}
                        subMenus={menuItem.subMenus || []}
                        icon={menuItem.icon}
                    />
                ))}
            </ul>
            </div>
        </div>
    )
}

export default Sidebar