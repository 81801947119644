import React    from 'react'
import TimePicker from 'react-time-picker';
import styles   from './timePick.module.scss';

/**
 * Text Input component.
 * @param {Number} flex           - Adjust width of the input. By default it is 1.
 * @param {String} placeholder    - Placeholder text to show in the input. By default it is ''.
 * @param {String} label          - Label to show with the input.
 * @param {String} type           - Type of input, text/password. By default it is text
 * @param {Boolean} required      - Shows required icon
 */


const TimePick = ({flex, placeholder, label, required, type, value, onBlur, onChange, maxWidth, isError, errorMessage, showError = true}) => {

  return (
    <div
        style={{ 
            flex : flex || 1,
            maxWidth : maxWidth || ''
         }} 
        className={styles.inputContainer}
    >
        {label && <label>{label} {required && <span className={styles.required}>*</span> }</label> }
        <input id="timeInput" onfocus="this.showPicker()" className= {isError ? 'error-outline' : ''} onChange={onChange} onBlur={onBlur} placeholder={placeholder || ''} type="time" value={value || ''} />
        {/* <TimePicker value={value} onChange={onChange} /> */}
        {showError && <p className={`errorMessage ${isError ? 'active' : ''}`}>{errorMessage || ''}&nbsp;</p> }
    </div>
    
  )
}

export default TimePick