import { useState, useEffect }  from "react";
import { useParams }            from 'react-router-dom';
import { showErrorToast, showSuccessToast }       from "functions/toasts";
import useApi                   from 'hooks/useApi';

const useNotifications = () => {

    const [notifOptions, setNotifOptions] = useState({
        tenant_notify_tenant_visitor_sign_in                : true,
        tenant_notify_tenant_visitor_sign_out               : false,
        tenant_notify_employee_visitor_sign_in              : false,
        tenant_notify_employee_visitor_sign_out             : false,
        tenant_notify_tenant_delivery_arrived               : true,
        tenant_notify_tenant_delivery_collected             : false,
        tenant_notify_employee_delivery_arrived             : false,
        tenant_notify_employee_delivery_collected           : false,
        tenant_notify_tenant_preregister_visitor            : true,
        tenant_notify_employee_preregister_visitor          : true,

        employee_notify_employee_visitor_sign_in            : true,
        employee_notify_employee_visitor_sign_out           : false,
        employee_notify_employee_delivery_arrived           : true,
        employee_notify_employee_delivery_collected         : false,
        employee_notify_employee_preregister_visitor        : true
    });

    const [followSiteNotifPolicy, setFollowSiteNotiffPolicy] = useState(false);

    const [visitorReceiverEmail, setVisitorReceiverEmail] = useState('');
    const [visitorReceiverEmails, setVisitorReceiverEmails] = useState([]);
    const [receiverEmailCount, setReceieverEmailCount] = useState(0);
    const [showSaveChanges, setShowSaveChanges] = useState(false);

    const [deliveryReceiverEmail, setDeliveryReceiverEmail] = useState('');
    const [deliveryReceiverEmails, setDeliveryReceiverEmails] = useState([]);
    const [loading, setLoading] = useState(true);

    const { get, post } = useApi();
    const { id, tenantId } = useParams();

    useEffect(() => {
        fetchNotificationData();
    }, []);

    // useEffect(() => {
    //     if(receiverEmailCount !== visitorReceiverEmails.count) {
    //         setShowSaveChanges(true);
    //     } else {
    //         setShowSaveChanges(false);
    //     }
    // },[visitorReceiverEmails]);

    const fetchNotificationData = async () => {
        setLoading(true);

        try {
            const response = await get(`tenants/${tenantId}`);
            const data = response.data.data.attributes;

            setFollowSiteNotiffPolicy(data.follow_site_notification_policy);

            if(data.visitor_notification_emails) {
                const visitorNotifEmailString = data.visitor_notification_emails.replace(/'/g, '"');
                const visitorNotifEmailArray = JSON.parse(visitorNotifEmailString);
                setReceieverEmailCount(visitorNotifEmailArray.length);
                setVisitorReceiverEmails(visitorNotifEmailArray);
            } else {
                setVisitorReceiverEmails([]);
            }

            if(data.delivery_notification_emails) {
                const deliveryNotifEmailString = data.delivery_notification_emails.replace(/'/g, '"');
                const deliveryNotifEmailArray = JSON.parse(deliveryNotifEmailString);
                setDeliveryReceiverEmails(deliveryNotifEmailArray);
            } else {
                setDeliveryReceiverEmails([]);
            }

            setNotifOptions({
                tenant_notify_tenant_visitor_sign_in             :   data.tenant_notify_tenant_visitor_sign_in,
                tenant_notify_tenant_visitor_sign_out            :   data.tenant_notify_tenant_visitor_sign_out,
                tenant_notify_employee_visitor_sign_in           :   data.tenant_notify_employee_visitor_sign_in,
                tenant_notify_employee_visitor_sign_out          :   data.tenant_notify_employee_visitor_sign_out,
                tenant_notify_tenant_delivery_arrived            :   data.tenant_notify_tenant_delivery_arrived,
                tenant_notify_tenant_delivery_collected          :   data.tenant_notify_tenant_delivery_collected,
                tenant_notify_employee_delivery_arrived          :   data.tenant_notify_employee_delivery_arrived,
                tenant_notify_employee_delivery_collected        :   data.tenant_notify_employee_delivery_collected,
                tenant_notify_tenant_preregister_visitor         :   data.tenant_notify_tenant_preregister_visitor,
                tenant_notify_employee_preregister_visitor       :   data.tenant_notify_employee_preregister_visitor,
                
                employee_notify_employee_visitor_sign_in         :   data.employee_notify_employee_visitor_sign_in,
                employee_notify_employee_visitor_sign_out        :   data.employee_notify_employee_visitor_sign_out,
                employee_notify_employee_delivery_arrived        :   data.employee_notify_employee_delivery_arrived,
                employee_notify_employee_delivery_collected      :   data.employee_notify_employee_delivery_collected,
                employee_notify_employee_preregister_visitor     :   data.employee_notify_employee_preregister_visitor,
            })

            console.log(data);

            setLoading(false);
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }

    const onStatusChange = async (event) => {
        let data = {};

        data[event] = notifOptions[event] ? 0 : 1;
        
        try {
            await post(`tenants/${tenantId}`, data);
            setNotifOptions(options => ({...options, [event] : !options[event]}));
            // await fetchNotificationData();
            showSuccessToast('Updated Successfully');
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }

    const onPolicyChange = async () => {
        let data ={
            follow_site_notification_policy : followSiteNotifPolicy ? 0 : 1
        };

        try {
            await post(`tenants/${tenantId}}`, data);
            setFollowSiteNotiffPolicy(!followSiteNotifPolicy);
            showSuccessToast('Notification Policy Updated');
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }

    const onSaveChanges = async () => {

        setLoading(true);

        console.log(visitorReceiverEmails, deliveryReceiverEmails)
        const visitorNotifEmailString = visitorReceiverEmails ? JSON.stringify(visitorReceiverEmails) : null;
        const deliveryNotifEmailString = deliveryReceiverEmails ? JSON.stringify(deliveryReceiverEmails) : null;


            try {
                await post(`tenants/${tenantId}`, {
                    // notify_tenant_sign_in : notifOptions.tenantVisitorSignIn ? 1 : 0,
                    // notify_tenant_sign_out : notifOptions.tenantVisitorSignOut ? 1 : 0,
                    // notify_employee_sign_in : notifOptions.employeeVisitorSignIn ? 1 : 0,
                    // notify_employee_sign_out : notifOptions.employeeVisitorSignOut ? 1 : 0,
                    // delivery_notify_tenant_sign_in : notifOptions.tenantDeliverySignIn ? 1 : 0,
                    // delivery_notify_tenant_sign_out : notifOptions.tenantDeliveryCollection ? 1 : 0,
                    // delivery_notify_employee_sign_in : notifOptions.employeeDeliverySignIn ? 1 : 0,
                    // delivery_notify_employee_sign_out : notifOptions.employeeDeliveryCollection ? 1 : 0,
                    visitor_notification_emails : visitorNotifEmailString,
                    // delivery_notification_emails : deliveryNotifEmailString,
                    // follow_site_notification_policy : followSiteNotifPolicy ? 1 : 0
                })
                
                await fetchNotificationData();
                showSuccessToast('Updated Successfully');

            } catch(err) {
                showErrorToast('Something Went Wrong');
            }
    }

    return {
        followSiteNotifPolicy, setFollowSiteNotiffPolicy,
        notifOptions, setNotifOptions,
        visitorReceiverEmail, setVisitorReceiverEmail,
        visitorReceiverEmails, setVisitorReceiverEmails,
        deliveryReceiverEmail, setDeliveryReceiverEmail,
        deliveryReceiverEmails, setDeliveryReceiverEmails,
        onSaveChanges,
        onStatusChange,
        onPolicyChange,
        showSaveChanges,
        loading, setLoading
    }
}

export default useNotifications;