import React    from 'react'
import Select   from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styles   from './creatableSelectInput.module.scss';

/**
 * SelectInput component.
 * @param {String} placeholder    - Placeholder text to show in the input. By default it is 'Select'.
 * @param {Array} options         - Array of objects to show as options.
 * @param {Number} flex           - To adjust the width of the input.
 * @param {Boolean} required      - Shows required Icon
 */

const CreatableSelectInput = ({label, menuIsOpen, onMenuOpen, onMenuClose, placeholder, options, onCreateOption, required, disabled = false, customStyles, flex, defaultValue, onChange, onBlur, multi, isError, errorMessage, showError = false}) => {
  return (
    <div
        style={{ 
            flex : flex || 1
         }} 
        className={styles.inputContainer}
    >
        {label && <label>{label} {required && <span className={styles.required}>*</span> }</label> }
        <CreatableSelect
          menuPlacement='auto'
          openMenuOnClick
          menuIsOpen={menuIsOpen}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          autoFocus
          isMulti={multi || false}
          onChange={onChange}
          isClearable
          closeMenuOnSelect
          onCreateOption={onCreateOption}
          onBlur={onBlur}
          isDisabled={disabled} 
          className={`${styles.customSelect} 
          ${isError ? 'error-outline' : ''}`} 
          placeholder={placeholder || 'Select'}  
          options={options} defaultValue={defaultValue}
          styles={customStyles ? customStyles : ''} 
        />
        {showError && <p className={`errorMessage ${isError ? 'active' : ''}`}>{errorMessage || ''}&nbsp;</p> }
    </div>
  )
}

export default CreatableSelectInput